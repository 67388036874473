<template>
    <div>
        <Header v-if="$store.getters.isEcosystem"></Header>
        <div v-if="$store.state.user.permissions.indexOf('custom-fields-manage') >= 0">
            <div v-if="$store.getters.isEnterprise" class="topbar topbar--full pt-4">
                <router-link to="/settings" class="link-back">All Settings
                </router-link>
                <h2 class="heading1 mb-0">
                    <template v-if="$store.getters.isEcosystem">Profile Fields</template>
                    <template v-else>Startups</template>
                </h2>
            </div>

            <div class="admin-page">
                <div class="admin-page__content main-inner">
                    <div class="tabs-primary-header">
                        <p class="d-flex mb-0">
                        </p>
                        <button @click="addSection" class="button">+ Add Section</button>
                    </div>

                    <div class="section-wrapper" v-for="section in sections" :key="section.id">
                        <SectionFields
                            :section="section"
                            type="company_diligence"
                            v-on:sectionSaved="updateSection"
                        >
                        </SectionFields>
                    </div>

                    <EditSection
                        ref="editSection"
                        v-on:sectionSaved="getSections"
                    >
                    </EditSection>

                    <div v-if="sections.length" class="link-line">
                        <button @click="addSection">+ Add Section</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import customFields from "@/services/custom-fields.service"
import SectionFields from "@/components/customFields/SectionIndex"
import EditSection from "@/components/customFields/EditSection";
import Header from "@/views/ecosystem/settings/Header.vue";

export default {
    components: {
        Header,
        SectionFields,
        EditSection,
    },

    data() {
        return {
            sections: [],
        }
    },

    mounted() {
        this.getSections()
    },

    methods: {
        getSections() {
            customFields.getIndexSections().then(response => {
                this.sections = response.data
            })
        },

        addSection() {
            this.$refs.editSection.open(0)
        },

        updateSection() {
            this.getSections()
        }
    }
}
</script>

<style scoped>

</style>
