import Resources from "@/views/Resources.vue";

export default [
    {
        path: '/resources',
        component: Resources,
        meta: {
            title: 'Resources'
        }
    }
]
