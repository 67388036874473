import axios from '@/axios';

const RESOURCE_NAME = '/trackers-reviews';

export default {

    save(trackerId, companyId, data) {
        return axios.post(`${RESOURCE_NAME}/save`, data, {
            params: {
                'tracker_id': trackerId,
                'company_id': companyId,
            }
        })
    },

    getEdit(trackerId, companyId) {
        return axios.get(`${RESOURCE_NAME}/get-edit`,
            {
                params: {
                    'tracker_id': trackerId,
                    'company_id': companyId,
                }
            })
    },

    delete(trackerId, companyId) {
        return axios.post(`${RESOURCE_NAME}/delete-review`, null, {
            params: {
                'tracker_id': trackerId,
                'company_id': companyId,
            }
        })
    },

    getReviews(trackerId, companyId) {
        return axios.get(`${RESOURCE_NAME}/get-reviews`, {
            params: {
                'tracker_id': trackerId,
                'company_id': companyId,
            }
        })
    },

    saveFiles(trackerId, companyId, fieldId, data) {
        return axios.post(`${RESOURCE_NAME}/save-files`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    'tracker_id': trackerId,
                    'company_id': companyId,
                    'field_id': fieldId,
                }
            })
    },

    getReviewsByField(trackerId, companyId, fieldId) {
        return axios.get(`${RESOURCE_NAME}/get-reviews-by-field`, {
            params: {
                'tracker_id': trackerId,
                'company_id': companyId,
                'field_id': fieldId,
            }
        })
    },

    updateReviewAnswer(trackerId, companyId, fieldId, value) {
        return axios.post(`${RESOURCE_NAME}/update-review-answer`,  value,{
            params: {
                'tracker_id': trackerId,
                'company_id': companyId,
                'field_id': fieldId,
            }
        })
    }
}
