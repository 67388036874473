<template>
    <form class="form pb-5">
        <div class="d-flex align-items-center mb-1">
            <div class="image-block image-block--rounded--lg mb-4">
                <img v-if="this.form.logo" :src="this.form.logo" alt="img">
            </div>
            <div>
                <a class="btn btn-bordered" @click="showLogoUpload = true">Upload Logo</a>
                <p class="modal-text m-0">Image size: 200 X 200 pixels, max file size 5 mb</p>
            </div>
        </div>

        <image-upload
            v-model="showLogoUpload"
            :width="200"
            :height="200"
            :noSquare="true"
            langType="en"
            @crop-success="logoCropped"
        >
        </image-upload>

        <b-row>
            <b-col cols="12" sm="6">
                <div class="form__item">
                    <h5 class="heading5 heading5--secondary">Ecosystem Type</h5>
                    <span class="form__error" v-if="errors.type.length">{{errors.type}}</span>
                    <select v-model="form.type" class="selectbox selectbox-mid w-100">
                        <option v-for="(name, value) in constants.ecosystemTypes" :key="value" :value="value">
                            {{name}}
                        </option>
                    </select>
                </div>
            </b-col>

            <b-col cols="12" sm="6">
                <div class="form__item">
                    <h5 class="heading5 heading5--secondary">Country</h5>
                    <span class="form__error" v-if="errors.country.length">{{errors.country}}</span>
                    <multiselect
                        v-model="country"
                        placeholder="Select Country"
                        :options="countriesList"
                        select-label=""
                        deselect-label=""
                        label="name"
                        track-by="name"
                        class="multiselect-mid pl-2"
                    >
                    </multiselect>
                </div>
            </b-col>

            <b-col cols="12" sm="6" v-if="form.country_id == 1">
                <div class="form__item">
                    <h5 class="heading5 heading5--secondary">State*</h5>

                    <span class="form__error" v-if="errors.state.length">{{errors.state}}</span>
                    <multiselect
                        v-model="state"
                        placeholder="Select State"
                        :options="statesList"
                        select-label=""
                        deselect-label=""
                        label="name"
                        track-by="name"
                        class="multiselect-mid pl-2"
                    >
                    </multiselect>
                </div>
            </b-col>

            <b-col cols="12" sm="6">
                <div class="form__item">
                    <h5 class="heading5 heading5--secondary">City*</h5>

                    <span class="form__error" v-if="errors.city.length">{{errors.city}}</span>
                    <input class="form-control--mid form-control" type="text" v-model="form.city">
                </div>
            </b-col>

            <b-col cols="12">
                <div class="form__item">
                    <h5 class="heading5 heading5--secondary">Description*</h5>
                    <span class="form__error" v-if="errors.description.length">{{errors.description}}</span>

                    <textarea class="form-control--mid form-control" v-model="form.description">

                    </textarea>
                </div>
            </b-col>

            <b-col cols="12">
                <div class="form__item">
                    <h5 class="heading5 heading5--secondary">Categories*</h5>
                    <span class="form__error" v-if="errors.categories.length">{{errors.categories}}</span>
                    <div class="form__item">
                        <multiselect
                            v-model="form.categories"
                            :multiple="true"
                            placeholder="Select Categories"
                            :options="$store.state.categories"
                            select-label=""
                            deselect-label=""
                            label="name"
                            track-by="name"
                            class="multiselect-mid pl-2"
                        >
                        </multiselect>
                    </div>
                </div>
            </b-col>

            <b-col cols="12">
                <h5 class="heading5 heading5--secondary">Startup Portfolio</h5>
                
                <b-row class="mb-4">
                    <b-col cols="12" sm="6">
                        <div class="form__item mb-0">
        
                            <span class="form__error" v-if="errors.portfolio.length">{{errors.portfolio}}</span>
                            <input class="form-control--mid form-control" type="text" v-model="form.portfolio_url" placeholder="Enter URL">
                        </div>
                    </b-col>
                    <b-col cols="12" sm="1" class="pt-2 pb-2 text-center">
                        or
                    </b-col>
                    <b-col cols="12" sm="5" class="text-center text-sm-left">
                        <span class="form__error" v-if="errors.portfolioFile.length">{{errors.portfolioFile}}</span>
                        <button type="button" class="btn btn-bordered button--add mx-auto" @click="$bvModal.show('upload-portfolio-form')">Upload CSV</button>
                        <p v-if="!form.portfolioFile" class="modal-text mt-3">Use <a :href="baseURL + '/files/add_startup.csv'" target="_blank">this file</a> for best results</p>
                    </b-col>
                </b-row>

                <template v-if="hasCrunchStatus">
                    or <label><input type="checkbox" v-model="form.sync_related_startups"> Sync related startups from Crunchbase</label>
                </template>
            </b-col>
        </b-row>
        <div class="text-center">
            <b-button variant="primary" size="lg" :disabled="!canSave" @click="save">
                <span v-if="!loading">Save Profile</span>
                <span v-else><b-spinner class="mr-1" small></b-spinner>Loading...</span>
            </b-button>
        </div>

        <b-modal id="upload-portfolio-form" :hide-footer="true" modal-class="modal-w-md" title="Upload Portfolio">
            <div class="form">

                <p v-if="!form.file" class="modal-text mt-3">Download and use <a :href="baseURL + '/files/add_startup.csv'" target="_blank">this file</a> for best results</p>
                <div class="input-file mb-3">
                    <template v-if="!form.portfolioFile">
                        <label for="input-file__upload" class="mr-2">Choose File</label>
                        <input type="file" ref="portfolioFile" id="input-file__upload" accept=".csv" v-on:change="handlePortfolioFileUpload()">
                    </template>

                    <div v-else class="tags-list tags-list--light">
                        <span class="tags-list__tag mt-3">
                        {{form.portfolioFile.name}}
                        <a @click="form.portfolioFile = null" class="tags-list__tag__close"></a>
                        </span>
                    </div>
                </div>
            </div>
        </b-modal>
    </form>
</template>

<script>
import locations from "@/services/locations.service"
import profile from "@/services/ecosystem/profile.service"
import multiselect from "vue-multiselect";
import imageUpload from "vue-image-crop-upload";
import { baseURL } from "@/axios";

export default {
    components: {
        multiselect,
        imageUpload,
    },

    data() {
        return {
            form: {
                type: '',
                country_id: null,
                state_id: null,
                city: "",
                description: "",
                categories: [],
                logo: "",
                portfolio_url: "",
                portfolioFile: null,
                sync_related_startups: 0,
            },

            showLogoUpload: false,
            logoChanged: false,
            loading: false,

            country: null,
            state: null,
            countriesList: [],
            statesList: [],
            hasCrunchStatus: false,

            errors: {
                type: "",
                country: "",
                state: "",
                city: "",
                description: "",
                categories: "",
                logo: "",
                portfolio: "",
                portfolioFile: "",
            },
            baseURL
        }
    },

    computed: {
        canSave() {
            if (this.loading) {
                return false
            }
            else {
                return true
            }
        }
    },

    watch: {
        country() {
            if (this.country) {
                this.form.country_id = this.country.id
            }
            else {
                this.form.country_id = 0
            }
        },

        state() {
            if (this.state) {
                this.form.state_id = this.state.id
            }
            else {
                this.form.state_id = 0
            }
        },
    },

    mounted() {
        locations.getCountriesList().then(response => {
            this.countriesList = response.data
            this.setCountry()
        })

        locations.getStatesList().then(response => {
            this.statesList = response.data
            this.setState()
        })

        this.getInfo()
    },

    methods: {
        getInfo() {
            profile.getInfo().then(response => {

                this.form.type = response.data.type

                this.form.country_id = response.data.country_id
                this.setCountry()

                this.form.state_id = response.data.state_id
                this.setState()

                this.form.city = response.data.city
                this.form.description = response.data.description
                this.form.categories = response.data.categories
                this.form.logo = response.data.logo
                this.form.portfolio_url = response.data.portfolio_url
                this.form.sync_related_startups = response.data.sync_related_startups
                this.hasCrunchStatus = (response.data.crunch_status == 1)
            })
        },

        setCountry() {
            if (!this.country && this.form.country_id) {
                this.countriesList.forEach(item => {
                    if (item.id == this.form.country_id) {
                        this.country = item
                        return
                    }
                })
            }
        },

        setState() {
            if (!this.state && this.form.state_id) {
                this.statesList.forEach(item => {
                    if (item.id == this.form.state_id) {
                        this.state = item
                        return
                    }
                })
            }
        },

        save() {
            this.loading = true
            this.form.updated_categories = this.arrayHelper.arrayColumn(this.form.categories, 'id')

            let formData = new FormData();

            for (let i in this.form) {
                if (i !== 'logo' && this.form[i]) {
                    if (i !== 'sync_related_startups') {
                        formData.append(i, this.form[i])
                    }
                    else {
                        formData.append(i, this.form[i] ? 1 : 0)
                    }
                }
            }

            if (this.logoChanged) {
                formData.append('newLogo', this.form.logo)
            }

            if (this.form.portfolioFile) {
                formData.append('portfolioFile', this.form.portfolioFile)
            }

            profile.saveInfo(formData).then((response) => {
                this.clearErrors()

                for (let attr in response.data.errors) {
                    this.errors[attr] = response.data.errors[attr]
                }

                if (!response.data.has_errors) {
                    this.$emit('saved')

                    if (this.form.portfolioFile) {
                        this.$store.commit('setNeedUpdateStartups', true)

                        this.$nextTick(() => {
                            this.$store.commit('setNeedUpdateStartups', false)
                        })
                    }

                    if (typeof response.data.go_to_settings != 'undefined') {
                        this.$router.push('/')
                    }

                    this.form.portfolioFile = null
                }
            }).finally(() => {
                this.loading = false
            })
        },

        clearErrors() {
            for (let i in this.errors) {
                this.errors[i] = ""
            }
        },

        logoCropped(imageDataUrl) {
            this.form.logo = imageDataUrl
            this.showAvatarUpload = false
            this.logoChanged = true
        },

        handlePortfolioFileUpload() {
            if (this.$refs['portfolioFile'].files.length) {
                this.form.portfolioFile = this.$refs['portfolioFile'].files[0]
                this.$bvModal.hide('upload-portfolio-form')
            }
        },
    }
}
</script>

<style scoped>

</style>
