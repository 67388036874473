<template>
    <b-modal title="Add categories" id="select-categories-modal" size="sm">
        <multiselect
            v-model="categories"
            :multiple="true"
            :options="actualCategoriesList"
            select-label=""
            deselect-label=""
            placeholder="Type to search categories"
            label="name"
            track-by="name"
            class="multiselect-mid selectbox selectbox-mid"
        >
        </multiselect>

        <template #modal-footer>
            <b-button :disabled="!categories.length" variant="primary" size="lg" @click="add">Add</b-button>
        </template>
    </b-modal>
</template>

<script>

import multiselect from "vue-multiselect";

export default {
    props: {
        excludeCategories: Array
    },

    components: {
        multiselect,
    },

    data() {
        return {
            categories: [],
        }
    },

    computed: {
        actualCategoriesList() {
            let array = []

            this.$store.state.categories.forEach(item => {
                if (this.excludeCategories.indexOf(item.id) < 0) {
                    array.push(item)
                }
            })

            return array
        }
    },

    methods: {
        open() {
            this.categories = []
            this.$bvModal.show('select-categories-modal')
        },

        add() {
            this.$bvModal.hide('select-categories-modal')
            this.$emit('categoriesSelected', this.categories)
            this.trackers = []
        },
    }
}
</script>
