import { render, staticRenderFns } from "./StartupCardPopup.vue?vue&type=template&id=200ac789&scoped=true&"
import script from "./StartupCardPopup.vue?vue&type=script&lang=js&"
export * from "./StartupCardPopup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "200ac789",
  null
  
)

export default component.exports