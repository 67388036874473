<template>
    <div>
        <div v-for="(section, sectionIndex) in sections" :key="sectionIndex">
            <h5 class="heading5 heading5--secondary">{{ section.name }}</h5>

            <a @click="() => selectAll(sectionIndex)" class="link">Select All</a>
            | <a @click="() => unselectAll(sectionIndex)" class="link">Unselect All</a>
            <div class="your-custom-fields__checkbox">
                <div v-for="(field, index) in section.fields" :key="field.id">

                <span class="input-checkbox">
                    <input :id="'field-' + field.id" v-model="sections[sectionIndex].fields[index].checked" type="checkbox" @click="() => {
                        sections[sectionIndex].fields[index].checked = !sections[sectionIndex].fields[index].checked
                    }">

                    <label :for="'field-' + field.id">{{ field.name }}</label>
                </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import customFieldsService from "@/services/custom-fields.service";

export default {
    props: {
        fieldsType: String
    },

    data() {
        return {
            sections: [],
        }
    },

    mounted() {
        this.getList();
    },

    watch: {
        sections: {
            deep: true,
            handler() {
                this.$emit('fields-selected', this.sections.map(section => section.fields.filter(field => field.checked).map(field => field.id)).flat(1))
            },
        }
    },

    methods: {
        getList() {
            if (this.fieldsType === 'company_diligence') {
                customFieldsService.getFieldsWithSections(this.fieldsType, 1).then(response => {
                    let sections = response.data

                    for (let sectionId in sections) {
                        this.sections.push({
                            name: sections[sectionId].name,
                            fields: sections[sectionId].fields.map(field => ({
                                id: field.id,
                                name: field.name,
                                checked: false,
                            })),
                        });
                    }
                })
            } else {
                customFieldsService.getFieldsArray(this.fieldsType, 1).then(response => {

                    this.sections.push({
                        name: "",
                        fields: response.data.map(field => ({
                            id: field.id,
                            name: field.name,
                            checked: false,
                        })),
                    });
                })
            }
        },

        selectAll(sectionIndex) {
            this.sections[sectionIndex].fields = this.sections[sectionIndex].fields.map(item => ({
                ...item,
                checked: true,
            }));
        },

        unselectAll(sectionIndex) {
            this.sections[sectionIndex].fields = this.sections[sectionIndex].fields.map(item => ({
                ...item,
                checked: false,
            }));
        }
    }
}
</script>

<style scoped>
    .your-custom-fields__checkbox {
        display: flex;
        flex-wrap: wrap;
        padding-top: 16px;
    }

    .your-custom-fields__checkbox > div {
        margin-bottom: 6px;
        flex: 0 0 calc(30% - 15px);
    }
</style>
