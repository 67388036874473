<template>
    <div>
        <Header></Header>

        <div class="topbar">
            <div class="topbar__inner">
                <div class="topbar__key">
                    <div class="form-search-primary-wrapper">
                        <input
                            v-model="searchString"
                            class="form-search-primary"
                            placeholder="Search by Name or Description"
                            v-debounce:500ms.lock="getList">

                        <header class="topbar__key__header">
                            Showing
                            <select v-if="totalRowsCount >= 25" class="topbar__key__select" v-model="pageLength">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </select>
                            <template v-else>{{totalRowsCount}}</template>
                            of {{totalRowsCount}}
                            ecosystems
                        </header>
                    </div>

                    <footer class="topbar__key__footer">
                        <ul class="topbar__side actions-list">
                            <template v-if="selectedRows.length">
                                <li class="actions-list__remove"><a @click="removeEcosystems"><font-awesome-icon :icon="['fa', 'trash']" />Remove Ecosystems</a></li>
                            </template>
                            <template v-else>
                                <li class="actions-list__add"><a @click="addEcosystems">Add Ecosystems</a></li>
                            </template>
                        </ul>
                    </footer>
                </div>
            </div>
        </div>

        <div class="main-table-wrapper overflow-auto">
            <div v-if="ecosystems.length" class="main-table">
                <Grid
                    :has-checkbox-column="true"
                    :columns="columns"
                    :data="ecosystems"
                    :addCellData="addCellData"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    v-on:changePage="changePage"
                    v-on:changeSort="changeSort"
                    v-on:selectedRowsChanged="changeSelectedRows"
                    v-on:selectAllResults="selectAllResults"
                >
                </Grid>
            </div>

            <div v-else-if="dataLoaded" class="not-found">
                <h2 class="heading2 heading2--ecosystems">No ecosystems</h2>
            </div>
        </div>
    </div>
</template>

<script>
import grid from "@/mixins/grid";
import ecosystems from "@/services/ecosystems.service";
import Grid from "@/components/Grid";
import Header from "@/components/ecosystems/Header";
import ecosystemsService from "@/services/ecosystems.service";

export default {
    mixins: [grid],

    components: {
        Grid,
        Header,
    },

    data() {
        return {
            ecosystems: [],

            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    component: 'SearchCompanyNameColumn',
                },
                {
                    label: 'Status',
                    name: 'status',
                    component: 'RawColumn',
                },
                {
                    label: 'Contact',
                    name: 'contact',
                },
                {
                    label: 'Portfolios',
                    name: 'portfolios',
                    component: 'RouterLinkColumn'
                },
            ],

            searchString: "",
            dataLoaded: false,

            addCellData: {
                companyType: "ecosystems",
            },
        }
    },

    mounted() {
        this.getList()

        this.$store.watch(state => {
            if (state.needUpdateEcosystems) {
                this.getList()
            }
        })
    },

    provide() {
        return {
            updateIsFavorite: async (id, isFavorited) => {
                await ecosystemsService.setFavorite(id, isFavorited);
                this.ecosystems.find(ecosystem => ecosystem.id === id).name.is_favorited = isFavorited;
            }
        }
    },

    methods: {
        getList() {
            this.dataLoaded = false;
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let sort = this.sort
            let searchString = this.searchString;

            ecosystems.getConnectedEcosystems({columns, sort, currentPage, pageLength, searchString}).then(response => {
                this.ecosystems = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
                this.dataLoaded = true;
            })
        },

        changeSelectedRows(ids) {
            this.selectedRows = ids
        },

        selectAllResults(result) {
            this.selectedAllResults = result
        },

        removeEcosystems() {
            if (this.selectedAllResults) {
                if (confirm(this.totalRowsCount + " " + this.stringHelper.getNoun('ecosystem', this.totalRowsCount) + " will be removed. Do you want to proceed?")) {
                    ecosystems.removeAllConnections().then(() => {
                        this.getList()
                    })
                }
            }
            else {
                if (confirm(this.selectedRows.length + " " + this.stringHelper.getNoun('ecosystem', this.selectedRows.length) + " will be removed. Do you want to proceed?")) {
                    ecosystems.removeConnections(this.selectedRows).then(() => {
                        this.getList()
                    })
                }
            }
        },

        addEcosystems() {
            this.$store.commit('setAddEcosystem', true);
            this.$nextTick(() => {
                this.$store.commit('setAddEcosystem', false);
            })
        }
    }
}
</script>

<style scoped>

</style>
