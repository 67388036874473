<template>
    <main class="enter-page">
        <header class="header">
            <span class="logo"></span>
        </header>

        <template v-if="sent">
            <div class="form form--enter">
                <div class="form--enter__container mt-2">
                    <div class="form--enter__fieldset">
                        <p>We have sent you an email to {{ email }} with a link to reset your password.</p>
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
            <form class="form form--enter form--reset" @submit.prevent="send">

                <header class="form--enter__header justify-content-start mb-4">
                    <router-link class="link-back align-self-center mb-0" :to="returnPage"></router-link>
                    <h1 class="heading1">Reset Password</h1>
                </header>

                <div class="form--enter__container">
                    <fieldset class="form--enter__fieldset">
                        <div v-for="error in errors" :key="error">
                            <span class="form__error">{{error}}</span>
                        </div>

                        <div class="form__item">
                            <label class="form__label">Email</label>
                            <input class="form__input" required v-model="email" type="text"/>
                        </div>
                        
                        <p>An email will be sent to verify your identity with a link to reset your password</p>

                        <div class="text-center">
                            <button class="button" type="submit" :disabled="!canSend">Reset</button>
                        </div>
                    </fieldset>
                </div>
            </form>
        </template>
    </main>
</template>

<script>

import auth from '../../services/auth.service';

export default {

    data () {
        return {
            email: "",
            errors: [],

            sent: false,
        }
    },

    computed: {
        canSend() {
            return this.email.length && this.stringHelper.validEmail(this.email)
        },

        returnPage() {
            if (this.$route.query.tracker_id) {
                return "/public-trackers/" + this.$route.query.tracker_id
            }
            else {
                return "/"
            }
        }
    },

    methods: {
        send() {
            auth.resetPassword(this.email)
                .then(response => {
                    this.errors = response.data.errors
                    if (!this.errors.length) {
                        this.sent = true
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>