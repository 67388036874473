<template>
    <li class="tags-list__tag" v-click-outside-with-exclusion="{exclude: ['currentValue'], handler: 'clickedOutside'}">
        <template v-if="!isNewSegment">
            <div v-show="!isEditing" ref="currentValue">
            <span style="cursor: pointer" @click.prevent="editValue">
                {{ currentValue.name }}
            </span>
                <a v-if="canBeDeleted" class="tags-list__tag__close" @click.prevent="removeSegment"></a>
            </div>

            <multiselect
                ref="multiselect"
                v-show="isEditing"
                v-model="currentValue"
                :multiple="false"
                track-by="value"
                label="name"
                placeholder="Select option"
                :options="options"
                select-label=""
                deselect-label=""
                class="multiselect-mid pl-2"
                @select="segmentChanged"
                @search-change="updateOptions"
            />
        </template>

        <template v-else>
            <multiselect
                ref="multiselect"
                v-model="currentValue"
                :multiple="false"
                track-by="value"
                label="name"
                placeholder="Select option"
                :options="options"
                select-label=""
                deselect-label=""
                class="multiselect-mid pl-2"
                @close="selectionClosed"
                @search-change="updateOptions"
            >
                <template v-if="type === 'client'" slot="noOptions">
                    <div>
                        Start typing...
                    </div>
                </template>
            </multiselect>
        </template>
    </li>
</template>

<script>
const TYPE_CLIENT = 'client';
import multiselect from 'vue-multiselect'
import clientsService from "@/services/clients.service";

export default {
    name: 'SettingsSegment',
    components: {
        multiselect,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        checkedValues: {
            type: Array,
            required: true,
        },
        staticOptions: {
            type: Array,
            required: true,
        },
        canBeDeleted: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            currentValue: {name: '', value: ''},
            prevValue: {name: '', value: ''},
            isEditing: false,
            dynamicOptions: [],
        }
    },

    mounted() {
        if (this.isNewSegment) {
            this.$refs.multiselect.activate()
        }
    },

    computed: {
        isNewSegment() {
            return !this.currentValue?.value
        },

        options() {
            if (this.type === TYPE_CLIENT && this.dynamicOptions.length) {
                return this.dynamicOptions;
            } else {
                return this.staticOptions;
            }
        }
    },

    watch: {
        value: {
            handler(newValue) {
                this.currentValue = newValue
                this.prevValue = newValue
            },
            immediate: true
        },
    },

    methods: {
        editValue() {
            this.isEditing = true
            this.$refs.multiselect.activate()
        },

        clickedOutside() {
            this.isEditing = false
        },

        removeSegment() {
            this.$emit('removeSegment', this.type, this.value)
        },

        selectionClosed() {
            this.$emit('selectionClosed', this.type, this.currentValue)
        },

        segmentChanged() {
            this.$emit('segmentChanged', this.type, this.currentValue, this.prevValue)
        },

        updateOptions(query) {
            if (query.length && this.type === TYPE_CLIENT) {
                clientsService.getListForSearch(query).then(response => {
                    this.dynamicOptions = response.data
                        .filter(item => !this.checkedValues.find(value => value === item.name))
                        .map(item => ({
                            name: item.name,
                            value: item.name,
                        }))
                })
            }
        },
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
