<template>
    <b-modal id="ecosystem-add-promo-code-modal" title="Add Promo Code" modal-class="modal-w-md">

        <div v-if="applied">
            {{codeInfo}}
        </div>

        <form class="form" v-else>
            <div class="form__item">
                <h6 class="heading6 mb-1">Promo Code</h6>
                <b-form-input class="form-control--mid mb-3" v-model="code" placeholder="Enter your code"></b-form-input>

                <template v-if="typeof errors.promo_code !== 'undefined'">
                    <div class="form__error" v-for="error in errors.promo_code" :key="error">{{error}}</div>
                </template>
            </div>
        </form>

        <template #modal-footer>
            <b-button variant="primary" size="lg" @click="handleSubmit">Submit</b-button>
        </template>
    </b-modal>
</template>

<script>
import registrationService from "../../../services/registration.service";

export default ({
    data() {
        return {
            code: '',
            errors: {},
            applied: false,
            codeInfo: ''
        };
    },
    name: 'AddPromoCode',
    methods: {
        init() {
            this.code = ''
            this.errors = {};
        },
        open() {
            this.init();
            this.$bvModal.show('ecosystem-add-promo-code-modal');
        },
        close() {
            this.$bvModal.hide('ecosystem-add-promo-code-modal');
        },
        async handleSubmit() {
            const response = await registrationService.checkPromoCode(this.code);
            
            if (Object.keys(response.data.errors).length > 0) {
                this.errors = response.data.errors;
            } else if (response.data.duration) {
                this.$emit('changeDuration', {
                    duration: response.data.duration,
                    promo_code: this.code
                });
                this.close()
            } else {
                console.error({ response });
                this.close();
            }
        }
    }
})
</script>
