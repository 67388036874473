<template>
    <multiselect
        class="multiselect--like-dropdown"
        v-model="trackers"
        :options="options"
        :multiple="true"
        group-values="trackers"
        group-label="folder"
        :group-select="true"
        placeholder="Type to search"
        track-by="id"
        label="name"
        selectLabel=""
        selectGroupLabel=""
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true">

        <span slot="noResult">No trackers found.</span>

        <template slot="selection" slot-scope="{ values }">
            <span class="multiselect__single" v-if="values.length ">
                Selected {{ values.length }} {{ values.length === 1 ? ' Tracker' : ' Trackers' }}
            </span>
        </template>

        <template slot="placeholder">
            All Trackers
        </template>

    </multiselect>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import multiselect from "vue-multiselect";

export default {
    components: {
        multiselect
    },

    data() {
        return {
            trackers: [],
        }
    },

    watch: {
        trackers() {
            this.$emit('changed', this.trackers.map(item =>item.id))
        }
    },

    mounted() {
        this.fetchFoldersTrackers()
    },

    computed: {
        ...mapGetters({
            foldersTrackersForFilter: 'foldersTrackersList',
        }),

        options() {
            let trackersGroups = []
            this.foldersTrackersForFilter.forEach(item => {
                let option = {}

                option.folder = item[0]
                option.trackers = item[1]

                trackersGroups.push(option)
            })

            return trackersGroups
        },
    },
    methods: {
        ...mapActions(['fetchFoldersTrackers']),

        setTrackers(trackersIds) {
            this.trackers = this.options
                .map(group => group.trackers).flat(1)
                .filter(tracker => trackersIds.some(trackerId => trackerId === tracker.id))
        },

        unselectTracker(trackerId) {
            let index = this.arrayHelper.findIndexByColumnValue(this.trackers, 'id', trackerId)

            if (index >= 0) {
                this.trackers.splice(index, 1)
            }
        },

        clearTrackers() {
            this.trackers = []
        }
    }
}
</script>

<style scoped>

</style>
