<template>
    <b-modal id="modal-upgrade" footer-hide title="" modal-class="modal-w-md modal-edit-tracker modal-edit-tracker--sub">
        <div class="modal-sub">
            <div class="modal-sub__inner">
                <h1 class="heading1">Upgrade To Premium</h1>

                <div class="form__item mb-3">
                    <h5 class="heading5 heading5--secondary">Selected Plan</h5>

                    <select v-model="subscriptionPlan" class="selectbox selectbox-mid w-100">
                        <option v-for="subscriptionPlan in this.subscriptionTypes" :key="subscriptionPlan.value" :value="subscriptionPlan.value">
                            {{subscriptionPlan.name}}
                        </option>
                    </select>

                    <span v-if="typeof errors.subscription_plan !== 'undefined'">{{errors.subscription_plan}}</span>
                </div>

                Payment
                <div v-if="loadingPaymentMethods">
                    <span><b-spinner class="mr-1" small></b-spinner>Loading...</span>
                </div>

                <div v-for="method in this.paymentMethods" :key="method.id">
                    Card ending in {{method.last4}}
                </div>

                <Payment
                    ref="payment"
                    v-show="showPaymentForm"
                    v-on:paymentMethodCreated="paymentMethodAdded"
                    v-on:start-loading="loading = true"
                    v-on:stop-loading="loading = false"
                >
                </Payment>

                <footer class="modal-upgrade__footer">
                    <button v-if="!loading" class="button" @click="apply" :disabled="!paymentMethodId && !showPaymentForm">Upgrade Account</button>
                    <span v-else><b-spinner class="mr-1" small></b-spinner>Subscription in progress...</span>
                </footer>
            </div>
        </div>

    </b-modal>
</template>

<script>

import Payment from "@/components/Payment";
import subscriptions from "@/services/subscriptions.service";
import Vue from "vue";
import user from "@/services/user.service";

export default {
    components: {
        Payment,
    },

    data() {
        return {
            accountType: '',
            subscriptionPlan: 'monthly',
            paymentMethods: [],
            paymentMethodId: 0,
            loading: false,
            showPaymentForm: false,
            loadingPaymentMethods: false,
            errors: {},
        }
    },

    watch: {
        showPaymentForm() {
            if (this.showPaymentForm) {
                this.$refs.payment.createForm()
            }
        }
    },

    computed: {
        subscriptionTypes() {
            let types = []

            if (this.$store.state.accountType != 'premium') {
                types.push(
                    {
                        'value': 'monthly',
                        'name': 'Premium Access - $399 billed monthly',
                    },
                    {
                        'value': 'premium',
                        'name': 'Premium Access - $3999 billed annually',
                    }
                )
            }
            return types
        },
    },

    mounted() {
        this.$store.watch(state => {
            if (state.showUpgrade) {
                this.accountType = state.showUpgrade
                this.open()
            }
        })

    },

    methods: {
        open() {
            this.errors = {}
            this.$bvModal.show('modal-upgrade')
            this.getPaymentMethods()
        },

        getPaymentMethods() {
            this.loadingPaymentMethods = true

            subscriptions.getPaymentMethods().then(response => {
                this.loadingPaymentMethods = false
                this.paymentMethods = response.data

                if (this.paymentMethods.length && !this.paymentMethodId) {
                    this.paymentMethodId = this.paymentMethods[0].id
                }
                else {
                    this.showPaymentForm = true
                }
            })
        },

        apply() {
            if (this.showPaymentForm) {
                this.$refs.payment.apply()
            }
            else {
                this.createSubscription()
            }
        },

        paymentMethodAdded(methodId) {
            this.loading = false
            this.showPaymentForm = false
            this.paymentMethodId = methodId

            this.createSubscription()
        },

        createSubscription() {
            this.loading = true

            subscriptions.createSubscription({subscription_plan: this.subscriptionPlan, payment_method_id: this.paymentMethodId}).then(response => {

                this.errors = response.data.errors
                let subscriptionData = response.data

                if (response.data.result == 'success') {

                    let prevApproveStatus = this.$store.state.approveStatus

                    this.$store.commit('setNeedUpdatePaymentData', true)
                    this.$nextTick(() => {
                        this.$store.commit('setNeedUpdatePaymentData', false)
                    })

                    user.getCurrentUserData().then(response => {
                        this.$store.commit('setUser', response.data)

                        Vue.notify({
                            group: 'app',
                            type: 'success',
                            duration: 10000,
                            text: "Your account has successfully been updated to " + subscriptionData.account
                        })

                        this.$bvModal.hide('modal-upgrade')
                        this.loading = false

                        if (prevApproveStatus != 'approved' && this.$store.state.approveStatus == 'approved') {
                            this.$router.push('/')
                        }
                    })
                }
                else {
                    this.loading = false
                }
            })
        }
    }
}
</script>
