import Signup from '@/views/registration/Signup'
import Confirm from '@/views/registration/Confirm'
import Ecosystem from '@/views/registration/Ecosystem'

export default [
    {
        path: '/signup',
        component: Signup,
        meta: {
            guest: true,
            title: 'Signup',
            onlyGuest: true,
        }
    },
    {
        path: '/signup/ecosystem',
        component: Ecosystem,
        meta: {
            guest: true,
            title: 'Signup - Ecosystem',
            onlyGuest: true
        }
    },
    {
        path: '/confirm-account/:activationCode',
        component: Confirm,
        meta: {
            guest: true,
            onlyGuest: true,
        }
    },
]
