<template>
    <div>
        <div class="topbar topbar--full pt-4">
            <router-link to="/settings" class="link-back">All Settings</router-link>
            <h2 class="heading1 mb-0">Email Templates</h2>
        </div>

        <div class="admin-page">
            <div class="admin-page__content main-inner">
                <div v-for="(template, index) in templates" :key="index">
                    <header class="card__header align-items-center justify-content-between">
                        <h4 class="heading4">{{ template.title }}</h4>
                    </header>
    
                    <p>{{template.description}}</p>
                    
                    <div class="card card--bordered-slim w-75">
                        <dl class="definition-list definition-list--email">
                            <dt>Subject:</dt>
                            <dd>{{template.subject}}</dd>
                            
                            <dt>Message:</dt>
                            <dd>
                                <div v-html="template.body"></div>
                            </dd>
                        </dl>
                        
                        <a class="link link-edit link-edit--blue card__link-edit" @click="editTemplate(template.type)">Edit</a>
                    </div>
                </div>
            </div>
        </div>

        <EditEmailTemplate
            ref="editEmailTemplate"
            v-on:saved="saveTemplate"
        >
        </EditEmailTemplate>
    </div>
</template>

<script>
import companyService from "@/services/company.service";
import EditEmailTemplate from "@/components/settings/EditEmailTemplate";

export default {
    components: {
        EditEmailTemplate
    },

    data() {
        return {
            templates: [],
            currentType: '',
        }
    },

    mounted() {
        this.getTemplates()
    },

    methods: {
        getTemplates() {
            companyService.getEmailTemplates().then(result => {
                this.templates = result.data
            })
        },

        editTemplate(type) {
            companyService.getEmailTemplateEdit(type).then(response => {
                this.currentType = type
                this.$refs.editEmailTemplate.open(response.data.subject, response.data.body)
            })
        },

        saveTemplate(template) {
            companyService.saveEmailTemplate(this.currentType, template).then(() => {
                this.getTemplates()
            })
        }
    },
}
</script>

<style scoped>

</style>
