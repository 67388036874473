import axios from '@/axios';

const RESOURCE_NAME = '/invite';

export default {

    addSingleStartup(data) {
        return axios.post(`${RESOURCE_NAME}/add-single-startup`, data)
    },

    addMultipleStartups(data) {
        return axios.post(`${RESOURCE_NAME}/add-multiple-startups`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
    },

    inviteStartup(id, data) {
        return axios.post(`${RESOURCE_NAME}/invite-startup?id=${id}`, data)
    },
}
