<template>
    <tr>
        <td class="main-table__check">
            <div class="input-checkbox">
                <input :id="'t' + startup.id" type="checkbox" v-model="mark">
                <label :for="'t' + startup.id"></label>
            </div>
        </td>
        <td>
            <div class="main-table__data">
                <div class="image-block">
                    <img :src="LogoHelper.getLogo(startup.image)" alt="">
                </div>
                <div class="pr-3">
                    <h5 class="heading5">
                        <a :href="'/startups/' + startup.id" target="_blank">{{startup.name}}</a>
                    </h5>
                    <div>
                        <span class="line-clamp">{{startup.description}}</span>

                    </div>
                </div>

                <AddToTracker
                    v-if="$store.getters.isEnterprise"
                    :startupId="startup.id"
                    :trackersList="trackersList"
                    :trackersCount="startup.trackersCount"
                    :selectedTrackerId="selectedTrackerId"
                    v-on="$listeners"
                />
            </div>
        </td>
        <td>{{scoreLabel(startup.score)}}</td>
        <td>{{startup.categories}}</td>
    </tr>
</template>

<script>
    import AddToTracker from '@/components/startups/AddToTracker.vue';

    export default {
        props: {
            selectedTrackerId: {
                type: Number,
                default: null,
            },
            startup: {
                type: Object,
                required: true,
            },
            trackersList: {
                type: Array,
                required: true,
            },
            selectedStartups: {
                type: Array,
                required: true,
            },
        },
        computed: {
            mark: {
                get: function () {
                    return this.selectedStartups.indexOf(this.startup.id) > -1
                },
                set: function (value) {
                    this.$emit("updateSelection", {'startupId': this.startup.id, 'selected': value})
                }
            }
        },
        components: {
            AddToTracker
        },
        methods: {
            scoreLabel: function (score) {
                if (score > 50) {
                    return 'High';
                } else if (score < 30) {
                    return 'Low';
                } else {
                    return 'Medium';
                }
            },
        }
    }
</script>

<style scoped>

</style>
