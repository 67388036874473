<template>
    <table class="details-table">
        <template v-for="field in fields">

            <tr :key="field.id" >

                <td v-bind:class="{ 'details-table__cell': ['select', 'text', 'date', 'numeric', 'links_list', 'file', 'multiselect'].includes(field.type)}">
                    <div class="details-table__cell__label d-flex">
                        {{field.name}}
                        <i v-if="field.description" class="hint-secondary" v-b-popover.hover.right="field.description"></i>
                    </div>
                </td>

                <td v-if="field.type !== 'static_text'">
                    <custom-field
                        :disabled="!$store.getters.isAdmin"
                        :field="field"
                        :value="field.value"
                        :startupId="companyId"
                        v-on="$listeners"
                    >
                    </custom-field>
                </td>
            </tr>
        </template>
    </table>
</template>

<script>

import customField from "@/components/customFields/CustomField.vue";

export default {
    props: {
        companyId: Number,
        fields: Array,
    },
    components: {customField}
}
</script>

<style scoped>
.popover {
        left: 11px !important;
        margin: auto !important;
        max-width: 160px !important;
        padding: 5px 10px;
        background: #fff;
        border-radius: 4px;
        color: #7b7b7b !important;
        filter: drop-shadow(1px 3px 4px rgba(#000, 0.3));
        box-shadow: none !important;
        font-size: 10px !important;
        font-weight: 400;
        line-height: 1.5;
    }
    .popover.bs-popover-bottom,
	.popover.bs-popover-top {
		margin-left: 0 !important;
        left: 0 !important;
	}
    .popover.bs-popover-bottom .arrow {
        display: none !important;
        opacity: 0;
        visibility: hidden !important;
    }
    div.popover.b-popover .arrow {display: none !important; visibility:hidden !important;}
    
    .popover-description {
        top: auto !important;
        max-width: 400px !important;
        font-size: 12px !important;
    }
    .popover-description .popover__content {
        padding: 12px;
    }
    @media (max-width: 479px) {
        .popover-description {
            max-width: 260px !important;
            font-size: 10px !important;
        }
    }
</style>
