import axios from '@/axios';

const RESOURCE_NAME = '/contacts';

export default {

    getEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}`)
    },

    save(id, startupId, ecosystemId, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}&startup_id=${startupId}&ecosystem_id=${ecosystemId}`, data)
    },

    togglePrimary(id) {
        return axios.get(`${RESOURCE_NAME}/toggle-primary?id=${id}`)
    },

    delete(id) {
        return axios.post(`${RESOURCE_NAME}/delete-contact?id=${id}`)
    },

    saveCustomDetail(id, data) {
        return axios.post(`${RESOURCE_NAME}/save-custom-detail?id=${id}`, data)
    },

    deleteCustomDetail(id) {
        return axios.post(`${RESOURCE_NAME}/delete-custom-detail?detail_id=${id}`)
    },

    getRocketReachInfo(id) {
        return axios.get(`${RESOURCE_NAME}/get-rocket-reach-info?id=${id}`)
    },

    getNotes(id) {
        return axios.get(`${RESOURCE_NAME}/get-notes?id=${id}`)
    },

    saveNote(id, data) {
        return axios.post(`${RESOURCE_NAME}/save-note?id=${id}`, data)
    },

    deleteNote(id) {
        return axios.post(`${RESOURCE_NAME}/delete-note?note_id=${id}`)
    },

    exportStartupsCustomContacts(filters) {
        return axios.post(`${RESOURCE_NAME}/export-startups-custom-contacts`, {filters})
    },

    invite(id, email) {
        return axios.post(`${RESOURCE_NAME}/invite?id=${id}`, {email})
    }
}
