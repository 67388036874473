<template>
    <div>
        <b-modal title="User Terms" id="modal-need-accept-user-terms" no-close-on-esc no-close-on-backdrop hide-header-close size="md">
            <p>I understand that the Fintech Relationship Management (FRM) tool [aka SwitchPitch] has been approved to hold information which is classified as Public or Limited only.</p>
            <p>I agree that I will not add any information rated Confidential or Highly Confidential.  In the event that any such information is identified, I agree to delete it as soon as possible and notify my line manager.</p>
            <p>If you are unsure as to the classification of any info please refer to this link <a target="_blank" href="https://lloydsbanking.sharepoint.com/sites/One%20Security/SitePages/Guide%20to%20Classification%20%26%20Encryption.aspx">link</a></p>
            <p>As default you are receiving “read only” access. If you wish to upgrade your access then please reach out to the FRM Team at this mailbox: <a href="mailto:FRMSupport@lloydsbanking.com">$FRMSupport</a></p>
            <template #modal-footer>
                <primary-button
                    caption="Accept Terms"
                    :loading="loading"
                    @click="accept"
                />
            </template>
        </b-modal>

    </div>
</template>

<script>

import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import userService from "@/services/user.service";

export default {
    components: {PrimaryButton},
    data() {
        return {
            loading: false,
        }
    },

    methods: {
        open() {
            this.$bvModal.show('modal-need-accept-user-terms')
        },

        accept() {
            this.loading = true;

            userService.acceptTerms().then(response => {
                if (response.data) {
                    this.$bvModal.hide('modal-need-accept-user-terms')
                }
            }).finally(() => {
                this.loading = false;
            })
        }
    }
}
</script>
