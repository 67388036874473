import axios from '@/axios';

const RESOURCE_NAME = '/saved-filters';

export default {
    getList() {
        return axios.get(`${RESOURCE_NAME}/get-list`)
    },

    getEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}`)
    },

    save(id, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}`, {data: data})
    },

    deleteFilter(id) {
        return axios.post(`${RESOURCE_NAME}/delete-filter?id=${id}`)
    },

    updateDefault(id) {
        axios.post(`${RESOURCE_NAME}/update-default`, {id: id})
    },
}
