<template>
    <b-modal id="modal-edit-interaction-tag" :title="title" modal-class="modal-w-md">
        <div class="form">
            <h6 class="heading6 mb-1">Tag Name*</h6>
            <template v-if="'name' in errors">
                <span class="form__error" v-for="(error, index) in errors.name" :key="index">{{error}}</span>
            </template>

            <b-form-input class="form-control--mid mb-3" v-model="tag.name"></b-form-input>

            <h6 class="heading6 mb-1">Available For Interaction Types (optional)</h6>

            <multiselect
                v-model="types"
                :multiple="true"
                :options="interactionTypes"
                label="name"
                track-by="id"
                class="multiselect-mid"
            >
            </multiselect>
        </div>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canSave" @click="save"><template v-if="id">Update</template><template v-else>Add</template></b-button>
        </template>
    </b-modal>
</template>

<script>
import tags from "@/services/interaction-tags.service"
import {interactionTypes} from "@/constants/constants";
import multiselect from "vue-multiselect";

export default {
    data() {
        return {
            id: 0,
            tag: {
                name: "",
                types: [],
            },

            types: [],
            errors: {},
        }
    },

    components: {
        multiselect
    },

    watch: {
        types() {
            this.tag.types = this.types.map(item => item.id)
        }
    },

    computed: {
        title() {
            if (this.id) {
                return "Edit Tag"
            } else {
                return "Add New Tag"
            }
        },

        canSave() {
            return this.tag.name.length
        },

        interactionTypes() {
            return Object.entries(interactionTypes).map(item => ({
                id: item[0],
                name: item[1],
            }))
        }
    },

    methods: {
        init() {
            this.tag.name = ""
            this.tag.types = []
            this.types = []
            this.errors = {}

            if (this.id) {
                tags.getEdit(this.id).then(response => {
                    this.tag.name = response.data.name
                    this.types = response.data.types.map(id => ({
                        id: id,
                        name: interactionTypes[id],
                    }))
                })
            }
        },

        open(id) {
            this.id = id;
            this.init();
            this.$bvModal.show('modal-edit-interaction-tag');
        },

        save() {
            tags.save(this.id, this.tag).then((response) => {

                if (Object.keys(response.data.errors).length) {
                    this.errors = response.data.errors;
                } else {
                    this.$emit('tagSaved');
                    this.$bvModal.hide('modal-edit-interaction-tag');
                }
            })
        },
    }
}
</script>

<style scoped>

</style>
