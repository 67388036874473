<template>
    <form class="form form--enter form--register pb-4">
        <div class="form--enter__container">
            <fieldset class="form--enter__fieldset">
                <div class="form--enter__error" v-for="(error, index) in errors.other" :key="index">
                    {{error}}
                </div>

                <template v-if="!form.company_id">
                    <div v-if="!fixedCompanyType" class="form__item">
                        <label class="form__label">Company Type</label>
                        <template>
                            <CustomSelect
                                :init-value="form.company_type"
                                v-on:input="changeCompanyType"
                                :options="{
                                    'enterprise': '<b class=\'select-custom__enterprise\'>Enterprise</b> More than 500 employees',
                                    'startup': '<b class=\'select-custom__startup\'>Startup</b> Fewer than 500 employees',
                                }"
                                :default="form.company_type"
                            />
                        </template>
                    </div>

                    <div class="form__item">
                        <label class="form__label pl-0">
                            Company Name
                            <span class="form__error" v-for="(error, index) in errors.company_name" :key="index">{{error}}</span>
                        </label>
                        <input class="form-control--mid form-control" v-model="form.company_name" type="text"/>
                    </div>

                    <div class="form__item">
                        <label class="form__label d-flex align-items-center pl-0">
                            Website
                            <span class="form__error ml-2" v-for="(error, index) in errors.website" :key="index">{{error}}</span>

                            <template v-if="errors.website.indexOf('Domain already registered') >= 0">
                                <div class="ml-auto">
                                    Is this yours? <a class="link" href="mailto:info@switchpitch.com" target="_blank">Contact us</a>
                                </div>
                            </template>
                        </label>
                        <input class="form-control--mid form-control" v-model="form.website" type="url"/>
                    </div>

                    <div class="form__item">
                        <label class="form__label pl-0">
                            Email
                            <span class="form__error" v-for="(error, index) in errors.email" :key="index">{{error}}</span>
                        </label>
                        <input class="form-control--mid form-control" v-model="form.email" type="email"/>
                    </div>
                </template>

                <div class="row">
                    <div class="form__item col-12 col-sm-6">
                        <label class="form__label pl-0">
                            Password
                        </label>
                        <input class="form-control--mid form-control" v-model="form.password" type="password"/>
                        <span class="form__error" v-for="(error, index) in errors.password" :key="index">{{error}}</span>
                    </div>
                    <div class="form__item col-12 col-sm-6">
                        <label class="form__label pl-0">
                            Confirm Password
                        </label>
                        <input class="form-control--mid form-control" v-model="form.confirm_password" type="password"/>
                        <span class="form__error" v-for="(error, index) in errors.confirm_password" :key="index">{{error}}</span>
                    </div>
                </div>

                <p>By creating an account you agree to our <a href="https://sp-files-prod.s3-us-west-2.amazonaws.com/Terms+of+Use+Nov+19.pdf">Terms and Conditions</a></p>
                <div class="text-center">

                    <button v-if="!loading" class="button" type="button" @click="recaptcha">Sign Up</button>
                    <span class="spinner-wrapper" v-else><b-spinner class="mr-1" small></b-spinner>Loading...</span>
                </div>
            </fieldset>
        </div>
    </form>
</template>

<script>
import CustomSelect from "@/components/CustomSelect";
import registration from "@/services/registration.service";

export default {
    props: {
        fixedCompanyType: {
            type: Boolean,
            default: false,
        },

        companyType: {
            type: String,
            default: "",
        },

        publicTrackerId: {
            type: String,
            default: "",
        }
    },

    components: {
        CustomSelect,
    },

    data() {
        return {
            form: {
                company_id: 0,
                company_type: "enterprise",
                company_name: "",
                email: "",
                website: "",
                password: "",
                confirm_password: "",
                public_tracker_id: this.publicTrackerId,
                invite_hash: '',
                invited_by_ecosystem_id: this.$route.query.invited_by_ecosystem,
            },

            errors: {
                company_name: [],
                email: [],
                website: [],
                password: [],
                confirm_password: [],
                other: [],
            },

            loading: false,

            invite: this.$route.query.invite,
            recaptchaToken: null,
        }
    },

    watch: {
        companyType() {
            if (this.companyType.length) {
                this.form.company_type = this.companyType
            }
        }
    },

    mounted() {
        if (this.companyType.length) {
            this.form.company_type = this.companyType
        }

        window.dataLayer.push({
            "event": "Accessed signup form"
        });

        if (this.invite) {
            registration.checkInvite(this.invite).then(response => {
                if (response.data) {
                    for (let i in response.data) {
                        this.form[i] = response.data[i]
                    }

                    this.form.invite_hash = this.invite
                    this.$emit('setAccountType', this.form.company_type)
                }
            })
        }
    },

    methods: {
        register() {
            this.loading = true

            registration.register(this.form, this.recaptchaToken).then(response => {
                this.errors = response.data.errors
                let status = response.data.status

                let hasErrors = false

                for (let i in this.errors) {
                    if (this.errors[i].length) {
                        hasErrors = true
                        break
                    }
                }

                if (!hasErrors) {
                    this.$emit('registered', {status: status, email: this.form.email})
                }
                else {
                    window.dataLayer.push(
                        {
                            "event":"triggered form error","registeredError": this.errors
                        }
                    );
                }
            }).finally(() => {
                this.loading = false
            })
        },

        changeCompanyType(type) {
            this.form.company_type = type
        },

        async recaptcha() {
            await this.$recaptchaLoaded()
            this.recaptchaToken = await this.$recaptcha()
            this.register()
        }
    }
}
</script>

