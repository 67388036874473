export const lessonsTitles = {
    1: 'Searching For Startups',
    2: 'Researching Startup Diligence',
    3: 'Creating & Managing Review Funnels',
    4: 'Exploring VCs, Accelerators & Other Startup Networks',
    5: 'Reporting & Progress Overview'
};

export const lessonsHints = {
    1: 'Use keywords, filters and groups to narrow your results',
    2: 'Gather startup data, connect with startup leadership',
    3: 'Create trackers, add diligence fields and define your funnel',
    4: 'Leverage ecosystems for scouting and partnerships',
    5: 'Use charts to view activity, generate usage reports'
};

export const lessonsCodes = {
    1: 'DH7wDbAtyVvxPQCUc637Rdf6fC0',
    2: 'gm2Jz59Yp72UpssBG9UgK4djucw',
    3: '5kQX6otzx2PNSEW_dQafP3OMNt0',
    4: 'bu0-zEF66lw4YoO6XDBNcAeMz28',
    5: 'xzZ2XZupJQSNLcJ2JAAhFIdEng8'
};

export const lessonsIconsClasses = {
    1: 'lesson-search',
    2: 'lesson-diligence',
    3: 'lesson-funnels',
    4: 'lesson-networks',
    5: 'lesson-overview'
};
