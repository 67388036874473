import axios from '@/axios';

const RESOURCE_NAME = '/public-trackers';

export default {

    getApplicationInfo(id) {
        return axios.get(`${RESOURCE_NAME}/get-application-info?id=${id}`)
    },

    checkEnterpriseAccess(id, enterpriseId) {
        return axios.get(`${RESOURCE_NAME}/check-enterprise-access?id=${id}&enterprise_id=${enterpriseId}`)
    },
}
