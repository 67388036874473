<template>
    <div v-if="$store.state.user.permissions.indexOf('tags-manage') >= 0">
        <div class="topbar topbar--full pt-4">
            <router-link to="/settings" class="link-back">All Settings</router-link>
            <h2 class="heading1 mb-0">Tags</h2>
        </div>

        <div class="admin-page">
            <div class="admin-page__content main-inner">
                <startup-tags></startup-tags>
                <interaction-tags></interaction-tags>
            </div>
        </div>
    </div>
</template>

<script>
import StartupTags from "@/components/settings/StartupTags.vue";
import InteractionTags from "@/components/settings/InteractionTags.vue";

export default {
    components: {
        StartupTags,
        InteractionTags
    },
}
</script>

<style scoped>

</style>
