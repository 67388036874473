import agencyClientsService from "@/services/agency-clients.service";
import {useStore} from "vue2-helpers/vuex";

export default function () {
    const store = useStore();

    const login = async function(id) {
        const {data} = await agencyClientsService.loginToClient(id);

        if (data.token) {
            store.commit('authSuccess', data);
            window.location.href = '/';
        }
    }

    return {
        login
    }
}
