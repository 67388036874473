<template>
    <div>
        Startup Tags
        <div class="text-right mb-4">
            <button @click="addTag" class="button">+ New Tag</button>
        </div>

        <div class="main-table-wrapper">
            <Grid v-if="tags.length"
                  :columns="columns"
                  :data="tags"
                  :page-length="pageLength"
                  :total-rows-count="totalRowsCount"
                  :currentPage="currentPage"
                  v-on:changePage="changePage"
                  v-on:action="action"
            >
            </Grid>

            <TagEdit
                ref="editTag"
                v-on:tagSaved="getList"
            >
            </TagEdit>
        </div>
    </div>
</template>

<script>
import tags from "@/services/tags.service";
import TagEdit from "@/components/tags/EditTag";
import Grid from "@/components/Grid.vue";
import grid from "@/mixins/grid";

export default {
    mixins: [grid],

    data() {
        return {
            tags: [],
            columns: [
                {
                    name: 'name',
                    label: 'Name',
                },
                {
                    name: 'description',
                    label: 'Description',
                },
                {
                    name: 'added_startups',
                    label: 'Added Startups',
                    component: 'Link',
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                }
            ]
        };
    },

    components: {
        Grid,
        TagEdit
    },

    mounted() {
        this.getList()
    },

    methods: {
        getList() {
            tags.getIndexList({currentPage: this.currentPage, pageLength: this.pageLength}).then(({data}) => {
                this.tags = data.data
                this.totalRowsCount = parseInt(data.total_count)
                this.currentPage = data.current_page
            })
        },

        addTag() {
            this.$refs.editTag.open(0)
        },

        action(params) {
            if (params.action === 'edit') {
                this.$refs.editTag.open(params.rowId)
            } else if (params.action === 'delete') {
                if (confirm("Remove tag?")) {
                    tags.delete(params.rowId).then(() => {
                        this.getList()
                    })
                }
            } else if (params.action === 'openStartupsWithTag') {
                let tagName = this.tags.find(item => parseInt(item.id) === parseInt(params.rowId)).tag_name
                window.open('/startups?tag=' + tagName, '_blank').focus();
            }
        },
    }
}
</script>

<style scoped>

</style>
