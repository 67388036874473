<template>
    <div class="text-center">
        <div class="py-4">
            <a v-if="blobData" @click="download" class="btn btn-primary btn-lg">Download</a>
        </div>
        
        <pdf
            v-for="i in pagesCount"
            :key="i"
            :src="src"
            :page="i"
            style="display: inline-block; width: 80%"
        ></pdf>
    </div>
</template>

<script>
import pdf from "vue-pdf";
import files from "@/services/files.service";

export default {
    components: {
        pdf
    },

    data() {
        return {
            id: this.$route.params.id,
            pagesCount: 0,
            src: null,
            blobData: null,
        }
    },

    mounted() {
        files.downloadCustomFile(this.id).then(response => {
            this.blobData = response.data
            let link = window.URL.createObjectURL(new Blob([response.data]));
            this.src = link

            pdf.createLoadingTask(link).promise.then(pdf => {
                this.pagesCount = pdf.numPages;
            })
        })
    },

    methods: {
        download() {
            files.getFileName(this.id).then(response => {
                this.fileHelper.download(this.blobData, response.data)
            })
        }
    }
}
</script>

<style scoped>

</style>