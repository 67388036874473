import clientsService from "@/services/clients.service";

export default {
    namespaced: true,

    state: {
        topClients: [],
        topClientsLoaded: false,
    },

    actions: {
        async fetchTopClients({state}, reload = false){
            if (!state.topClientsLoaded || reload) {
                const response = await clientsService.getTopListForSearch()
                state.topClients = response.data
                state.topClientsLoaded = true
            }
        }
    },

    mutations: {
        setTopClients: (state, topClients) => (state.topClients = topClients),
    },

    getters: {
        topClients: state => state.topClients
    }
}
