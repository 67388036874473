<template>
    <div class="row">
        <div class="col-12 col-md-9">
            <edit-interaction
                ref="editInteraction"
                :type="type"
                :companyType="companyType"
                :companyId="companyId"
                :usersMentionList="usersMentionList"
                v-on:saved="saved"
            >
            </edit-interaction>
        </div>
        
        <div class="col-12 col-md-3">
            <b-button class="w-100 w-md-auto" variant="primary" size="lg" @click="save" :disabled="!canAdd">Add</b-button>
        </div>
    </div>
</template>

<script>
import EditInteraction from "@/components/interactions/EditInteraction";

export default {
    components: {
        EditInteraction,
    },

    props: {
        type: String,
        companyType: String,
        companyId: Number,
        usersMentionList: Array,
    },

    data() {
        return {
            canAdd: true,
        }
    },

    mounted() {
        this.init()
    },

    methods: {
        init() {
            this.$refs.editInteraction.init(0)
        },

        save() {
            this.canAdd = false
            this.$refs.editInteraction.save()
        },

        saved() {
            this.canAdd = true
            this.$emit('saved')
            this.init()
        },
    }
}
</script>

<style scoped>

</style>
