<template>
    <b-modal :id="'modal-edit-email-template-user' + prefix" title="Edit email template" modal-class="modal-w-md" @hide="beforeClose" no-enforce-focus>

        <div class="form form-modal mb-4">
            Subject
            <b-form-input class="form-control--mid" v-model="template.subject"></b-form-input>
        </div>

        <div class="form form-modal mb-4">
            Message
            <editor
                v-if="showEditor"
                v-model="template.body"
                :apiKey="apiKeys.tinyMCE"
                :init="defaults.editorOptions"
            >
            </editor>
        </div>

        <template #modal-footer>
            <b-button variant="primary" size="lg" @click="save">Save</b-button>
        </template>
    </b-modal>
</template>

<script>

import editor from "@tinymce/tinymce-vue";

export default {
    props: {
        prefix: {
            type: String,
            default: "",
        }
    },

    components: {
        editor
    },

    data() {
        return {

            template: {
                subject: "",
                body: "",
            },

            showEditor: false,
        }
    },

    methods: {
        open(subject, body) {
            this.showEditor = false
            this.template.subject = subject
            this.template.body = body

            this.$bvModal.show('modal-edit-email-template-user' + this.prefix)
            this.$nextTick(() => {
                this.showEditor = true
            })
        },

        save() {
            this.$emit('saved', this.template)
            this.$bvModal.hide('modal-edit-email-template-user' + this.prefix)
        },

        beforeClose() {
            this.$emit('close')
        },
    }
}
</script>