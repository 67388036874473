<template>
    <b-modal :id="modalId" :title="title" modal-class="modal-w-md" @hidden="modalClosed">
        <template #modal-title>
            <div class="modal-top">
                {{ title }}
                <div v-if="['pipeline','POC_preset'].includes(field.type.type) && $store.state.isAdmin" class="input-checkbox">
                    <input id="dp0" type="checkbox" v-model="defaultPipeline" :disabled="!!field.defaultPipeline">
                    <label v-if="field.type.type === 'pipeline'" for="dp0">Default Funnel</label>
                    <label v-else for="dp0">Default Preset</label>
                </div>
            </div>
        </template>

        <b-row class="mb-4">
            <b-col v-if="field.type.type === 'pipeline'" cols="12" class=mb-3 >
                    Changing the funnel stages will apply those changes to every tracker that uses this template.
            </b-col>

            <b-col cols="12" sm="6">

                <h5 class="heading5 heading5--secondary">
                    <template v-if="field.type.type === 'pipeline'">
                        Funnel Title*
                    </template>
                    <template v-else-if="field.type.type === 'POC_preset'">
                        Preset Title*
                    </template>
                    <template v-else>
                        Field Label*
                    </template>
                </h5>
                <b-form-input class="form-control--mid" v-model="field.name" :placeholder="placeholder"></b-form-input>
            </b-col>

            <b-col cols="12" sm="6">
                <template v-if="!fieldType">
                    <h5 class="heading5 heading5--secondary">Type*</h5>
                    <multiselect class="multiselect-mid mb-2"
                        v-model="field.type"
                        :options="fieldTypes"
                        select-label=""
                        deselect-label=""
                        label="name"
                        track-by="name"
                        :allow-empty="false"
                        :disabled="id > 0"
                    >
                    </multiselect>
                </template>
            </b-col>
        </b-row>

        <template v-if="this.type === 'company_diligence'">
            <b-row class="mb-4">
                <b-col cols="12">
                    <h5 class="heading5 heading5--secondary">
                        Description
                    </h5>
                    <b-form-input class="form-control--mid" v-model="field.description" placeholder="Description"></b-form-input>

                    <span v-if="errors.description.length" class="error">{{errors.description}}</span>
                </b-col>
            </b-row>

            <b-row v-if="$store.state.companyType === accountTypes.ENTERPRISE && field.type.type !== 'users_list'" class="mb-4">
                <b-col cols="12">
                    <h5 class="heading5 heading5--secondary">
                        Startup Sharing
                    </h5>

                    <p class="modal-text">Allow your team to send startups a request to populate this field</p>

                    <div class="input-checkbox">
                        <input id="enable-startup-population" type="checkbox" v-model="field.allow_startup_population">
                        <label for="enable-startup-population">Enable sharing</label>
                    </div>
                </b-col>
            </b-row>
        </template>

        <template v-if="hasOptions">
            <h5 class="heading5 heading5--secondary">
                <template v-if="field.type.type === 'pipeline'">
                    Stages
                </template>
                <template v-else-if="field.type.type === 'POC_preset'">
                    Statuses
                </template>
                <template v-else>
                    Dropdown Options
                </template>
            </h5>
            
            <draggable v-model="field.options" class="list-colors mb-2">
                <div class="form__grabbing drag-n-drop-item" inline v-for="(option, index) in field.options" :key="index">
                    <input v-model="field.options[index].value" v-focus class="form-control--light w-50 form-control form-control--borderless">

                    <div v-if="field.type.type === 'POC_preset'" class="list-colors__input">
                        <span class="list-colors__input__text">Color:</span>
                        <div class="list-colors__input__wrapper list-colors__input__wrapper--editable">
                            <input type="color" :id="'color-picker-'+index" v-model="field.options[index].color">
                            <label :for="'color-picker-'+index"></label>
                        </div>
                    </div>

                    <a @click="field.options.splice(index, 1)" class="link-remove"></a>
                </div>
            </draggable>

            <a @click="addOption" class="link">
                <template v-if="field.type.type === 'pipeline'">
                    + Add Stage
                </template>
                <template v-else-if="field.type.type === 'POC_preset'">
                    + Add Status
                </template>
                <template v-else>
                    + Add an option
                </template>
            </a>
        </template>

        <div v-if="field.type.type === 'numeric'" class="mb-4">
            <h5 class="heading5 heading5--secondary">Min Value*</h5>
            <b-form-input type="number" class="form-control--mid" v-model="field.minValue" placeholder="Min value"></b-form-input>

            <h5 class="heading5 heading5--secondary">Max Value*</h5>
            <b-form-input type="number" class="form-control--mid" v-model="field.maxValue" placeholder="Max value"></b-form-input>
        </div>

        <div v-if="this.type === 'tracker_question'" class="input-checkbox">
            <input id="default_question" type="checkbox" v-model="field.defaultQuestion">
            <label for="default_question">Default Question</label>
        </div>

        <h4 class="heading4 mt-3">Permissions

            <b-dropdown class="ml-sm-3 dropdown--tertiary" variant="link" ref="hasPermissionsDropdown" toggle-class="text-decoration-none" no-caret>

                <template v-if="hasPermissions" #button-content>
                    Only users I select can access
                </template>

                <template v-else #button-content>
                    All users can access
                </template>

                <b-dropdown-header>
                    <ul class="modal-list">
                        <li @click="hasPermissions = false; $refs.hasPermissionsDropdown.hide()">
                            <h5 class="heading5 heading5--secondary">All users can access</h5>
                        </li>
                        <li @click="hasPermissions = true; $refs.hasPermissionsDropdown.hide()">
                            <h5 class="heading5 heading5--secondary">Only users I select can access</h5>
                        </li>
                    </ul>
                </b-dropdown-header>

            </b-dropdown>
        </h4>

        <ul class="rows-list mb-3">

            <li class="rows-list__form" v-if="hasPermissions">
                <!-- Please add here the logic first -->

                <multiselect class="multiselect-mid col-md-5 mb-2 mb-md-0"
                             v-model="newPermission.holder"
                             :options="actualPermissionHolders"
                             select-label=""
                             deselect-label=""
                             label="name"
                             track-by="name"
                >
                    <template slot="option" slot-scope="props">
                        <template v-if="props.option.type === 'group'">
                            <div class="d-flex align-items-center">
                                <div class="mr-2">
                                    <font-awesome-icon :icon="['fa', 'users']" size="lg"  />
                                </div>
                                {{ props.option.name }}
                            </div>

                        </template>
                        <template v-else>
                            <div class="d-flex align-items-center">
                                <div class="mr-2">
                                    <Avatar
                                        :username="props.option.name"
                                        :src="props.option.avatar"
                                        :size="30">
                                    </Avatar>
                                </div>
                                {{ props.option.name }}
                            </div>
                        </template>
                    </template>
                </multiselect>
                
                <div class="d-flex justify-content-between align-items-center flex-wrap">

                    <template v-if="!['pipeline','POC_preset'].includes(field.type.type)">
                        <span class="text-nowrap ml-sm-2">Add as:</span>
                        <b-dropdown class="ml-2 dropdown--tertiary" variant="link" ref="roleDropdown" toggle-class="text-decoration-none" no-caret>

                            <template #button-content>
                                {{ constants.customFieldAccessTypes[newPermission.accessType] }}
                            </template>

                            <b-dropdown-header>
                                <ul class="modal-list">
                                    <li v-for="(name, accessType) in constants.customFieldAccessTypes" :key="name" @click="newPermission.accessType = accessType; $refs.roleDropdown.hide()">
                                        <h5 class="heading5 heading5--secondary">{{ name }}</h5>
                                    </li>
                                </ul>
                            </b-dropdown-header>

                        </b-dropdown>
                    </template>

                    <b-button @click="addPermission" class="ml-2" variant="primary" size="md" :disabled="!newPermission.holder">Add</b-button>
                </div>
            </li>

            <li v-for="(permission, index) in field.permissions" :key="index">
                <template v-if="hasPermissions">
                    <div class="image-block image-block--rounded">
                        <Avatar
                            :username="permission.name"
                            :src="permission.avatar"
                            :size="30">
                        </Avatar>
                        <img v-if="permission.type === 'user'" :src="permission.avatar" alt=""/>
                    </div>

                    <h5 class="heading5 heading5--secondary">{{ permission.name }}</h5>

                    <b-dropdown v-if="!['pipeline','POC_preset'].includes(field.type.type)" class="ml-auto dropdown--tertiary modal-list" variant="link" toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <h5 class="heading5 heading5--secondary text-capitalize">{{constants.customFieldAccessTypes[permission.accessType]}}</h5>
                        </template>

                        <b-dropdown-item v-for="(name, accessType) in constants.customFieldAccessTypes" :key="name" @click="permission.accessType = accessType">
                            <h5 class="heading5 heading5--secondary">{{name}}</h5>
                        </b-dropdown-item>
                    </b-dropdown>

                    <a @click="field.permissions.splice(index, 1)" class="remove-action" href="#">Remove</a>
                </template>
            </li>
        </ul>

        <template #modal-footer>
            <primary-button
                :loading="loading"
                :disabled="!canSave"
                @click="save"
            />
        </template>
    </b-modal>
</template>

<script>
import customFields from "@/services/custom-fields.service"
import multiselect from "vue-multiselect"
import draggable from 'vuedraggable'
import team from "@/services/team.service";
import Avatar from "vue-avatar";
import {mapActions, mapGetters} from "vuex";
import accountTypes from "@/constants/accountTypes";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";

export default {
    components: {
        PrimaryButton,
        multiselect,
        draggable,
        Avatar,
    },

    props: {
        sectionId: Number,
        type: String,
        index: String,
        fieldType: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            id: 0,
            hasPermissions: false,
            defaultPipeline: false,

            field: {
                name: "",
                description: '',

                type: {
                    type: null,
                    name: '',
                },

                options: [],
                minValue: '',
                maxValue: '',
                permissions: [],
                defaultPipeline: 0,
                defaultQuestion: 0,
                allow_startup_population: false,
            },

            fieldTypes: [],

            permissionHolders: [],
            newPermission: {
                holder: null,
                accessType: 'read-only',
            },

            errors: {
                description: '',
            },

            formSubmitted: false,
            loading: false,
        }
    },

    mounted() {
        this.getFieldTypes()
    },

    computed: {
        accountTypes() {
            return accountTypes
        },
        ...mapGetters(['allFieldTypes']),

        title() {
            if (this.fieldType === 'pipeline') {
                if (this.id) {
                    return "Edit Funnel"
                } else {
                    return "Add New Funnel"
                }
            }
            else if (this.fieldType === 'POC_preset') {
                if (this.id) {
                    return "Edit Preset"
                } else {
                    return "Add New Preset"
                }
            }
            else {
                if (this.id) {
                    return "Edit Field"
                } else {
                    return "Add New Field"
                }
            }
        },

        placeholder() {
            if (this.fieldType === 'pipeline') {
                return "Enter Funnel Title"
            }
            else if (this.fieldType === 'POC_preset') {
                return "Enter Preset Title"
            }
            else {
                return "Enter Field label"
            }
        },

        modalId() {
            return 'modal-edit-field-' + this.index
        },

        canSave() {
            let hasEmpty = false
            this.field.options.forEach(item => {
                if (!item.value.length) {
                    hasEmpty = true
                    return
                }
            })

            return this.field.name.length && this.field.type.type && !hasEmpty && !(this.field.type.type === 'numeric' && (!this.field.minValue || !this.field.maxValue || parseInt(this.field.minValue) < 1 || parseInt(this.field.maxValue) < 1 || parseInt(this.field.minValue) > parseInt(this.field.maxValue)))
        },

        hasOptions() {
            return this.arrayHelper.inArray([
                'radio',
                'checkbox',
                'select',
                'multiselect',
                'pipeline',
                'POC_preset',
            ], this.field.type.type)
        },

        actualPermissionHolders() {
            let array = []
            this.permissionHolders.forEach(item => {
                if (!this.arrayHelper.exists(this.field.permissions, ['type', 'id'], [item.type, item.id])) {
                    array.push(item)
                }
            })

            return array
        }
    },

    methods: {
        ...mapActions(['fetchFieldTypes']),

        init() {
            this.arrayHelper.fillObject(this.errors)
            this.formSubmitted = false

            team.getPermissionHolders().then(response => {
                this.permissionHolders = response.data
            })

            this.newPermission.holder = null

            if (this.id) {

                customFields.getEdit(this.id).then(response => {
                    this.field.name = response.data.name
                    this.field.description = response.data.description
                    this.field.options = response.data.options

                    this.field.minValue = response.data.min_value
                    this.field.maxValue = response.data.max_value

                    this.field.type = {
                        type: response.data.field_type.type,
                        name: response.data.field_type.name
                    }

                    this.field.permissions = response.data.permissions
                    this.field.allow_startup_population = response.data.allow_startup_population

                    this.hasPermissions = !!this.field.permissions.length;

                    this.field.defaultPipeline = response.data.default_pipeline
                    this.field.defaultQuestion = response.data.default_question
                    this.defaultPipeline = this.field.defaultPipeline

                    this.$bvModal.show(this.modalId)
                })
            }
            else {
                this.field = {
                    name: "",
                    description: '',
                    type: {
                        type: null,
                        name: '',
                    },
                    options: [],
                    minValue: '',
                    maxValue: '',
                    permissions: [],
                    allow_startup_population: false,
                    defaultQuestion: false,
                }

                this.defaultPipeline = false

                if (this.fieldType) {
                    this.field.type = {
                        type: this.fieldType,
                        name: this.fieldType,
                    }
                }

                this.$bvModal.show(this.modalId)
            }
        },

        async getFieldTypes() {
            await this.fetchFieldTypes(this.type)
            this.fieldTypes = this.allFieldTypes[this.type]
        },

        open(id) {
            this.id = id
            this.init()
        },

        save() {
            this.loading = true;
            let hasErrors = false

            if (this.type === 'company_diligence' && this.field.description?.length > 256) {
                this.errors.description = "Description length cannot be more than 256 characters"
                hasErrors = true
            }
            else {
                this.errors.description = ""
            }

            if (!hasErrors) {
                let data = {
                    type: this.type,
                    name: this.field.name,
                    description: this.field.description,
                    fieldType: this.field.type.type,
                    options: this.field.options,
                    minValue: this.field.minValue,
                    maxValue: this.field.maxValue,
                    defaultPipeline: this.defaultPipeline,
                    defaultQuestion: this.field.defaultQuestion,
                    allow_startup_population: this.field.allow_startup_population,
                    permissions: this.hasPermissions ? this.field.permissions : [],
                }

                customFields.save(this.id, this.sectionId, data).then((response) => {
                    let id = response.data
                    this.$emit('fieldSaved', id)
                    this.formSubmitted = true
                    this.$bvModal.hide(this.modalId)
                }).finally(() => {
                    this.loading = false;
                })
            }
        },

        addPermission() {
            this.field.permissions.push(
                {
                    type: this.newPermission.holder.type,
                    id: this.newPermission.holder.id,
                    name: this.newPermission.holder.name,
                    accessType: this.newPermission.accessType,
                    avatar: this.newPermission.holder.avatar,
                }
            )

            this.newPermission.holder = null
        },

        modalClosed() {
            if (!this.formSubmitted) {
                this.$emit('modalClosedWithoutChanges')
            }
        },

        addOption() {
            let option = {
                id: 0,
                value: ''
            };

            if (this.field.type.type === 'POC_preset') {
                option.color = '#000000';
            }

            this.field.options.push(option)
        }
    }
}
</script>

<style scoped>

</style>
