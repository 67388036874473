<template>
    <div>
        <div class="topbar topbar--full pt-4">
            <router-link to="/settings" class="link-back">All Settings</router-link>
            <h2 class="heading1 mb-0">Admin</h2>
        </div>
        
        <div class="admin-page">
            <Header></Header>
            
            <div class="admin-page__content">
                <form class="form form--report">
                    <h4 class="form--report__title">Generate A Report</h4>
                    
                    <div v-if="exportType" class="form--report__section">
                        <label class="form__label">Report type</label>
                        <select v-model="exportType" class="form-control form-control--mid">
                            <option v-for="type in exportTypes" :value="type.value" :key="type.value">{{type.label}}</option>
                        </select>
                    </div>

                    <label class="form__label">Filters</label>
                    
                    <div class="form--report__filters">
                        <div v-if="availableFilters['period'].includes(exportType)">
                            <label class="form__label">Timeframe</label>
                            <select v-model="filter.period" class="form-control form-control--light">
                                <option v-for="period in periodTypes" :value="period.value" :key="period.value">{{period.label}}</option>
                            </select>
                        </div>
    
                        <div v-if="availableFilters['users'].includes(exportType)" class="form--report__filters__element">
                            <label class="form__label">Users</label>
                            <GroupsAndUsersList
                                v-on:selectedChanged="(items) => {this.filter.users = items.map(item => ({
                                    type: item.type,
                                    id: item.id
                                }))}"
                            />
                        </div>
                    </div>

                    <template v-if="['interactions','tracked-startups'].includes(exportType)">
                        <label class="form__label">Profile Custom Fields</label>

                        <custom-fields-list
                            fields-type="company_diligence"
                            @fields-selected="setProfileFields"
                        />
                    </template>

                    <template v-if="['tracked-startups'].includes(exportType)">
                        <label class="form__label">Tracker Diligence Fields</label>

                        <custom-fields-list
                            fields-type="tracker_diligence"
                            @fields-selected="setTrackerFields"
                        />
                    </template>

                    <div class="text-center pt-5">
                        <primary-button
                            caption="Generate report"
                            loading-caption="Generating report..."
                            :loading="loading"
                            v-on:click="generateReport"
                        />
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/views/settings/admin/AdminHeader";
import GroupsAndUsersList from "@/components/GroupsAndUsersList.vue";
import interactionsService from "@/services/interactions.service";
import trackersCompaniesService from "@/services/trackers-companies.service";
import startupsService from "@/services/startups.service";
import contactsService from "@/services/contacts.service";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import CustomFieldsList from "@/components/customFields/CustomFieldsList.vue";

export default {
    components: {
        CustomFieldsList,
        PrimaryButton,
        Header,
        GroupsAndUsersList,
    },

    data() {
        return {
            exportType: 'interactions',
            exportTypes: [
                {
                    value: 'interactions',
                    label: 'Notes and Interactions'
                },
                {
                    value: 'trackers-startups-stages',
                    label: 'Tracker Startup Stages'
                },
                {
                    value: 'tracked-startups',
                    label: 'Tracked Startups'
                },
                {
                    value: 'added-startups',
                    label: 'Added Startup'
                },
                {
                    value: 'custom-contacts',
                    label: 'Custom Startups Contacts'
                },
            ],

            periodTypes: this.constants.commonFilterPeriods,
            usersList: [],

            filter: {
                period: this.constants.commonFilterPeriods[0].value,
                users: [],
            },

            profileFields: [],
            trackerFields: [],

            availableFilters: {
                'period': ['interactions','trackers-startups-stages','tracked-startups','added-startups','custom-contacts'],
                'users': ['interactions','trackers-startups-stages','tracked-startups','added-startups','custom-contacts'],
            },

            loading: false,
        }
    },

    methods: {
        generateReport() {
            this.loading = true
            if (this.exportType === 'interactions') {
                interactionsService.exportCSV(this.filter, this.profileFields).then(response => {
                    this.fileHelper.download(response.data, 'interactions.csv')
                }).finally(() => {
                    this.loading = false
                })
            } else if (this.exportType === 'trackers-startups-stages') {
                trackersCompaniesService.exportTrackersStartupsStages(this.filter).then(response => {
                    this.fileHelper.download(response.data, 'startups-stages.csv')
                }).finally(() => {
                    this.loading = false
                })
            } else if (this.exportType === 'tracked-startups') {
                trackersCompaniesService.exportTrackedStartups(this.filter, this.profileFields, this.trackerFields).then(response => {
                    this.fileHelper.download(response.data, 'tracked-startups.csv')
                }).finally(() => {
                    this.loading = false
                })
            } else if (this.exportType === 'added-startups') {
                startupsService.exportAddedStartups(this.filter).then(response => {
                    this.fileHelper.download(response.data, 'added-startups.csv')
                }).finally(() => {
                    this.loading = false
                })
            } else if (this.exportType === 'custom-contacts') {
                contactsService.exportStartupsCustomContacts(this.filter).then(response => {
                    this.fileHelper.download(response.data, 'custom-startups-contacts.csv')
                }).finally(() => {
                    this.loading = false
                })
            }
        },

        setProfileFields(fieldsIds) {
            this.profileFields = fieldsIds;
        },

        setTrackerFields(fieldsIds) {
            this.trackerFields = fieldsIds;
        }
    }
}
</script>

<style scoped>

</style>
