<template>
    <div>
        <enterprise-settings v-if="$store.state.user.company_type == 'enterprise'"></enterprise-settings>
        <ecosystem-settings v-if="$store.state.user.company_type == 'ecosystem'"></ecosystem-settings>
    </div>
</template>

<script>
import EnterpriseSettings from "@/views/settings/Settings"
import EcosystemSettings from "@/views/ecosystem/settings/Settings"

export default {
    components: {
        EnterpriseSettings,
        EcosystemSettings,
    }
}
</script>

<style scoped>

</style>