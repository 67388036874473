<template>
    <main class="enter-page">
        <header class="header">
            <span class="logo"></span>
        </header>
        <div class="form form--enter">
            <header class="form--enter__header">
                <h1 class="heading1">Confirmation</h1>
            </header>
            <div class="form--enter__container mt-2">
                <fieldset class="form--enter__fieldset">
                    <div class="text-center">
                        <div class="form--enter__error" v-for="(error, index) in errors" :key="index">
                            <span class="form__error" >{{error}}</span>
                        </div>
                        <div v-if="status == 'confirmed'">
                            <p>Account has been successfully confirmed.</p>
                            <p>Please login:</p>
                            <div class="text-center">
                                <button class="button" type="button" @click="$router.push('/login')">Log In</button>
                            </div>
                        </div>
                        <div v-if="status != 'confirmed' && !errors.length">
                            <p>Registration in progress...</p>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>

    </main>
</template>


<script>
import registration from '../../services/registration.service';

export default {
    name: "Confirm",
    data() {
        return {
            status: "",
            errors: []
        }
    },
    mounted() {
        registration.confirm(this.$route.params.activationCode)
            .then(response => {
                this.errors = response.data.errors
                this.status = response.data.status

                if (response.data.return_url) {
                    this.$router.push(response.data.return_url)
                }
            })
    }
}
</script>

<style scoped>

</style>