<template>
    <b-modal id="modal-view-answers" hide-footer title="Submitted answers" modal-class="modal-w-md">
        <div v-for="(answer, index) in answers" :key="index" class="mt-2 mb-4">
            <h5 class="heading5 heading5--secondary">{{answer.field.name}}</h5>

            <custom-field
                :field="answer.field"
                :value="answer.value"
                :editable="false"
                :disabled="true"
            >
            </custom-field>
        </div>
    </b-modal>
</template>

<script>
import CustomField from "@/components/customFields/CustomField";
import trackersCompanies from "@/services/trackers-companies.service";

export default {
    components: {
        CustomField
    },

    data() {
        return {
            trackerId: 0,
            startupId: 0,
            answers: [],
        }
    },

    methods: {
        init() {
            this.answers = []

            trackersCompanies.getApplicationData(this.trackerId, this.startupId).then(result => {
                this.answers = result.data
            })
        },

        open(trackerId, startupId) {
            this.trackerId = trackerId
            this.startupId = startupId
            this.init()
            this.$bvModal.show('modal-view-answers')
        },
    }
}
</script>

<style scoped>

</style>
