<script setup>
import {onMounted, ref} from "vue";
import Avatar from "vue-avatar";
import agencyClientsService from "@/services/agency-clients.service";
import useProxyLogin from "@/composables/useProxyLogin";

const clients = ref([]);

onMounted(() => {
    getClients();
});

async function getClients() {
    const {data} = await agencyClientsService.getClients();
    clients.value = data;
}

const {login} = useProxyLogin();

</script>

<template>
    <div>
        <div v-for="client in clients" :key="client.id">
            <Avatar
                :src="client.logo"
                :username="client.name"
            />

            {{client.name}}

            <a class="link" @click="login(client.id)">Login</a>
            <span>{{client.users_count}} {{stringHelper.getNoun('user', client.users_count)}}</span>
            <span v-if="client.last_activity === 'online'">Online</span>
            <span v-else>Last seen {{client.last_activity}}</span>
            <span>{{ client.trackers_count }} {{stringHelper.getNoun('tracker', client.trackers_count)}}</span>
        </div>
    </div>
</template>

<style scoped>

</style>
