<template>
    <b-modal id="modal-invite-startup" title="Invite Startup">
            <div class="form mt-3">
                <h5 class="heading5 heading5--secondary">Contact Email*</h5>
                <template v-if="typeof errors.contact_email !== 'undefined'">
                    <span class="form__error" v-for="(error, index) in errors.contact_email" :key="'contact_email-' + index">{{error}}</span>
                </template>

                <b-form-input class="form-control--mid mb-2" v-model="email"></b-form-input>
            </div>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canSave" @click="save">
                <span v-if="!loading">Invite</span>
                <span v-else><b-spinner class="mr-1" small></b-spinner>Loading...</span>
            </b-button>
        </template>
    </b-modal>
</template>

<script>

import invite from "@/services/invite.service"
import Vue from 'vue';

export default {
    props: {
        trackerId: Number
    },

    data() {
        return {
            id: 0,
            email: "",
            errors: {},
            result: "",
            loading: false,
        }
    },

    computed: {
        canSave() {
            if (this.loading) {
                return false
            }
            else {
                return this.email.length > 0
            }
        }
    },

    methods: {
        open(id) {
            this.id = id
            this.errors = {}
            this.$bvModal.show('modal-invite-startup')
            this.email = ""
        },

        save() {
            this.loading = true
            this.errors = {}
            this.result = ""

            let data = {
                email: this.email,
                tracker_id: this.trackerId,
            }

            invite.inviteStartup(this.id, data).then(response => {
                this.loading = false
                this.errors = response.data.errors
                this.result = response.data.result

                if (! Object.keys(this.errors).length) {
                    this.$bvModal.hide('modal-invite-startup');
                    this.$emit('invited')

                    if (response.data.failure) {
                        Vue.notify({
                            group: 'app',
                            type: 'error',
                            duration: 5000,
                            text: response.data.result
                        })
                    }
                    else {
                        Vue.notify({
                            group: 'app',
                            type: 'success',
                            duration: 5000,
                            text: response.data.result
                        })
                    }
                }

            })
        },
    }
}
</script>

<style scoped>

</style>
