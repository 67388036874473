<template>
    <div class="card card--bordered card--user">
        <div class="card--user__inner">
            <div class="card--user__image"></div>

            <div>
                <h6 class="heading6">Pali Sabbineni</h6>
                <p>Account Manager</p>
            </div>
        </div>

        <div class="card--user__controls">
            <a href="https://meetings.hubspot.com/pali-sabbineni" target="_blank" class="link-schedule" @click="scheduleMeeting">Schedule Meeting</a>
            <a class="link-envelope" @click="$refs.messageModal.show()">Send Message</a>
        </div>

        <message-modal
            id="enterprise-message-modal"
            ref="messageModal"
            title="Message Account Manager"
            type="accountManager"
        />
    </div>
</template>

<script>
import MessageModal from "@/components/MessageModal.vue";

export default {
    name: "ManagerBlock",
    components: {MessageModal},
    methods: {
        scheduleMeeting() {
            window.dataLayer.push(
                {
                    "event":"schedule meeting clicked"
                }
            );
        },
    }
}
</script>

<style scoped>

</style>
