import axios from '@/axios';

const RESOURCE_NAME = '/interaction-tags';

export default {

    getIndexList(data) {
        return axios.post(`${RESOURCE_NAME}/get-index-list`, data)
    },

    save(id, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}`, data)
    },

    getEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}`)
    },

    delete(id) {
        return axios.post(`${RESOURCE_NAME}/delete-tag?id=${id}`)
    },

    getListForInteraction(type) {
        return axios.get(`${RESOURCE_NAME}/get-list-for-interaction?type=${type}`)
    }
}
