<template>
    <div>
        <div class="topbar">

            <a @click="$router.go(-1)" class="link-back">Back</a>
            <div class="topbar__inner">
                <div class="topbar__key">
                    <header class="topbar__key__header">

                        {{info.name}}
                    </header>

                    Show
                    <select v-if="totalRowsCount >= 25" class="topbar__key__select" v-model="pageLength">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                    <template v-else>{{totalRowsCount}}</template>

                    entities

                </div>
            </div>
        </div>

        <div class="main-table-wrapper overflow-auto">
            <div class="main-table">
                <Grid
                    :columns="columns"
                    :data="startups"
                    :addCellData="addCellData"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    v-on:changePage="changePage"
                    v-on:changeSort="changeSort"
                    v-on:action="gridAction"
                >
                </Grid>
            </div>
        </div>

        <AddToTrackers
            ref="addToTrackers"
            :trackers-list="trackersList"
            v-on:trackersSelected="addToTrackers"
        >
        </AddToTrackers>
    </div>
</template>

<script>
import grid from "@/mixins/grid";
import ecosystems from "@/services/ecosystems.service";
import Grid from "@/components/Grid";
import AddToTrackers from "@/components/manage/SelectTrackers";
// import trackers from "@/services/trackers.service";
import trackersCompanies from "@/services/trackers-companies.service";
import {mapActions, mapGetters} from "vuex";

export default {
    mixins: [grid],

    components: {
        Grid,
        AddToTrackers,
    },

    data() {
        return {
            id: this.$route.params.id,

            info: {
                name: '',
            },

            startups: [],
            // trackersList: [],
            selectedStartupId: 0,

            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    component: 'SearchCompanyNameColumn',
                },
                {
                    label: 'Stage',
                    name: 'stage',
                },
                {
                    label: 'Location',
                    name: 'location',
                    component: 'SearchCompanyLocationColumn',
                },
                {
                    label: 'Total Raised',
                    name: 'total_raised',
                },
                {
                    label: 'Employees',
                    name: 'employees',
                },
                {
                    label: 'Added',
                    name: 'created_at',
                },
                {
                    label: 'Actions',
                    name: 'actions',
                    component: 'Actions',
                },
            ],

            addCellData: {
                companyType: "startups",
            },

            selectedStartups: [],
        }
    },

    computed: {
        ...mapGetters(['trackersList']),
    },

    mounted() {
        this.getInfo()
        this.getList()
        // this.getTrackersList()

        // this.$store.watch(state => {
        //     if (state.needUpdateTrackers) {
        //         this.getTrackersList()
        //     }
        // })

        this.fetchTrackers()
    },

    methods: {
        ...mapActions(['fetchTrackers']),
        getInfo() {
            ecosystems.getPortfolioInfo(this.id).then(response => {
                this.info = response.data
            })
        },

        // getTrackersList() {
        //     trackers.getListForStartupAdd().then(response => {
        //         this.trackersList = response.data
        //     })
        // },

        getList() {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let sort = this.sort

            ecosystems.getPortfolioStartups(this.id, {columns, sort, currentPage, pageLength}).then(response => {
                this.startups = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            })
        },

        gridAction(params) {
            if (params.action === 'add-to-tracker') {
                this.selectedStartupId = params.rowId
                this.$refs.addToTrackers.open()
                /*this.addStartupToTracker = params.rowId
                this.$refs.editTracker.open(0)*/
            }
        },

        addToTrackers(addedTrackers) {
            let trackersIds = addedTrackers.map(item => item.id)
                let startups = [this.selectedStartupId]
                trackersCompanies.addCompanies(trackersIds, startups).then(() => {
                    this.selectedStartupId = []
                })
        },
    }
}
</script>

<style scoped>

</style>
