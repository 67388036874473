<template>
    <div>
        <div class="topbar">
            <enterprises-header></enterprises-header>
        </div>

        <search
            v-if="$store.state.user.hasAccessToEnterprises"
            ref="search"
            search-type="enterprises"
            v-on:invite="requestConnection"
        >
        </search>

        <add-connection
            ref="requestConnection"
        >
        </add-connection>
    </div>
</template>

<script>
import grid from "@/mixins/grid";
import Search from "@/views/Search";
import addConnection from "@/components/connections/AddEnterpriseConnection.vue";
import EnterprisesHeader from "@/components/enterprises/EnterprisesHeader.vue";

export default {
    mixins: [grid],

    components: {
        EnterprisesHeader,
        Search,
        addConnection,
    },

    data() {
        return {

            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    component: 'SearchCompanyNameColumn',
                },
                {
                    label: 'Description',
                    name: 'description',
                },
                {
                    label: 'Category',
                    name: 'categoriesStr',
                },
                {
                    label: 'Location',
                    name: 'location',
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                },
            ],

            addCellData: {
                companyType: "enterprises",
            },
        }
    },

    mounted() {
        this.$store.watch(state => {
            if ('hasAccessToEnterprises' in state.user && !state.user.hasAccessToEnterprises) {
                this.$store.commit('setPageNotFound', true)
            }

            if (state.needUpdateEnterprises) {
                this.$refs.search.getList()
            }
        })
    },

    methods: {
        requestConnection(id) {
            this.$refs.requestConnection.open(id)
        },
    }
}
</script>

<style scoped>

</style>
