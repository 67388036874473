<template>
    <div>
        <date-picker v-model="date" class="mb-2 w-100" format = "MM/DD/YYYY"></date-picker>
        
        <Mentionable
            :keys="['@']"
            :items="usersMentionList"
            offset="6"
            insert-space
            placement="top-start"
        >
            <template #item-@="{ item }">
                <div class="user">
                    <Avatar
                        :username="item.name"
                        :src="item.avatar"
                        :size="25"
                    >
                    </Avatar>

                    <div class="mention-item__inner">
                        {{ item.value }}
                        <span class="dim">
                            ({{ item.name }})
                        </span>
                    </div>
                </div>
            </template>

            <textarea v-model="interaction.comment" class="form-control--mid form-control"></textarea>
        </Mentionable>
        
        <div v-if="companyType === accountTypes.STARTUP && tagsList.length" class="tags-list d-flex flex-wrap">
            <p>Interaction Tags:</p>
            
            <div v-if="addedTags.length" class="d-flex flex-wrap">
                <!--Tagged:-->
                <span v-for="tag in addedTags" :key="tag.id" class="tags-list__tag" :title="tag.name">
                    {{ tag.name }}
                    <a><span @click="removeTag(tag.id)" class="tags-list__tag__close"></span></a>
                </span>
            </div>

            <multiselect
                v-model="addTag"
                :options="actualTagsList"
                placeholder="Add Tag"
                label="name"
                track-by="id"
                class="multiselect-mid"
            />
        </div>
    </div>
</template>

<script>
import interactions from "@/services/interactions.service"
import tags from "@/services/interaction-tags.service"
import DatePicker from "vue2-datepicker";
import multiselect from "vue-multiselect";
import {Mentionable} from "vue-mention";
import Avatar from "vue-avatar";
import accountTypes from "@/constants/accountTypes";

export default {
    components: {
        DatePicker,
        Mentionable,
        multiselect,
        Avatar
    },

    props: {
        type: String,
        usersMentionList: Array,
        companyType: String,
        companyId: Number,
    },

    data() {
        return {
            id: 0,

            interaction: {
                type: this.type,
                comment: "",
                date: "",
                tags: [],
            },

            addedTags: [],
            tagsList: [],
            addTag: null,
            date: "",
        }
    },

    watch: {
        type: {
            immediate: true,
            handler() {
                if (this.type) {
                    this.init(0)
                    this.getTagsList()
                }
            }
        },

        date() {
            this.interaction.date = this.dateHelper.encodeDateForServer(this.date)
        },

        addTag() {
            if (this.addTag) {
                this.addedTags.push(this.addTag)
                this.addTag = null
            }
        },

        addedTags() {
            this.interaction.tags = this.addedTags.map(tag => tag.id)
        }
    },

    computed: {
        accountTypes() {
            return accountTypes
        },

        actualTagsList() {
            return this.tagsList.filter(tag => !this.addedTags.some(existedTag => existedTag.id === tag.id))
        },
    },

    methods: {
        init(id) {
            this.id = id
            this.interaction.type = this.type
            this.interaction.date = ""
            this.interaction.comment = ""
            this.addedTags = []

            if (this.id) {
                interactions.getEdit(this.id).then(response => {
                    this.interaction = response.data

                    if (this.interaction.date) {
                        this.date = this.dateHelper.decodeDateFromServer(this.interaction.date)
                    }

                    this.getTagsList()
                })
            }
            else {
                this.date = new Date()
            }
        },

        save() {
            interactions.save(this.id, this.companyType, this.companyId, this.interaction).then(() => {
                this.$emit('saved')
            })
        },

        getTagsList() {
            if (this.$store.getters.isEnterprise) {
                tags.getListForInteraction(this.interaction.type).then(response => {
                    this.tagsList = response.data
                    this.addedTags = this.tagsList.filter(tag => this.interaction.tags.some(tagId => parseInt(tagId) === parseInt(tag.id)))
                })
            }
        },

        removeTag(id) {
            this.addedTags = this.addedTags.filter(tag => tag.id !== id)
        },
    }
}
</script>

<style scoped>

</style>
