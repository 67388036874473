import axios from '@/axios';

const RESOURCE_NAME = '/ecosystem/portfolios';

export default {

    getPortfolios(data) {
        return axios.post(`${RESOURCE_NAME}/get-portfolios`, data)
    },

    save(id, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}`, data)
    },

    getInfo(id) {
        return axios.get(`${RESOURCE_NAME}/get-info?id=${id}`)
    },

    getEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}`)
    },

    getList() {
        return axios.get(`${RESOURCE_NAME}/get-list`)
    },

    getListToAddStartup(startupId) {
        return axios.get(`${RESOURCE_NAME}/get-list-to-add-startup?startup_id=${startupId}`)
    },

    getStartups(id, data) {
        return axios.post(`${RESOURCE_NAME}/get-startups?id=${id}`, data)
    },

    addStartups(portfolioIds, startupIds) {
        return axios.post(`${RESOURCE_NAME}/add-startups`, {portfolioIds: portfolioIds, startupIds: startupIds})
    },

    getStartupsListToAdd(id) {
        return axios.get(`${RESOURCE_NAME}/get-startups-list-to-add?id=${id}`)
    },

    getEnterprisesList(id) {
        return axios.get(`${RESOURCE_NAME}/get-enterprises-list?id=${id}`)
    },

    saveEnterprises(id, enterpriseIds) {
        return axios.post(`${RESOURCE_NAME}/save-enterprises?id=${id}`, {enterpriseIds: enterpriseIds})
    },

    getPortfoliosForEnterprise(enterpriseId) {
        return axios.get(`${RESOURCE_NAME}/get-portfolios-for-enterprise?enterprise_id=${enterpriseId}`);
    },

    deletePortfolio: portfolioId => axios.post(`${RESOURCE_NAME}/delete-portfolio?id=${portfolioId}`)
}
