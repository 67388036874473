<template>
    <div>
        <div v-if="!opportunity" class="loading__spinner mt-3">
            <span><b-spinner class="mb-1 mr-2" small></b-spinner>Loading...</span>
        </div>
        <template v-else-if="opportunity.posted_by_name">
            <h4 class="heading4">Posted By</h4>

            <div class="page-startup__aside__header">
                <div class="image-block image-block--rounded--md">
                    <div aria-hidden="true" class="vue-avatar--wrapper" style="display: flex; width: 60px; height: 60px; border-radius: 50%; align-items: center; justify-content: center; text-align: center; user-select: none; background: 0% 0% / 75px 75px no-repeat scroll content-box border-box transparent;">
                        <img :src="opportunity.posted_by_avatar" :alt="opportunity.posted_by_name">
                    </div>
                </div>

                <div>
                    <h4 class="heading4">{{ opportunity.posted_by_name }}</h4>
                    <p>{{ opportunity.posted_by_title }}</p>

                    <a v-if="opportunity.posted_by_linkedin_url" :href="opportunity.posted_by_linkedin_url" target="_blank" class="icon-in"></a>
                </div>
            </div>
        </template>

        <h4 v-if="opportunity" class="heading4">Info</h4>

        <dl v-if="opportunity" class="definition-list definition-list--notaligned">
            <dt>Created:</dt>
            <dd>{{ opportunity.created }}</dd>

            <div v-if="opportunity.start_date && opportunity.end_date">
                <dt>Timeline:</dt>
                <dd>{{ opportunity.start_date }} - {{ opportunity.end_date }}</dd>
            </div>
        </dl>

        <div v-if="opportunity" class="buttons-container">
            <button
                v-for="category in opportunity.categories"
                v-bind:key="category.id"
                class="btn btn-transparent"
                @click="goToOpportunitysWithCategory(category.id)"
            >
                {{ category.name }}
            </button>
        </div>

        <h4 v-if="opportunity" class="heading4">Activity</h4>

        <ul v-if="opportunity" class="list-activity">
            <li class="list-activity__ecosystem">{{ opportunity.total_startups_count }} startups in Opportunity</li>
            <li class="list-activity__startup">You've added {{ opportunity.added_startups_count }} startups</li>
            <li class="list-activity__track">{{ opportunity.advanced_startups_count }} of your startups have advanced</li>
        </ul>
    </div>
</template>

<script>
import qs from 'qs'

export default {
    name: 'PostedBy',
    props: {
        opportunity: Object
    },
    methods: {
        goToOpportunitysWithCategory: (categoryId) => {
            window.open(`/?${qs.stringify({ categories: [categoryId] }, { arrayFormat: 'brackets' })}`)
        }
    }
}
</script>
