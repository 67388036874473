<template>
    <ul class="action-logs-list">
        <li v-for="(log, logIndex) in logs" :key="logIndex">
            <Avatar
                :username="log.user_name"
                :src="log.user_avatar"
                :size="44">
            </Avatar>

            <div class="action-logs-list__inner">
                <span class="action-logs-list__user" :class="{deleted: log.user_deleted}">{{log.user_name}}</span>

                <small>{{log.operation}}</small>

                <span class="action-logs-list__operation" v-if="typeof log.tag !== 'undefined'">
                    <a :href="'/startups?tag=' + log.tag.name" target="_blank">{{log.tag.name}}</a>
                </span>

                <span class="action-logs-list__operation" v-if="typeof log.startup !== 'undefined'">
                    <div class="image-block">
                        <img :src="LogoHelper.getLogo(log.startup.logo)">
                    </div>
                    <a :href="'/startups/' + log.startup.id" target="_blank">{{log.startup.name}}</a>
                </span>

                <span class="action-logs-list__operation" v-if="typeof log.ecosystem !== 'undefined'">
                    <div class="image-block">
                        <img :src="LogoHelper.getLogo(log.ecosystem.logo)">
                    </div>
                    <a :href="'/ecosystems/' + log.ecosystem.id" target="_blank">{{log.ecosystem.name}}</a>
                </span>

                <small v-if="typeof log.between !== 'undefined'">
                    {{log.between}}
                </small>

                <span class="mr-2" v-if="typeof log.tracker !== 'undefined'">
                    <a class="action-logs-list__tracker-button" :href="'/trackers/' + log.tracker.id" target="_blank">{{log.tracker.name}}</a>
                </span>

                <small v-if="typeof log.between2 !== 'undefined'">
                    {{log.between2}}
                </small>

                <time v-if="typeof types[type] !== 'undefined' || !type" class="action-logs-list__date">{{log.time}}</time>

                <div v-if="typeof log.folder !== 'undefined'">
                    <a class="action-logs-list__tracker-folder" :href="'/trackers/folder/' + log.folder.id" target="_blank">{{log.folder.name}}</a>
                </div>

                <template v-if="typeof log.users !== 'undefined'">
                    <div class="action-logs-list__user-wrapper">
                        <div class="action-logs-list__user-inner" v-for="(item, index) in log.users" :key="index">
                            <Avatar
                                :username="item.name"
                                :src="item.avatar"
                                :size="40">
                            </Avatar>
                            {{item.name}} <template v-if="item.post">| {{item.post}}</template>
                        </div>

                        <template v-if="log.add_count">
                            <span class="action-logs-list__more">+ {{log.add_count}} More</span>
                        </template>
                    </div>
                </template>

                <template v-if="typeof log.startups !== 'undefined'">
                    <div>
                        <template v-for="(item, index) in log.startups">
                            <div class="action-logs-list__operation" :key="index">
                                <div class="image-block">
                                    <img :src="LogoHelper.getLogo(item.logo)" alt="">
                                </div>
                                <a :href="'/startups/' + item.id" target="_blank">{{item.name}}</a>
                            </div>
                        </template>

                        <template v-if="log.add_count">
                            <span class="action-logs-list__more">+ {{log.add_count}} More</span>
                        </template>
                    </div>
                </template>

                <template v-if="typeof log.ecosystems !== 'undefined'">
                    <div>
                        <div class="action-logs-list__operation" v-for="(item, index) in log.ecosystems" :key="index">
                            <div class="image-block">
                                <img :src="LogoHelper.getLogo(item.logo)" alt="">
                            </div>
                            <a :href="'/ecosystems/' + item.id" target="_blank">{{item.name}}</a>
                        </div>

                        <template v-if="log.add_count">
                            <span class="action-logs-list__more">+ {{log.add_count}} More</span>
                        </template>
                    </div>
                </template>

                <template v-if="typeof log.trackers !== 'undefined'">
                    <div class="action-logs-list__trackers">
                        <span class="action-logs-list__tracker" v-for="(item, index) in log.trackers" :key="index">
                            <a :href="'/trackers/' + item.id" target="_blank">{{item.name}}</a>
                        </span>

                        <template v-if="log.add_count">
                            <span class="action-logs-list__more">+ {{log.add_count}} More</span>
                        </template>
                    </div>
                </template>

                <template v-if="typeof log.fields !== 'undefined'">
                    <div>
                        <div class="action-logs-list__custom" v-for="(item, index) in log.fields" :key="index">
                            {{item.name}}
                        </div>

                        <template v-if="log.add_count">
                            <span class="action-logs-list__more">+ {{log.add_count}} More</span>
                        </template>
                    </div>
                </template>

                <template v-if="typeof log.field_value !== 'undefined'">
                    <div class="action-logs-list__custom line-clamp line-clamp1">
                        <b>{{log.field_value.name}}</b>
                        <template v-if="log.field_value.value.length">: {{log.field_value.value}}</template>
                    </div>
                </template>

                <template v-if="typeof log.note !== 'undefined'">
                    <div>
                        <div class="line-clamp line-clamp1 action-logs-list__activity">
                            <span :class="getActivityIconClass(log.note.type)"></span>{{log.note.text}}
                        </div>
                    </div>
                </template>

                <template v-if="typeof log.tags !== 'undefined'">
                    <div class="tags-list tags-list--light">
                        <div class="tags-list__tag" v-for="(item, index) in log.tags" :key="index">
                            <a :id="'tag-' + prefix + '-' + logIndex + '-' + item.id" :href="'/startups?tag=' + item.name" target="_blank">{{item.name}}</a>
                            <b-tooltip :target="'tag-' + prefix + '-' + logIndex + '-' + item.id" placement="right" variant="light" :title="item.description"/>
                        </div>

                        <template v-if="log.add_count">
                            <span class="action-logs-list__more">+ {{log.add_count}} More</span>
                        </template>
                    </div>
                </template>

                <template v-if="typeof log.tasks !== 'undefined'">
                    <div class="action-logs-list__tasks">
                        <template v-for="(item, index) in log.tasks">
                            <div class="action-logs-list__task" v-if="item.name" :key="index">
                                <a :href="'/?open_task=' + item.id" target="_blank">{{item.name}}</a>
                            </div>
                        </template>

                        <template v-if="log.add_count">
                            <span class="action-logs-list__more">+ {{log.add_count}} More</span>
                        </template>

                        <div class="action-logs-list__operation" v-if="typeof log.tasks[0].startup !== 'undefined'">
                            <div class="image-block">
                                <img :src="LogoHelper.getLogo(log.tasks[0].startup.logo)" alt="">
                            </div>
                            <a :href="'/startups/' + log.tasks[0].startup.id" target="_blank">{{log.tasks[0].startup.name}}</a>
                        </div>

                        <span class="action-logs-list__tracker" v-if="typeof log.tasks[0].tracker !== 'undefined'">
                            <a :href="'/trackers/' + log.tasks[0].tracker.id" target="_blank">{{log.tasks[0].tracker.name}}</a>
                        </span>

                        <template   v-if="typeof log.tasks[0].status !== 'undefined'">
                            <span :class="{'action-logs__status-open': log.tasks[0].status == 'Open',
                            'action-logs__status-progress': log.tasks[0].status == 'In progress',
                            'action-logs__status-blocked': log.tasks[0].status == 'Blocked',
                            'action-logs__status-done': log.tasks[0].status == 'Done'}">{{log.tasks[0].status}}</span>
                        </template>

                    </div>
                </template>

                <template v-if="typeof log.contacts !== 'undefined'">
                    <div>
                        <div class="action-logs-list__user-inner" v-for="(item, index) in log.contacts" :key="index">
                            <Avatar
                                :username="item.name"
                                :src="item.avatar"
                                :size="40">
                            </Avatar>
                            {{item.details}}
                        </div>

                        <template v-if="log.add_count">
                            <span class="action-logs-list__more">+ {{log.add_count}} More</span>
                        </template>
                    </div>
                </template>

                <template v-if="typeof log.files !== 'undefined'">
                    <div class="action-logs-list__file-wrapper">
                        <div class="action-logs-list__file" v-for="(item, index) in log.files" :key="index">
                            {{item.name}}
                        </div>

                        <template v-if="log.add_count">
                            <span class="action-logs-list__more">+ {{log.add_count}} More</span>
                        </template>
                    </div>
                </template>

                <template v-if="typeof log.startup_stage !== 'undefined'">
                    <div>
                        <div class="action-logs-list__operation">
                            <div class="image-block">
                                <img :src="LogoHelper.getLogo(log.startup_stage.startup.logo)" alt="">
                            </div>
                            <a :href="'/startups/' + log.startup_stage.startup.id" target="_blank">{{log.startup_stage.startup.name}}</a>
                        </div>
                        was moved to {{log.startup_stage.stage}}
                    </div>
                </template>
            </div>
        </li>
    </ul>
</template>

<script>
import Avatar from "vue-avatar";
import {activityFeedTypes} from "@/constants/constants";

export default {
    props: {
        logs: Array,
        type: String,
        prefix: String,
    },

    components: {
        Avatar,
    },

    data() {
        return {
            types: activityFeedTypes
        }
    },

    methods: {
        getActivityIconClass(type) {
            if (type == 'note') {
                return 'activity-list__title--note'
            }
            else if (type == 'phone_call') {
                return 'activity-list__title--call'
            }
            else if (type == 'email') {
                return 'activity-list__title--email'
            }
            else if (type == 'meeting') {
                return 'activity-list__title--meeting'
            }
            else if (type == 'demo') {
                return 'activity-list__title--demo'
            }
            else {
                return ''
            }
        },
    }
}
</script>

<style scoped>

</style>
