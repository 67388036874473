<template>
    <div>
        <Header></Header>

        <div class="topbar">

            <div class="topbar__inner">
                <footer class="topbar__key__footer">

                    <ul class="topbar__side actions-list" v-if="logs.length">
                        <li>
                            <div>
                                <b-dropdown id="dropdown-1" text="Actions" class="topbar__side__actions">
                                    <b-dropdown-item class="action" @click="exportCSV">
                                        <font-awesome-icon :icon="['fa', 'file-csv']" />
                                        Export CSV
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </li>
                    </ul>
                </footer>
            </div>

            <div class="input-checkbox">
                <input id="show-latest" type="checkbox" v-model="showLatest">
                <label for="show-latest">Only show latest</label>
            </div>
        </div>

        <div class="main-table-height">
            <Grid
                :columns="columns"
                :data="logs"
                :addCellData="addCellData"
                :has-filters="true"
                :page-length="parseInt(pageLength)"
                :total-rows-count="totalRowsCount"
                :sort="sort"
                :currentPage="currentPage"
                v-on:changePage="changePage"
                v-on:changeFilters="changeFilters"
                v-on:changeSort="changeSort"
            >
            </Grid>
        </div>
    </div>
</template>

<script>
import Grid from "@/components/Grid";
import grid from "@/mixins/grid";
import Header from "@/components/logs/Header";
import actionLogs from "@/services/action-logs.service";

export default {
    components: {
        Grid,
        Header,
    },

    mixins: [grid],

    data() {
        return {
            logs: [],

            columns: [
                {
                    label: 'Startup/Tracker/User',
                    name: 'startup',
                    filter: 'text',
                    sortable: true,
                    component: 'SearchCompanyNameColumn',
                },
                {
                    label: 'Interaction Type',
                    name: 'object_type',
                    filter: 'select',
                    options: [
                        {
                            id: 'startup',
                            name: 'Startup'
                        },
                        {
                            id: 'tracker',
                            name: 'Tracker'
                        },
                        {
                            id: 'contact',
                            name: 'Contact'
                        },
                        {
                            id: 'contact-note',
                            name: 'Contact Note'
                        },
                        {
                            id: 'custom-value',
                            name: 'Custom Value'
                        },
                        {
                            id: 'startup-profile-custom-field',
                            name: 'Startup Profile Field'
                        },
                        {
                            id: 'startup-profile-custom-field-section',
                            name: 'Startup Profile Field Section'
                        },
                        {
                            id: 'tracker-custom-field',
                            name: 'Tracker Field'
                        },
                        {
                            id: 'custom-value-section',
                            name: 'Custom Value Section'
                        },
                        {
                            id: 'interaction',
                            name: 'Interaction'
                        },
                        {
                            id: 'startup-note',
                            name: 'Startup Note'
                        },
                        {
                            id: 'startup-tag',
                            name: 'Startup Tag'
                        },
                        {
                            id: 'tracker-startup',
                            name: 'Tracker Startup'
                        },
                        {
                            id: 'tracker-review',
                            name: 'Tracker Review'
                        },
                        {
                            id: 'profile',
                            name: 'Profile'
                        },
                        {
                            id: 'user',
                            name: 'User'
                        },
                        {
                            id: 'download-report',
                            name: 'Download Report'
                        },
                    ],
                    sortable: true,
                },
                {
                    label: 'Operation',
                    name: 'operation',
                    filter: 'select',
                    options: [
                        {
                            id: 'C',
                            name: 'Create'
                        },
                        {
                            id: 'U',
                            name: 'Update'
                        },
                        {
                            id: 'D',
                            name: 'Delete'
                        },
                    ],
                    sortable: true,
                },
                {
                    label: 'User',
                    name: 'user_name',
                    component: 'RawColumn',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Date',
                    name: 'created_at',
                    component: 'Date',
                    sortable: true,
                },
            ],

            addCellData: {
                companyType: "startups",
            },

            showLatest: true,

            filters: {},
        }
    },

    watch: {
        showLatest() {
            this.getList()
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        getList(forExport = false) {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let filters = this.arrayHelper.cloneArray(this.filters)
            let sort = this.sort

            if (this.showLatest) {
                filters.showLatest = true
            }

            actionLogs.getActionLogs({columns, sort, currentPage, pageLength, filters: filters, forExport}).then(response => {
                if (!forExport) {
                    this.logs = response.data.data
                    this.totalRowsCount = parseInt(response.data.total_count)
                    this.currentPage = response.data.current_page
                }
                else {
                    this.fileHelper.download(response.data, 'activity.csv')
                }
            })
        },

        changeFilters(filters) {
            this.filters = filters
            this.getList()
        },

        exportCSV() {
            this.getList(true)
        }
    }
}
</script>

<style scoped>

</style>
