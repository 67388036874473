<template>
    <div>
        <Header></Header>

        <div class="topbar">

            <div class="topbar__inner">
                <footer class="topbar__key__footer">

                    <ul class="topbar__side actions-list" v-if="logs.length">
                        <li>
                            <div>
                                <b-dropdown id="dropdown-1" text="Actions" class="topbar__side__actions">
                                    <b-dropdown-item class="action" @click="exportCSV">
                                        <font-awesome-icon :icon="['fa', 'file-csv']" />
                                        Export CSV
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </li>
                    </ul>
                </footer>
            </div>

            <div class="input-checkbox">
                <input id="show-latest" type="checkbox" v-model="showLatest">
                <label for="show-latest">Only show latest</label>
            </div>
        </div>

        <div class="main-table-height">
            <Grid
                :columns="columns"
                :data="logs"
                :has-filters="true"
                :page-length="parseInt(pageLength)"
                :total-rows-count="totalRowsCount"
                :sort="sort"
                :currentPage="currentPage"
                :addCellData="addStartupData"
                v-on:changePage="changePage"
                v-on:changeFilters="changeFilters"
                v-on:changeSort="changeSort"
            >
            </Grid>
        </div>
    </div>
</template>

<script>
import Grid from "@/components/Grid";
import grid from "@/mixins/grid";
import customFields from "@/services/custom-fields.service";
import team from "@/services/team.service";
import tags from "@/services/tags.service";
import Header from "@/components/logs/Header";

export default {
    components: {
        Grid,
        Header,
    },

    mixins: [grid],

    data() {
        return {
            logs: [],

            columns: [
                {
                    label: 'Company',
                    name: 'startup',
                    component: 'SearchCompanyNameColumn',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Section',
                    name: 'section',
                    filter: 'select',
                    options: [],
                    sortable: true,
                },
                {
                    label: 'User',
                    name: 'user',
                    component: 'RawColumn',
                    filter: 'select',
                    options: [],
                    sortable: true,
                },
                {
                    label: 'Field Name',
                    name: 'field',
                    filter: 'select',
                    options: [],
                    sortable: true,
                },
                {
                    label: 'Previous Value',
                    name: 'old_value',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'New Value',
                    name: 'new_value',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Date',
                    name: 'created_at',
                    component: 'Date',
                    sortable: true,
                },
            ],

            addStartupData: {
                companyType: "startups",
                tagsList: [],
            },

            filters: {},
            showLatest: true,
        }
    },

    mounted() {
        this.getTagsList()
        this.getFieldsList()
        this.getList()
    },

    watch: {
        showLatest() {
            this.getList()
        }
    },

    methods: {
        getList(forExport = false) {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let filters = this.arrayHelper.cloneArray(this.filters)
            let sort = this.sort

            if (this.showLatest) {
                filters.showLatest = true
            }

            customFields.getChangeLogs({columns, sort, currentPage, pageLength, filters: filters, forExport}).then(response => {
                if (!forExport) {
                    this.logs = response.data.data
                    this.totalRowsCount = parseInt(response.data.total_count)
                    this.currentPage = response.data.current_page
                }
                else {
                    this.fileHelper.download(response.data, 'activity.csv')
                }
            })
        },

        getTagsList() {
            tags.getList().then(response => {
                this.addStartupData.tagsList = response.data
            })
        },

        changeFilters(filters) {
            this.filters = filters
            this.getList()
        },

        getFieldsList() {
            customFields.getFieldsWithSections('company_diligence').then(response => {
                let fields = []
                let sections = []

                for (let sectionId in response.data) {
                    let sectionData = response.data[sectionId]

                    sections.push({
                        id: sectionId,
                        name: sectionData.name
                    })

                    sectionData.fields.forEach(item => {
                        if (item.field_type !== 'static_text') {
                            fields.push(item)
                        }
                    })
                }

                this.columns[1].options = sections
                this.columns[3].options = fields
            })

            team.getUsersList().then(response => {
                this.columns[2].options = response.data
            })
        },

        exportCSV() {
            this.getList(true)
        }
    }
}
</script>

<style scoped>

</style>