<template>
    <div>
        <div class="topbar topbar--full pt-4">
            <router-link to="/settings" class="link-back">All Settings</router-link>
            <h2 class="heading1 mb-0">Admin</h2>
        </div>
        
        <div class="admin-page">
            <Header/>
            <div class="admin-page__content">
                <div class="admin-page__settings">
                    <div class="admin-page__settings__item">
                        <h5 class="heading5 heading5--secondary">Add Startups Customization</h5>
                        <p>Tags and custom fields will display on the popup when adding new startups</p>
        
                        <a @click="showFieldsModal" class="link link-open">Edit Fields</a>
        
                        <div class="admin-page__settings__item__footer">
                            <a @click="$bvModal.show('preview-add-startup-modal')" class="link-preview">Preview feature</a>
        
                            <div class="content-switcher content-switcher--secondary">
                                <div class="content-switcher__slider" :class="{'enabled': settings.show_tags_on_add_startup_form}">
                                    <input type="checkbox" id="show-tags-switcher__slider" v-model="settings.show_tags_on_add_startup_form">
                                    <label for="show-tags-switcher__slider"></label>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div class="admin-page__settings__item">
                        <h5 class="heading5 heading5--secondary">Hide Company From Directory</h5>
                        <p>Enabling will hide your company from the SwitchPitch enterprise directory</p>
                        
                        <div class="admin-page__settings__item__footer admin-page__settings__item__footer--eco">
                            <div class="content-switcher content-switcher--secondary">
                                <div class="content-switcher__slider" :class="{'enabled': settings.hidden}">
                                    <input type="checkbox" id="hidden-switcher__slider" v-model="settings.hidden">
                                    <label for="hidden-switcher__slider"></label>
                                </div>
                            </div>
                        </div>

                        <div class="admin-page__settings__item__footer admin-page__settings__item__footer--startup">
                            <div class="content-switcher content-switcher--secondary">
                                <div class="content-switcher__slider" :class="{'enabled': settings.hidden_for_startups}">
                                    <input type="checkbox" id="hidden-for-startups-switcher__slider" v-model="settings.hidden_for_startups">
                                    <label for="hidden-for-startups-switcher__slider"></label>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div class="admin-page__settings__item">
                        <h5 class="heading5 heading5--secondary">Action Logs & Custom Field History</h5>
                        <p>Admins can access user action logs and custom field edit history from the profile dropdown.</p>
                        <div class="admin-page__settings__item__footer">
                            <a @click="$bvModal.show('preview-fields-history-modal')" class="link-preview">Preview feature</a>
        
                            <div class="content-switcher content-switcher--secondary">
                                <div class="content-switcher__slider" :class="{'enabled': settings.show_fields_history}">
                                    <input type="checkbox" id="show_fields_history-switcher__slider" v-model="settings.show_fields_history">
                                    <label for="show_fields_history-switcher__slider"></label>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div class="admin-page__settings__item">
                        <h5 class="heading5 heading5--secondary">Analyst Hours</h5>
                        <p>Show analyst diligence hours on the homepage and on empty diligence fields in startup profiles</p>
        
                        <div class="admin-page__settings__item__footer">
                            <a @click="$bvModal.show('preview-analyst-hours-modal')" class="link-preview">Preview feature</a>
                        
                            <div class="content-switcher content-switcher--secondary">
                                <div class="content-switcher__slider" :class="{'enabled': settings.analyst_hours}">
                                    <input type="checkbox" id="analyst_hours-switcher__slider" v-model="settings.analyst_hours">
                                    <label for="analyst_hours-switcher__slider"></label>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div class="admin-page__settings__item">
                        <h5 class="heading5 heading5--secondary">Show In Search</h5>
                        <p>Select company types to show in search by default</p>

                        <div class="input-checkbox">
                            <input id="search_big_companies" type="checkbox" v-model="settings.search_big_companies">
                            <label for="search_big_companies">Large companies</label>
                        </div>

                        <div class="input-checkbox">
                            <input id="search_inactive_companies" type="checkbox" v-model="settings.search_inactive_companies">
                            <label for="search_inactive_companies">Inactive companies</label>
                        </div>

                        <div class="input-checkbox">
                            <input id="search_acquired_companies" type="checkbox" v-model="settings.search_acquired_companies">
                            <label for="search_acquired_companies">Acquired companies</label>
                        </div>
                    </div>

                    <div class="admin-page__settings__item">
                        <h5 class="heading5 heading5--secondary">Activity Feed</h5>
                        <p>View updates from your team and SwitchPitch on the Activity tab and startup profiles</p>

                        <div class="input-checkbox">
                            <input id="usersFeedAccessLimited" type="checkbox" :disabled="!usersFeedAccessEnabled" v-model="usersFeedAccessLimited">
                            <label for="usersFeedAccessLimited">Non-admins only see own data</label>
                        </div>

                        <!-- Since Activity migrated to Insights page widget. Temporary hidden ability to disable Feed.
                        ToDo: cleanup code from "usersFeedAccessEnabled" after Insights page is live and concept of new Activity feed widget approved
                        <div class="admin-page__settings__item__footer">
                            <a @click="$bvModal.show('preview-activity-feed-modal')" class="link-preview">Preview feature</a>
                            <div class="content-switcher content-switcher--secondary">
                                <div class="content-switcher__slider" :class="{'enabled': usersFeedAccessEnabled}">
                                    <input type="checkbox" id="usersFeedAccessEnabled-switcher__slider" v-model="usersFeedAccessEnabled">
                                    <label for="usersFeedAccessEnabled-switcher__slider"></label>
                                </div>
                            </div>
                        </div>
                        -->
                    </div>
                    <div class="admin-page__settings__item"></div>
                </div>
            </div>
    
            <b-modal id="preview-add-startup-modal" modal-class="admin-page__preview-modal" hide-footer>
                <img :src="require('@/images/modal-previews/add-startup-preview.png')">
            </b-modal>
    
            <b-modal id="preview-fields-history-modal" modal-class="admin-page__preview-modal" hide-footer>
                <img :src="require('@/images/modal-previews/fields-history-preview.png')">
            </b-modal>
    
            <b-modal id="preview-analyst-hours-modal" modal-class="admin-page__preview-modal" hide-footer>
                <img :src="require('@/images/modal-previews/analyst-hours.png')">
            </b-modal>

            <b-modal id="preview-activity-feed-modal" modal-class="admin-page__preview-modal" hide-footer>
                <img :src="require('@/images/modal-previews/activity-feed-preview.png')">
            </b-modal>
    
            <b-modal id="custom-fields" modal-class="custom-fields" title="Custom Fields On Add Startup Popup">
                <div>
                    <p>Select the fields you would like to appear when you’re adding a new startup.</p>
                    
                    <h5 class="heading5 heading5--secondary">Selected Fields</h5>
    
                    <select v-model="newField" @change="addField" class="multiselect__input selectbox selectbox-mid .w-75">
                        <option :value="null">
                            Select field to add
                        </option>∂
                        <option v-for="(field, index) in notAddedFields" :key="index" :value="field">
                            {{field.name}}
                        </option>
                    </select>
    
                    <ul class="rows-list">
                        <li v-for="(field, index) in fieldsOnStartupAdd" :key="index">
                            {{field.name}}
                            <a @click="removeField(field.id)" class="remove-action">Remove</a>
                        </li>
                    </ul>
                </div>
    
                <template #modal-footer>
                    <b-button variant="primary" size="lg" @click="saveFieldsOnStartupAdd">Done</b-button>
                </template>
            </b-modal>
        </div>
    </div>
</template>

<script>

import Header from "@/views/settings/admin/AdminHeader";
import enterprise from "@/services/enterprise.service";
import customFields from "@/services/custom-fields.service";
export default {
    components: {
        Header
    },
    data() {
        return {
            settings: {
                show_tags_on_add_startup_form: false,
                hidden: false,
                hidden_for_startups: false,
                show_fields_history: false,
                analyst_hours: false,
                search_big_companies: false,
                search_inactive_companies: false,
                search_acquired_companies: false,
                users_feed_access: 'disabled',
            },

            usersFeedAccessEnabled: false,
            usersFeedAccessLimited: false,

            newField: null,
            fieldsOnStartupAdd: [],
            profileFields: [],
            watchChanges: false
        }
    },

    mounted() {
        this.getSettings()
    },

    computed: {
        notAddedFields() {
            return this.profileFields.filter(item => !this.fieldsOnStartupAdd.some(field => field.id === item.id))
        }
    },

    methods: {
        async getSettings() {
            const response = await enterprise.getFeatureSettings()
            for (let settingsName in response.data) {
                this.settings[settingsName] = response.data[settingsName]
            }

            this.usersFeedAccessEnabled = this.settings.users_feed_access !== 'disabled'
            this.usersFeedAccessLimited = this.settings.users_feed_access === 'limited'

            this.$nextTick(() => {
                for (let settingsName in this.settings) {
                    this.$watch('settings.' + settingsName, () => {
                        if (this.watchChanges) {
                            this.updateSettings(settingsName)
                        }
                    })
                }

                ['usersFeedAccessEnabled','usersFeedAccessLimited'].forEach(prop => {
                    this.$watch(prop, () => {
                        if (prop === 'usersFeedAccessEnabled' && !this.usersFeedAccessEnabled && this.usersFeedAccessLimited) {
                            this.usersFeedAccessLimited = false
                        }

                        this.settings.users_feed_access = this.usersFeedAccessLimited ? 'limited' : this.usersFeedAccessEnabled ? 'full' : 'disabled'
                    })
                })

                this.watchChanges = true
            })
        },

        async updateSettings(name) {
            await enterprise.setFeatureSettings(name, this.settings[name])
            this.$store.commit('setFeatureSettings', {name, value: this.settings[name]})
        },

        async showFieldsModal() {
            let response = await customFields.getFieldsForStartupAddForm()
            this.fieldsOnStartupAdd = response.data
            response = await customFields.getFieldsArray('company_diligence')
            this.profileFields = response.data.filter(item => item.type !== 'file')
            this.$bvModal.show('custom-fields')
        },

        addField() {
            if (this.newField) {
                this.fieldsOnStartupAdd.push(this.newField)
                this.newField = null
            }
        },

        removeField(id) {
            this.fieldsOnStartupAdd = this.fieldsOnStartupAdd.filter(item => item.id !== id)
        },

        saveFieldsOnStartupAdd() {
            const fields = this.fieldsOnStartupAdd.map(item => item.id)
            customFields.setFieldsOnStartupAdd(fields)
            this.$bvModal.hide('custom-fields')
        },
    }
}

</script>


<style scoped>

</style>
