<template>
    <main class="main" :class="{'main--collapsed': leftCollapsed}">
        <aside class="aside">
            <div class="aside__inner">
                <header class="aside__header"> Filters
                    <a v-if="!modal" class="aside__collapse" @click="leftCollapsed = !leftCollapsed"></a>
                </header>

                <ul class="filters__list">
                    <li v-if="false">
                        <a class="filters__list__title" v-b-toggle.company-type>
                            Company Type
                            <span class="hint" title="The text for the hint">i</span>
                        </a>

                        <b-collapse class="filters__list__content" id="company-type">
                            <ul class="list-input">
                                <li class="input-radio mb-2">
                                    <input id="rfilter-fundingSeries-Angel" type="radio" name="qwe" value="[object Object]">
                                    <label for="rfilter-fundingSeries-Angel">Startups</label>
                                </li>
                                <li class="input-radio mb-2">
                                    <input id="r2filter-fundingSeries-Angel" type="radio" name="qwe" value="[object Object]">
                                    <label for="r2filter-fundingSeries-Angel">Ecosystems</label>
                                </li>
                            </ul>
                        </b-collapse>
                    </li>
                    <li>
                        <a class="filters__list__title" v-b-toggle.activity-type>Activity Type</a>

                        <b-collapse class="filters__list__content" id="activity-type">
                            <ul class="filters__list__choose">
                                <li class="input-checkbox mr-1">
                                    <input id="filter-activityType-all" type="checkbox" v-model="showAll">
                                    <label for="filter-activityType-all">All</label>
                                </li>
                                <li class="input-checkbox mr-1">
                                    <input id="filter-activityType-notes" type="checkbox" v-model="filter.activityType.notes">
                                    <label for="filter-activityType-notes">Notes</label>
                                </li>
                                <li class="input-checkbox mr-1">
                                    <input id="filter-activityType-contacts" type="checkbox" v-model="filter.activityType.contacts">
                                    <label for="filter-activityType-contacts">Contacts</label>
                                </li>
                                <li class="input-checkbox mr-1">
                                    <input id="filter-activityType-files" type="checkbox" v-model="filter.activityType.files">
                                    <label for="filter-activityType-files">Files</label>
                                </li>
                                <li class="input-checkbox mr-1">
                                    <input id="filter-activityType-interactions" type="checkbox" v-model="filter.activityType.interactions">
                                    <label for="filter-activityType-interactions">Interactions</label>
                                </li>
                            </ul>
                        </b-collapse>
                    </li>
                    <li>
                        <a class="filters__list__title" v-b-toggle.period>Filter By Date</a>

                        <b-collapse class="filters__list__content" id="period">
                            <date-picker v-model="period" range></date-picker>
                        </b-collapse>
                    </li>
                    <li>
                        <a class="filters__list__title" v-b-toggle.trackers>Filter By Trackers</a>

                        <b-collapse class="filters__list__content" id="trackers">
                            <multiselect
                                v-model="filter.trackers"
                                :multiple="true"
                                placeholder="Select trackers"
                                :options="trackersList"
                                select-label=""
                                deselect-label=""
                                label="name"
                                track-by="name"
                                class="multiselect-mid pl-2"
                            />
                        </b-collapse>
                    </li>
                    <li v-if="$store.state.isAdmin || $store.state.user.settings.users_feed_access === 'full'">
                        <a class="filters__list__title" v-b-toggle.users>Filter By Users</a>

                        <b-collapse class="filters__list__content" id="users">
                            <multiselect
                                v-model="filter.users"
                                :multiple="true"
                                placeholder="Select users"
                                :options="usersList"
                                select-label=""
                                deselect-label=""
                                label="name"
                                track-by="name"
                                class="multiselect-mid pl-2"
                            />
                        </b-collapse>
                    </li>
                    <li>
                        <a class="filters__list__title" v-b-toggle.tags>Filter By Tags</a>

                        <b-collapse class="filters__list__content" id="tags">
                            <multiselect
                                v-model="filter.tags"
                                :multiple="true"
                                placeholder="Select tags"
                                :options="tagsList"
                                select-label=""
                                deselect-label=""
                                label="name"
                                track-by="name"
                                class="multiselect-mid pl-2"
                            />
                        </b-collapse>
                    </li>
                </ul>
            </div>
        </aside>

        <div class="content">
            <header v-if="!modal" class="content__header">
                <h1 class="heading1">Feed</h1>
            </header>

            <ActivityFeed
                ref="feed"
                :modal="modal"
                :filter="filter"
                :show-all="showAll"
            />
        </div>
    </main>
</template>

<script>
import DatePicker from "vue2-datepicker";
import multiselect from "vue-multiselect";
import trackers from "@/services/trackers.service";
import team from "@/services/team.service";
import scrolledBottom from "@/mixins/scrolledBottom";
import ActivityFeed from "@/components/activity/ActivityFeed.vue";
import {activityFeedTypes} from "@/constants/constants";
import {mapActions, mapGetters} from "vuex";

export default {
    props: {
        modal: {
            type: Boolean,
            default: false
        }
    },

    components: {
        multiselect,
        DatePicker,
        ActivityFeed,
    },

    mixins: [scrolledBottom],

    data() {
        return {
            logs: [],
            types: activityFeedTypes,
            leftCollapsed: false,

            showAll: true,
            watchAll: true,

            filter: {
                activityType: {
                    'notes': false,
                    'contacts': false,
                    'files': false,
                    'interactions': false,
                },

                period: [null, null],
                trackers: [],
                tags: [],
                users: [],
            },

            trackersList: [],
            usersList: [],

            loaded: false,

            period: [null, null],
            maxDate: null,
            loading: false,
        }
    },

    mounted() {
        this.getTrackersList()
        this.fetchTags()

        if (this.$store.state.isAdmin) {
            this.getUsersList()
        }
    },

    watch: {
        filter: {
            deep: true,
            handler() {
                let selectedAll = true

                for (let i in this.filter.activityType) {
                    if (!this.filter.activityType[i]) {
                        selectedAll = false
                        break
                    }
                }

                if (!selectedAll) {
                    this.watchAll = false
                    this.showAll = false
                }

                this.$nextTick(() => {
                    this.$refs.feed.updateList()
                    this.watchAll = true
                })
            }
        },

        showAll() {
            if (this.watchAll) {
                if (this.showAll) {
                    for (let i in this.filter.activityType) {
                        this.filter.activityType[i] = false
                    }
                }

                this.$refs.feed.updateList()
            }
        },

        period() {
            this.filter.period = [
                this.dateHelper.encodeDateForServer(this.period[0]),
                this.dateHelper.encodeDateForServer(this.period[1]),
            ]
        }
    },

    computed: {
        ...mapGetters(['tagsList']),
    },

    methods: {
        ...mapActions(['fetchTags']),

        getTrackersList() {
            trackers.getList().then(({data}) => {
                this.trackersList = data
            })
        },

        getUsersList() {
            team.getUsersAndGroupsList().then(({data}) => {
                this.usersList = data
            })
        }
    }
}
</script>

<style scoped>

</style>
