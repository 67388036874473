<template>
    <div>
        <Header></Header>
    </div>
</template>

<script>
import Header from "@/views/ecosystem/settings/Header"

export default {
    components: {
        Header
    }
}
</script>

<style scoped>

</style>