<template>
    <Observer @on-change="scrolledBottom">
        <div style="height: 1px"></div>
    </Observer>
</template>

<script>
import Observer from 'vue-intersection-observer'

export default {
    components: {
        Observer,
    },

    methods: {
        scrolledBottom(entry) {
            if (entry.isIntersecting) {
                this.$emit('scrolledBottom')
            }
        },
    }
}
</script>
