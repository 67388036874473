<template>
    <div v-if="itemsForPage.length" class="help-section">
        <div>
            <b-dropdown id="dropdown-help-section" text="" class="m-2">
                <header class="help-section__header">
<!--                    <a class="help-section__close" href="#" v-on:click="isHidden = true"></a>-->

                    <b-dropdown-item-button aria-describedby="" class="help-section__close">
<!--                        <button id="" aria-haspopup="menu" aria-expanded="false" type="button" class="help-section__close"></button>-->
                    </b-dropdown-item-button>

                    <h3 class="help-section__title">Help</h3>

                    <div class="help-section__search">
                        <input v-model="filter" v-debounce:500ms.lock="getFilteredItems" type="text" placeholder="Search walkthroughs & knowledgebase"/>
                    </div>
                </header>

                <div class="help-section__inner">
                    <template v-if="walkThroughs.length">
                        <h4 class="heading4 heading4--mouse">Walkthroughs</h4>

<!--                        <div class="card card&#45;&#45;bordered-slim">-->
                            <ul class="list-help">
                                <li v-for="(item, index) in walkThroughs" :key="index">
                                    <a @click="() => {startWalkthrough(item.source_id)}" target="_blank">{{item.name}}</a>
                                </li>
                            </ul>
<!--                        </div>-->
                    </template>

                    <template v-if="knowledgeBases.length">
                        <h4 class="heading4 heading4--knowledge">Knowledgebase</h4>

<!--                        <div class="card card&#45;&#45;bordered-slim">-->
                            <ul class="list-help">
                                <li v-for="(item, index) in knowledgeBases" :key="index">
                                    <a :href="item.source_id" target="_blank">{{item.name}}</a>
                                </li>
                            </ul>
<!--                        </div>-->
                    </template>

                    <template v-if="videos.length">
                        <h4 class="heading4 heading4--play">Videos</h4>

<!--                        <div class="card card&#45;&#45;bordered-slim">-->
                            <ul class="list-help">
                                <li v-for="(item, index) in videos" :key="index">
                                    <a @click="() => {showVideo(item.source_id)}">{{item.name}}</a>
                                </li>
                            </ul>
<!--                        </div>-->
                    </template>
                </div>
            </b-dropdown>
        </div>

<!--        <div class="help-section__content" v-if="!isHidden">-->
<!--            <header class="help-section__header">-->
<!--                <a class="help-section__close" href="#" v-on:click="isHidden = true"></a>-->
<!--                <h3 class="help-section__title">Help</h3>-->

<!--                <div class="help-section__search">-->
<!--                    <input v-model="filter" v-debounce:500ms.lock="getFilteredItems" type="text" placeholder="Search walkthroughs & knowledgebase"/>-->
<!--                </div>-->
<!--            </header>-->

<!--            <div class="help-section__inner">-->
<!--                <template v-if="walkThroughs.length">-->
<!--                    <h4 class="heading4 heading4&#45;&#45;mouse">Walkthroughs</h4>-->

<!--                    <div class="card card&#45;&#45;bordered-slim">-->
<!--                        <ul class="list-mouse">-->
<!--                            <li v-for="(item, index) in walkThroughs" :key="index">-->
<!--                                <a @click="() => {startWalkthrough(item.source_id)}" target="_blank">{{item.name}}</a>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </template>-->

<!--                <template v-if="knowledgeBases.length">-->
<!--                    <h4 class="heading4 heading4&#45;&#45;knowledge">Knowledgebase</h4>-->

<!--                    <div class="card card&#45;&#45;bordered-slim">-->
<!--                        <ul class="list-knowledge">-->
<!--                            <li v-for="(item, index) in knowledgeBases" :key="index">-->
<!--                                <a :href="item.source_id" target="_blank">{{item.name}}</a>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </template>-->

<!--                <template v-if="videos.length">-->
<!--                    <h4 class="heading4 heading4&#45;&#45;knowledge">Videos</h4>-->

<!--                    <div class="card card&#45;&#45;bordered-slim">-->
<!--                        <ul class="list-knowledge">-->
<!--                            <li v-for="(item, index) in videos" :key="index">-->
<!--                                <a @click="() => {showVideo(item.source_id)}" target="_blank">{{item.name}}</a>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </template>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="help-section__button">-->
<!--            <span class="help-section__button__text">Help</span>-->
<!--        </div>-->

        <video-modal
            id="helper-video-modal"
            ref="videoModal"
        />
    </div>
</template>

<script>
import helperItemsService from "@/services/helper-items.service";
import VideoModal from "@/components/widgets/VideoModal.vue";

export default {
    components: {VideoModal},
    props: {
        page: String
    },

    data() {
        return {
            items: [],
            itemsForPage: [],
            filter: '',
        }
    },

    computed: {
        knowledgeBases() {
            return this.items.filter(item => item.type === 'knowledge_base')
        },

        walkThroughs() {
            return this.items.filter(item => item.type === 'walkthrough')
        },

        videos() {
            return this.items.filter(item => item.type === 'video')
        }
    },

    mounted() {
        this.getItemsForPage();
    },

    methods: {
        getItemsForPage() {
            helperItemsService.getItems(this.page).then(response => {
                this.itemsForPage = response.data
                this.items = response.data
            })
        },

        getFilteredItems() {
            if (this.filter.length) {
                helperItemsService.getItems(this.page, this.filter).then(response => {
                    this.items = response.data
                })
            } else {
                this.items = [...this.itemsForPage];
            }
        },

        startWalkthrough(id) {
            window.pendo.showGuideById(id);
        },

        showVideo(id) {
            this.$refs.videoModal.show(`https://player.vimeo.com/video/${id}`);
        }
    },
}
</script>

<style scoped>

</style>
