import Login from "@/views/common/Login";
import ResetPassword from "@/views/common/ResetPassword";
import SetPassword from "@/views/common/SetPassword";

export default [
    {
        path: '/login',
        component: Login,
        meta: {
            guest: true,
            title: 'Login',
            onlyGuest: true,
        }
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        meta: {
            guest: true,
            title: 'Reset Password',
            onlyGuest: true,
        }
    },
    {
        path: '/set-password/:resetPasswordCode',
        component: SetPassword,
        meta: {
            guest: true,
            title: 'Set Password',
            onlyGuest: true,
        }
    },
    {
        path: '/about',
        meta: {
            guest: true,
            title: 'About'
        }
    },
]