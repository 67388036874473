<template>
    <b-modal id="related-trackers-modal" modal-class="modal-md" hide-footer title="Related Trackers">
        <div v-for="tracker in trackers" :key="tracker.id" class="related-trackers-modal-list-item">
            <a :href="`/trackers/${tracker.id}`" target="_blank">
                {{tracker.name}} 
                <span class="related-trackers-modal-list-item__counter">{{tracker.companies_count}} startups</span>
            </a>
        </div>
    </b-modal>
</template>

<script>
export default {
    data() {
        return {
            trackers: [],
        }
    },

    methods: {
        open(trackers) {
            this.trackers = trackers;
            this.$bvModal.show('related-trackers-modal');
        }
    }
}
</script>

<style scoped>

</style>
