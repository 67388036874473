<template>
    <multiselect class="multiselect-mid col-sm-5 mb-2 mb-sm-0"
                 v-model="selectedItems"
                 :multiple="true"
                 :options="options"
                 select-label=""
                 deselect-label=""
                 label="name"
                 track-by="name"
    >
        <template slot="option" slot-scope="props">
            <template v-if="props.option.type === 'group'">
                <div class="d-flex align-items-center">
                    <div class="mr-2">
                        <font-awesome-icon :icon="['fa', 'users']" size="lg"  />
                    </div>
                    {{ props.option.name }}
                </div>

            </template>
            <template v-else>
                <div class="d-flex align-items-center">
                    <div class="mr-2">
                        <Avatar
                            :username="props.option.name"
                            :src="props.option.avatar"
                            :size="30">
                        </Avatar>
                    </div>
                    {{ props.option.name }}
                </div>
            </template>
        </template>
    </multiselect>
</template>

<script>
import multiselect from "vue-multiselect";
import team from "@/services/team.service";
import Avatar from "vue-avatar";

export default {
    data() {
        return {
            selectedItems: [],
            options: [],
        }
    },

    components: {
        multiselect,
        Avatar
    },

    mounted() {
        this.getOptions()
    },

    watch: {
        selectedItems() {
            this.$emit('selectedChanged', this.selectedItems)
        }
    },

    methods: {
        getOptions() {
            team.getUsersAndGroupsList().then(response => {
                this.options = response.data
            })
        }
    }
}

</script>

<style scoped>

</style>
