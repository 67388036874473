<template>
    <div>
        <div v-if="errorMessage.length">
            {{errorMessage}}
        </div>

        <Application
            ref="application"
            :loaded="loaded"
            :applicationStatus="applicationStatus"
            :showApplication="showApplication"
            :loadingQuestions="loadingQuestions"
            :info="info"
            v-on:apply="showApplicationForm"
            v-on:sendApplication="apply"
        >
        </Application>

        <div v-if="loaded">
            <b-modal id="modal-form" :hide-footer="true" modal-class="modal-w-md" title="">
                <template #modal-title>
                    <div v-if="modalAction === 'signup' && registerStatus != 'registered'" >
                        <div class="modal-top">
                            <h2 class="heading2 mb-0">Sign Up to SwitchPitch</h2>
                        </div>
                        <p class="mb-0 mt-3">Have an account? <a @click="modalAction = 'login'" class="link">Log In</a></p>

                    </div>
                    <div v-if="modalAction === 'login'">
                        <div class="modal-top">
                            <h2 class="heading2 mb-0">Log In to SwitchPitch</h2>
                        </div>
                        <p class="mb-0 mt-3">Need an account? <a @click="modalAction = 'signup'" class="link">Sign Up</a></p>
                    </div>
                    <div v-if="modalAction === 'signup' && registerStatus === 'registered'" class="modal-top">
                        <h2 class="heading2 mb-0">Confirm Email</h2>
                    </div>
                </template>

                <template v-if="modalAction === 'signup'">

                    <template v-if="registerStatus != 'registered'">
                        <Signup
                            company-type="startup"
                            :fixed-company-type="true"
                            :publicTrackerId="id"
                            v-on:registered="registered"
                        >
                        </Signup>
                    </template>

                    <div v-else>
                        <div class="form form--enter">
                            <div class="form--enter__container mt-2">
                                <div class="form--enter__fieldset">
                                  <p>We sent an email to {{ registerEmail }} to activate your account. Click the link to complete registration.</p>
                                    <p>If you don’t receive the email, check your spam folder.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                
                <template v-if="modalAction === 'login'">
                    <Login
                        :from-tracker="id"
                        v-on:login="login">
                    </Login>
                </template>
            </b-modal>

            <b-modal id="profile-form" :hide-footer="true" modal-class="modal-w-lg" title="Complete Your Company Profile">
                <template>
                    <ProfileInfoForm
                        :from-tracker="true"
                        v-on:saved="() => {
                            $bvModal.hide('profile-form');
                            showApplicationForm();
                        }"
                    />
                </template>

            </b-modal>

            <edit-user
                prefix="-application"
                :profile="true"
                ref="editProfile"
                v-on:user-saved="userSaved"
            >
            </edit-user>
        </div>
    </div>
</template>

<script>
import publicTrackers from "@/services/public-trackers.service"
import profile from "@/services/startup/profile.service"
import Application from "@/components/trackers/Application.vue";
import Signup from "@/components/common/Signup";
import Login from "@/components/common/Login";
import ProfileInfoForm from "@/components/startup/Profile";
import trackers from "@/services/trackers.service";
import trackersCompanies from "@/services/trackers-companies.service";
import editUser from "@/components/team/EditUser";
import user from "@/services/user.service";

export default {
    components: {
        Application,
        Signup,
        Login,
        ProfileInfoForm,
        editUser
    },

    data() {
        return {
            id: this.$route.params.id, //Do not parse this to integer, because it can be referral link
            loaded: false,
            errorMessage: "",

            info: {
                company_name: '',
                company_logo: '',
                company_description: '',
                name: '',
                description: '',
                posted: "",
                start_date: "",
                end_date: "",
                categories: [],
                need_complete_profile: true,
                has_terms: false,
                terms: "",
            },

            showApplication: false,
            loadingQuestions: false,

            registerStatus: "new",
            registerEmail: "new",

            modalAction: "signup",
            applicationStatus: "",
            modalWasOpened: false,
            appliedAnswers: [],
            answerFilesNumber : 0,
            processedFilesNumber : 0,
            loggedIn: false,
        }
    },

    mounted() {

        this.$store.watch(state => {

            if (this.$store.getters.isLoggedIn) {

                if (state.user.company_type) {

                    if (state.user.company_type === 'enterprise') {

                        publicTrackers.checkEnterpriseAccess(this.id, state.user.company_id).then((response) => {
                            if (response.data) {
                                window.location.href = '/trackers/' + response.data
                            }
                            else {
                                this.$store.commit('setPageNotFound', true)
                            }
                        })
                    } else {
                        if (!this.loggedIn) {
                            this.loggedIn = true
                            this.getInfo()
                            this.checkApplicationStatus()
                        }
                    }
                }
            }
            else {
                if (!this.loaded) {
                    this.getInfo()
                }
            }

            if (this.$route.query['show-login'] && !this.modalWasOpened) {
                this.$root.$on('bv::modal::show', () => {
                    this.modalWasOpened = true
                })
            }
        })
    },

    updated() {
        if (this.$route.query['show-login'] && !this.modalWasOpened) {
            this.modalAction = 'login'
            this.$bvModal.show('modal-form')
        }
    },

    methods: {
        getInfo() {
            let service

            if (this.$store.getters.isLoggedIn) {
                service = trackers
            }
            else {
                service = publicTrackers
            }

            service.getApplicationInfo(this.id).then(response => {
                this.info = response.data
                document.title = this.info.name + ' | SwitchPitch'
                this.loaded = true
            }).catch(error => {
                this.errorMessage = error.response.data.message
            })
        },

        checkApplicationStatus() {
            trackers.checkApplicationStatus(this.id).then(response => {
                this.applicationStatus = response.data
            })
        },

        userSaved() {
            user.getCurrentUserData().then(response => {
                this.$store.commit('setUser', response.data)
                this.showApplicationForm()
            })
        },

        showApplicationForm() {
            if (this.$store.getters.isLoggedIn) {
                /* Pfizer disable force populating First Name and Last name
                if (!this.$store.state.user.profileCompleted) {
                    this.$refs.editProfile.open()
                } else */

                if (this.info.need_complete_profile) {
                    profile.profileCompleted().then(response => {
                        if (!response.data) {
                            if (this.$store.state.user.is_admin) {
                                this.$bvModal.show('profile-form')
                            } else {
                                alert("Profile info must be completed")
                            }
                        } else {
                            this.showApplicationData()
                        }
                    })
                } else {
                    this.showApplicationData()
                }
            }
            else {
                this.$bvModal.show('modal-form')
            }
        },

        showApplicationData() {
            this.showApplication = true
            this.loadingQuestions = true

            trackers.getQuestions(this.id).then((response) => {
                response.data = this.arrayHelper.addColumn(response.data, 'error', '')
                this.$refs['application'].questions = response.data
                this.loadingQuestions = false
            })
        },

        registered({status, email}) {
            this.registerStatus = status
            this.registerEmail = email
        },

        apply(answers) {
            this.appliedAnswers = answers;
            this.answerFilesNumber = this.fileHelper.fieldsWithNewFilesNumber(this.appliedAnswers)
            this.processedFilesNumber = this.answerFilesNumber

            if (this.answerFilesNumber) {
                this.appliedAnswers.forEach(field => {

                    let formData = new FormData();
                    let hasFiles = false

                    field.value.files.forEach(file => {
                        formData.append('file[]', file)
                        hasFiles = true
                    })

                    if (hasFiles) {
                        trackersCompanies.saveAnswerFiles(this.id, field.field.id, formData).then(response => {
                            --this.processedFilesNumber;
                            if (response.data) {
                                --this.answerFilesNumber;
                            } else {
                                this.$refs.application.setErrors([
                                    {
                                        id: field.field.id,
                                        text: 'File was not uploaded'
                                    }
                                ]);
                            }

                            if (!this.processedFilesNumber) {
                                if (this.answerFilesNumber) {
                                    this.$refs.application.sendingApplication = false;
                                    trackersCompanies.deleteAnswers(this.id);
                                } else {
                                    this.sendApplication();
                                }
                            }
                        })
                    }
                })
            } else {
                this.sendApplication();
            }
        },

        sendApplication() {
            trackers.apply(this.id, {answers: this.appliedAnswers}).then(() => {
                this.applicationStatus = "applied"
                this.showApplication = false
            })
        },

        login(params) {
            this.$bvModal.hide('modal-form')

            if (this.$route.query['show-login']) {
                this.$router.push('/public-trackers/' + this.id)
            }

            this.checkApplicationStatus()

            if (params.showProfile) {
                this.$emit('showProfile')
            }
        },
    }
}
</script>

<style scoped>

</style>
