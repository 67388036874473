<template>
    <div class="row">
        <div class="col-12 col-md-9">
            <edit-note
                ref="editNote"
                :companyType="companyType"
                :companyId="companyId"
                :usersMentionList="usersMentionList"
                v-on:saved="saved"
                v-on:canSave="setCanSave"
            >
            </edit-note>
        </div>
        
        <div class="col-12 col-md-3">
            <b-button class="w-100 w-md-auto" variant="primary" size="lg" @click="save" :disabled="!canSave">Add</b-button>
        </div>
    </div>
</template>

<script>
import EditNote from "@/components/notes/EditNote";

export default {
    components: {
        EditNote,
    },

    props: {
        companyType: String,
        companyId: Number,
        usersMentionList: Array,
    },

    data() {
        return {
            canSave: false,
        }
    },

    methods: {
        init() {
            this.$refs.editNote.init(0)
        },

        save() {
            this.setCanSave(false)
            this.$refs.editNote.save()
        },

        saved() {
            this.$emit('saved')
            this.init()
        },

        setCanSave(canSave) {
            this.canSave = canSave
        }
    }
}
</script>

<style scoped>

</style>
