<template>
    <b-modal title="Add location" id="select-location-modal" size="sm">
        <form class="form pb-5">

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Country</h5>
                <multiselect
                    v-model="location.country"
                    placeholder="Select Country"
                    :options="countriesList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                >
                </multiselect>
            </div>

            <div class="form__item" v-if="location.country&& location.country.id == 1">
                <h5 class="heading5 heading5--secondary">State</h5>

                <multiselect
                    v-model="location.state"
                    placeholder="Select State"
                    :options="statesList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                >
                </multiselect>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Region Name</h5>
                <input class="form-control--mid form-control" type="text" v-model="location.region">
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">City</h5>
                <input class="form-control--mid form-control" type="text" v-model="location.city">
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Zip</h5>
                <input class="form-control--mid form-control" type="text" v-model="location.zip">
            </div>
        </form>

        <template #modal-footer>
            <b-button :disabled="!validLocation" variant="primary" size="lg" @click="add">Add</b-button>
        </template>
    </b-modal>
</template>

<script>

import multiselect from "vue-multiselect";
import locations from "@/services/locations.service";

export default {

    components: {
        multiselect,
    },

    data() {
        return {
            location: {
                country: null,
                state: null,
                region: '',
                city: '',
                zip: '',
            },

            countriesList: [],
            statesList: [],
        }
    },

    computed: {
        validLocation() {
            return true
        }
    },

    mounted() {
        locations.getCountriesList().then(response => {
            this.countriesList = response.data
        })

        locations.getStatesList().then(response => {
            this.statesList = response.data
        })
    },

    methods: {
        open() {
            this.location.country = null
            this.location.state = null
            this.location.region = ''
            this.location.city = ''
            this.location.zip = ''
            this.$bvModal.show('select-location-modal')
        },

        add() {
            this.$bvModal.hide('select-location-modal')
            this.$emit('locationSelected', this.location)
            this.trackers = []
        },
    }
}
</script>