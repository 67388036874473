<template>
    <form class="form pb-5">
        <div class="d-flex align-items-center mb-1">
            <div class="image-block image-block--rounded--lg mb-4">
                <img v-if="this.form.logo" :src="this.form.logo" alt="img">
            </div>
            <div>
                <a @click="showLogoUpload = true" class="btn btn-primary">Upload Logo</a>
                <p class="modal-text m-0">Image size: 200 X 200 pixels, max file size 5 mb</p>
            </div>
        </div>

        <image-upload
            v-model="showLogoUpload"
            :width="200"
            :height="200"
            :noSquare="true"
            langType="en"
            @crop-success="logoCropped"
        >
        </image-upload>

        <div class="form__item">
            <h5 class="heading5 heading5--secondary">Company name</h5>
			<div v-if="errors.name" class="error text-right">{{ errors.name }}</div>
            <input class="form-control--mid form-control" v-model="form.name" />
        </div>

        <div class="form__item">
            <h5 class="heading5 heading5--secondary">Company Description</h5>
            <textarea class="form-control--mid form-control" v-model="form.description">

            </textarea>
        </div>

        <div class="form__item">
            <h5 class="heading5 heading5--secondary">Profile Text</h5>
            <textarea class="form-control--mid form-control" v-model="form.profile_text">

            </textarea>
        </div>

        <div class="form__item">
            <h5 class="heading5 heading5--secondary">Country</h5>
            <multiselect
                v-model="country"
                placeholder="Select Country"
                :options="countriesList"
                select-label=""
                deselect-label=""
                label="name"
                track-by="name"
                class="multiselect-mid pl-2"
            >
            </multiselect>
        </div>

        <div class="form__item" v-if="form.country_id == 1">
            <h5 class="heading5 heading5--secondary">State</h5>

            <multiselect
                v-model="state"
                placeholder="Select State"
                :options="statesList"
                select-label=""
                deselect-label=""
                label="name"
                track-by="name"
                class="multiselect-mid pl-2"
            >
            </multiselect>
        </div>

        <div class="form__item">
            <h5 class="heading5 heading5--secondary">Categories</h5>
            <multiselect
                v-model="form.categories"
                :multiple="true"
                placeholder="Select Categories"
                :options="$store.state.categories"
                select-label=""
                deselect-label=""
                label="name"
                track-by="name"
                class="multiselect-mid pl-2"
            >
            </multiselect>
        </div>

        <div class="text-center">
            <b-button variant="primary" size="lg" @click="save">Save Profile</b-button>
        </div>
    </form>

</template>

<script>
import enterprise from "@/services/enterprise.service"
import imageUpload from "vue-image-crop-upload";
import locations from "@/services/locations.service";
import multiselect from "vue-multiselect";


export default {
    components: {
        imageUpload,
        multiselect
    },

    data() {
        return {
            form: {
                description: "",
                profile_text: "",
                logo: "",
                country_id: "",
                state_id: "",
                categories: [],
            },

			errors: {
				name: ''
			},

            showLogoUpload: false,
            logoChanged: false,

            country: null,
            state: null,

            countriesList: [],
            statesList: [],
        }
    },

    watch: {
        country() {
            if (this.country) {
                this.form.country_id = this.country.id
            } else {
                this.form.country_id = 0
            }
        },

        state() {
            if (this.state) {
                this.form.state_id = this.state.id
            }
            else {
                this.form.state_id = 0
            }
        },
    },

    mounted() {
        locations.getCountriesList().then(response => {
            this.countriesList = response.data
            this.setCountry()
        })

        locations.getStatesList().then(response => {
            this.statesList = response.data
            this.setState()
        })

        this.getInfo()
    },

    methods: {
        getInfo() {
            enterprise.getProfileInfo().then(response => {
                this.form = response.data
                this.setCountry()
                this.setState()
            })
        },

        save() {
            if (this.logoChanged) {
                this.form.newLogo = this.form.logo
            }

			if (!this.form.name.length) {
				this.errors.name = 'Company name is empty';
				this.$emit('errors', this.errors);
				return;
			}

            enterprise.saveProfileInfo(this.form).then(() => {
                this.$emit('saved')
            })
        },

        logoCropped(imageDataUrl) {
            this.form.logo = imageDataUrl
            this.showAvatarUpload = false
            this.logoChanged = true
        },

        setCountry() {
            if (!this.country && this.form.country_id) {
                this.countriesList.forEach(item => {
                    if (item.id == this.form.country_id) {
                        this.country = item
                        return
                    }
                })
            }
        },

        setState() {
            if (!this.state && this.form.state_id) {
                this.statesList.forEach(item => {
                    if (item.id == this.form.state_id) {
                        this.state = item
                        return
                    }
                })
            }
        },
    }
}
</script>

<style scoped>

</style>
