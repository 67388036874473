import ArrayHelper from '@/helpers/array'
import DateHelper from '@/helpers/date'
import chatsService from '@/services/chats.service'

const uniqueId = require('lodash.uniqueid')

export default {
    actions: {
        async fetchChats({ commit }, { search, filter }) {
            const response = await chatsService.getChatsList(search, filter)

            commit('setChats', response.data)
            commit('syncRooms')
        }
    },
    mutations: {
        addRoom: (state, room) => (state.chatRooms.push(room)),
        addNewRoom(state, selectedRecipients = []) {
            let room = {
                'id': 'new-' + uniqueId(),
                'type': 'new',
                'selectedRecipients': selectedRecipients
            }

            state.chatRooms.push(room)
        },
        removeFirstRoom: (state) => (state.chatRooms.shift()),
        openedRooms: (state, rooms) => (state.chatRooms = rooms),
        updateRoom(state, room) {
            let index = ArrayHelper.findIndexByColumnValue(state.chatRooms, 'id', room.id)

            if (index >= 0) {
                state.chatRooms.splice(index, 1, room.data)
            }
        },
        syncRooms: (state) => {
            state.chatRooms.forEach((chatRoom, roomIndex) => {
                let chatIndex = ArrayHelper.findIndexByColumnValue(state.chats, 'id', chatRoom.id)

                if (chatIndex >= 0) {
                    state.chatRooms.splice(roomIndex, 1, state.chats[chatIndex])
                }
            })

            state.roomsSynced = true;
        },
        setChats: (state, chats) => (state.chats = chats),
        refreshLastMessageSent: (state, chatId) => {

            let chatIndex = ArrayHelper.findIndexByColumnValue(state.chats, 'id', chatId)

            if (chatIndex >= 0) {
                let chatData = state.chats[chatIndex]
                chatData.last_message_sent = DateHelper.now('YYYY-MM-DD HH:mm:ss')
                state.chats.splice(chatIndex, 1, chatData)
            }

        },
        updateChat(state, chat) {
            let chatIndex = ArrayHelper.findIndexByColumnValue(state.chats, 'id', chat.id)

            if (chatIndex >= 0) {
                state.chats.splice(chatIndex, 1, chat.data)
            }
        },
        chatNewMessageReceived(state, chat) {
            let chatIndex = ArrayHelper.findIndexByColumnValue(state.chats, 'id', chat.id)

            if (chatIndex >= 0) {
                state.chats[chatIndex].last_message_sent = chat.data.last_message_sent
                state.chats[chatIndex].unread = true
            }
        },
        openChat: (state, chatId) => {
            if (!ArrayHelper.exists(state.chatRooms, 'id', chatId)) {
                let chatIndex = ArrayHelper.findIndexByColumnValue(state.chats, 'id', chatId)

                if (chatIndex >= 0) {
                    state.chats[chatIndex].unread = false

                    chatsService.getChatDetails(state.chats[chatIndex].id).then(response => {
                        if (response.data) {
                            state.chats[chatIndex] = response.data
                        }

                        state.chatRooms.push(state.chats[chatIndex])
                    })
                }
            }
        },
    },
    state: {
        chatRooms: [],
        chats: [],
        roomsSynced: false,
    },
    getters: {
        chatRooms: state => state.chatRooms,
        chats: state => state.chats,
    },
}
