<template>
    <div>
    <header class="header">
        <div class="header__navigation">
            <span class="logos-set__item">
                <a href="https://switchpitch.com" title="SwitchPitch" class="logo"></a>
            </span>
            <ul class="menu">
                <li class="menu__home">
                    <a href="/" class="">Home</a>
                </li>
                <li class="menu__activity">
                    <a href="/login" class="">Activity</a>
                </li>
                <li class="menu__startups">
                    <a href="/login" class="router-link-exact-active router-link-active">Startups</a>
                </li>
                <li class="menu__track">
                    <a href="/login" class="">Track</a>
                </li>
            </ul>
        </div>

        <div class="header__side">
            <input v-if="false" type="text" placeholder="Search" class="public-form-search">

            <a href="/signup" class="public-enter-link">Sign Up/ Log In</a>
        </div>

    </header>
    <main class="public-startup">
        <div class="public-startup__inner">
            <div class="public-startup__content">
                <div class="public-startup__content__inner">
                    <div class="public-startup__name">
                        <div class="image-block image-block--lg">
                            <img :src="LogoHelper.getLogo(startupInfo.logo_url)" :alt="startupInfo.name"/>
                        </div>

                        <div>
                            <h2 class="heading2">{{ startupInfo.name }}</h2>
                            <a class="copy-link" @click="copyPublicLink" href="#">Copy profile link</a>
                        </div>
                    </div>

                    <div class="public-startup__section">
                        <h4 class="heading4 heading4--tertiary">Summary</h4>

                        <p>{{ startupInfo.short_description }}
                        <a href="/login">(more)</a></p>
                    </div>

                    <div class="public-startup__section">
                        <h4 class="heading4 heading4--tertiary">Overview</h4>

                        <div class="row">
                            <ul class="overview-list col">
                                <li v-if="startupInfo.website">
                                    <a :href="startupInfo.website" class="d-flex align-items-center" target="_blank">
                                        <span class="overview-list__icon icon-globe"></span>
                                        {{ startupInfo.url_domain }}
                                    </a>
                                </li>
                                <li v-if="startupInfo.total_raised > 0">
                                    <a v-if="startupInfo.funding_rounds.length" class="d-flex align-items-center" @click="showFundingRounds">
                                        <span class="overview-list__icon icon-funding icon-funding--light"></span>
                                        {{stringHelper.formatNumber(startupInfo.total_raised)}} raised
                                    </a>
                                    <span v-else class="d-flex align-items-center">
                                        <span class="overview-list__icon icon-funding icon-funding--light"></span>
                                        {{stringHelper.formatNumber(startupInfo.total_raised)}} raised
                                    </span>
                                </li>
                                <li v-if="startupInfo.revenue_range">
                                    <span class="overview-list__icon icon-income icon-income--light"></span>
                                    {{ revenueRange }} Annual Income
                                </li>
                                <li v-if="startupInfo.employees">
                                    <span class="overview-list__icon icon-employees--light"></span>
                                    {{ startupInfo.employees }} Employees
                                </li>
                            </ul>
                            <ul class="overview-list col">
                                <li v-if="location">
                                    <span class="overview-list__icon icon-location--light"></span>
                                    {{ location }}
                                </li>
                                <li v-if="startupInfo.stage">
                                    <span class="overview-list__icon icon-startups icon-startups--light"></span>
                                    {{ startupInfo.stage }}
                                </li>
                                <li v-if="startupInfo.founded">
                                    <span class="overview-list__icon icon-founded icon-founded--light"></span> 
                                    Founded {{ startupInfo.founded }}
                                </li>
                                <li v-if="startupInfo.linkedin_url || startupInfo.twitter_url || startupInfo.facebook_url">
                                    <ul class="social-list">
                                        <li v-if="startupInfo.linkedin_url">
                                            <a target="_blank" :href="startupInfo.linkedin_url">
                                                <font-awesome-icon :icon="['fab', 'linkedin-in']" />
                                            </a>
                                        </li>
                                        <li v-if="startupInfo.twitter_url">
                                            <a target="_blank" :href="startupInfo.twitter_url">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 448 512">
                                                    <path fill="#353535" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z"/>
                                                </svg>
                                            </a>
                                        </li>
                                        <li v-if="startupInfo.facebook_url">
                                            <a target="_blank" :href="startupInfo.facebook_url">
                                                <font-awesome-icon :icon="['fab', 'facebook-square']" />
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div v-if="startupInfo.categories.length" class="public-startup__section">
                        <h4 class="heading4 heading4--tertiary">Categories</h4>

                        <div class="tags-list tags-list--light">
                            <span v-for="category in startupInfo.categories" :key="category.id" class="tags-list__tag" :title="category.name">{{ category.name }}</span>
                        </div>
                    </div>
                </div>

                <footer class="public-startup__footer">
                    <p>Data provided by Crunchbase</p>
                    <p>Is this your company? <a href="/signup?account_type=startup">Sign up to claim this page</a></p>
                </footer>
            </div>
            <div class="public-startup__info">
                <h3 class="heading3">Innovate Smarter</h3>

                <ul class="public-startup__info__list">
                    <li class="public-startup__info__contact">
                        <h5 class="heading5">View company contacts</h5>
                        <p>Get in touch with the right peopple at any startup, all with one click.</p>
                    </li>
                    <li class="public-startup__info__track">
                        <h5 class="heading5">Track all potential leads</h5>
                        <p>Follow startups of interest and perform diligence with customizable fields.</p>
                    </li>
                    <li class="public-startup__info__news">
                        <h5 class="heading5">News and activity</h5>
                        <p>See up-to-date information about companies: funding rounds, news, employee changes, and more.</p>
                    </li>
                </ul>

                <p class="text-center">
                    <a href="https://switchpitch.com/request-demo/" class="button">Request Demo</a>
                </p>
            </div>
        </div>
    </main>

        <funding-rounds
            ref="fundingRounds"
            :init-funding-rounds="startupInfo.funding_rounds"
        >
        </funding-rounds>
    </div>
</template>

<script>
    import publicStartups from "@/services/startup/public-startups.service"
    import FundingRounds from "@/components/startups/FundingRounds"

    export default {
        components: {
            FundingRounds
        },
        data() {
            return {
                domain: this.$route.params.domain,
                startupInfo: {
                    categories: [],
                    city: '',
                    state: '',
                    country: '',
                    continent: '',
                    crunch_status: null,
                    description: '',
                    diffbot_status: null,
                    ecosystems: [],
                    ecosystem_account: null,
                    founded: null,
                    funding_rounds: [],
                    has_account: false,
                    investors: [],
                    keywords_with_count: [],
                    last_crunchbase_sync_date: null,
                    logo_url: '',
                    name: '',
                    employees: '',
                    provided_by: '',
                    revenue_range: '',
                    short_description: '',
                    stage: '',
                    total_raised: 0,
                    linkedin_url: '',
                    twitter_url: '',
                    facebook_url: '',
                    url_domain: '',
                    website: '',
                },
            }
        },

        mounted() {
            this.getStartupInfo()
        },

        computed: {
            location() {
                let locationParts = []

                if (this.startupInfo.city) {
                    locationParts.push(this.startupInfo.city)
                }

                if (this.startupInfo.state) {
                    locationParts.push(this.startupInfo.state)
                }

                if (this.startupInfo.country) {
                    locationParts.push(this.startupInfo.country)
                }

                if (this.startupInfo.continent) {
                    locationParts.push(this.startupInfo.continent)
                }

                return locationParts.join(', ')
            },
            revenueRange() {
                let string = this.startupInfo.revenue_range
                string = string.replace('Less than', '<')
                string = string.replace('Greater than', '>')
                return string
            }
        },

        methods: {
            getStartupInfo() {
                publicStartups.getInfo(this.domain).then(response => {
                    this.startupInfo = response.data
                    document.title = this.startupInfo.name + ' | SwitchPitch'
                }).catch(error => {
                    this.errorMessage = error.response.data.message
                })
            },
            showFundingRounds() {
                this.$refs.fundingRounds.open(this.id)
            },
            copyPublicLink() {
                navigator.clipboard.writeText(document.location.origin + '/public-startup/' + this.domain)
            },
        }
    }
</script>

<style scoped>

</style>
