import userService from "@/services/user.service";

const common = {
    methods: {
        showNeedUpgrade: function (type) {
            this.$store.commit('showNeedUpgrade', type)
            this.$nextTick(() => {
                this.$store.commit('showNeedUpgrade', false)
            })
        },

        showUpgrade: function (type) {
            this.$store.commit('showUpgrade', type)
            this.$nextTick(() => {
                this.$store.commit('showUpgrade', false)
            })
        },

        showAddEnterpriseForm: function () {
            this.$store.commit('setAddEnterprise', true);
            this.$nextTick(() => {
                this.$store.commit('setAddEnterprise', false);
            })
        },

        setLessonWatched: function (number) {
            userService.setLessonWatched(number).then(() => {
                window.app.$store.commit('setNeedUpdateUserData', true);

                window.app.$nextTick(() => {
                    window.app.$store.commit('setNeedUpdateUserData', false);
                })
            })
        }
    },
}

export default common
