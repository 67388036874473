<template>
    <div>
        <div class="topbar topbar--full">
            <h1 class="heading1 pt-4 mb-0">Settings</h1>
        </div>
        
        <ul class="list-settings">
            <li v-if="arrayHelper.inArray($store.state.user.permissions, 'custom-fields-manage')" class="list-settings__startups">
                <router-link to="/settings/custom-fields">
                    <div>
                        <h4 class="heading4 heading4--secondary">Startups</h4>
                        <p>Add and edit diligence profile fields and manage their permissions</p>
                    </div>
                </router-link>
            </li>
            <li v-if="arrayHelper.inArray($store.state.user.permissions, 'tracker-template-manage')" class="list-settings__trackers">
                <router-link to="/settings/tracker-templates">
                    <div>
                        <h4 class="heading4 heading4--secondary">Trackers</h4>
                        <p>Create tracker templates, add fields for team members and startups to fill</p>
                    </div>
                </router-link>
            </li>
            <li class="list-settings__notifications">
                <router-link to="/settings/notifications">
                    <div>
                        <h4 class="heading4 heading4--secondary">Notifications</h4>
                        <p>Toggle notifications on/off and set the delivery frequency</p>
                    </div>
                </router-link>
            </li>
            <li v-if="arrayHelper.inArray($store.state.user.permissions, 'tags-manage')" class="list-settings__tags">
                <router-link to="/settings/my-tags">
                    <div>
                        <h4 class="heading4 heading4--secondary">Tags</h4>
                        <p>Add tags to startups to easily find them according to your customized data</p>
                    </div>
                </router-link>
            </li>
            <li class="list-settings__email">
                <router-link to="/settings/email-templates">
                    <div>
                        <h4 class="heading4 heading4--secondary">Email Templates</h4>
                        <p>Customize emails that are sent when inviting team members, startups  ecosystems.</p>
                    </div>
                </router-link>
            </li>
            <li v-if="$store.state.isAdmin" class="list-settings__admin">
                <router-link to="/admin/permissions/">
                    <div>
                        <h4 class="heading4 heading4--secondary">Admin</h4>
                        <p>Manage your company’s data and preferences on SwitchPitch</p>
                    </div>
                </router-link>
            </li>
        </ul>

        <helper
            page="settings"
        />
    </div>
</template>

<script>
import Helper from "@/components/Helper.vue";

export default {
    components: {
        Helper
    }
}
</script>

<style scoped>

</style>
