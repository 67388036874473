<template>
    <b-modal id="add-startups" title="Submit Startups" @shown="void fetchData()">

        Add entire portfolios or individual startups that match the Enterprise company's needs

        <div class="form">
            <multiselect
                class="multiselect--complex multiselect-mid"
                v-model="newStartup"
                :options="startupsList"
                select-label=""
                deselect-label=""
                label="name"
                track-by="name"
                placeholder="Search startups or portfolio names"
                @search-change="updateStartupsList"
                :loading="loading"
            >
                <template slot="option" slot-scope="props">
                    <template v-if="props.option.type == 'startup'">
                        <img :src="LogoHelper.getLogo(props.option.logo)" style="width:40px" alt=""> {{ props.option.name }}
                    </template>
                    <template v-else>
                        {{ props.option.name }}
                    </template>
                </template>
            </multiselect>
        </div>

        <ul class="rows-list mb-3">

            <li v-for="(startup, index) in startups" :key="index" class="">
                <div v-if="startup.type == 'startup'" class="image-block">
                    <img :src="LogoHelper.getLogo(startup.logo)" alt="">
                </div>
                <div v-if="startup.type == 'portfolio'" class="image-block image-block__portfolio">
                    <!--<img v-if="startup.type == 'portfolio'" src="/img/icon-portfolio.svg" alt="">-->
                    <span class=""></span>
                </div>

                <h5 class="heading5 heading5--secondary">{{ startup.name }}</h5>

                <a @click="startups.splice(index, 1)" class="link-remove link-remove--blue link ml-auto" href="#">Remove</a>
            </li>
        </ul>

        <template #modal-footer>
            <b-button variant="primary" @click="save" size="lg" :disabled="!startups.length">Add To Project</b-button>
        </template>
    </b-modal>
</template>

<script>
import Vue from 'vue';
import multiselect from "vue-multiselect"
import trackersCompanies from "@/services/trackers-companies.service"

export default {
    props: {
        trackerId: Number,
        defaultStartups: Array
    },

    components: {
        multiselect,
    },

    data() {
        return {
            loading: false,
            newStartup: null,
            startupsList: [],
            startups: [],
            initList: [],
        }
    },

    watch: {
        newStartup() {
            if (this.newStartup) {
                this.addStartup()
            }
        }
    },

    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const response = await trackersCompanies.getStartupsListToAdd(this.trackerId);
                this.initList = response.data
                this.startupsList = this.initList;
                this.loading = false;
            } catch (e) {
                this.loading = false;
                console.error(e);
            }
        },
        updateStartupsList(search) {
            if (search.length) {
                trackersCompanies.getStartupsListToAdd(this.trackerId, search).then(response => {
                    this.startupsList = response.data
                })
            }
            else {
                this.startupsList = this.initList
            }
        },

        open() {
            this.startups = []
            this.startupsList = this.initList
            this.$bvModal.show('add-startups')
        },

        addStartup() {
            this.startups.push(this.newStartup)
            this.newStartup = null
        },

        save() {
            let items = []

            this.startups.forEach(item => {
                items.push({
                    type: item.type,
                    id: item.id,
                })
            })

            trackersCompanies.addCompaniesToSharedTracker(this.trackerId, items).then((response) => {
                this.$emit('startupsAdded')
                this.$bvModal.hide('add-startups')
                if (Object.keys(response.data.errors).length) {
                    Vue.notify({
                        group: 'app',
                        type: 'error',
                        duration: 5000,
                        text:  response.data.errors.join(', ')
                    })
                }

            })
        }
    }
}
</script>

<style scoped>

</style>
