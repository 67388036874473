<template>
    <b-modal :id="id" :title="title">
        <p class="modal-text">
            Use your analyst hours to get utilize our analysts for finding the right startups. Send us a message detailing what you’re looking for and how we can help.
        </p>
        <b-form-group label="Message" :label-for="`${id}-input-message`">
            <b-form-textarea class="form-control--mid mb-3" v-model="message"></b-form-textarea>
        </b-form-group>

        <template #modal-footer>
            <b-button :disabled="!message || sending" size="lg" variant="primary" @click="sendMessage">
                Send Message
            </b-button>
        </template>
    </b-modal>
</template>
<script>
import Vue from 'vue';
import userService from '@/services/user.service';
import analyst from "@/services/analyst.service";
import companyService from "@/services/company.service";

export default {
    data() {
        return {
            message: '',
            sending: false,
        };
    },
    name: 'MessageModal',
    props: {
        id: String,
        title: String,
        type: String,
    },
    methods: {
        show() {
            this.$bvModal.show(this.id);
        },
        hide() {
            this.$bvModal.hide(this.id);
        },
        async sendMessage() {
            this.sending = true
            let data = false
            let successMessage = ''

            if ('accountManager' === this.type) {
                const response = await userService.sendMessageToAccountManager({ message: this.message })
                data = response.data
                successMessage = 'Message sent, your account manager will contact you within 1-2 business days.'
            } else if ('analyst' === this.type) {
                const response = await analyst.messageAnalyst({ message: this.message })
                data = response.data
                successMessage = 'Message Sent'
            } else if ('contactUs' === this.type) {
                const response = await companyService.sendMessage({ message: this.message })
                data = response.data
                successMessage = 'Message Sent'
            }

            if (data === true) {
                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 5000,
                    text: successMessage
                });
                this.message = ''
                this.hide()
            } else {
                Vue.notify({
                    group: 'app',
                    type: 'error',
                    duration: 5000,
                    text: 'Error. Message has not been sent.'
                });
            }

            this.sending = false
        }
    }
}
</script>
