<template>
    <b-tab>
        <template #title>
            Activity
            <small v-if="loading">Loading...</small>
            <small v-if="!loading">{{ activities.length }} updates</small>
        </template>

        <div class="form-add activity-notes-section">
            <div class="activity-notes-list">
                <a @click="interactionType = 'meeting'" class="activity-notes-list__meeting"
                   :class="{active: interactionType === 'meeting'}">Meeting</a>
                <a @click="interactionType = 'email'" class="activity-notes-list__email"
                   :class="{active: interactionType === 'email'}">Email</a>
                <a @click="interactionType = 'phone_call'" class="activity-notes-list__call"
                   :class="{active: interactionType === 'phone_call'}">Call</a>
                <a @click="interactionType = 'note'" class="activity-notes-list__note"
                   :class="{active: interactionType === 'note'}">Note</a>
            </div>

            <div class="activity-notes-section__form">
                <AddNote
                    v-if="interactionType === 'note'"
                    companyType="enterprise"
                    :companyId="companyId"
                    :usersMentionList="usersMentionList"
                    v-on:saved="activitySaved"
                >
                </AddNote>

                <AddInteraction
                    v-if="['phone_call','email','meeting'].includes(interactionType)"
                    :type="interactionType"
                    companyType="enterprise"
                    :companyId="companyId"
                    :usersMentionList="usersMentionList"
                    v-on:saved="activitySaved"
                >
                </AddInteraction>
            </div>

            <EditNote
                ref="editNote"
                company-type="enterprise"
                :company-id="companyId"
                v-on:saved="activitySaved"
            >
            </EditNote>

            <EditInteraction
                ref="editInteraction"
                company-type="enterprise"
                v-on:saved="activitySaved"
            >
            </EditInteraction>
        </div>

        <div v-if="activities.length" class="accordion accordion--secondary accordion--tabs">
            <div class="accordion__header-wrapper">
                <div class="accordion__header" v-b-toggle.collapse-activities-list>
                    <h4 class="heading4 accordion__title">All Activity</h4>
                </div>

                <div class="tasks__select"
                     v-click-outside="function() {openInteractionOrderSelect = false}">
                    <div @click="openInteractionOrderSelect = true" class="tasks__select__chosen">
                        <span :title="interactionOrderTypes[interactionOrder]">{{ interactionOrderTypes[interactionOrder] }}</span>
                    </div>
                    <ul v-show="openInteractionOrderSelect" class="multiselect__content">
                        <li class="multiselect__element" v-for="(name, value) in interactionOrderTypes"
                            :key="value"
                            @click="interactionOrder = value; openInteractionOrderSelect = false; getActivities()">
                            {{ name }}
                        </li>
                    </ul>
                </div>
            </div>

            <activities-list
                ref="activitiesList"
                :activities="activities"
                v-on:edit="editActivity"
                v-on:remove="removeActivity"
            />
        </div>
    </b-tab>
</template>

<script>
import enterprisesService from "@/services/startup/enterprises.service";
import ActivitiesList from "@/components/activity/ActivitiesList.vue";
import AddInteraction from "@/components/interactions/EditInteractionInline.vue";
import AddNote from "@/components/notes/EditNoteInline.vue";
import team from "@/services/team.service";
import EditInteraction from "@/components/interactions/EditInteractionModal.vue";
import EditNote from "@/components/notes/EditNoteModal.vue";

export default {
    props: {
        companyId: Number,
    },

    components: {
        EditNote, EditInteraction,
        AddNote,
        AddInteraction,
        ActivitiesList
    },

    data() {
        return {
            activities: [],
            loading: false,

            interactionType: 'meeting',
            interactionOrder: 'created_at',
            openInteractionOrderSelect: false,
            
            interactionOrderTypes: {
                'created_at': 'Date added',
                'interaction_date': 'Interaction date',
            },

            usersMentionList: [],
        }
    },
    
    mounted() {
        this.getUsersMentionList()
        this.getActivities()
    },
    
    methods: {
        getActivities() {
            enterprisesService.getActivities(this.companyId, this.interactionOrder).then(response => {
                this.activities = response.data
            })
        },

        getUsersMentionList() {
            team.getUsersMentionList().then(response => {
                this.usersMentionList = response.data
            })
        },

        editActivity(activity) {
            if (activity.object_type === 'note') {
                this.$refs.editNote.open(activity.object_id)
            } else if (activity.object_type === 'interaction') {
                this.$refs.editInteraction.open(activity.object_id)
            }
        },

        removeActivity(activity) {
            if (confirm("Remove activity?")) {
                if (activity.object_type === 'note') {
                    enterprisesService.removeNote(this.companyId, activity.object_id).then(() => {
                        this.getActivities();
                    })
                } else if (activity.object_type === 'interaction') {
                    enterprisesService.removeInteraction(this.companyId, activity.object_id).then(() => {
                        this.getActivities();
                    })
                }
            }
        },

        activitySaved() {
            this.getActivities()
        },
    }
}
</script>

<style scoped>

</style>

<style scoped>
    .tab-pane {
        /*margin-top: -1rem !important;*/
    }
</style>
