<template>
    <div>
        <div class="topbar">
            <enterprises-header v-if="$store.getters.isStartup || $store.state.accountType !== 'invited'"></enterprises-header>

            <div class="topbar__inner">
                <div class="topbar__key">
                    <header class="topbar__key__header">
                        Show
                        <select v-if="totalRowsCount >= 25" class="topbar__key__select" v-model="pageLength">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                        <template v-else>{{totalRowsCount}}</template>
                        of {{totalRowsCount}}
                        enterprises
                    </header>

                    <footer v-if="$store.getters.isEcosystem" class="topbar__key__footer">
                        <ul class="topbar__side actions-list">
                            <li class="actions-list__add"><a class="" @click="addEnterprise">Add Connection</a></li>
                        </ul>
                    </footer>
                </div>
            </div>
        </div>

        <div class="main-table-wrapper overflow-auto pb-0">
            <div v-if="hasConnections" class="main-table">
                <Grid
                    :columns="columns"
                    :data="connections"
                    :addCellData="addCellData"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    v-on:changePage="changePage"
                    v-on:changeSort="changeSort"
                >
                </Grid>
            </div>

            <div v-if="$store.getters.isEcosystem && hasConnections === false" class="card card--empty-connect">
                <div class="card--empty-connect__inner">
                    <h4 class="heading4">Connect With Enterprises</h4>
    
                    <p>Click on the directory tab to browse the companies on the platform or the + Add Connection button above to add your own.</p>

                    <a href="/enterprises" class="button">Browse Directory</a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import grid from "@/mixins/grid";
import companyService from "@/services/company.service";
import Grid from "@/components/Grid";
import EnterprisesHeader from "@/components/enterprises/EnterprisesHeader";

export default {
    mixins: [grid],

    components: {
        Grid,
        EnterprisesHeader,
    },

    data() {
        return {
            connections: [],

            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    component: 'SearchCompanyNameColumn',
                },
                {
                    label: 'Status',
                    name: 'status',
                    component: 'RawColumn',
                },
                {
                    label: 'Contact',
                    name: 'contact',
                    component: 'RawColumn',
                },
            ],

            hasConnections: null,

            addCellData: {
                companyType: "enterprises",
            },
        }
    },

    mounted() {
        this.getList()

        this.$store.watch(state => {
            if (state.needUpdateEnterprises) {
                this.getList()
            }
        })
    },

    methods: {
        getList() {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let sort = this.sort

            companyService.getConnectedEnterprises({columns, sort, currentPage, pageLength}).then(response => {
                this.connections = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page

                if (this.hasConnections === null && !this.connections.length) {
                    this.hasConnections = false
                }
                else {
                    this.hasConnections = true
                }
            })
        },

        addEnterprise() {
            this.showAddEnterpriseForm()
        },
    }
}
</script>

<style scoped>

</style>
