export default {
    rules : {
        'pfizer.com': '/pfizer-saml/login',
        'colpal.com': '/colgate-saml/login',
        'hillspet.com': '/colgate-saml/login',
        'hello-products.com': '/colgate-saml/login',
        'filorga.com': '/colgate-saml/login',
        'pcaskin.com': '/colgate-saml/login',
        'travelers.com' : '/travelers-saml/login',
        'dt.lloydsbanking.com' : '/lloyds-saml/login',
        'lloydsbanking.com' : '/lloyds-saml/login',
        'scottishwidows.co.uk' : '/lloyds-saml/login',
        'halifax.co.uk' : '/lloyds-saml/login',
        'bankofscotland.co.uk' : '/lloyds-saml/login',
        'lexautolease.co.uk' : '/lloyds-saml/login',
        'tralbgassetfinance.co.uk' : '/lloyds-saml/login',
        'mbna.co.uk' : '/lloyds-saml/login',
        'schroderspersonalwealth.co.uk' : '/lloyds-saml/login',
        'birminghammidshires.co.uk' : '/lloyds-saml/login',
    },

    exceptions: [
        'emtech@pfizer.com'
    ]
};
