<template>
    <div>
        <startups-header></startups-header>
        <search search-type="startups"
        />
    </div>
</template>

<script>
import Search from "@/views/Search";
import StartupsHeader from "@/components/ecosystem/StartupsHeader.vue";

export default {
    components: {
        StartupsHeader,
        Search,
    },
}
</script>
