<template>
    <ul class="list-contacts">
        <li v-for="contact in contacts" :key="contact.id" @click="() => {$emit('contact-clicked', contact.id)}">
            <div class="card card--bordered card--centered card--contact" :class="{'card--rated': contact.is_primary}">
                <div class="card--contact__inner">

                    <b-dropdown v-if="(contact.custom && contact.can_edit) || ($store.getters.isEnterprise && (contact.can_edit || !contact.custom))" class="drag-n-drop icons-list dropdown-simple dropdown-small position-absolute" boundary="viewport">
                        <b-dropdown-item v-if="contact.custom && contact.can_edit" class="icons-list__delete2" @click.stop="() => {$emit('delete-contact', contact.id)}">
                            Delete Contact
                        </b-dropdown-item>

                        <b-dropdown-item v-if="$store.getters.isEnterprise && !contact.custom" class="icons-list__edit" @click.stop="() => {$emit('edit-contact', contact.id)}">
                            Edit Contact
                        </b-dropdown-item>
                    </b-dropdown>

                    <div class="image-block image-block--rounded--lg">
                        <Avatar
                            :username="contact.name"
                            :src="contact.avatar"
                            :size="75"
                        >
                        </Avatar>
                    </div>
                    <h5 class="heading5">{{ contact.name }}</h5>
                    <p>{{ contact.job_title }}</p>
                </div>

                <footer class="card__footer" :class="{'card__footer--button': $store.getters.isStartup && !contact.is_active}"
                        @click.stop="() => {$emit('footer-clicked', contact)}"
                >
                    <template v-if="contact.is_active">
                        Active on SwitchPitch
                    </template>
                    <template v-else>
                        <template v-if="$store.getters.isStartup && $store.getters.isAdmin">
                            <template v-if="contact.user_id">Re-</template>Invite To SwitchPitch
                        </template>
                        <template v-else>
                            Added by
                            <figure class="card__footer__image">
                                <img v-if="contact.added_by === 'sp'" src="/apple-touch-icon.png" alt=""/>
                                <img v-if="[accountTypes.ENTERPRISE, accountTypes.ECOSYSTEM].includes(contact.added_by)" :src="LogoHelper.getLogo($store.state.user.company_logo)" alt=""/>
                                <img v-if="contact.added_by === 'startup'" :src="LogoHelper.getLogo(companyLogo)" alt=""/>
                            </figure>
                        </template>
                    </template>
                </footer>
            </div>
        </li>
    </ul>
</template>

<script>
import Avatar from "vue-avatar"
import accountTypes from "@/constants/accountTypes";

export default {
    props: {
        contacts: Array,
        companyLogo: String,
    },

    components: {
        Avatar
    },

    computed: {
        accountTypes() {
            return accountTypes
        }
    },
}
</script>

<style scoped>

</style>
