/**
 * @todo: unsubscribe on condition
 */

const infiniteScroll = {
    bind: (element, binding) => {
        window.addEventListener('scroll', () => {
            const handler = binding.value ?? (() => undefined);

            if (element.getBoundingClientRect().bottom <= window.innerHeight) {
                handler(true);
            } else {
                handler(false);
            }
        });
    },
    unbind: () => {
        window.removeEventListener('scroll', () => undefined);
    }
};

export default infiniteScroll;
