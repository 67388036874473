import radar from '@/services/radar.service'

export default {
    namespaced: true,
    state: {
        defaultSettings: {
            yAxis: {
                type: "category",
                options: "auto"
            },
            xAxis: {
                type: "stage",
                options: ["Angel", "Seed", "Series A", "Series B", "Series C"]
            }
        },
        radarInfo: {},
        radarSettings: {},
        customRadarSettings: {},
        settingsTypes: {},
    },
    getters: {
        defaultSettings: state => state.defaultSettings,
        radarInfo: state => state.radarInfo,
        radarSettings: state => state.radarSettings,
        customRadarSettings: state => state.customRadarSettings,
        settingsTypes: state => state.settingsTypes,
    },
    actions: {
        async fetchRadarInfo({commit}, {query, params, settings}) {
            commit('search/setSpinnerVisibility', true, {root: true})

            const response = await radar.getList({query, params, settings})

            commit('setSettingsTypes', {
                yAxis: {
                    type: settings.yAxis.type,
                },
                xAxis: {
                    type: settings.xAxis.type,
                },
            })
            commit('setRadarInfo', response.data)
            commit('setRadarSettings', {xAxis: response.data.levels, yAxis: response.data.subfields})
            commit('search/setSpinnerVisibility', false, {root: true})
        }
    },
    mutations: {
        setRadarInfo: (state, radarInfo) => (state.radarInfo = radarInfo),
        setSettingsTypes: (state, settingsTypes) => (state.settingsTypes = settingsTypes),
        setRadarSettings: (state, radarSettings) => {
            let settings = {
                yAxis: {
                    type: '',
                    options: [],
                },
                xAxis: {
                    type: '',
                    options: [],
                },
            }

            for (const [axisIndex, options] of Object.entries(radarSettings)) {
                settings[axisIndex].type = state.settingsTypes[axisIndex].type
                settings[axisIndex].options = options.map(option => {
                    let value = state.settingsTypes[axisIndex].type === 'tracker' ? option.id : option.name

                    return {
                        value: value,
                        name: option.name,
                    }
                })
            }

            state.radarSettings = settings
        },

        setCustomRadarSettings: (state, customRadarSettings) => {
            let customSettings = JSON.parse(JSON.stringify(customRadarSettings))

            for (const [axisIndex, axisData] of Object.entries(customSettings)) {
                if (!axisData.options.length || !customSettings[axisIndex].hasChanged) {
                    if (axisData.type === 'stage') {
                        let defaultSettingsAxisIndex = Object.keys(state.defaultSettings).find(defaultSettingsIndex => state.defaultSettings[defaultSettingsIndex].type === axisData.type)

                        customSettings[axisIndex].options = state.defaultSettings[defaultSettingsAxisIndex].options
                    } else if (axisData.type === 'client') {
                        customSettings[axisIndex].options = axisData.options.map(item => item.value);
                    } else {
                        customSettings[axisIndex].options = "auto"
                    }
                } else {
                    customSettings[axisIndex].options = axisData.options.map(option => option.value)
                }
            }

            state.customRadarSettings = customSettings
        },
    },
}
