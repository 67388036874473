<template>
    <b-modal id="release-notes-modal" modal-class="release-notes-modal modal-w-xl" hide-footer>
        <div class="modal-wrapper">
            <aside class="modal-wrapper__aside">
                <h6 class="heading7">Previous Versions</h6>

                <div class="modal-wrapper__aside__version" v-for="version in versions" :key="version.id">
                    <a @click="() => {getVersionData(version.id)}">v{{version.version}}</a>
                </div>
            </aside>

            <div class="modal-wrapper__content">
                <h5 class="heading5">Release Notes</h5>

                <div v-if="version">
                    <p class="modal-wrapper__content__version">
                        Version {{version.version}}
                        <span>Released {{version.date}}</span>
                    </p>

                    <div class="list-dotted" v-html="version.content"/>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import helperItemsService from "@/services/helper-items.service";

export default {
    name: "ReleaseNotes",

    data() {
        return {
            versions: [],
            version: null,
        }
    },

    methods: {
        getVersionsList() {
            helperItemsService.getVersionsList().then(response => {
                this.versions = response.data;
                this.getVersionData(this.versions[0].id);
            })
        },

        getVersionData(id) {
            helperItemsService.getVersionData(id).then(response => {
                this.version = response.data
            })
        },

        show() {
            this.getVersionsList();
            this.$bvModal.show('release-notes-modal');
        }
    }
}
</script>

<style scoped>

</style>
