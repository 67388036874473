import axios from '@/axios';

const RESOURCE_NAME = '/chats';

export default {
    getAvailableRecipients(searchStr, selectedRecipients) {
        return axios.get(`${RESOURCE_NAME}/get-available-recipients?search=${searchStr}`, {
            params: {
                users: selectedRecipients
            },
        })
    },

    createChat(id, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}`, data)
    },

    getChatsList(searchStr, filter) {
        return axios.get(`${RESOURCE_NAME}/get-chats-index?search=${searchStr}&filter=${filter}`)
    },

    getChatByMembers(users, groups) {
        return axios.get(`${RESOURCE_NAME}/get-chat-by-members`, {
            params: {
                users,
                groups
            },
        })
    },

    getChatDetails(chatId) {
        return axios.get(`${RESOURCE_NAME}/get-chat-details?id=${chatId}`)
    },

    sendMessage(chatId, message, returnMessages = 0, lastMessageId = 0, ) {
        return axios.post(`${RESOURCE_NAME}/send-message?id=${chatId}&return_messages=${returnMessages}&last_message_id=${lastMessageId}`, {'message': message})
    },

    getMessages(chatId, offset = 0) {
        return axios.get(`${RESOURCE_NAME}/get-messages?id=${chatId}&offset=${offset}`)
    },

    checkNewMessages() {
        return axios.get(`${RESOURCE_NAME}/check-new-messages`)
    },

    getRecentMessages(chatId, lastMessageId) {
        return axios.get(`${RESOURCE_NAME}/get-recent-messages?id=${chatId}&last_message_id=${lastMessageId}`)
    },

    leaveChat(chatId) {
        return axios.get(`${RESOURCE_NAME}/leave-chat?id=${chatId}`)
    },

    getChatMembers(chatId) {
        return axios.get(`${RESOURCE_NAME}/get-chat-members?id=${chatId}`)
    },

    deleteChatMember(chatId, memberId) {
        return axios.get(`${RESOURCE_NAME}/delete-chat-member?id=${chatId}&user_id=${memberId}`)
    },

    changeChatAdmin(chatId, memberId) {
        return axios.get(`${RESOURCE_NAME}/change-chat-admin?id=${chatId}&user_id=${memberId}`)
    },
}
