<template>
    <div>
        <Mentionable
            :keys="['@']"
            :items="usersMentionList"
            offset="6"
            insert-space
            placement="top-start"
        >
            <template #item-@="{ item }">
                <div class="user">
                    <Avatar
                        :username="item.name"
                        :src="item.avatar"
                        :size="25"
                    >
                    </Avatar>

                    <div class="mention-item__inner">
                        {{ item.value }}
                        <span class="dim">
                            ({{ item.name }})
                        </span>
                    </div>
                </div>
            </template>

            <textarea class="form-control form-control--mid" v-model="note.text" placeholder="Add note text*"></textarea>
        </Mentionable>
    </div>
</template>

<script>
import startupsService from "@/services/startups.service"
import ecosystemsService from "@/services/ecosystems.service"
import enterprisesService from "@/services/startup/enterprises.service"
import {Mentionable} from "vue-mention";
import accountTypes from "@/constants/accountTypes";

export default {
    components: {
        Mentionable,
        Avatar: () => import("vue-avatar"),
    },
    props: {
        companyType: String,
        companyId: Number,
        usersMentionList: Array,
    },

    data() {
        return {
            id: 0,

            note: {
                text: "",
            },
        }
    },

    watch: {
        "note.text"() {
            this.$emit('canSave', this.note.text.length > 0)
        }
    },

    computed: {
        service() {
            if (this.companyType === accountTypes.STARTUP) {
                return startupsService;
            } else if (this.companyType === accountTypes.ENTERPRISE) {
                return enterprisesService;
            } else {
                return ecosystemsService;
            }
        }
    },

    methods: {
        init(id) {
            this.id = id
            this.note.text = ""

            if (this.id) {
                this.service.getNoteEdit(this.companyId, this.id).then(response => {
                    this.note = response.data
                })
            }
        },

        save() {
            this.service.saveNote(this.companyId, this.id, this.note).then(() => {
                this.$emit('saved')
            })
        },
    }
}
</script>

<style scoped>

</style>
