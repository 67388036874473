<template>
    <b-modal
        size="md"
        :id="modalId"
        :title="title"
        @hide="beforeClose"
        no-close-on-esc
        no-enforce-focus
        no-close-on-backdrop
    >
        <template #modal-title>
            <div class="d-flex align-items-baseline">
                <h4 class="heading4 mb-0 mr-4">{{ title }}</h4>
            </div>
        </template>
        <h5 class="heading5 heading5--secondary">Current password</h5>
        <div class="form form-modal mb-4">
			<div v-if="errors.oldPassword" class="error text-right">{{ errors.oldPassword }}</div>
            <b-form-input
                type="password"
                class="form-control form-control--mid"
                autocomplete="new-password"
                v-model="form.oldPassword"
            ></b-form-input>
        </div>
        <h5 class="heading5 heading5--secondary">New Password</h5>
        <div class="form form-modal mb-4">
			<div v-if="errors.password" class="error text-right">{{ errors.password }}</div>
            <b-form-input
                type="password"
                class="form-control form-control--mid"
                autocomplete="new-password"
                v-model="form.password"
            ></b-form-input>
        </div>
        <p style="font-size: 14px">
            Password must be at least eight characters long, contain uppercase and lowercase letters and numbers
        </p>
        <h5 class="heading5 heading5--secondary">Confirm New Password</h5>
        <div class="form form-modal mb-4">
			<div v-if="errors.confirmPassword" class="error text-right">{{ errors.confirmPassword }}</div>
            <b-form-input
                type="password"
                class="form-control form-control--mid"
                autocomplete="new-password"
                v-model="form.confirmPassword"
            ></b-form-input>
        </div>
        <template #modal-footer>
			<div v-for="error in responseErrors" v-bind:key="error" class="error">{{ error }}</div>
            <div class="buttons-wrapper">
                <button class="btn btn-primary btn-lg" @click="handleChangePassword" :disabled="loading">Change Password</button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import Vue from 'vue';
import userService from '@/services/user.service';

const validate = form => {
	const errors = {};

	if (!form.password.length) {
		errors.password = 'New password is empty';
	}

	if (!form.oldPassword.length) {
		errors.oldPassword = 'Current password is empty';
	}

	if (!form.confirmPassword.length) {
		errors.confirmPassword = 'Confirm new password is empty';
	} else if (form.password.toString() !== form.confirmPassword.toString()) {
		errors.confirmPassword = 'Passwords don\'t match';
	}

	return errors;
};

export default {
    data() {
        return {
            form: {
                password: '',
                oldPassword: '',
                confirmPassword: ''
            },
            title: 'Change Password',
			errors: {},
			loading: false,
            modalId: 'change-password',
			responseErrors: [],
			editUserModalId: undefined
        }
    },
    name: 'ChangePassword',
    methods: {
        open(editUserModalId) {
			this.editUserModalId = editUserModalId;
            this.$bvModal.show(this.modalId);
        },
        beforeClose() {
            this.form.password = '';
            this.form.oldPassword = '';
            this.form.confirmPassword = '';
            this.errors = {};
            this.responseErrors = [];
            this.$bvModal.hide(this.modalId);
			this.$bvModal.show(this.editUserModalId);
        },
		async handleChangePassword() {
			try {
				this.errors = validate(this.form);

				if (Object.keys(this.errors).length > 0) {
					return;
				}

				this.loading = true;
				const result = await userService.changePassword(this.form);
				this.loading = false;

				if (result.data.errors.length > 0) {
					this.responseErrors = result.data.errors;
					return;
				}

				Vue.notify({
					group: 'app',
					type: 'success',
					duration: 5000,
					text: 'The password has been changes successfully'
				})

				this.beforeClose();
			} catch (e) {
				console.error(e);
				this.loading = false;
			}
		}
    }
}
</script>
