<template>
    <b-tab>
        <template #title>
            Shared portfolios
            <small v-if="loading">Loading...</small>
            <small v-if="!loading">{{ total }} added</small>
        </template>
        <div class="tabs-primary__inner">
            <span v-if="loading">Loading...</span>
            <!-- NO DATA STATE -->
            <template v-if="!loading && !portfolios.length">
                <h2 class="heading2 heading2--no-portfolio">This enterprise hasn't added any portfolios.</h2>
                <div class="no-portfolio-share">
                    <router-link class="button button--link" :to="`/my-startups/portfolios`">Manage Portfolios</router-link>
                </div>
            </template>

            <!-- NO DATA STATE END -->
            <ul class="list-cards list-cards--bordered" v-if="!loading && portfolios.length">
                <li v-for="portfolio in portfolios" :key="portfolio.id">
                    <div class="list-cards__content">
                        <div>
                            <router-link class="heading5 heading5--tertiary" :to="`/my-startups/portfolios/${portfolio.id}`">
                                {{ portfolio.name }}
                            </router-link>
                            <p>
                                <v-clamp autoresize :max-lines="3">
                                    {{ portfolio.description }}
                                    <template #after="{ toggle, expanded, clamped }">
                                        <a v-if="expanded" class="link" @click="toggle"> Less</a>
                                        <a v-if="clamped" class="link" @click="toggle"> More</a>
                                    </template>
                                </v-clamp>
                            </p>
                        </div>
                        <div>
                            <span class="portfolio-startups">{{ portfolio.startups_count ? portfolio.startups_count : 0 }} startups</span>
                            <span class="portfolio-enterprises">{{ portfolio.enterprises_count ? portfolio.enterprises_count
                            : 0 }} enterprise shared</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </b-tab>
</template>
<script>
import VClamp from "vue-clamp";

export default {
    name: 'PortfoliosTab',
    props: {
        total: Number,
        loading: Boolean,
        portfolios: Array
    },
    components: {
        VClamp
    }
}
</script>
