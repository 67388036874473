<template>
    <b-modal id="modal-edit-folder" :title="title" modal-class="modal-w-md">

        <div class="form form-modal mb-4">
            <h5 v-if="folder.type === trackerTypes.PUBLIC" class="heading5 heading5--secondary">Collection Name*</h5>
            <h5 v-else class="heading5 heading5--secondary">Folder Name*</h5>
            <b-form-input class="form-control--mid" v-model="folder.name" placeholder="Enter Name"></b-form-input>
        </div>

        <template v-if="folder.type === trackerTypes.PUBLIC">
            <h5 class="heading5 heading5--secondary">Collection Description (shown for startups)</h5>
            <b-form-textarea class="form-control--mid mb-4" placeholder="Enter Collection Description (shown for startups)" v-model="folder.public_description"/>
            <h5 class="heading5 heading5--secondary">Collection Description (shown for internal users)</h5>
            <b-form-textarea class="form-control--mid mb-4" placeholder="Enter Collection Description (shown for internal users)" v-model="folder.description"/>
        </template>
        <template v-else>
            <h5 class="heading5 heading5--secondary">Folder Description</h5>
            <b-form-textarea  class="form-control--mid mb-4" placeholder="Enter Description" v-model="folder.description"/>
        </template>


        <template v-if="folder.type === trackerTypes.PUBLIC">
            <h5 class="heading5 heading5--secondary">Customize "Challenge" term (shown to startups on collection page)</h5>
            <b-form-input class="form-control--mid mb-4" placeholder='Challenges' v-model="folder.challenges_title"/>

            <div class="input-checkbox">
                <input id="show-categories-filter" type="checkbox" v-model="folder.show_categories_filter">
                <label for="show-categories-filter">Show categories filter</label>
            </div>
        </template>

        <h4 class="heading4 mt-4">Permissions</h4>

        <ul class="rows-list mb-3">

            <li class="rows-list__form">
                <!-- Please add here the logic first -->

                <multiselect class="multiselect-mid col-sm-5 mb-2 mb-sm-0"
                     v-model="newPermission.holder"
                     :options="actualPermissionHolders"
                     select-label=""
                     deselect-label=""
                     label="name"
                     track-by="name"
                >
                    <template slot="option" slot-scope="props">
                        <template v-if="props.option.type === 'group'">
                            <div class="d-flex align-items-center">
                                <div class="mr-2">
                                    <font-awesome-icon :icon="['fa', 'users']" size="lg"  />
                                </div>
                                {{ props.option.name }}
                            </div>

                        </template>
                        <template v-else>
                            <div class="d-flex align-items-center">
                                <div class="mr-2">
                                    <Avatar
                                        :username="props.option.name"
                                        :src="props.option.avatar"
                                        :size="30">
                                    </Avatar>
                                </div>
                                {{ props.option.name }}
                            </div>
                        </template>
                    </template>
                </multiselect>

                <template v-if="roles.length">
                    <span class="text-nowrap ml-sm-2">Add as:</span>
                    <b-dropdown class="modal-list mx-3 dropdown--tertiary" variant="link" ref="roleDropdown"
                                toggle-class="text-decoration-none" no-caret>

                        <template #button-content>
                            {{ newPermission.role.name }}
                        </template>

                        <!--<b-dropdown-header>-->
                        <b-dropdown-item v-for="role in roles" :key="role.id"
                                         @click="newPermission.role = role; $refs.roleDropdown.hide()">
                            <!--<li>-->
                            <!--<ul class="modal-list modal-list--permission">-->
                            <!--<li>-->
                            <h5 class="heading5 heading5--secondary">{{ role.name }}</h5>
                            <p>{{ role.description }}</p>
                            <!--</li>-->
                            <!--</ul>-->
                            <!--</li>-->
                            <!--</b-dropdown-header>-->
                        </b-dropdown-item>

                    </b-dropdown>
                </template>

                <b-button @click="addPermission" variant="primary" size="md" :disabled="!newPermission.holder">Add</b-button>
            </li>

            <li v-for="(permission, index) in folder.permissions" :key="index">
                <div class="image-block image-block--rounded">
                    <Avatar
                        :username="permission.name"
                        :src="permission.avatar"
                        :size="30">
                    </Avatar>
                    <img v-if="permission.type === 'user'" :src="permission.avatar" alt=""/>
                </div>

                <h5 class="heading5 heading5--secondary">{{ permission.name }}</h5>

                <b-dropdown v-if="roles.length" class="ml-auto dropdown--tertiary modal-list" variant="link"
                            toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                        <h5 class="heading5 heading5--secondary text-capitalize">{{ permission.role.name }}</h5>
                    </template>

                    <b-dropdown-item v-for="(role, index) in roles" :key="index" @click="permission.role = role">
                        <h5 class="heading5 heading5--secondary">{{ role.name }}</h5>
                        <p>{{ role.description }}</p>
                    </b-dropdown-item>
                </b-dropdown>

                <a @click="folder.permissions.splice(index, 1)" class="remove-action" href="#">Remove</a>
            </li>
        </ul>

        <template #modal-footer>
            <primary-button
                caption="Save"
                loading-caption="Saving..."
                :loading="loading"
                :disabled="!canSave || loading"
                @click="save"
            />
        </template>
    </b-modal>
</template>

<script>

import trackersFolders from "@/services/trackers-folders.service";
import companies from "@/services/company.service";
import team from "@/services/team.service";
import multiselect from "vue-multiselect";
import Avatar from 'vue-avatar';
import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import {trackerTypes} from "@/constants/trackers";

export default {
    components: {
        PrimaryButton,
        multiselect,
        Avatar
    },

    data() {
        return {
            folder: {
                name: "",
                type: trackerTypes.PRIVATE,
                description: "",
                public_description: "",
                challenges_title: "",
                show_categories_filter: 0,
                permissions: [],
            },

            id: 0,
            loading: false,

            permissionHolders: [],
            newPermission: {
                holder: null,
                role: {
                    id: 0,
                    name: "",
                    description: "",
                },
            },

            roles: [],

            usersList: {},

        }
    },

    mounted() {
        if (this.$store.getters.isEnterprise) {
            companies.getRoles().then(result => {
                this.roles = result.data

                this.newPermission.role = this.roles[0]
            })
        }
    },

    computed: {
        trackerTypes() {
            return trackerTypes;
        },

        title() {
            if (this.id) {
                return "Edit Folder"
            } else {
                return "Create New Folder"
            }
        },

        canSave() {
            return this.folder.name.length
        },

        actualPermissionHolders() {
            let array = []
            this.permissionHolders.forEach(item => {
                if (!this.arrayHelper.exists(this.folder.permissions, ['type', 'id'], [item.type, item.id])) {
                    array.push(item)
                }
            })

            return array
        }
    },

    methods: {

        init() {
            this.newPermission.holder = null

            this.folder.name = ""
            this.folder.description = ""
            this.folder.public_description = ""
            this.folder.challenges_title = "Challenges"
            this.folder.show_categories_filter = 0
            this.folder.permissions = []
            this.getPermissionHolders()

            if (this.id) {
                trackersFolders.getEdit(this.id).then(response => {
                    this.folder = response.data
                })
            } else {
                if (this.$store.state.user.id) {
                    this.folder.permissions.push({
                        type: 'user',
                        id: this.$store.state.user.id,
                        name: this.$store.state.user.name,
                        avatar: this.$store.state.user.avatar,
                        role: this.roles[0],
                    })
                } else {
                    this.folder.permissions.push({
                        type: 'user',
                        id: this.$store.state.user.id,
                        name: this.$store.state.user.name,
                        avatar: this.$store.state.user.avatar,
                        role: this.roles[0],
                    })
                }
            }
        },

        getPermissionHolders() {
            team.getPermissionHolders().then(response => {
                this.permissionHolders = response.data
            })
        },

        open(id, type = trackerTypes.PRIVATE) {
            this.id = id

            if (!this.id) {
                this.folder.type = type;
            }

            this.init()
            this.$bvModal.show('modal-edit-folder')
        },

        addPermission() {
            this.folder.permissions.push(
                {
                    type: this.newPermission.holder.type,
                    id: this.newPermission.holder.id,
                    name: this.newPermission.holder.name,
                    role: this.newPermission.role,
                    avatar: this.newPermission.holder.avatar,
                }
            )

            this.newPermission.holder = null
        },

        save() {
            this.loading = true;
            trackersFolders.save(this.id, this.folder).then(() => {
                this.$emit('folderSaved')
                this.$bvModal.hide('modal-edit-folder')
            }).finally(() => {
                this.loading = false;
            })
        }
    }
}
</script>
