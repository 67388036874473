<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { useStore } from 'vue2-helpers/vuex';
import pulseLoader from 'vue-spinner/src/PulseLoader.vue';
import startupsService from "@/services/startups.service";

const domain = useRoute().query.domain;
const router = useRouter();
const store = useStore();
const loading = ref(true);
const notFound = ref(false);

(async () => {
    const {data: id} = await startupsService.findByDomain(domain);
    loading.value = false;

    if (id) {
        router.push('/startups/' + id);
    } else {
        notFound.value = true;
    }
})();

function addStartup() {
    store.state.addStartupDomain = domain;
    router.push('/startups/my-startups');
}

</script>

<template>
    <div :class="{'page-404': notFound}">
        <pulse-loader v-if="loading" :loading="loading" :color="constants.spinnerColor"></pulse-loader>

        <template v-if="notFound">
            <h1 class="heading1">Startup with this domain not found.</h1>

            <p>Try to find it in <router-link to="/startups">search</router-link> or <a href="javascript:void(0)" @click="addStartup">add new profile</a></p>
        </template>
    </div>
</template>

<style scoped>

</style>
