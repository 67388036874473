<template>
    <div>
        <b-modal title="Save Preset" id="save-filter-modal" modal-class="modal-w-lg">

            <form class="form form-modal">
                <div class="form__item mb-4">
                    <label class="form__label">Preset Name</label>
                    <input v-model="name" class="form-control form-control--mid">
                </div>

                <div class="form__item">
                    <label class="form__label">Privacy</label>

                    <div class="input-radio mb-3">
                        <input id="pr1" type="radio" v-model="type" value="personal">
                        <label for="pr1">Only me</label>
                    </div>
                    <div class="input-radio">
                        <input id="pr2" type="radio" v-model="type" value="company">
                        <label for="pr2">Shared with company</label>
                    </div>
                </div>
            </form>

            <template #modal-footer>
                <b-button :disabled="!name.length" variant="primary" size="lg" @click="save">Save</b-button>
            </template>
        </b-modal>

        <b-modal title="Edit Presets" id="manage-saved-filters-modal" size="md" ok-only ok-variant="secondary" ok-title="Close" hide-footer>

            <div v-for="(group, groupName) in list" :key="groupName">
                <label>{{groupName}}</label>

                <ul class="manage-list">
                    <li v-for="(filter, index) in group" :key="index">
                        <p class="manage-list__title">{{list[groupName][index].name}}</p>

                        <div v-if="filter.canManage" class="manage-list__controls">
                            <a @click="openEdit(filter.id)" class="link-edit">Edit</a>
                            <a @click="deleteFilter(filter.id)" class="link-remove">Delete</a>
                        </div>
                    </li>
                </ul>
            </div>
        </b-modal>
    </div>
</template>

<script>

import savedFilters from '@/services/saved-filters.service'

export default {
    props: {
        filter: Object,
        filtersList: Array,
    },

    data() {
        return {
            id: 0,
            name: '',
            type: 'personal',

            list: {},
        }
    },

    watch: {
        filtersList() {
            let list = {}

            this.filtersList.forEach(group => {
                list[group.group] = group.items.map(item => {
                    return {
                        id: item.id,
                        name: item.name,
                        newName: item.name,
                        editMode: false,
                        canManage: item.can_manage,
                    }
                })
            })

            this.list = list
        },
    },

    methods: {
        openEdit(id = 0) {
            this.id = 0
            this.name = ''
            this.type = 'personal'

            if (id) {
                savedFilters.getEdit(id).then(response => {
                    this.id = id
                    this.name = response.data.name
                    this.type = response.data.type

                    this.$bvModal.show('save-filter-modal')
                })
            }
            else {
                this.$bvModal.show('save-filter-modal')
            }
        },

        save() {
            let data = {
                name: this.name,
                type: this.type,
            }

            if (!this.id) {
                data.filter = this.filter;
            }

            savedFilters.save(this.id, data).then(response => {
                this.$bvModal.hide('save-filter-modal')

                if (this.id) {
                    this.$emit('saved-filters-changed')
                }
                else {
                    this.$emit('saved-filter-created', response.data)
                }
            })
        },

        openManage() {
            this.$bvModal.show('manage-saved-filters-modal')
        },

        deleteFilter(id) {
            if (confirm("Delete Preset?")) {
                savedFilters.deleteFilter(id).then(() => {
                    this.$emit('saved-filters-changed')
                })
            }
        }
    }
}
</script>
