import { render, staticRenderFns } from "./PdfLayout.vue?vue&type=template&id=a905b958&scoped=true&"
import script from "./PdfLayout.vue?vue&type=script&lang=js&"
export * from "./PdfLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a905b958",
  null
  
)

export default component.exports