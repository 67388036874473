export default {
    data() {
        return {
            hasMore: false,
        }
    },

    methods: {
        checkWindowBottom() {
            window.onscroll = () => {
                let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 1 >= document.documentElement.offsetHeight

                if (bottomOfWindow && this.hasMore) {
                    this.loadMore()
                }
            }
        },
    }
}
