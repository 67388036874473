<template>
    <div>
        <Application
            ref="application"
            :from-preview="true"
            :loaded="loaded"
            :showApplication="showApplication"
            :loadingQuestions="loadingQuestions"
            :info="info"
            v-on:apply="apply"
        >
        </Application>
    </div>
</template>

<script>
import Application from "@/components/trackers/Application.vue";
import trackers from "@/services/trackers.service";

export default {
    components: {
        Application,
    },

    data() {
        return {
            id: this.$route.params.id, //Do not parse this to integer, because it can be referral link
            loaded: false,

            info: {
                company_name: '',
                company_logo: '',
                company_description: '',
                name: '',
                description: '',
                created: "",
                start_date: "",
                end_date: "",
                categories: [],
                has_terms: false,
                terms: "",
            },

            showApplication: false,
            loadingQuestions: false,
        }
    },

    mounted() {
        trackers.getApplicationInfo(this.id).then(response => {
            this.info = response.data
            document.title = this.info.name + ' | SwitchPitch'
            this.loaded = true
        })
    },

    methods: {
        apply() {
            this.showApplication = true
            this.loadingQuestions = true

            trackers.getQuestions(this.id).then((response) => {
                this.$refs['application'].questions = response.data
                this.loadingQuestions = false
            })
        },
    }
}
</script>

<style scoped>

</style>
