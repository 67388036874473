import {getCurrentRuleLevel, getCurrentRuleLevelByField, getLastId} from "@/helpers/equation";

const equation = {
        data() {
            return {
                query: {
                    'rules': [],
                },

                customFieldsRules: []
            }
        },

        methods: {
            updateCustomDataFilter(filters) {
                let filtersNames = []

                for (let i in filters) {
                    let filter = filters[i]
                    let nextId = getLastId(this.query) + 1;

                    let rule = {
                        "id": nextId,
                        "name": filter.name,
                        "label": filter.label,
                        "negative": false,
                        "value": filter.value,
                        "valueAlias": filter.valueAlias,
                    }

                    let level = getCurrentRuleLevelByField(this.query, rule)
                    if (typeof level.level_1 === "undefined") {
                        this.query.rules.push(rule)
                    } else {
                        this.updateRule(rule, level)
                    }

                    filtersNames.push(filter.name)
                    this.customFieldsRules.push(filter.name)
                }

                for (let i in this.customFieldsRules) {
                    let name = this.customFieldsRules[i]
                    let index = filtersNames.indexOf(name)

                    if (index < 0) {
                        let rule = {
                            "name": name
                        }

                        this.deleteRule(rule, false, 'name')
                    }
                }

                this.customFieldsRules = filtersNames
            },

            updateRule(rule, level) {

                if (level['count'] === 1) {
                    this.query.rules[level['level_1']].value = rule.value;
                    this.query.rules[level['level_1']].valueAlias = rule.valueAlias;

                    // exceptional case for that condition
                    if (rule.name === 'trackers' && rule.value[0] === 'all') {
                        this.query.rules[level['level_1']].negative = false;
                    }
                }

                if (level['count'] === 2) {
                    this.query.rules[level['level_1']].rules[level['level_2']].value = rule.value;
                    this.query.rules[level['level_1']].rules[level['level_2']].valueAlias = rule.valueAlias;

                    // exceptional case for that condition
                    if (rule.name === 'trackers' && rule.value[0] === 'all') {
                        this.query.rules[level['level_1']].rules[level['level_2']].negative = false;
                    }
                }
            },

            toggleCondition(rule, inGroup, oldCond) {

                inGroup = inGroup || false;
                let currentLevel = getCurrentRuleLevel(this.query, rule, inGroup);
                let nextLevel  = this.nextRuleLevel(rule, currentLevel);

                let usecase = this.determineCase(currentLevel, nextLevel);

                if (usecase === '1') {
                    if (oldCond === 'AND') {
                        let spliced = this.query.rules.splice(currentLevel['level_1'], 2);
                        let newRule = {
                            'condition' : 'OR',
                            'rules'     : spliced,
                        }
                        this.query.rules.splice(currentLevel['level_1'], 0, newRule);
                    }
                }

                if (usecase === '2') {
                    if (oldCond === 'AND') {
                        let spliced = this.query.rules.splice(currentLevel['level_1'], 1);
                        let newIndex = nextLevel['level_1'] - 1;
                        this.query.rules[newIndex].rules.unshift(spliced[0]);
                    }
                }

                if (usecase === '3') {
                    if (oldCond === 'AND') {
                        if (currentLevel.count === -1) {
                            let extractedRules = this.query.rules[nextLevel['level_1']].rules;
                            this.query.rules.splice(nextLevel['level_1'], 1);
                            for (let i = 0; i < extractedRules.length; i++) {
                                this.query.rules[currentLevel['level_1']].rules.push(extractedRules[i]);
                            }
                        }
                    } else {
                        let groupLength = this.query.rules[nextLevel['level_1']].rules.length;

                        // Just two items in the group
                        if (currentLevel['level_2'] === 0 && nextLevel['level_2'] === (groupLength-1)) {
                            let extractedRues = this.query.rules[currentLevel['level_1']].rules;
                            this.query.rules.splice(currentLevel['level_1'], 1);

                            for (let i = 0; i < extractedRues.length; i++) {
                                let position = currentLevel['level_1'] + i;
                                this.query.rules.splice(position, 0, extractedRues[i]);
                            }
                        }

                        // First item of the group
                        else if (currentLevel['level_2'] === 0) {
                            let extracted = this.query.rules[currentLevel['level_1']].rules.splice(currentLevel['level_2'], 1);
                            this.query.rules.splice(currentLevel['level_1'], 0, extracted[0]);
                        }

                        // Next is last of group
                        else if (nextLevel['level_2'] === (groupLength-1)) {
                            let extracted = this.query.rules[nextLevel['level_1']].rules.splice(nextLevel['level_2'], 1);
                            this.query.rules.splice(currentLevel['level_1']+1, 0, extracted[0]);
                        }

                        else {
                            // Next and current in the middle
                            let itemsToExtract = groupLength - nextLevel['level_2'];
                            let extracted = this.query.rules[nextLevel['level_1']].rules.splice(nextLevel['level_2'], itemsToExtract);
                            let newRule = {
                                'condition': 'OR',
                                'rules': extracted,
                            }
                            this.query.rules.splice(currentLevel['level_1'] + 1, 0, newRule);
                        }
                    }
                }

                if (usecase === '4') {
                    if (oldCond === 'AND') {
                        // When current is group
                        if (currentLevel.count === -1) {
                            // Add next rule to current group
                            let spliced = this.query.rules.splice(nextLevel['level_1'], 1);
                            this.query.rules[currentLevel['level_1']].rules.push(spliced[0]);
                        }
                    }
                }
            },

            nextRuleLevel(rule, level) {
                let rules = this.query.rules;
                let currentIndex = parseInt(level['level_1']);
                let nextIndex = currentIndex + 1;
                let nextLevel = {'level_1': nextIndex, 'count': 1};

                // Check if current is inside group
                if (level['count'] === 2) {
                    let secondeLevelNextIndex = level['level_2'] + 1;
                    // First search inside current group
                    if (typeof rules[currentIndex].rules[secondeLevelNextIndex] != 'undefined') {
                        Object.assign(nextLevel, {
                            'level_1' : currentIndex,
                            'level_2' : secondeLevelNextIndex,
                            'count': 2
                        });
                        return nextLevel;
                    }
                }

                if (typeof rules[nextIndex] != 'undefined') {
                    if (typeof rules[nextIndex].condition != 'undefined') {
                        Object.assign(nextLevel, {'level_2' : 0, 'count': 2});
                    }
                } else {
                    if (typeof rules[currentIndex].condition != 'undefined') {
                        let nextSecondLevel = parseInt(level['level_2']) + 1;
                        Object.assign(nextLevel, {
                            'level_1' : currentIndex,
                            'level_2' : nextSecondLevel,
                            'count'   : 2
                        });
                    }
                }

                return nextLevel;
            },

            determineCase(currentLevel, nextLevel) {
                // Case 1: current and next in level 1
                if (currentLevel.count === 1 && nextLevel.count === 1) {
                    return '1';
                }

                // Case 2: current in level 1 and next in group
                if (currentLevel.count === 1 && nextLevel.count === 2) {
                    return '2';
                }

                // Case 3: current and next in group
                if (currentLevel.count === 2 && nextLevel.count === 2) {
                    return '3';
                }
                // Case 3: current and next in group
                if (currentLevel.count === -1 && nextLevel.count === 2) {
                    return '3';
                }

                // Case 4: current in group and next in level 1
                if (currentLevel.count === 2 && nextLevel.count === 1) {
                    return '4';
                }
                // Case 4: current in group and next in level 1
                if (currentLevel.count === -1 && nextLevel.count === 1) {
                    return '4';
                }

                return '0';
            },

            prependRule: function(rule) {
                let rules = [];
                let id = 1;
                rule.id = id;
                rules.push(rule);

                let groups = [];
                let oldRules = JSON.parse ( JSON.stringify ( this.query.rules) );

                for (let i in oldRules) {
                    let newRule = oldRules[i];

                    if (typeof newRule.rules !== 'undefined') {
                        groups.push({
                            id: (id+1),
                            count: newRule.rules.length
                        });

                        for (let j in newRule.rules) {
                            ++id;
                            let subRule =  newRule.rules[j];
                            subRule.id = id;
                            rules.push(subRule);
                        }
                    } else {
                        ++id;
                        newRule.id = id;
                        rules.push(newRule);
                    }
                }

                this.query.rules = rules

                for (let gr in groups) {
                    let groupId = groups[gr].id;
                    let count = groups[gr].count;

                    let ruleIndex = 0;

                    for (let i in this.query.rules) {
                        if (parseInt(this.query.rules[i].id) === groupId) {
                            ruleIndex = i;
                            rule = this.query.rules[i];
                            this.toggleCondition(rule, false, 'AND')
                        }
                    }

                    for (let j = 1; j < count - 1; j++) {
                        rule = this.query.rules[ruleIndex];

                        this.toggleCondition(rule, true, 'AND')
                    }
                }

            },

            invertRule(rule) {
                if (rule.name === "ecosystems" && this.$store.getters.isFreeEnterprise) {
                    this.showUpgradeModal()
                    return;
                }

                let level = getCurrentRuleLevel(this.query, rule);
                // Level 1
                if (level['count'] === 1) {
                    this.query.rules[level['level_1']].negative = !this.query.rules[level['level_1']].negative;
                }

                // Level 2
                if (level['count'] === 2) {
                    this.query.rules[level['level_1']].rules[level['level_2']].negative = !this.query.rules[level['level_1']].rules[level['level_2']].negative;
                }
            },

            updateRuleProps(query, rule, props) {
                let level = getCurrentRuleLevel(query, rule);
                // Level 1
                if (level['count'] === 1) {
                    for (let i in props) {
                        query.rules[level['level_1']][i] = props[i];
                    }
                }

                // Level 2
                if (level['count'] === 2) {
                    for (let i in props) {
                        query.rules[level['level_1']].rules[level['level_2']][i] = props[i];
                    }
                }
            },

            normalizeQuery() {
                let rules = []

                this.query.rules.forEach(rule => {
                    if (rule.condition !== undefined) {
                        if (rule.rules.length > 1) {
                            rules.push(rule)
                        }
                        else if (rule.rules.length === 1) {
                            rules.push(rule.rules[0])
                        }
                    }
                    else {
                        rules.push(rule)
                    }
                })

                this.query.rules = rules
            },
        }
    }

export default equation
