<template>
    <div>
        <div class="topbar">
            <h2 class="heading2">
                <router-link class="link-back-big" to="/opportunities">All opportunities</router-link>
            </h2>
        </div>
        
        <main class="page-startup">
            <section class="page-startup__content loading__spinner" v-if="loading">
                <span><b-spinner class="mb-1 mr-2" small></b-spinner>Loading...</span>
            </section>
            <section class="page-startup__content" v-if="!loading">
                <header class="page-startup__header">
                    <div class="image-block-wrapper">
                        <div class="image-block image-block--md">
                            <img :src="LogoHelper.getLogo(opportunity.company_logo)" :alt="opportunity.name">
                        </div>
                        
                        <div>
                            <h2 class="page-startup__heading2">{{ opportunity.name }}</h2>
                            
                            <h3 class="page-startup__heading3">{{ opportunity.company_name }}</h3>
                        </div>
                    </div>
                    
                    <a
                        class="button button--link"
                        @click="$refs.addStartups.open()"
                    >
                        Submit Startups
                    </a>

                    <add-startups
                        ref="addStartups"
                        :trackerId="+$route.params.opportunityId"
                        :defaultStartups="startups"
                        v-on:startupsAdded="startupsAdded"
                    >
                    </add-startups>
                </header>
                
                <p>
                    <v-clamp autoresize :max-lines="3" v-html="opportunity.description">
                        <template #after="{ toggle, expanded, clamped }">
                            <a v-if="expanded" class="link" @click="toggle"> Less</a>
                            <a v-if="clamped" class="link" @click="toggle"> More</a>
                        </template>
                    </v-clamp>
                </p>

                <div v-if="opportunity && this.displayStartups">

                    <form class="form-search form-search--inline">
                        <label for="fsi">My Submitted Startups</label>

                        <input id="fsi" placeholder="Search" v-model="search" />
                    </form>

                    <startups-table
                        :opportunity-id="+this.$route.params.opportunityId"
                        :loading="loadingStartups"
                        :startups="startups"
                        :removing-id="removingId"
                        :remove-startup="removeStartup"
                    ></startups-table>
                </div>
            </section>
            <aside class="page-startup__aside">
                <posted-by :opportunity="opportunity"></posted-by>
            </aside>
        </main>
    </div>
</template>

<script>
    import VClamp from "vue-clamp"

    import trackers from "@/services/trackers.service";
    import trackersCompanies from "@/services/trackers-companies.service";
    import PostedBy from "@/views/opportunities/components/PostedBy"
    import AddStartups from "@/components/trackers/AddEcosystemStartups"
    import StartupsTable from "@/views/opportunities/components/StartupsTable"
    import debounce from "@/mixins/debounce";

    export default {
        name: "OpportunityDetails",
        data() {
            return {
                opportunity: undefined,
                search: '',
                loading: true,
                startups: [],
                removingId: undefined,
                displayStartups: false,
                loadingStartups: true,
            }
        },
        mixins: [debounce],
        watch: {
            search() {
                this.debounce(() => {this.getStartups(this.computeParams())}, 500);
            }
        },
        methods: {
            getData: async function () {
                this.opportunity = undefined
                this.loading = true

                try {
                    await this.getOpportunity()
                    await this.getStartups()

                    this.loading = false
                    this.displayStartups = this.startups.length > 0
                } catch (e) {
                    console.error(e)
                    this.loading = false
                }
            },

            getOpportunity: async function () {
                const opportunityResponse = await trackers.getInfo(this.$route.params.opportunityId)
                this.opportunity = opportunityResponse.data
            },

            getStartups: async function () {
                this.startups = []
                this.loadingStartups = true

                try {
                    const response = await trackersCompanies.getListForOpportunity(this.$route.params.opportunityId, {search: this.search})
                    this.startups = response.data
                    this.loadingStartups = false
                } catch (e) {
                    console.error(e)
                    this.loadingStartups = false
                }
            },

            computeParams() {
                const id = this.$route.params.opportunityId
                const search = this.search

                return { id, search }
            },

            removeStartup: async function (startupId) {
                if (confirm("Startup will be removed from tracker. Do you want to proceed?")) {
                    this.removingId = startupId

                    await trackersCompanies.removeCompanies(this.$route.params.opportunityId, [startupId])
                    await this.getOpportunity()
                    this.startups = this.startups.filter(({ id }) => id !== startupId)
                    this.removingId = undefined
                    this.displayStartups = this.startups.length > 0
                }
            },

            startupsAdded() {
                void this.getOpportunity()
                void this.getStartups()
                this.displayStartups = true
            }
        },

        mounted() {
            void this.getData()
        },

        components: {
            VClamp,
            PostedBy,
            AddStartups,
            StartupsTable
        }
    }
</script>
