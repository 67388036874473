<template>
    <div class="insights-page__section">
        <header class="insights-page__header">
            <h3 class="heading3">Top Funded Categories</h3>
            <p>Funding per category, of startups on SwitchPitch</p>

            <b-dropdown id="dropdown-1" :text="filterLabels.topFundedCategories.period" class="insights-page__select">
                <b-dropdown-item v-for="period in periods" :key="period.value"
                                 :active="period.value === filters.topFundedCategories.period"
                                 @click="filters.topFundedCategories.period = period.value">{{ period.label }}
                </b-dropdown-item>
            </b-dropdown>
        </header>

        <div v-if="topFundedCategoriesLoading" class="d-flex justify-content-center">
            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
        </div>

        <div v-else class="cols">
            <div v-for="row in topFundedCategories" :key="row.category" class="column column5">
                <div class="card card--bordered text-center">
                    <a @click="openSearchWithCategory(row.category)"><h4 class="heading4 heading4--secondary">
                        {{ row.category }}</h4></a>
                    <h2 class="heading2">{{ row.total_raised }}</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import insights from "@/services/insights.service";

export default {
    data() {
        return {
            periods: this.constants.commonFilterPeriods,

            filters: {
                topFundedCategories: {
                    period: 'year'
                }
            },

            topFundedCategories: [],
            topFundedCategoriesLoading: false,
        }
    },

    watch: {
        'filters.topFundedCategories': {
            deep: true,
            handler() {
                this.getTopFundedCategories()
            },
            immediate: true
        }
    },

    computed: {
        filterLabels() {
            return {
                topFundedCategories: {
                    period: this.periods.find(item => item.value === this.filters.topFundedCategories.period).label.firstLetterUpperCase()
                }
            }
        }
    },

    methods: {
        async getTopFundedCategories() {
            this.topFundedCategoriesLoading = true
            let {data} = await insights.getTopFundedCategories(this.filters.topFundedCategories)
            this.topFundedCategoriesLoading = false
            this.topFundedCategories = data
        },

        openSearchWithCategory(category) {
            window.open("/startups?category=" + encodeURIComponent(category), '_blank')
        },
    }
};
</script>

<style scoped>

</style>
