<template>
    <b-modal id="company-profile-form" :hide-footer="true" modal-class="modal-w-md" title="Company Profile">
        <StartupProfile
            v-if="$store.state.user.company_type === 'startup'"
            ref="editCompanyProfile"
            @saved="$bvModal.hide('company-profile-form'); $emit('saved')"
        >
        </StartupProfile>

        <EcosystemProfile
            v-else-if="$store.state.user.company_type === 'ecosystem'"
            ref="editEcosystemProfile"
            v-on:saved="$bvModal.hide('company-profile-form'); $emit('saved')"
        >
        </EcosystemProfile>

        <CompanyProfile
            v-else
            ref="editCompanyProfile"
            v-on:saved="$bvModal.hide('company-profile-form'); $emit('saved')"
        >
        </CompanyProfile>

    </b-modal>
</template>

<script>
import StartupProfile from "@/components/startup/Profile";
import CompanyProfile from "@/components/company/Profile";
import EcosystemProfile from "@/components/ecosystem/Profile";

export default {
    components: {
        StartupProfile,
        CompanyProfile,
        EcosystemProfile,
    },

    methods: {
        open() {
            this.$bvModal.show('company-profile-form')
        },
    }
}
</script>
