<template>
    <b-modal id="modal-funding-rounds" hide-footer title="Funding History">
        <ul class="history-list">
            <li v-for="(round, index) in fundingRounds" :key="index">
                <header class="history-list__header">
                    <strong class="history-list__title">{{ stringHelper.formattedFundingSeries(round.funding_type, round.series)}}</strong> Announced {{dateHelper.decodeDate(round.announced_on)}}
                </header>
                <div class="history-list__content">
                    <strong class="history-list__title">Funds raised</strong> {{round.raised}}
                </div>

                <template v-if="round.investors.length">
                    <div class="history-list__content">
                        <strong class="history-list__title">Investors:</strong>
                        <ul>
                            <li v-for="(investor, index) in round.investors" :key="index">
                                <a v-if="investor.ecosystem_id" :href="'/ecosystems/' + investor.ecosystem_id" target="_blank">{{investor.name}}</a>
                                <template v-else>
                                    {{investor.name}}
                                </template>
                            </li>
                        </ul>
                    </div>
                </template>
            </li>
        </ul>
    </b-modal>
</template>

<script>
import startups from "@/services/startups.service";

export default {
    props: {
        initFundingRounds: Array,
    },

    data() {
        return {
            startupId: 0,
            fundingRounds: [],
        }
    },

    methods: {
        init() {
            this.fundingRounds = []
            if (this.initFundingRounds.length) {
                this.fundingRounds = this.initFundingRounds
            }
            else {
                this.getList()
            }
        },

        getList() {
            startups.getFundingRounds(this.startupId).then(result => {
                this.fundingRounds = result.data
            })
        },

        open(startupId) {
            this.startupId = startupId

            this.init()
            this.$bvModal.show('modal-funding-rounds')
        },
    }
}
</script>

<style scoped>

</style>
