import axios from '@/axios';

const RESOURCE_NAME = '/permissions';

export default {

    getRolesWithPermissions() {
        return axios.get(`${RESOURCE_NAME}/roles-with-permissions`)
    },

    getTrackerPermissionsList() {
        return axios.get(`${RESOURCE_NAME}/tracker-permissions-list`)
    },

    getCompanyPermissionsIndex() {
        return axios.get(`${RESOURCE_NAME}/company-permissions-index`)
    },

    togglePermission(roleId, permission) {
        return axios.post(`${RESOURCE_NAME}/toggle-permission?role_id=${roleId}&permission=${permission}`)
    },

    getRole(roleId) {
        return axios.post(`${RESOURCE_NAME}/get-role?role_id=${roleId}`)
    },

    saveRole(roleId, data) {
        return axios.post(`${RESOURCE_NAME}/save-role?role_id=${roleId}`, data)
    },

    deleteRole(roleId) {
        return axios.post(`${RESOURCE_NAME}/delete-role?role_id=${roleId}`)
    },

    saveCompanyPermission(data) {
        return axios.post(`${RESOURCE_NAME}/save-company-permission`, data)
    }
}
