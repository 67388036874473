<template>
    <div v-if="$store.state.user.is_admin">
        <div class="topbar topbar--full pt-4">
            <router-link to="/settings" class="link-back">All Settings</router-link>
            <h2 class="heading1 mb-0">Admin</h2>
        </div>

        <div class="admin-page">
            <Header></Header>
            <div class="admin-page__content tab-pane">
                <h4 class="heading4">The items below are features only admins can access. Add users to grant them permissions to use them.</h4>
                
                <div class="tabs-primary__inner">
                    <table class="main-table">
                        <thead>
                            <tr>
                                <th>Features</th>
                                <th>Access</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="permission in permissions" :key="permission.name">
                                <td>
                                    {{permission.label}}
                                </td>
                                <td>
                                    <template v-if="permission.holders.length">
                                        <a @click="editPermission(permission)">
                                            <div class="section-users">
                                                <div class="section-users__images">
                                                    <div v-for="(avatar, index) in stringHelper.getAvatarsForDisplay(permission.holders)" :key="index" class="section-users__images__item">
                                                        <img :src="avatar" alt=""/>
                                                    </div>
                                                </div>
                
                                                <p>
                                                    <a v-for="(item, index) in stringHelper.getPermissionNamesForDisplay(permission.holders)" :key="index">
                                                        {{item}}
                                                    </a>
                                                </p>
                                            </div>
                                        </a>
                                    </template>
                
                                    <template v-else>
                                        <a class="link" @click="editPermission(permission)">+ Add</a>
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <b-modal id="modal-edit-permissions" title="Manage permission" modal-class="modal-w-md">
    
               <ul class="rows-list mb-3">
    
                    <li class="rows-list__form">
                        <!-- Please add here the logic first -->
    
                        <multiselect class="multiselect-mid col-6"
                                     v-model="newPermissionHolder"
                                     :options="actualPermissionHolders"
                                     select-label=""
                                     deselect-label=""
                                     label="name"
                                     track-by="name"
                        >
                            <template slot="option" slot-scope="props">
                                <template v-if="props.option.type == 'group'">
                                    <div class="d-flex align-items-center">
                                        <div class="mr-2">
                                            <Avatar v-if="props.option.avatar"
                                                    :username="props.option.name"
                                                    :src="props.option.avatar"
                                                    :size="30">
                                            </Avatar>
                                            <font-awesome-icon v-else :icon="['fa', 'users']" size="lg"  />
                                        </div>
                                        {{ props.option.name }}
                                    </div>
    
                                </template>
                                <template v-else>
                                    <div class="d-flex align-items-center">
                                        <div class="mr-2">
                                            <Avatar
                                                :username="props.option.name"
                                                :src="props.option.avatar"
                                                :size="30">
                                            </Avatar>
                                        </div>
                                        {{ props.option.name }}
                                    </div>
                                </template>
                            </template>
                        </multiselect>
    
                        <b-button @click="addHolder" variant="primary" size="md" :disabled="!newPermissionHolder">Add</b-button>
                    </li>
    
                    <li v-for="(holder, index) in currentPermission.holders" :key="index">
                        <div class="image-block image-block--rounded">
                            <Avatar
                                :username="holder.name"
                                :src="holder.avatar"
                                :size="30">
                            </Avatar>
                            <img v-if="holder.type == 'user'" :src="holder.avatar" alt=""/>
                        </div>
    
                        <h5 class="heading5 heading5--secondary">{{ holder.name }}</h5>
    
                        <a @click="currentPermission.holders.splice(index, 1)" class="remove-action" href="#">Remove</a>
                    </li>
                </ul>
    
                <template #modal-footer>
                    <b-button variant="primary" size="lg" @click="save">Save</b-button>
                </template>
            </b-modal>
        </div>
    </div>
</template>

<script>
import Header from "@/views/settings/admin/AdminHeader";
import permissions from "@/services/permissions.service";
import multiselect from "vue-multiselect";
import Avatar from 'vue-avatar';
import team from "@/services/team.service";

export default {
    components: {
        Header,
        multiselect,
        Avatar,
    },

    data() {
        return {
            permissions: {},
            currentPermission: {
                name: "",
                holders: []
            },
            permissionHolders: [],
            newPermissionHolder: null,
            currentTab: "templates",
        }
    },

    computed: {
        actualPermissionHolders() {
            let array = []
            this.permissionHolders.forEach(item => {
                if (!this.arrayHelper.exists(this.currentPermission.holders, ['type', 'id'], [item.type, item.id])) {
                    array.push(item)
                }
            })

            return array
        }
    },

    mounted() {
        team.getPermissionHolders().then(response => {
            this.permissionHolders = response.data
        })

        this.getList()
    },

    methods: {
        getList() {
            permissions.getCompanyPermissionsIndex().then(result => {
                this.permissions = result.data
            })
        },

        addHolder() {
            this.currentPermission.holders.push(this.newPermissionHolder)
            this.newPermissionHolder = null
        },

        editPermission(permission) {
            this.newPermissionHolder = null;
            this.currentPermission.name = permission.name;
            this.currentPermission.holders = [];

            permission.holders.forEach(holder => {
                this.currentPermission.holders.push(holder)
            })
            this.$bvModal.show('modal-edit-permissions')
        },

        save() {
            permissions.saveCompanyPermission(this.currentPermission).then(() => {
                this.$bvModal.hide('modal-edit-permissions')
                this.getList()
            })
        }
    }
}
</script>

<style scoped>

</style>
