<template>
    <div>
        <ul class="tags-list tags-list--bordered">
            <li class="tags-list__tag" v-for="(word, index) in words" :key="index">
                {{ word }}
                <a @click="() => {words.splice(index, 1)}" class="tags-list__tag__close"></a>
            </li>
            <li>
                <div v-if="editMode" class="form-control form-control--sm">
                    <input v-model="newWord" @keyup.enter="addWord" autofocus>
                    <button @click="addWord">+Add</button>
                </div>

                <a v-else class="btn btn-transparent" @click="editMode = true"><b>+ Add A Keyword</b></a>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    props: {
        value: Array
    },

    data() {
        return {
            words: [...this.value],
            editMode: false,
            newWord: '',
        }
    },

    watch: {
        words() {
            this.$emit('input', this.words);
        }
    },

    methods: {
        addWord() {
            if (this.newWord.length) {
                this.words.push(this.newWord)
            }

            this.editMode = false
            this.newWord = ""
        }
    }
}
</script>

<style scoped>

</style>
