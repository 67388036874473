<template>
    <div>
        <div class="topbar topbar--full">
            <h2 class="heading2 pt-4 mb-0">Insights</h2>
        </div>
        
        <div class="insights-page">
            <activities/>
            <top-startups/>
            <top-funded-categories/>
        </div>
    </div>
</template>

<script>
import Activities from "@/components/insights/Activities.vue";
import TopStartups from "@/components/insights/TopStartups.vue";
import TopFundedCategories from "@/components/insights/TopFundedCategories.vue";

export default {
    components: {
        Activities,
        TopStartups,
        TopFundedCategories
    },
};
</script>

<style scoped>

</style>
