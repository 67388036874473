export default {
    'fundingSeries': [
        {
            name: 'Angel',
            value: 'Angel',
        },
        {
            name: 'Crowdfunding',
            value: 'Crowdfunding',
        },
        {
            name: 'Seed',
            value: 'Seed',
        },
        {
            name: 'Series A',
            value: 'Series A',
        },
        {
            name: 'Series B',
            value: 'Series B',
        },
        {
            name: 'Series C',
            value: 'Series C',
        },
        {
            name: 'Unknown',
            value: 'Unknown',
        },
        {
            name: 'VC Unspecified',
            value: 'Venture - Unspecified',
        },
    ],
    'fundingRange': [
        {
            name: 'Less than $250k',
            value: {
                'max': 250000
            }
        },
        {
            name: '$250k-$500k',
            value: {
                'min': 250000,
                'max': 500000
            }
        },
        {
            name: '$500k-$1M',
            value: {
                'min': 500000,
                'max': 1000000
            }
        },
        {
            name: '$1-$2.5M',
            value: {
                'min': 1000000,
                'max': 2500000
            }
        },
        {
            name: '$2.5-$5M',
            value: {
                'min': 2500000,
                'max': 5000000
            }
        },
        {
            name: '$5-$10M',
            value: {
                'min': 5000000,
                'max': 10000000
            }
        },
        {
            name: '$10-$50M',
            value: {
                'min': 10000000,
                'max': 50000000
            }
        },
        {
            name: '$50-$100M',
            value: {
                'min': 50000000,
                'max': 100000000
            }
        },
        {
            name: '$100M+',
            value: {
                'min': 100000000,
            }
        },
    ],

    'revenueRange': [
        {
            name: 'Less than $1M',
            value: 'Less than $1M',
        },
        {
            name: '$1M to $10M',
            value: '$1M to $10M',
        },
        {
            name: '$10M to $50M',
            value: '$10M to $50M',
        },
        {
            name: '$50M to $100M',
            value: '$50M to $100M',
        },
        {
            name: '$100M to $500M',
            value: '$100M to $500M',
        },
        {
            name: '$500M to $1B',
            value: '$500M to $1B',
        },
        {
            name: '$1B to $10B',
            value: '$1B to $10B',
        },
        {
            name: 'Greater than $10B',
            value: '$10B+',
        }
    ],
    employeeCount: [
        {
            name: 'Unknown',
            value: {
                'min': 0,
                'max': 0,
            }
        },
        {
            name: '1-10',
            value: {
                'min': 1,
                'max': 10,
            }
        },
        {
            name: '11-50',
            value: {
                'min': 11,
                'max': 50,
            }
        },
        {
            name: '51-100',
            value: {
                'min': 51,
                'max': 100,
            }
        },
        {
            name: '101-250',
            value: {
                'min': 101,
                'max': 250,
            }
        },
        {
            name: '251-500',
            value: {
                'min': 251,
                'max': 500,
            }
        },
    ],

    'tags': [],
    'categories': [],
    'locations': [],
    'ecosystems': [],
    'trackers': [],
    'investors': [],
    'clients': [],
    'ecosystemTypes': [
        {
            name: 'VC',
            value: 'venture-capitalist'
        },
        {
            name: 'Accelerator',
            value: 'accelerator'
        },
        {
            name: 'Event',
            value: 'event'
        },

        {
            name: 'Incubator',
            value: 'incubator'
        },
        {
            name: 'University',
            value: 'university'
        },
        {
            name: 'Association',
            value: 'association'
        },

    ]
}
