<template>
    <div>
        <input :class="inputClass"
               :placeholder="placeHolder"
               v-debounce:500="updateOptions"
               v-model.trim="value"
               @keyup.enter="submitInput"
               @keyup.down="nextItem"
               @keyup.up="prevItem"
               @keyup.esc="items = []"
        />

        <div class="topbar__key__autocomplete">
            <ul v-if="items.length" class="topbar__key__autocomplete__list" v-click-outside="function () {items = []; oldValue = '';}">
                <li :class="{'selected': currentItem === (index + 1)}" @click="itemClicked(index)" v-for="(item, index) in items" :key="item.id">{{item.name}}</li>
            </ul>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        searchString: {
            type: String,
            default: ''
        },

        clearOnSubmit: Boolean,
        placeHolder: String,
        updateFunction: Function,
        inputClass: String,
        exclude: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            oldValue: '',
            items: [],
            currentItem: 0,
        }
    },

    computed: {
        value: {
            get() {
                return this.searchString
            },

            set(value) {
                this.$emit('update:searchString', value)
            }
        }
    },

    methods: {
        updateOptions() {
            if (this.oldValue !== this.value) {

                this.items = []
                if (this.value.length) {
                    this.updateFunction(this.value).then(response => {
                        let data = response.data.map(item => {
                            if (typeof item === 'string') {
                                return {id: item, name: item}
                            } else {
                                return item
                            }
                        });

                        if (this.value.length) {
                            this.items = data.filter(item => !this.exclude.some(excludeId => excludeId === item.id))
                        }
                    })
                }

                this.oldValue = this.value
            }
        },

        nextItem() {
            if (this.items.length) {
                if (this.items.length > this.currentItem) {
                    ++this.currentItem
                } else {
                    this.currentItem = 1
                }
            }
        },

        prevItem() {
            if (this.items.length) {
                if (this.currentItem > 1) {
                    --this.currentItem
                } else {
                    this.currentItem = this.items.length
                }
            }
        },

        itemClicked(index) {
            this.currentItem = index + 1;
            this.submitInput();
        },

        submitInput() {
            let text = ""

            if (this.currentItem > 0) {
                let selected = this.items[this.currentItem - 1]
                this.$emit('submitted', selected.id)
                text = selected.name
            } else {
                this.$emit('submitted', this.value)
                text = this.value
            }

            this.items = []
            this.currentItem = 0

            if (this.clearOnSubmit) {
                this.clear();
            } else {
                this.oldValue = text
            }
        },

        clear() {
            this.$emit('input', '');
            this.value = '';
            this.oldValue = '';
        }
    }
}
</script>
