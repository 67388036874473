<template>
    <b-modal id="knowledge-base-modal" modal-class="modal-knowledgebase modal-w-full" :hide-footer="true">
        <div class="modal-wrapper">
            <aside class="modal-wrapper__aside">
                <div v-for="(items, section) in items" :key="section">
                    <h6 class="heading7">{{ pages[section] }}</h6>

                    <ul class="list-basic">
                        <li v-for="item in items" :key="item.source_id">
                            <a @click="() => {showArticle(item)}">{{item.name}}</a>
                        </li>
                    </ul>
                </div>
            </aside>

            <div class="modal-wrapper__content" v-html="content"/>
        </div>
    </b-modal>
</template>

<script>
import helperItemsService from "@/services/helper-items.service";
import {pages} from "@/constants/helperItems";

export default {
    data() {
        return {
            items: {},
            content: "",
        }
    },

    computed: {
        pages() {
            return pages
        },
    },

    methods: {
        open(item = null) {
            helperItemsService.getKnowledgeBase().then(response => {
                this.items = response.data;
                this.$bvModal.show('knowledge-base-modal');
                this.showArticle(item)
            })
        },

        showArticle(item) {
            if (item.internal) {
                this.content = '';
                helperItemsService.getText(item.id).then(response => {
                    this.content = response.data;
                })
            } else {
                window.open(item.source_id, '_blank').focus();
            }
        },
    }
}
</script>

<style scoped>

</style>
