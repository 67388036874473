<template>
    <div>
        <form v-show="!showEditFundingRounds" class="form pb-5">
            <b-row>
                <template v-if="editType === 'summary'">
                    <b-col cols="12">
                        <div class="form__item">
                            <h5 class="heading5 heading5--secondary">Company Description*</h5>
                            <span class="form__error" v-if="errors.description.length">{{errors.description.join(', ')}}</span>

                            <textarea class="form-control--mid form-control" v-model="form.description"/>
                        </div>
                    </b-col>

                    <b-col cols="12">
                        <div class="form__item">
                            <h5 class="heading5 heading5--secondary">Short Description*</h5>

                            <textarea class="form-control--mid form-control" v-model="form.short_description"/>
                        </div>
                    </b-col>
                </template>

                <template v-if="editType === 'socials'">
                    <b-col cols="12">
                        <div class="form__item">
                            <h5 class="heading5 heading5--secondary">Facebook</h5>
                            <input class="form-control--mid form-control" type="text" v-model="form.facebook_url">
                        </div>
                    </b-col>

                    <b-col cols="12">
                        <div class="form__item">
                            <h5 class="heading5 heading5--secondary">Twitter</h5>
                            <input class="form-control--mid form-control" type="text" v-model="form.twitter_url">
                        </div>
                    </b-col>

                    <b-col cols="12">
                        <div class="form__item">
                            <h5 class="heading5 heading5--secondary">Linkedin</h5>
                            <input class="form-control--mid form-control" type="text" v-model="form.linkedin_url">
                        </div>
                    </b-col>
                </template>

                <template v-if="editType === 'overview'">
                    <b-col cols="12">
                        <div class="form__item">
                            <h5 class="heading5 heading5--secondary">Company Name*</h5>
                            <span class="form__error" v-if="errors.name.length">{{errors.name.join(', ')}}</span>
                            <input class="form-control--mid form-control" type="text" v-model="form.name">
                        </div>
                    </b-col>

                    <b-col v-if="fromTracker" cols="12">
                        <div class="form__item">
                            <h5 class="heading5 heading5--secondary">Company Description*</h5>
                            <span class="form__error" v-if="errors.description.length">{{errors.description.join(', ')}}</span>

                            <textarea class="form-control--mid form-control" v-model="form.description"/>
                        </div>
                    </b-col>

                    <b-col cols="12">
                        <div class="form__item">
                            <h5 class="heading5 heading5--secondary">URL*</h5>
                            <span class="form__error" v-if="errors.url.length">{{errors.url.join(', ')}}</span>
                            <input class="form-control--mid form-control" type="text" v-model="form.url_domain">
                        </div>
                    </b-col>

                    <b-col v-if="fromTracker" cols="12">
                        <div class="form__item">
                            <h5 class="heading5 heading5--secondary">Categories*</h5>
                            <span class="form__error" v-if="errors.categories.length">{{errors.categories.join(', ')}}</span>
                            <div class="form__item">
                                <multiselect
                                    v-model="form.categories"
                                    :multiple="true"
                                    placeholder="Select Categories"
                                    :options="$store.state.categories"
                                    select-label=""
                                    deselect-label=""
                                    label="name"
                                    track-by="name"
                                    class="multiselect-mid pl-2"
                                >
                                </multiselect>
                            </div>
                        </div>
                    </b-col>

                    <b-col cols="12">
                        <div class="form__item">
                            <h5 class="heading5 heading5--secondary">
                                Funding
                                <!--<a @click="openEditFundingRounds" class="heading-edit-link">({{fundingRoundsCount}})</a>-->
                            </h5>
                            <a @click="openEditFundingRounds" class="heading-edit-link">Edit Funding Rounds ({{fundingRoundsCount}})</a>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="6">
                        <div class="form__item">
                            <h5 class="heading5 heading5--secondary">Total Raised</h5>
                            <span class="form__error" v-if="errors.total_raised.length">{{errors.total_raised.join(', ')}}</span>
                            <input class="form-control--mid form-control" type="text" v-model="form.total_raised">
                        </div>
                    </b-col>
                    <b-col cols="12" sm="6">
                        <div class="form__item">
                            <h5 class="heading5 heading5--secondary">Funding Round*</h5>

                            <select v-model="form.stage" class="selectbox selectbox-mid w-100">
                                <option v-for="(value, index) in fundingFilter" :key="index" :value="value">
                                    {{value}}
                                </option>
                            </select>

                        </div>
                    </b-col>
                    
                    <b-col cols="12" sm="6">
                        <div class="form__item">
                            <h5 class="heading5 heading5--secondary">Revenue Range</h5>

                            <select v-model="form.revenue_range" class="selectbox selectbox-mid w-100">
                                <option v-for="(name, value) in revenueFilter" :key="value" :value="value">
                                    {{name}}
                                </option>
                            </select>
                        </div>
                    </b-col>
                    <b-col cols="12" sm="6">
                        <div class="form__item">
                            <h5 class="heading5 heading5--secondary">Year Founded*</h5>
                            <span class="form__error" v-if="errors.founded.length">{{errors.founded.join(', ')}}</span>

                            <input class="form-control--mid form-control" type="number" v-model="form.founded">
                        </div>
                    </b-col>
                    
                    <b-col cols="12" sm="6">
                        <div class="form__item">
                            <h5 class="heading5 heading5--secondary">Country*</h5>
                            <span class="form__error" v-if="errors.country.length">{{errors.country.join(', ')}}</span>
                            <multiselect
                                v-model="country"
                                placeholder="Select Country"
                                :options="countriesList"
                                select-label=""
                                deselect-label=""
                                label="name"
                                track-by="name"
                                class="multiselect-mid pl-2"
                            >
                            </multiselect>
                        </div>
                    </b-col>
                    <b-col cols="12" sm="6">
                        <div class="form__item" v-if="form.country_id === 1">
                            <h5 class="heading5 heading5--secondary">State*</h5>

                            <span class="form__error" v-if="errors.state.length">{{errors.state.join(', ')}}</span>
                            <multiselect
                                v-model="state"
                                placeholder="Select State"
                                :options="statesList"
                                select-label=""
                                deselect-label=""
                                label="name"
                                track-by="name"
                                class="multiselect-mid pl-2"
                            >
                            </multiselect>
                        </div>
                    </b-col>
                    
                    <b-col cols="12" sm="6">
                        <div class="form__item">
                            <h5 class="heading5 heading5--secondary">City*</h5>

                            <span class="form__error" v-if="errors.city.length">{{errors.city.join(', ')}}</span>
                            <input class="form-control--mid form-control" type="text" v-model="form.city">
                        </div>
                    </b-col>
                    <b-col cols="12" sm="6">
                        <div class="form__item">
                            <h5 class="heading5 heading5--secondary">Employees*</h5>

                            <span class="form__error" v-if="errors.employees.length">{{errors.employees.join(', ')}}</span>
                            <select v-model="employees" class="selectbox selectbox-mid w-100">
                                <option v-for="item in filtersOptions.employeeCount.filter(option => option.name !== 'Unknown')" :key="item.name" :value="item">
                                    {{item.name}}
                                </option>
                            </select>

                        </div>
                    </b-col>
                </template>
            </b-row>

            <div class="text-center">
                <primary-button
                    caption="Save"
                    loading-caption="Saving Profile..."
                    :loading="loading"
                    @click="save"
                />
            </div>

        </form>

        <edit-funding-rounds
            v-show="showEditFundingRounds"
            ref="editFundingRounds"
            :id="startupId"
            :from-startup="true"
            :startups-service="startupsService"
            :investors-service="investorsService"
            @saved="fundingRoundsSaved"
            @back="() => {showEditFundingRounds = false}"
        >
        </edit-funding-rounds>
    </div>
</template>

<script>
import filterOptions from '@/components/search/filter-options'
import locations from "@/services/locations.service"
import profile from "@/services/startup/profile.service"
import multiselect from "vue-multiselect";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import EditFundingRounds from "@/components/EditFundingRounds.vue";
import investorsService from "@/services/investors.service";

export default {
    components: {
        EditFundingRounds,
        PrimaryButton,
        multiselect,
    },

    mixins: [
        filterOptions
    ],

    props: {
        editType: {
            type: String,
            default: 'overview',
        },

        fromTracker: {
            type: Boolean,
            default: false,
        },

        startupId: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            form: {
                name: "",
                url_domain: "",
                total_raised: "",
                categories: [],
                stage: "",
                country_id: null,
                state_id: null,
                city: "",
                founded: "",
                revenue_range: "",
                description: "",
                short_description: "",
                num_employees_min: 0,
                num_employees_max: 0,
                facebook_url: "",
                twitter_url: "",
                linkedin_url: "",
            },

            showEditFundingRounds: false,

            loading: false,
            employees: null,
            country: null,
            state: null,
            countriesList: [],
            statesList: [],
            fundingRoundsCount: 0,

            errors: {
                name: "",
                url: "",
                total_raised: "",
                employees: "",
                country: "",
                state: "",
                city: "",
                founded: "",
                description: "",
                categories: "",
            },
        }
    },

    watch: {
        country() {
            if (this.country) {
                this.form.country_id = this.country.id
            }
            else {
                this.form.country_id = 0
            }
        },

        state() {
            if (this.state) {
                this.form.state_id = this.state.id
            }
            else {
                this.form.state_id = 0
            }
        },
    },

    computed: {
        employeesFilter() {
            let list = {}

            this.filtersOptions.employeeCount.forEach(item => {
                if (item.name !== "Unknown") {
                    list[item.name] = {
                        min: item.value.min,
                        max: item.value.max,
                    }
                }
            })

            return list
        },

        fundingFilter() {
            let list = []

            this.filtersOptions.fundingSeries.forEach(item => {
                list.push(item.name)
            })

            return list
        },

        revenueFilter() {
            let list = {}

            this.filtersOptions.revenueRange.forEach(item => {
                list[item.value] = item.name
            })

            return list
        },

        startupsService() {
            return profile;
        },

        investorsService() {
            return investorsService;
        },
    },

    mounted() {
        locations.getCountriesList().then(response => {
            this.countriesList = response.data
            this.setCountry()
        })

        locations.getStatesList().then(response => {
            this.statesList = response.data
            this.setState()
        })

        this.getInfo()
    },

    methods: {
        getInfo() {
            profile.getInfo(0).then(response => {
                this.form.name = response.data.name;
                this.form.url_domain = response.data.url_domain;
                this.form.total_raised = response.data.total_raised;
                this.fundingRoundsCount = response.data.funding_rounds_count;
                let employees_min = response.data.num_employees_min;
                let employees_max = response.data.num_employees_max;

                this.employees = this.filtersOptions.employeeCount.find(item => item.value.min === parseInt(employees_min) && item.value.max === parseInt(employees_max)) ?? null;

                this.form.country_id = response.data.country_id
                this.setCountry()

                this.form.state_id = response.data.state_id
                this.setState()

                this.form.categories = response.data.categories
                this.form.city = response.data.city
                this.form.stage = response.data.stage
                this.form.founded = response.data.founded
                this.form.revenue_range = response.data.revenue_range
                this.form.description = response.data.description
                this.form.short_description = response.data.short_description
                this.form.linkedin_url = response.data.linkedin_url
                this.form.twitter_url = response.data.twitter_url
                this.form.facebook_url = response.data.facebook_url
            })
        },

        setCountry() {
            if (!this.country && this.form.country_id) {
                this.countriesList.forEach(item => {
                    if (item.id === this.form.country_id) {
                        this.country = item
                        return
                    }
                })
            }
        },

        setState() {
            if (!this.state && this.form.state_id) {
                this.statesList.forEach(item => {
                    if (item.id === this.form.state_id) {
                        this.state = item
                        return
                    }
                })
            }
        },

        save() {
            this.loading = true;

            let {description, short_description, facebook_url, twitter_url, linkedin_url, ...mainData} = this.form;
            let data;

            if (this.editType === 'summary') {
                data = {
                    description,
                    short_description,
                }
            } else if (this.editType === 'socials') {
                data = {
                    facebook_url,
                    twitter_url,
                    linkedin_url,
                }
            } else {
                data = mainData;

                if (this.fromTracker) {
                    data.description = description;
                } else {
                    delete data.categories;
                }

                if (this.employees) {
                    data.num_employees_min = this.employees.value.min
                    data.num_employees_max = this.employees.value.max
                }
            }

            profile.saveInfo(this.editType, data).then(response => {
                this.clearErrors()

                if (Object.keys(response.data.errors).length) {
                    for (let attr in response.data.errors) {
                        this.errors[attr] = response.data.errors[attr]
                    }

                } else {
                    this.$emit('saved')
                }
            }).finally(() => {
                this.loading = false
            })
        },

        clearErrors() {
            for (let i in this.errors) {
                this.errors[i] = ""
            }
        },

        openEditFundingRounds() {
            this.showEditFundingRounds = true;
            this.$refs.editFundingRounds.init();
        },

        fundingRoundsSaved(roundsCount) {
            this.showEditFundingRounds = false;
            this.fundingRoundsCount = roundsCount;
        }
    }
}
</script>

<style scoped>

</style>
