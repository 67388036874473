import search from "@/services/search.service";
import searchTypes from "@/constants/searchTypes";
import {getLastId, updateFilterRule} from "@/helpers/equation";
const aiInputMinWidth = 400;

export default {
    data() {
        return {
            promptQuery: {
                'rules': [],
                'params': {
                    'text': '',
                    'rewritten_query': '',
                    'exclusions': [],
                },
            },

            aiMode: false,
            aiText: "",
            aiTextForModal: "",
            aiLoading: false,
            suggestedAiParams: null,
            geminiAIModel: false,
            autoApplyAiKeywords: true,
            aiFailed: false,
            aiInputWidth: aiInputMinWidth,
        };
    },

    watch: {
        aiMode() {
            if (this.aiMode) {
                if (this.searchString.length) {
                    this.aiText = this.searchString
                }
            } else {
                if (this.aiText.length) {
                    this.searchString = this.aiText
                }
            }
        },

        aiText() {
            this.$nextTick(() => {
                const aiInputMaxWidth = this.$refs.topbar.clientWidth - 80;
                const spanWidth = Math.ceil(this.$refs.aiTextSpan.offsetWidth * 0.92) + 75;

                if (spanWidth > aiInputMinWidth) {
                    if (spanWidth < aiInputMaxWidth) {
                        this.aiInputWidth = spanWidth;
                    } else {
                        this.aiInputWidth = aiInputMaxWidth;
                    }
                } else {
                    this.aiInputWidth = aiInputMinWidth;
                }
            });
        }
    },

    computed: {
        canAISearch() {
            return this.searchType === searchTypes.STARTUPS && this.$store.getters.isEnterprise && !this.customView;
        }
    },

    methods: {
        getAIQueryParams(apply = false) {
            this.aiFailed = false;
            this.suggestedAiParams = null;
            this.elasticQuery = null;
            this.clearFilters()

            if (this.aiText) {
                this.aiLoading = true;

                search.getAIQueryParams(this.aiText, this.geminiAIModel ? 'GeminiAI' : 'OpenAI').then(response => {
                    this.suggestedAiParams = response.data;
                    this.suggestedAiParams.text = this.aiText;

                    if (Object.keys(this.suggestedAiParams.keywords).length) {
                        if (apply) {
                            this.applyAIQueryParams('query');
                        }
                    } else {
                        this.aiFailed = true;
                    }
                }).finally(() => {
                    this.aiLoading = false;
                })
            } else {
                this.currentPage = 1;
                this.$bvModal.hide('ai-text-modal');
                this.getList();
            }
        },

        getAIQueryParamsForModal() {
            this.aiText = this.aiTextForModal;
            this.getAIQueryParams();
        },

        applyAIQueryParams(type) {
            this.aiMode = false;
            this.aiText = '';
            this.notWatchQuery = true;
            this.notWatchFilters = true;

            this.query.rules = [];
            this.promptQuery.rules = [];
            let id = 0;

            this.promptQuery.params = {
                text: this.suggestedAiParams.text,
                rewritten_query: this.suggestedAiParams.rewritten_query,
                exclusions: this.suggestedAiParams.exclusions,
            };

            this.aiTextForModal = this.suggestedAiParams.text;

            if (this.suggestedAiParams.categories.length) {
                this.suggestedAiParams.categories.forEach(category => {
                    this.updateFilter('categories', [category]);
                    updateFilterRule(this.query, 'categories', this.filters.categories);
                })

                id += this.suggestedAiParams.categories.length;
            }

            if (this.suggestedAiParams.locations.length) {
                this.updateFilter('locations', this.suggestedAiParams.locations.map(item => item.value));
                updateFilterRule(this.query, 'locations', this.filters.locations);
                ++id;
            }

            let filters = {
                fundingSeries: 'funding_types',
                fundingRange: 'funding_ranges',
                revenueRange: 'revenue_ranges',
                lastFundingDate: 'last_funding_date',
                employeeCount: 'employees_count',
            }

            for (let filterType in filters) {
                let filterValues = this.suggestedAiParams[filters[filterType]];

                if (filterValues.length) {
                    this.updateFilter(filterType, filterValues);
                    updateFilterRule(this.query, filterType, this.filters[filterType]);
                    ++id;
                }
            }

            let rules = [];

            for (let mainWord in this.suggestedAiParams.keywords) {
                ++id;

                rules.push({
                    "id": id,
                    "label": "Keyword",
                    "name": "keyword",
                    "value": mainWord,
                    "valueAlias": mainWord,
                    "negative": false,
                    "suggestedGroupWords": this.suggestedAiParams.keywords[mainWord],
                    "groupWords": this.autoApplyAiKeywords ? this.suggestedAiParams.keywords[mainWord] : [],
                    "matchCount": 1,
                    "broadMode": false,
                })
            }

            if (type === 'keywords') {
                this.query.rules.push({
                    'condition': 'OR',
                    'rules': rules
                })
            } else {
                this.promptQuery.rules = rules;

                this.suggestedAiParams.exclusions.forEach(exclusion => {
                    ++id;

                    this.promptQuery.rules.push({
                        "id": id,
                        "label": "Keyword",
                        "name": "keyword",
                        "value": exclusion,
                        "valueAlias": exclusion,
                        "negative": true,
                        "groupWords": [],
                        "matchCount": 1,
                        "broadMode": false,
                    });
                });
            }

            this.$bvModal.hide('ai-text-modal');
            this.currentPage = 1;

            this.$nextTick(() => {
                this.notWatchQuery = false
                this.notWatchFilters = false
            })

            if (!this.suggestedAiParams.locations.filter(item => !this.filtersOptions['locations'].some(option =>option.value === item.value)).length) {
                // If filter has missing locations, list would be automatically updated after locations loading
                this.getList();
            }
        },

        editPrompt() {
            this.aiMode = true;

            this.$nextTick(() => {
                this.aiText = this.promptQuery.params.text;
            })
        },

        convertPromptToEquation() {
            let id = parseInt(getLastId(this.query));

            this.promptQuery.rules.forEach(rule => {
                ++id;
                rule.id = id;
                this.query.rules.push(rule);
            });

            this.promptQuery.rules = [];
        },

        clearPrompt() {
            this.promptQuery.rules = [];
            this.promptQuery.params.text = '';
            this.promptQuery.params.rewritten_query = '';
            this.promptQuery.params.exclusions = [];
        }
    }
}
