<template>
    <div>
        <enterprise-team v-if="$store.state.user.company_type == 'enterprise'"></enterprise-team>
        <ecosystem-team v-if="$store.state.user.company_type == 'ecosystem'"></ecosystem-team>
        <startup-team v-if="$store.state.user.company_type == 'startup'"></startup-team>
    </div>
</template>

<script>
import enterpriseTeam from "@/views/team/Team";
import ecosystemTeam from "@/views/ecosystem/Team";
import startupTeam from "@/views/startup/Team";

export default {
    components: {
        enterpriseTeam,
        ecosystemTeam,
        startupTeam,
    },
}
</script>

<style scoped>

</style>