import axios from '@/axios';

const RESOURCE_NAME = '/ecosystem/investors';

export default {

    saveRelation(startupId, investorId, data) {
        return axios.post(`${RESOURCE_NAME}/save-relation?investor_id=${investorId}&startup_id=${startupId}`, data)
    },

    getList(data) {
        return axios.post(`${RESOURCE_NAME}/get-list`, data)
    },

    getEdit(startupId, investorId) {
        return axios.post(`${RESOURCE_NAME}/get-edit?investor_id=${investorId}&startup_id=${startupId}`)
    },

    deleteRelation(startupId, investorId) {
        return axios.post(`${RESOURCE_NAME}/delete-relation?investor_id=${investorId}&startup_id=${startupId}`)
    },
}
