<template>
    <div class="public-collection-page">
        <div v-if="collectionInfoLoaded" class="topbar">
            <div class="image-block image-block--lg">
                <img :src="LogoHelper.getLogo(collectionInfo.company_logo)" alt="img">
            </div>
            <div>
                <h2 class="heading2">{{ collectionInfo.name }}</h2>
            </div>
            <div class="powered-by">
                Powered by
                
                <a href="#" class="logo"></a>
            </div>
        </div>

        <div class="main-inner">

            <h3 class="heading3">
                <template v-if="collectionInfo.challenges_title">
                    {{collectionInfo.challenges_title}}
                </template>

                <template v-else>
                    Challenges
                </template>
            </h3>

            <div v-if="collectionInfo.description.length" class="public-collection-page__description">
                <div class="public-collection-page__description__inner">
                    <h4 class="heading4">About {{collectionInfo.name}}</h4>
                    <p>{{collectionInfo.description}}</p>
                </div>
            </div>

            <div v-if="collectionInfo.show_categories_filter" class="categories-section">
                <!--<label class="categories-section__label">Categories:</label>-->

                <multiselect
                    v-model="selectedCategories"
                    :multiple="true"
                    :options="this.collectionInfo.categories"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid multiselect-modern-slim"
                >
                </multiselect>
            </div>

            <div v-for="tracker in trackers" :key="tracker.id" class="list-challenges">
                <h5 class="heading5">{{tracker.name}}</h5>
                
                <div class="list-challenges__content">
                    <p v-html="tracker.description" class="line-clamp line-clamp3"></p>

                    <template v-if="tracker.applied">
                        <button class="btn btn-secondary btn-lg">Applied</button>
                    </template>

                    <router-link v-else :to="'/public-trackers/' + tracker.id" class="btn btn-bordered">Apply</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import trackerFolders from "@/services/trackers-folders.service";
import multiselect from "vue-multiselect";
import publicCollectionsService from "@/services/public-collections.service";

export default {
    components: {
        multiselect,
    },

    data() {
        return {
            collectionHash: '',
            collectionInfo: {
                'company_name': '',
                'company_logo': '',
                'name': '',
                'description': '',
                'challenges_title': '',
                'show_categories_filter': '',
                'categories': [],
            },
            collectionInfoLoaded: false,
            trackers: [],
            filter: {
                categories: [],
            },

            selectedCategories: [],
        }
    },

    watch: {
        filter: {
            deep: true,
            handler() {
                this.getList()
            }
        },

        selectedCategories() {
            this.filter.categories = this.selectedCategories.map(item => item.id);
        },
    },

    mounted() {
        this.collectionHash = this.$route.params.hash;

        if (this.$store.getters.isEnterprise) {
            trackerFolders.getCollectionInfo(this.collectionHash).then(response => {
                this.$router.push('/trackers/folder/' + response.data.id);
            })
        } else if (this.$store.getters.isEcosystem) {
            this.$store.commit('setPageNotFound', true);
        } else {
            this.getList()
        }
    },

    methods: {
        getList() {
            publicCollectionsService.getCollectionInfo(this.collectionHash).then(response => {
                this.collectionInfo = response.data;
                this.collectionInfoLoaded = true;
            });

            publicCollectionsService.getTrackersFromCollection(this.collectionHash, {filter: this.filter}).then(response => {
                this.trackers = response.data
            });
        },
    }
}
</script>

<style scoped>

</style>
