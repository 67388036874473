export default {
    namespaced: true,
    state: {
        showSpinner: false,
    },
    getters: {
        isShownSpinner: state => state.showSpinner,
    },
    mutations: {
        setSpinnerVisibility: (state, isShownSpinner) => (state.showSpinner = isShownSpinner),
    },
}
