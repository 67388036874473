import MyStartups from '@/views/ecosystem/MyStartups'
import Portfolios from '@/views/ecosystem/Portfolios'
import Portfolio from '@/views/Portfolio'
import Account from "@/views/ecosystem/settings/Account";
import Greeting from "@/views/ecosystem/settings/Greeting";
import Search from "@/views/ecosystem/startups/Search";
import Opportunities from "@/views/ecosystem/Opportunities.vue";

export default [
    {
        path: '/find-startups',
        component: Search,
        meta: {
            access: ['ecosystem'],
            accountAccess: ['premium','trial']
        }
    },
    {
        path: '/my-startups',
        component: MyStartups,
        meta: {
            access: ['ecosystem']
        }
    },
    {
        path: '/my-startups/portfolios',
        component: Portfolios,
        meta: {
            access: ['ecosystem']
        }
    },
    {
        path: '/my-startups/portfolios/:id',
        component: Portfolio,
        meta: {
            access: ['ecosystem','enterprise']
        }
    },
    {
        path: '/settings/account',
        component: Account,
        meta: {
            access: ['ecosystem']
        }
    },
    {
        path: '/settings/greeting',
        component: Greeting,
        meta: {
            access: ['ecosystem']
        }
    },
    {
        path: '/opportunities',
        component: Opportunities,
        meta: {
            access: ['ecosystem']
        }
    },
]
