<template>
    <div :class="{'signup-page' : status != 'registered', 'enter-page' : status == 'registered'}">
        <header v-if="status == 'notregistered'" class="header">
            <a href="/signup" class="link-back">Change Company Type</a>
            <a href="/" class="logo router-link-active" title="Switch Pitch"></a>
            <a href="/" class="signup-page__close"></a>
        </header>
        <div v-if="status == 'notregistered'" class="signup-page__inner">
            <aside class="signup-page__aside">
                <h3 class="heading3">Innovate Smarter</h3>
                <div class="card">
                    <ul class="signup-page__list">
                        <li class="signup-page__list__portfolio">
                            <h5 class="heading5 heading5--secondary">Clients for portfolio companies</h5>
                            <p>Get exclusive access to enterprises’ budgeted needs on SwitchPitch. Companies like Pfizer, Citi, T-Mobile, and many others use SwitchPitch to match startups to their technology needs.</p>
                        </li>
                        <li class="signup-page__list__fund">
                            <h5 class="heading5 heading5--secondary">Funded enterprise needs</h5>
                            <p>Recommend your startups for specific enterprise needs. Your startups go to the top of the review list for enterprise consideration.</p>
                        </li>
                        <li class="signup-page__list__decision">
                            <h5 class="heading5 heading5--secondary">Decision-makers at 300+ enterprises</h5>
                            <p>Reach out directly to the right enterprise contact to discuss fits with your portfolio. Get contact information for top decision-makers posting their technology needs.</p>
                        </li>
                    </ul>
                </div>
            </aside>
            <div class="signup-page__content">
                <header class="signup-page__header">
                    <h1 class="heading1">Sign Up - Free {{ form.duration }}-day trial</h1>
                    <a href="javascript:void(0)" class="promo-code" @click="openPromoCodeModal">Enter promo code</a>
                </header>
                <div class="card">
                    <form class="signup-page__form" @submit="handleSubmit" novalidate autocomplete="off">
                        <fieldset>
                            <legend>Account</legend>

                            <div class="signup-page__form__wrapper">
                                <div class="signup-page__form__col">
                                    <span v-for="error in errors.company_name" v-bind:key="error" class="error">{{ error }}</span>
                                    <input
                                        type="text"
                                        class="form-control form-control--mid"
                                        placeholder="Company Name"
                                        v-model="form.company_name"
                                        :disabled="created_by"
                                    />
                                </div>
                                <div class="signup-page__form__col">
                                    <span v-for="error in errors.website" v-bind:key="error" class="error">{{ error }}</span>
                                    <input
                                        type="url"
                                        class="form-control form-control--mid"
                                        placeholder="Website"
                                        v-model="form.website"
                                        :disabled="created_by"
                                    />
                                </div>
                            </div>
                            <div class="signup-page__form__wrapper">
                                <div class="signup-page__form__col">
                                    <span v-for="error in errors.email" v-bind:key="error" class="error">{{ error }}</span>
                                    <input
                                        type="email"
                                        class="form-control form-control--mid"
                                        placeholder="Your Email"
                                        v-model="form.email"
                                        :disabled="created_by"
                                    />
                                </div>
                                <div class="signup-page__form__col">
                                    <div class="signup-page__form__wrapper">
                                        <div class="signup-page__form__col">
                                            <span v-for="error in errors.first_name" v-bind:key="error" class="error">{{ error }}</span>
                                            <input
                                                type="text"
                                                class="form-control form-control--mid"
                                                placeholder="First Name"
                                                v-model="form.first_name"
                                                :disabled="created_by"
                                            />
                                        </div>
                                        <div class="signup-page__form__col">
                                            <span v-for="error in errors.last_name" v-bind:key="error" class="error">{{ error }}</span>
                                            <input
                                                type="text"
                                                class="form-control form-control--mid"
                                                placeholder="Last Name"
                                                v-model="form.last_name"
                                                :disabled="created_by"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="signup-page__form__wrapper">
                                <div class="signup-page__form__col">
                                    <span v-for="error in errors.password" v-bind:key="error" class="error">{{ error }}</span>
                                    <input
                                        type="password"
                                        class="form-control form-control--mid"
                                        placeholder="Password"
                                        autocomplete="new-password"
                                        v-model="form.password"
                                        :disabled="created_by"
                                    />
                                </div>
                                <div class="signup-page__form__col">
                                    <span v-for="error in errors.confirm_password" v-bind:key="error" class="error">{{ error }}</span>
                                    <input
                                        type="password"
                                        class="form-control form-control--mid"
                                        placeholder="Confirm Password"
                                        autocomplete="new-password"
                                        v-model="form.confirm_password"
                                        :disabled="created_by"
                                    />
                                </div>
                            </div>
                        </fieldset>


                        <template v-if="this.form.duration <= 7">
                            <fieldset>
                                <legend>Payment</legend>

                                <div class="signup-page__form__wrapper">
                                    <div class="signup-page__form__col">
                                        <span v-for="error in errors.card_number" v-bind:key="error" class="error">{{ error }}</span>
                                        <span v-if="card_error" class="error">{{ card_error }}</span>
                                        <div id="card-number" />
                                    </div>
                                    <div class="signup-page__form__col">
                                        <span v-for="error in errors.full_name" v-bind:key="error" class="error">{{ error }}</span>
                                        <input
                                            type="text"
                                            class="form-control form-control--mid"
                                            placeholder="Full Name On Card"
                                            v-model="form.full_name"
                                        />
                                    </div>
                                </div>
                                <div class="signup-page__form__col">
                                    <div class="signup-page__form__wrapper">
                                        <div class="signup-page__form__col">
                                            <div id="card-expiry" />
                                        </div>
                                        <div class="signup-page__form__col">
                                            <div id="card-cvc" />
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset>
                                <legend>Billing Address</legend>

                                <div class="signup-page__form__wrapper">
                                    <div class="signup-page__form__col">
                                        <span v-for="error in errors.address_line1" v-bind:key="error" class="error">{{ error }}</span>
                                        <input
                                            type="text"
                                            class="form-control form-control--mid"
                                            placeholder="Street Address"
                                            v-model="form.address_line1"
                                        />
                                    </div>
                                    <div class="signup-page__form__col">
                                        <span v-for="error in errors.address_line2" v-bind:key="error" class="error">{{ error }}</span>
                                        <input
                                            type="text"
                                            class="form-control form-control--mid"
                                            placeholder="Address Line 2 (Optional)"
                                            v-model="form.address_line2"
                                        />
                                    </div>
                                </div>


                                <div class="signup-page__form__wrapper">
                                    <div class="signup-page__form__col">
                                        <span v-for="error in errors.address_country" v-bind:key="error" class="error">{{ error }}</span>
                                        <multiselect
                                            v-model="addressCountry"
                                            placeholder="Country"
                                            :options="countries"
                                            select-label=""
                                            deselect-label=""
                                            label="name"
                                            track-by="name"
                                            class="form-control form-control--mid multiselect-mid pl-2"
                                        >
                                        </multiselect>
                                    </div>
                                    <div class="signup-page__form__col">
                                        <div v-if="form.address_country && form.address_country == 1">
                                            <span v-for="error in errors.address_state" v-bind:key="error" class="error">{{ error }}</span>
                                            <multiselect
                                                v-model="addressState"
                                                placeholder="State"
                                                :options="states"
                                                select-label=""
                                                deselect-label=""
                                                label="name"
                                                track-by="name"
                                                class="form-control form-control--mid multiselect-mid pl-2"
                                            >
                                            </multiselect>
                                        </div>
                                    </div>

                                </div>

                                <div class="signup-page__form__wrapper">
                                    <div class="signup-page__form__col">
                                        <span v-for="error in errors.address_city" v-bind:key="error" class="error">{{ error }}</span>
                                        <input
                                            type="text"
                                            class="form-control form-control--mid"
                                            placeholder="City"
                                            v-model="form.address_city"
                                        />
                                    </div>

                                    <div class="signup-page__form__col">
                                        <span v-for="error in errors.address_zip" v-bind:key="error" class="error">{{ error }}</span>
                                        <input
                                            type="text"
                                            class="form-control form-control--mid"
                                            placeholder="Zip"
                                            v-model="form.address_zip"
                                        />
                                    </div>
                                </div>

                            </fieldset>
                        </template>
                        <fieldset class="signup-page__form__footer">
                            <div v-if="this.form.duration <= 7">
                                <div class="input-radio">
                                    <input type="radio" value="monthly" id="asd1" name="subscription_type" v-model="form.subscription_type">
                                    <label for="asd1">Monthly <br><span class="type-description" style="font-size: 11px">(Free trial then $399/month)</span></label>
                                </div>
                                <div class="input-radio">
                                    <input type="radio" value="annually" id="asd2" name="subscription_type" v-model="form.subscription_type">
                                    <label for="asd2">Annually <br/><span class="type-description">(Free trial then 3999/year)</span></label>
                                </div>
                            </div>

                            <big class="signup-page__form__price">Free {{ form.duration }} day trial</big>
                            <button class="btn btn-primary btn-lg" type="submit" :disabled="submitting" ><b-spinner v-if="submitting" class="mr-2 mb-1" small></b-spinner>Sign Up</button>
                        </fieldset>
                    </form>

                    <footer class="signup-page__footer">
                        <p>By creating an account you agree to our <a href="https://sp-files-prod.s3-us-west-2.amazonaws.com/Terms+of+Use+Nov+19.pdf">Terms and Conditions</a></p>
                    </footer>
                </div>
            </div>
        </div>
        <template v-if="status == 'registered'">
            <header class="header">
                <span class="logo"></span>
            </header>

            <div class="form form--enter">
                <header class="form--enter__header">
                    <h1 class="heading1">Confirm Email</h1>
                </header>
                <div class="form--enter__container mt-2">
                    <div class="form--enter__fieldset">
                        <p>We sent an email to {{ this.form.email }} to activate your account. Click the link to complete  registration.</p>
                        <p>If you don’t receive the  email, check your  spam folder.</p>
                        <div class="text-center">
                            <button class="button" type="button" @click="$router.push('/login')">Log In</button>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <add-promo-code ref="addPromoCode" @changeDuration="changeDuration($event)"></add-promo-code>
    </div>
</template>

<style>
    .error {
        color: red;
        font-size: .8rem;
    }
</style>

<script>
import multiselect from "vue-multiselect";
import registrationService from "@/services/registration.service";
import AddPromoCode from "./AddPromoCode.vue";

const validate = (values, duration) => {
    const errors = {};

    if (!values.company_name.length) {
        errors.company_name = ['Company name cannot be empty'];
    }

    if (!values.email.length) {
        errors.email = ['Email cannot be empty'];
    }

    if (!values.website.length) {
        errors.website = ['Website cannot be empty'];
    }

    if (!values.first_name.length) {
        errors.first_name = ['First name cannot be empty'];
    }

    if (!values.last_name.length) {
        errors.last_name = ['Last name cannot be empty'];
    }

    if (!values.password.length) {
        errors.password = ['Password cannot be empty'];
    } else if (values.password != values.confirm_password) {
        errors.confirm_password = ['Confirm password should be the same as password'];
    }
    // if (!values.card_number.length) {
    //     errors.card_number = ['Card number cannot be empty'];
    // }

    //Only Collect billing info in case if promo code has not been applied (default trial 7 days not changed)
    if (duration <= 7) {
        if (!values.full_name.length) {
            errors.full_name = ['Full name cannot be empty'];
        }

        if (!values.address_line1.length) {
            errors.address_line1 = ['Address cannot be empty'];
        }

        if (!values.address_country) {
            errors.address_country = ['Country cannot be empty'];
        }

        if (!values.address_city.length) {
            errors.address_city = ['City cannot be empty'];
        }

        if (!values.address_state && values.address_country == 1) {
            errors.address_state = ['State cannot be empty'];
        }

        if (values.address_zip.length === 0) {
            errors.address_zip = ['ZIP code cannot be empty'];
        } else if (isNaN(values.address_zip)) {
            errors.address_zip = ['ZIP code should be numeric'];
        } else if (values.address_zip.length < 5) {
            errors.address_zip = ['ZIP code cannot be less than five numbers'];
        } else if (values.address_zip.length > 5) {
            errors.address_zip = ['ZIP code cannot be more than five numbers'];
        }
    }



    return errors;
};

const hasErrors = errors => Object.keys(errors).reduce((acc, errorKey) => acc + errors[errorKey].length, 0) > 0;

const stripeElementsCreateProps = (name, placeholder, tabindex) => [
    name,
    {
        style: { base: { lineHeight: '36px' } },
        classes: { base: 'form-control--mid form-control' },
        tabindex,
        placeholder
    }
];

const valuesToRegisterData = values => ({
    email: values.email,
    website: values.website,
    password: values.password,
    last_name: values.last_name,
    first_name: values.first_name,
    company_name: values.company_name,
    company_type: values.company_type,
    confirm_password: values.confirm_password,
    promo_code: values.promo_code
});

const valuesToCreateTokenData = values => ({
    name: values.full_name,
    address_line1: values.address_line1,
    address_line2: values.address_line2,
    address_country: values.address_country.toString(),
    address_state: values.address_state.toString(),
    address_city: values.address_city,
    address_zip: values.address_zip
});

const valuesToPaymentMethodData = (values, created_by, token) => ({
    token,
    created_by,
    payment_method: 'card',
    name_on_card: values.full_name,

    address_street: values.address_line1,
    address_line2: values.address_line2,
    address_country: values.address_country,
    address_city: values.address_city,
    address_state: values.address_state,
    address_zip: values.address_zip,

    account_number: '',
    routing_number: '',
    account_holder_name: '',
    account_type: 'personal',
    account_country: '',
    currency: ''
});

const valuesToCreateSubscriptionData = (values, created_by, payment_method_id) => ({
    email: values.email,
    created_by,
    promo_code: values.promo_code,
    payment_method_id,
    subscription_type: values.subscription_type
})

export default ({
    name: 'Ecosystem',
    components: {
        multiselect,
        AddPromoCode
    },
    data() {
        return {
            form: {
                company_id: 0,
                company_type: "ecosystem",
                company_name: "",
                email: "",
                website: "",
                password: "",
                confirm_password: "",
                first_name: "",
                last_name: "",
                card_number: undefined,
                full_name: "",
                cvv: "",
                expiration_date: "",
                address_line1: "",
                address_line2: "",
                address_country: 0,
                address_city: "",
                address_state: 0,
                address_zip: "",
                payment_method_id: '',
                subscription_type: 'annually',
                duration: 7,
                promo_code: ''
            },
            addressCountry: null,
            addressState: null,

            errors: {},

            status: 'notregistered',

            loading: false,
            recaptchaToken: null,

            states: [],
            countries: [],

            submitting: false,

            created_by: undefined,
            card_error: ''
        }
    },
    watch: {
        addressCountry() {
            if (this.addressCountry) {
                this.form.address_country = this.addressCountry.id
            }
            else {
                this.form.address_country = 0
            }
        },

        addressState() {
            if (this.addressState) {
                this.form.address_state = this.addressState.id
            }
            else {
                this.form.address_state = 0
            }
        }
    },
    methods: {
        createForm() {
            if (typeof this.cardNumber == 'object') {
                this.cardNumber.destroy();
            }

            if (typeof this.cardExpiry == 'object') {
                this.cardExpiry.destroy();
            }

            if (typeof this.cardCvc == 'object') {
                this.cardCvc.destroy();
            }

            this.cardNumber = this.stripeElements.create(...stripeElementsCreateProps('cardNumber', 'Card Number', 9));
            this.cardNumber.mount('#card-number');

            this.cardExpiry = this.stripeElements.create(...stripeElementsCreateProps('cardExpiry', 'Exp. MM/YY', 10));
            this.cardExpiry.mount('#card-expiry');

            this.cardCvc = this.stripeElements.create(...stripeElementsCreateProps('cardCvc', 'CVV', 11));
            this.cardCvc.mount('#card-cvc');
        },
        changeDuration({ duration, promo_code }) {
            this.form.duration = duration;
            this.form.promo_code = promo_code;
        },
        openPromoCodeModal() {
            this.$refs.addPromoCode.open();
        },
        async recaptcha() {
            await this.$recaptchaLoaded();
            this.recaptchaToken = await this.$recaptcha();
        },
        async handleSubmit(e) {
            e.preventDefault();

            this.errors = {};
            this.card_error = '';

            try {
                if (this.submitting) {
                    return;
                }

                console.log('here');
                this.submitting = true;

                this.errors = validate(this.form, this.form.duration);

                console.log(this.errors);

                if (hasErrors(this.errors)) {
                    this.submitting = false;
                    return;
                }

                if (!this.created_by) {
                    await this.recaptcha();
                    this.created_by = await this.register();
                }

                if (this.form.duration <= 7) {
                    const token = await this.createToken();
                    this.payment_method_id = await this.addPaymentMethod(token);
                    await this.createSubscription(this.payment_method_id);
                }

                this.submitting = false;
                this.status = 'registered';
            } catch (e) {
                this.submitting = false;
                console.error('submit error', e);
            }
            
        },
        async register() {
            const response = await registrationService.register(valuesToRegisterData(this.form), this.recaptchaToken);

            if (hasErrors(response.data.errors)) {
                console.log('errors', response.data.errors);
                this.errors = response.data.errors;
                throw Error(response.data.errors);
            }

            return response.data.created_by;
        },
        async fetchStates() {
            const { data } = await registrationService.getStatesList();
            this.states = data;
        },
        async fetchCountries() {
            const { data } = await registrationService.getCountriesList();
            this.countries = data;
        },
        async createToken() {
            const response = await this.$stripe.createToken(this.cardNumber, valuesToCreateTokenData(this.form));

            if (response.error) {
                this.card_error = response.error.message;
                throw Error(response.error.message);
            }

            return response.token.id;
        },
        async addPaymentMethod(token) {
            const response = await registrationService.addPaymentMethod(valuesToPaymentMethodData(this.form, this.created_by, token));
            return response.data.method_id;
        },
        async createSubscription(payment_method_id = false) {
            return await registrationService.createSubscription(valuesToCreateSubscriptionData(this.form, this.created_by, payment_method_id));
        }
    },
    mounted() {
        this.createForm();
        void this.fetchStates();
        void this.fetchCountries();
    },
    computed: {
        stripeElements () {
            return this.$stripe.elements();
        },
    },
})
</script>
