<template>
    <b-modal id="modal-share-portfolio" title="Share Portfolio" modal-class="modal-w-md">
        <p class="modal-text">Select an Enterprise company to share this portfolio with:</p>

        <multiselect class="multiselect-mid"
                     v-model="addEnterprise"
                     :options="enterprisesToAdd"
                     select-label=""
                     deselect-label=""
                     label="name"
                     track-by="name"
        >
            <template slot="option" slot-scope="props">
                <div class="d-flex align-items-center">
                    <div class="mr-2">
                        <div class="image-block">
                            <img :src="LogoHelper.getLogo(props.option.shortLogoURL)" alt=""/>
                        </div>
                    </div>
                    {{ props.option.name }}
                </div>
            </template>
        </multiselect>

        <ul class="rows-list mb-3">
            <li v-for="(enterprise, index) in enterprises" :key="index">
                <div class="image-block">
                    <img :src="LogoHelper.getLogo(enterprise.shortLogoURL)" alt=""/>
                </div>
                <h5 class="heading5 heading5--secondary">{{ enterprise.name }}</h5>

                <a @click="enterprises.splice(index, 1)" class="link-remove link-remove--blue link ml-auto" href="#">Remove</a>
            </li>
        </ul>

        <template #modal-footer>
            <b-button variant="primary" size="lg" @click="save">Share Portfolio</b-button>
        </template>
    </b-modal>
</template>

<script>
import portfolios from "@/services/ecosystem/portfolios.service"
import enterprises from "@/services/ecosystem/enterprises.service"
import multiselect from "vue-multiselect";

export default {
    components: {
        multiselect
    },

    data() {
        return {
            id: 0,
            enterprises: [],
            enterprisesList: [],
            addEnterprise: null,
        }
    },

    watch: {
        addEnterprise() {
            if (this.addEnterprise) {
                this.enterprises.push(this.addEnterprise)
                this.addEnterprise = null
            }
        }
    },

    computed: {
        enterprisesToAdd() {
            let array = []

            this.enterprisesList.forEach(item => {
                if (!this.arrayHelper.exists(this.enterprises, 'id', item.id)) {
                    array.push(item)
                }
            })

            return array
        }
    },

    methods: {
        init() {
            this.enterprises = []

            if (this.id) {
                portfolios.getEnterprisesList(this.id).then(response => {
                    this.enterprises = response.data
                })
            }

            this.getEnterprisesList()
        },

        getEnterprisesList() {
            enterprises.getConnectedEnterprisesList(this.id).then(response => {
                this.enterprisesList = response.data
            })
        },

        open(id) {
            this.id = id
            this.init()
            this.$bvModal.show('modal-share-portfolio')
        },

        save() {
            let enterpriseIds = this.arrayHelper.arrayColumn(this.enterprises, 'id')
            portfolios.saveEnterprises(this.id, enterpriseIds).then(() => {
                this.$emit('saved')
                this.$bvModal.hide('modal-share-portfolio')
            })
        },
    }
}
</script>

<style scoped>

</style>
