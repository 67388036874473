import companies from "@/services/company.service";

export default {
    state: {
        roles: [],
        rolesLoaded: false,
    },

    actions: {
        async fetchRoles({state}, reload = false){
            if (!state.rolesLoaded || reload) {
                const response = await companies.getRoles()
                state.roles = response.data
                state.rolesLoaded = true
            }
        }
    },

    mutations: {
        clearRoles: (state) => {state.rolesLoaded = false; state.roles = []}
    },

    getters: {
        roles: state => state.roles,
    }
}
