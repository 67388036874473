import axios from '@/axios';

const RESOURCE_NAME = '/analyst';

export default {
    getPlans() {
        return axios.get(`${RESOURCE_NAME}/get-plans`)
    },

    purchasePlan(data) {
        return axios.post(`${RESOURCE_NAME}/purchase-plan`, data)
    },

    getAnalystHoursLeft() {
        return axios.get(`${RESOURCE_NAME}/get-analyst-hours-left`)
    },

    messageAnalyst(data) {
        return axios.post(`${RESOURCE_NAME}/send-message`, data);
    },

    requestAnalystHours(data) {
        return axios.post(`${RESOURCE_NAME}/request-analyst-hours`, data)
    }
}
