<template>
    <div>
        <div class="row">
            <div class="col-12 mb-3">
                <b-form-input class="form-control--mid" v-model="article.title" placeholder="Article Title*"></b-form-input>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4 mb-3">
                <date-picker class="mx-datepicker-range" format = "MM/DD/YYYY" v-model="date" placeholder="Article date*"></date-picker>
            </div>
            
            <div class="col-12 col-md-8 mb-3">
                <span class="form__error" v-for="(error, index) in errors.url" :key="index">{{error}}</span>
                <b-form-input class="form-control--mid" v-model="article.url" placeholder="Article URL*"></b-form-input>
            </div>
        </div>
    </div>
</template>

<script>

import articlesService from "@/services/articles.service";
import DatePicker from "vue2-datepicker";

export default {
    props: {
        startupId: {
            type: Number,
            default: 0,
        },
    },

    components: {
        DatePicker
    },

    data() {
        return {
            id: 0,

            article: {
                title: "",
                url: "",
                posted_on: "",
            },

            date: null,

            errors: {
                url: [],
            },
        }
    },

    watch: {
        "article.title" () {
            this.checkCanSave()
        },

        "article.url" () {
            this.checkCanSave()
        },

        date() {
            this.checkCanSave()
        }
    },

    methods: {

        init(id) {
            this.id = id
            this.article.title = ""
            this.article.url = ""
            this.date = ""
            this.errors.url = []

            if (this.id) {
                articlesService.getEdit(this.id).then(response => {
                    this.article = response.data
                    this.date = this.dateHelper.decodeDateFromServer(this.article.posted_on)
                })
            }
        },

        checkCanSave() {
            let canSave = (this.article.title.length && this.article.url.length && this.date instanceof Date)
            this.$emit('canSave', canSave)
        },

        save() {
            this.article.posted_on = this.dateHelper.encodeDateForServer(this.date)

            articlesService.save(this.id, this.startupId, this.article).then((response) => {
                if (Object.keys(response.data.errors).length) {
                    this.errors = response.data.errors
                }
                else {
                    this.$emit('saved')
                }
            })
        }
    }
}
</script>
