import axios from '@/axios';

const RESOURCE_NAME = '/clients';

export default {
    getListForSearch(query) {
        return axios.get(`${RESOURCE_NAME}/get-list-for-search?query=${query}`)
    },

    getTopListForSearch() {
        return axios.get(`${RESOURCE_NAME}/get-list-for-search-top`)
    },
}
