<template>
    <div class="insights-page__section">
        <div class="cols">
            <div class="column column2">

                <header class="insights-page__header">
                    <h3 class="heading3">Activity feed</h3>
                    <a v-if="!!actionLogs.length" class="link insights-page__link" @click="() => {
                                $bvModal.show('actionLogsModal')
                            }">View All</a>

                    <b-modal id="actionLogsModal" modal-class="action-logs-modal modal-w-xxxl"
                             title="Your Team's Activity" hide-footer>
                        <ActivityWidget :modal="true"/>
                    </b-modal>

                </header>

                <div class="card">
                    <div v-if="actionLogsLoading" class="d-flex justify-content-center m-auto">
                        <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                    </div>
                    <template v-else>
                        <ActivityFeedSection v-if="actionLogs.length"
                                             :logs="actionLogs"
                        />
                        <div v-else-if="actionLogsFetched" class="activity-no-activity">
                            <h4 class="heading4">No Team Updates Yet</h4>
                        </div>
                    </template>
                </div>
            </div>

            <div class="column column2">

                <header class="insights-page__header insights-page__header--flex">
                    <h3 class="heading3">Tracked startups news</h3>
                    <a v-if="!!startupsActionLogs.length" class="link insights-page__link" @click="() => {$refs.startupsActivityList.open()}">View All</a>
                    
                    <div class="insights-page__header__filter">
                        <FilterByTrackers
                            @changed="trackersFilterChanged"
                        />
                    </div>
                </header>

                <startups-activity-list
                    ref="startupsActivityList"/>

                <div class="card">
                    <div v-if="startupsActionLogsLoading" class="d-flex justify-content-center">
                        <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                    </div>
                    <template v-else>
                        <ul v-if="startupsActionLogs.length" class="activity-list">
                            <li v-for="(log, index) in startupsActionLogs" :key="index">
                                <div class="activity-list__author">
                                    <div class="image-block image-block--sm">
                                        <img :src="LogoHelper.getLogo(log.startup_logo)" alt="img">
                                    </div>

                                    <div class="activity-list__name text-truncate">
                                        <a :href="'/startups/' + log.startup_id" target="_blank">
                                            <h5 class="heading5 heading5--secondary text-truncate">{{
                                                    log.startup_name
                                                }}</h5>
                                        </a>
                                        <time class="activity-list__time">{{ dateHelper.decodeDate(log.date) }}</time>
                                    </div>
                                </div>
                                <div class="activity-list__info">
                                    <h5 class="heading5 heading5--secondary mb-0 activity-list__title"
                                        :class="getStartupActivityIconClass(log)">
                                            <span class="line-clamp line-clamp1">
                                                {{ log.title }} Total Amount Raised Change Total Amount Raised Change Total Amount Raised Change Total Amount Raised Change
                                            </span>
                                    </h5>

                                    <p class="line-clamp line-clamp1 mb-0" v-html="log.text"></p>
                                </div>
                            </li>
                        </ul>

                        <div v-else-if="startupsActionLogsFetched" class="startup-empty">
                            <h4 class="heading4">No Startup Updates Yet</h4>

                            <p v-if="$store.state.user.permissions.includes('tracker-create')">
                                <a href="/trackers?create_tracker=1" target="_blank">Create a tracker</a> to monitor startups
                            </p>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import insights from "@/services/insights.service";
import ActivityFeedSection from "@/components/activity/ActivityFeedSection.vue";
import ActivityWidget from "@/components/activity/ActivityWidget.vue";
import StartupsActivityList from "@/components/activity/StartupsActivityList";
import FilterByTrackers from "@/components/widgets/FilterByTrackers.vue";

export default {
    components: {
        FilterByTrackers,
        ActivityWidget,
        ActivityFeedSection,
        StartupsActivityList,
    },

    data() {
        return {
            periods: this.constants.commonFilterPeriods,
            filter: {
                trackers: [],
            },

            actionLogs: [],
            actionLogsLoading: false,
            actionLogsFetched: false,

            startupsActionLogs: [],
            startupsActionLogsLoading: false,
            startupsActionLogsFetched: false,
        }
    },

    watch: {
        filter: {
            deep: true,
            immediate: true,
            handler() {
                this.getStartupsActionLogs()
            }
        }
    },

    mounted() {
        this.getActionLogs()
    },

    methods: {
        async getActionLogs() {
            this.actionLogsLoading = true
            let {data} = await insights.getLastActionLogs()
            this.actionLogsLoading = false
            this.actionLogsFetched = true
            this.actionLogs = data
        },

        async getStartupsActionLogs() {
            this.startupsActionLogsLoading = true

            let {data} = await insights.getLastStartupsActionLogs(this.filter)
            this.startupsActionLogsLoading = false
            this.startupsActionLogsFetched = true
            this.startupsActionLogs = data
        },

        getStartupActivityIconClass(log) {
            return 'activity-list__title--' + log.type
        },

        trackersFilterChanged(trackersIds) {
            this.filter.trackers = trackersIds
        }
    }
};
</script>

<style scoped>

</style>
