<template>
    <div style="position: relative">
        <div v-if="showSpinner" class="inactive-area"></div>
        <div class="radar-filter" v-for="(axisData, axisIndex) in axes" :key="axisIndex">
            <select :class="[axisIndex === 'xAxis' ? 'x-axis' : 'y-axis', 'selectbox selectbox-mid']"
                    @change="changeType($event, axisIndex)"
                    :disabled="isDisabledActions"
            >
                <option v-for="type in entityTypes"
                        :key="type.value"
                        :value="type.value"
                        :selected="axes[axisIndex].type === type.value"
                >
                    {{ type.name }}
                </option>
            </select>

            <ul class="selected-values tags-list">
                <draggable v-model="axisData.options" :group="axisIndex" @change="changedOrder($event, axisIndex)">
                    <SettingsSegment v-for="option in axisData.options"
                                     :key="option.value"
                                     :value="option"
                                     :type="axisData.type"
                                     :checked-values="axisData.options.map(item => item.value)"
                                     :static-options="getSegmentOptions(axisData.type)"
                                     :canBeDeleted="axes[axisIndex].options.length > 1"
                                     @removeSegment="removeSegment"
                                     @selectionClosed="selectionClosed"
                                     @segmentChanged="segmentChanged"
                    />
                </draggable>

                <li v-if="(getSegmentOptions(axisData.type).length || axisData.type === 'client') && axes[axisIndex].options.length < 6">
                    <a class="link" :class="{'link-disabled': isDisabledActions}"
                       @click.prevent="!isDisabledActions && addSegment(axisIndex)">+ Add Segment</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import SettingsSegment from '@/components/radar/SettingsSegment'
    import radar from '@/services/radar.service'
    import draggable from 'vuedraggable'
    import {mapMutations, mapGetters} from 'vuex'
    import radarEntityTypes from "@/constants/radarEntityTypes";
    import accountTypes from "@/constants/accountTypes";

    export default {
        name: 'RadarSettings',
        components: {
            SettingsSegment,
            draggable,
        },
        data() {
            return {
                isDisabledActions: true,
                defaultFunnelSteps: [],
                fundingSeries: [],
                categoryOptions: [],
                tagOptions: [],
                axes: {
                    yAxis: {
                        type: 'category',
                        options: [],
                        hasChanged: false,
                    },
                    xAxis: {
                        type: 'stage',
                        options: [],
                        hasChanged: false,
                    },
                },
            }
        },

        mounted() {
            this.initComponentData()
        },

        computed: {
            ...mapGetters({
                settings: 'radar/radarSettings',
                showSpinner: 'search/isShownSpinner',
                topClients: 'clients/topClients'
            }),
            ...mapGetters(['categories', 'trackersList', 'tagsList']),

            entityTypes() {
                if (this.$store.state.companyType === accountTypes.ENTERPRISE) {
                    return radarEntityTypes
                }
                else {
                    return radarEntityTypes.filter(item => !['tracker','funnel','tag'].includes(item.value))
                }
            }
        },

        watch: {
            settings(newValue) {
                this.axes.yAxis.type = newValue.yAxis.type
                this.axes.yAxis.options = newValue.yAxis.options

                this.axes.xAxis.type = newValue.xAxis.type
                this.axes.xAxis.options = newValue.xAxis.options

                this.isDisabledActions = false
            },
            categories: {
                handler(newValue) {
                    this.categoryOptions = newValue.map(category => {
                        return {
                            name: category.name,
                            value: category.name,
                        }
                    })
                },
                immediate: true
            },
            tagsList: {
                handler(newValue) {
                    this.tagOptions = newValue.map(tag => {
                        return {
                            name: tag.name,
                            value: tag.name,
                        }
                    })
                },
                immediate: true
            },
        },

        methods: {
            ...mapMutations({
                setCustomRadarSettings: 'radar/setCustomRadarSettings',
            }),

            initComponentData() {
                this.getDefaultFunnel()
                this.fundingSeries = Object.keys(this.constants.fundingTypes).map(value => {
                    return {
                        name: value,
                        value: value,
                    }
                })
            },

            setCategory(category, options) {
                this.axes.yAxis.type = category;
                this.axes.yAxis.options = options.filter((item, index) => index < 6).map(item => ({name: item, value: item}));
                this.setCustomRadarSettings(this.axes)
            },

            getDefaultFunnel() {
                radar.getDefaultFunnel().then(response => {
                    if (response.data && response.data.steps.length) {
                        // map result to rename property and follow common style of naming
                        this.defaultFunnelSteps = response.data.steps.map(step => {
                            return {
                                name: step.name,
                                value: step.name
                            }
                        })
                    }
                })
            },

            changeType(event, axisIndex) {
                let notChangedAxisIndex = Object.keys(this.axes).filter(key => key !== axisIndex)
                let updatedType = event.target.value
                let oldType = this.axes[axisIndex].type

                // swap sets of options if both were selected
                if (updatedType === this.axes[notChangedAxisIndex].type) {
                    this.axes[notChangedAxisIndex].type = oldType
                    this.axes[notChangedAxisIndex].options = []
                }

                this.axes[axisIndex].type = updatedType
                this.axes[axisIndex].options = []

                this.isDisabledActions = true
                this.setCustomRadarSettings(this.axes)
            },

            getSegmentOptions(segmentType) {
                let axisIndex = this.getAxisIndexByType(segmentType)

                // excluded selected per type
                if (segmentType === 'stage') {
                    return this.fundingSeries.filter(x => !this.axes[axisIndex].options.find(option => option.value === x.value))
                } else if (segmentType === 'funnel') {
                    return this.defaultFunnelSteps.filter(x => !this.axes[axisIndex].options.find(option => option.value === x.value))
                } else if (segmentType === 'category') {
                    return this.categoryOptions.filter(x => !this.axes[axisIndex].options.find(option => option.value === x.value))
                } else if (segmentType === 'tracker') {
                    return this.trackersList.map(item => ({...item, value: item.id})).filter(x => !this.axes[axisIndex].options.find(option => option.value === x.value))
                } else if (segmentType === 'tag') {
                    return this.tagOptions.filter(x => !this.axes[axisIndex].options.find(option => option.value === x.value))
                } else if (segmentType === 'client') {
                    return this.topClients.filter(item => !this.axes[axisIndex].options.find(option => option.value === item.value))
                }
            },

            removeSegment(segmentType, segmentValue) {
                let axisIndex = this.getAxisIndexByType(segmentType)

                let index = this.axes[axisIndex].options.findIndex(option => option.value === segmentValue.value)
                if (index >= 0) {
                    this.axes[axisIndex].options.splice(index, 1)
                    this.axes[axisIndex].hasChanged = true
                }

                this.setCustomRadarSettings(this.axes)
            },

            addSegment(axisIndex) {
                this.axes[axisIndex].options.push({name: '', value: ''})
            },

            selectionClosed(segmentType, segmentValue) {
                let axisIndex = this.getAxisIndexByType(segmentType)

                // remove empty segment
                let index = this.axes[axisIndex].options.findIndex(option => option.value === '')
                if (index >= 0) {
                    this.axes[axisIndex].options.splice(index, 1)
                }

                if (segmentValue?.value) {
                    this.axes[axisIndex].options.push(segmentValue)
                    this.axes[axisIndex].hasChanged = true

                    this.setCustomRadarSettings(this.axes)
                }
            },

            segmentChanged(segmentType, segmentValue, segmentOldValue) {
                let axisIndex = this.getAxisIndexByType(segmentType)

                let index = this.axes[axisIndex].options.findIndex(option => option.value === segmentOldValue.value)

                if (index >= 0) {
                    this.axes[axisIndex].options.splice(index, 1, segmentValue)
                    this.axes[axisIndex].hasChanged = true

                    this.setCustomRadarSettings(this.axes)
                }
            },

            getAxisIndexByType(axisType) {
                return Object.keys(this.axes).find(axisIndex => this.axes[axisIndex].type === axisType)
            },

            changedOrder(param, axisIndex) {
                if (param.moved) {
                    this.axes[axisIndex].hasChanged = true

                    this.setCustomRadarSettings(this.axes)
                }
            }
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
