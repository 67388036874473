<template>
    <div v-if="$store.state.user.permissions.indexOf('team-manage') >= 0 || $store.state.user.admin_groups.length">
        <div v-if="errorMessage.length">
            {{errorMessage}}
        </div>

        <div class="topbar topbar--full">
            <router-link to="/team" class="link-back">Team</router-link>
            
            <div class="topbar__inner">
                <h2 class="tracker-name heading2">
                    {{ info.name }}
                </h2>

                <div class="topbar__side" v-if="$store.state.user.permissions.indexOf('team-manage') >= 0">
                    <ul class="actions-list">
                        <li>
                            <div>
                                <b-dropdown id="dropdown-1" text="Actions" class="topbar__side__actions">
                                    <b-dropdown-item class="action" @click="$refs.editGroup.open(id)">
                                        <font-awesome-icon :icon="['fa', 'users']" />
                                        Edit Group
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="main-inner">
            <header class="main-inner__header">
                <h2 class="heading2">Users</h2>

                <a class="link" @click="$refs.editUser.open(0, false)">+ Add User</a>
            </header>

            <b-row class="main-inner__content">
                <ul class="list-contacts">
                    <li v-for="user in users" :key="user.id">
                        <div class="card card--bordered card--centered">
                            <div class="image-block image-block--rounded--lg">
                                <Avatar
                                    :username="user.name"
                                    :src="user.avatar"
                                    :size="75"
                                >
                                </Avatar>
                            </div>
                            <h5 class="heading5">{{ user.name }}</h5>
                            <p>{{ user.job_title }}</p>

                            <b-dropdown v-if="$store.state.user.permissions.indexOf('team-manage') >= 0 || !user.is_admin" class="drag-n-drop icons-list dropdown-simple position-absolute" boundary="viewport">
                                <b-dropdown-item class="icons-list__edit" @click="$refs.editUser.open(user.id, false)">
                                    Edit User
                                </b-dropdown-item>
                                <b-dropdown-item class="icons-list__delete2" v-if="$store.state.user.permissions.indexOf('team-manage') >= 0 || ($store.state.user.admin_groups.indexOf(id) >= 0 && user.role !== 'admin')" @click="removeUserFromGroup(user.id)">
                                    Remove From Group
                                </b-dropdown-item>
                                <b-dropdown-item v-if="!user.confirmed" class="icons-list__invite" @click="sendActivationEmail(user.id)">
                                    Re-send Invite
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </li>
                </ul>
            </b-row>
        </div>

        <edit-group
            ref="editGroup"
            v-on:groupSaved="getInfo"
        >
        </edit-group>

        <edit-user
            ref="editUser"
            :group-id="id"
            v-on:userSaved="getUsersList"
        >
        </edit-user>
    </div>
</template>

<script>
import team from "@/services/team.service"
import editUser from "@/components/team/EditUser";
import Avatar from "vue-avatar";
import editGroup from "@/components/team/EditGroup";
import Vue from "vue";

export default {
    components: {
        editGroup,
        editUser,
        Avatar,
    },
    data() {
        return {
            id: parseInt(this.$route.params.id),

            info: {
                name: '',
            },

            users: [],

            errorMessage: "",
        }
    },
    mounted() {
        this.getInfo()
        this.getUsersList()

        this.$store.watch(state => {
            if (state.needUpdateUsers) {
                this.getUsersList()
            }
        })
    },

    methods: {
        getInfo() {
            team.getGroupInfo(this.id).then(response => {
                this.info = response.data
                document.title = this.info.name + ' | SwitchPitch'
            }).catch(error => {
                this.errorMessage = error.response.data.message
            })
        },

        getUsersList() {
            team.getGroupUsersIndex(this.id).then(response => {
                this.users = response.data
            })
        },

        removeUserFromGroup(userId) {
            if (confirm("Remove user from current group?")) {
                team.removeUserFromGroup(this.id, userId).then(() => {
                    this.getUsersList()
                })
            }
        },

        sendActivationEmail(id) {
            team.sendActivationEmail(id).then((response) => {

                let notifyText

                if (response.data) {
                    notifyText = "Email sent"
                }
                else {
                    notifyText = "Error occurred while sending message"
                }

                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 5000,
                    text: notifyText
                })
            })
        }
    }
}
</script>

<style scoped>

</style>
