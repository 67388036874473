<template>
    <b-modal id="modal-add-error-report" title="Add Error Report" modal-class="modal-w-md">
        <div class="form form-modal mb-4">
            What's the issue with the <template v-if="startupId">startup</template><template v-else>ecosystem</template>  data*
            <b-form-textarea class="form-control--mid" v-model="report.text"></b-form-textarea>
        </div>

        <template #modal-footer>
            <b-button v-if="loading" variant="primary" disabled size="lg">Sending report...</b-button>
            <b-button v-else variant="primary" size="lg" :disabled="!canSave" @click="save">Save</b-button>
        </template>
    </b-modal>
</template>

<script>

import startups from "@/services/startups.service";
import ecosystems from "@/services/ecosystems.service";
import Vue from "vue";

export default {
    props: {
        startupId: Number,
        ecosystemId: Number,
    },

    data() {
        return {
            report: {
                text: "",
            },

            loading: false,
        }
    },

    computed: {
        canSave() {
            return this.report.text.length
        },
    },

    methods: {

        init() {
            this.report.text = ""
        },

        open() {
            this.init()
            this.$bvModal.show('modal-add-error-report')
        },

        async save() {
            this.loading = true

            if (this.startupId) {
                await startups.addErrorReport(this.startupId, this.report).then(() => {
                    this.$bvModal.hide('modal-add-error-report')
                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: "Your report has been sent, thank you!"
                    })
                })
            }
            else if (this.ecosystemId) {
                await ecosystems.addErrorReport(this.ecosystemId, this.report).then(() => {
                    this.$bvModal.hide('modal-add-error-report')
                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: "Your report has been sent, thank you!"
                    })
                })
            }

            this.loading = false
        }
    }
}
</script>
