<template>
    <b-modal :id="'modal-edit-section-'+id" :title="title" modal-class="modal-w-md">

        <b-form-input class="form-control--mid" v-model="section.name" placeholder="Enter a name*"></b-form-input>

        <h4 class="heading4 mt-3">Permissions

            <b-dropdown class="ml-3 dropdown--tertiary" variant="link" ref="hasPermissionsDropdown" toggle-class="text-decoration-none" no-caret>

                <template v-if="hasPermissions" #button-content>
                    Only users I select can access
                </template>

                <template v-else #button-content>
                    All users can access
                </template>

                <b-dropdown-header>
                    <ul class="modal-list">
                        <li @click="hasPermissions = false; $refs.hasPermissionsDropdown.hide()">
                            <h5 class="heading5 heading5--secondary">All users can access</h5>
                        </li>
                        <li @click="hasPermissions = true; $refs.hasPermissionsDropdown.hide()">
                            <h5 class="heading5 heading5--secondary">Only users I select can access</h5>
                        </li>
                    </ul>
                </b-dropdown-header>

            </b-dropdown>
        </h4>

        <ul class="rows-list mb-3">

            <li class="rows-list__form" v-if="hasPermissions">
                <!-- Please add here the logic first -->

                <multiselect class="multiselect-mid col-12 col-md-5 mb-3 mb-sm-0"
                             v-model="newPermission.holder"
                             :options="actualPermissionHolders"
                             select-label=""
                             deselect-label=""
                             label="name"
                             track-by="name"
                >
                    <template slot="option" slot-scope="props">
                        <template v-if="props.option.type == 'group'">
                            <div class="d-flex align-items-center">
                                <div class="mr-2">
                                    <font-awesome-icon :icon="['fa', 'users']" size="lg"  />
                                </div>
                                {{ props.option.name }}
                            </div>

                        </template>
                        <template v-else>
                            <div class="d-flex align-items-center">
                                <div class="mr-2">
                                    <Avatar
                                        :username="props.option.name"
                                        :src="props.option.avatar"
                                        :size="30">
                                    </Avatar>
                                </div>
                                {{ props.option.name }}
                            </div>
                        </template>
                    </template>
                </multiselect>

                <span class="text-nowrap ml-0 ml-sm-2">Add as:</span>
                <b-dropdown class="mx-2 dropdown--tertiary" variant="link" ref="roleDropdown" toggle-class="text-decoration-none" no-caret>

                    <template #button-content>
                        {{ constants.customFieldAccessTypes[newPermission.accessType] }}
                    </template>

                    <b-dropdown-header>
                        <ul class="modal-list">
                            <li v-for="(name, accessType) in constants.customFieldAccessTypes" :key="name" @click="newPermission.accessType = accessType; $refs.roleDropdown.hide()">
                                <h5 class="heading5 heading5--secondary">{{ name }}</h5>
                            </li>
                        </ul>
                    </b-dropdown-header>

                </b-dropdown>

                <b-button @click="addPermission" variant="primary" size="md" :disabled="!newPermission.holder">Add</b-button>
            </li>

            <li v-for="(permission, index) in section.permissions" :key="index">
                <template v-if="hasPermissions">
                    <div class="image-block image-block--rounded">
                        <Avatar
                            :username="permission.name"
                            :src="permission.avatar"
                            :size="30">
                        </Avatar>
                        <img v-if="permission.type == 'user'" :src="permission.avatar" alt=""/>
                    </div>

                    <h5 class="heading5 heading5--secondary">{{ permission.name }}</h5>

                    <b-dropdown class="ml-auto dropdown--tertiary modal-list" variant="link" toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <h5 class="heading5 heading5--secondary text-capitalize">{{constants.customFieldAccessTypes[permission.accessType]}}</h5>
                        </template>

                        <b-dropdown-item v-for="(name, accessType) in constants.customFieldAccessTypes" :key="name" @click="permission.accessType = accessType">
                            <h5 class="heading5 heading5--secondary">{{name}}</h5>
                        </b-dropdown-item>
                    </b-dropdown>

                    <a @click="section.permissions.splice(index, 1)" class="remove-action" href="#">Remove</a>
                </template>
            </li>
        </ul>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canSave" @click="save">Save</b-button>
        </template>
    </b-modal>
</template>

<script>
import customFields from "@/services/custom-fields.service"
import multiselect from "vue-multiselect";
import team from "@/services/team.service";
import Avatar from "vue-avatar";

export default {
    props: {
        id: {
            type: Number,
            default: 0,
        }
    },

    components: {
        multiselect,
        Avatar,
    },

    data() {
        return {

            section: {
                name: "",
                permissions: [],
            },

            hasPermissions: false,

            permissionHolders: [],
            newPermission: {
                holder: null,
                accessType: 'read-only',
            },
        }
    },

    computed: {
        title() {
            if (this.id) {
                return "Edit Section"
            } else {
                return "Add New Section"
            }
        },

        canSave() {
            return this.section.name.length
        },

        actualPermissionHolders() {
            let array = []
            this.permissionHolders.forEach(item => {
                if (!this.arrayHelper.exists(this.section.permissions, ['type', 'id'], [item.type, item.id])) {
                    array.push(item)
                }
            })

            return array
        }
    },

    methods: {
        init() {
            team.getPermissionHolders().then(response => {
                this.permissionHolders = response.data
            })

            this.newPermission.holder = null

            if (this.id) {
                customFields.getEditSection(this.id).then(response => {
                    this.section.name = response.data.name
                    this.section.permissions = response.data.permissions

                    if (this.section.permissions.length) {
                        this.hasPermissions = true
                    }
                    else {
                        this.hasPermissions = false
                    }

                    this.$bvModal.show('modal-edit-section-' + this.id)
                })
            }
            else {
                this.field = {
                    name: "",
                    permissions: [],
                }

                this.$bvModal.show('modal-edit-section-' + this.id)
            }
        },

        open() {
            this.init()
        },

        save() {
            let data = {
                name: this.section.name,
                permissions: this.hasPermissions ? this.section.permissions : [],
            }

            customFields.saveSection(this.id, data).then(() => {
                this.$emit('sectionSaved')
                this.$bvModal.hide('modal-edit-section-' + this.id)
            })
        },

        addPermission() {
            this.section.permissions.push(
                {
                    type: this.newPermission.holder.type,
                    id: this.newPermission.holder.id,
                    name: this.newPermission.holder.name,
                    accessType: this.newPermission.accessType,
                    avatar: this.newPermission.holder.avatar,
                }
            )

            this.newPermission.holder = null
        },
    }
}
</script>

<style scoped>

</style>
