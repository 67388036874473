<template>
    <div class="mystartups-page">
        <header class="topbar mystartups-page__header">
            <startups-header></startups-header>
            <input
                type="text"
                class="form-search-primary"
                placeholder="Search Portfolios"
                v-debounce:500ms.lock="applySearch"
                :disabled="loading"
            >
            <div class="topbar__side actions-list">
                <a class="link-wrapper ml-4" @click="addPortfolio"><span class="link-wrapper__add" >New Portfolio</span></a>
            </div>
        </header>
        
        <main class="main main--dark">
            <div class="main__inner">
                <div class="showing-select">
                    Showing
                    <select v-if="totalRowsCount >= 25" v-model="pageLength">
                        <option value="15">15</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                    <template v-else>{{totalRowsCount}}</template>

                    of {{totalRowsCount}} portfolios
                </div>
                
                <ul class="list-cards">
                    <li v-for="portfolio in portfolios" :key="portfolio.id">
                        <div class="list-cards__content">
                            <div>
                                <router-link
                                    class="heading5 heading5--tertiary"
                                    v-for="name in portfolio.name"
                                    :to="name.to"
                                    :key="name.to"
                                >{{ name.label }}</router-link>
                                <p>
                                    <v-clamp autoresize :max-lines="3">
                                        {{ portfolio.description }}
                                        <template #after="{ toggle, expanded, clamped }">
                                            <a v-if="expanded" class="link" @click="toggle"> Less</a>
                                            <a v-if="clamped" class="link" @click="toggle"> More</a>
                                        </template>
                                    </v-clamp>
                                </p>
                            </div>
                            <div>
                                <span class="portfolio-startups">{{ portfolio.startups_count ? portfolio.startups_count : 0 }} startups</span>
                                <span class="portfolio-enterprises">{{ portfolio.enterprises_count ? portfolio.enterprises_count : 0 }} enterprise shared</span>
                            </div>
                        </div>
                        <b-dropdown right text="Actions" class="list-cards__actions icons-list dropdown-simple dropdown-small ml-auto">
                            <b-dropdown-item @click="editPortfolio(portfolio.id)" class="icons-list__edit">Edit </b-dropdown-item>
                            <b-dropdown-item @click="sharePortfolio(portfolio.id)" class="icons-list__share">Share</b-dropdown-item>
                            <b-dropdown-item @click="deletePortfolio(portfolio.id)" class="icons-list__delete2">Delete</b-dropdown-item>
                        </b-dropdown>
                    </li>
                </ul>
            </div>
        </main>

        <edit-portfolio
            ref="editPortfolio"
            v-on:saved="getList"
        >
        </edit-portfolio>

        <share-portfolio
            ref="sharePortfolio"
            v-on:saved="getList"
        >
        </share-portfolio>
    </div>
</template>

<script>
import VClamp from "vue-clamp";

import grid from "@/mixins/grid";
import portfolios from "@/services/ecosystem/portfolios.service";
import EditPortfolio from "@/components/portfolios/EditPortfolio";
import SharePortfolio from "@/components/portfolios/SharePortfolio";
import StartupsHeader from "@/components/ecosystem/StartupsHeader.vue";

export default {
    mixins: [grid],

    components: {
        StartupsHeader,
        VClamp,
        EditPortfolio,
        SharePortfolio,
    },

    data() {
        return {
            search: '',
            loading: false,
            portfolios: [],

            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    component: 'RouterLinkColumn',
                },
                {
                    label: 'Description',
                    name: 'description',
                },
                {
                    label: 'Startups',
                    name: 'startups_count',
                },
                {
                    label: 'Enterprises shared',
                    name: 'enterprises_count',
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                }
            ],

            addCellData: {
                companyType: "startups",
            },
        }
    },

    watch: {
        search() {
            this.getList();
        },
        pageLength() {
            this.getList()
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        getList() {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let sort = this.sort
            const search = this.search;

            portfolios.getPortfolios({columns, sort, currentPage, pageLength, search }).then(response => {
                this.portfolios = response.data.data;
                this.totalRowsCount = parseInt(response.data.total_count);
                this.currentPage = response.data.current_page;
            })
        },

        editPortfolio(portfolioId) {
            this.$refs.editPortfolio.open(portfolioId);
        },

        sharePortfolio(portfolioId) {
            this.$refs.sharePortfolio.open(portfolioId);
        },

        addPortfolio() {
            this.$refs.editPortfolio.open(0);
        },

        applySearch(search) {
            this.search = search;
        },

        async deletePortfolio(portfolioId) {
            if (confirm("Remove portfolio?")) {
                await portfolios.deletePortfolio(portfolioId)

                this.getList()
            }
        }
    }
}
</script>
