<template>
    <main class="enter-page">
        <header class="header">
            <span class="logo"></span>
        </header>

        <template v-if="status === 'new'">
            <div class="enter-page__inner">
                <Signup
                    v-show="accountType"
                    :company-type="accountType"
                    v-on:setAccountType="setAccountType"
                    v-on:registered="registered">
                </Signup>
    
                <template v-if="!accountType">
                    
                    <h1 class="heading1">Sign Up</h1>

                    <header class="enter-page__header">
                        <p>Select the account type that matches your company.</p>
                        <p class="enter-page__login">Have an account? <router-link to="/login">Log In</router-link></p>
                    </header>
                    
                    <ul class="signup-list">
                        <li class="signup-list__startup">
                            <a @click="accountType = 'startup'">
                                Startup
                                <small>Less than 500 employees</small>
                            </a>
                        </li>
                        <li class="signup-list__enterprise">
                            <a @click="accountType = 'enterprise'">
                                Enterprise
                                <small>More than 500 employees</small>
                            </a>
                        </li>
                        <li class="signup-list__ecosystem">
                            <router-link to="/signup/ecosystem">
                                Ecosystem
                                <small>Incubators, accelerators, VCs & others</small>
                            </router-link>
                        </li>
                    </ul>
                </template>
            </div>
        </template>

        <div v-if="status === 'registered'">
            <div class="form form--enter">
                <header class="form--enter__header">
                    <h1 class="heading1">Confirm Email</h1>
                </header>
                <div class="form--enter__container mt-2">
                    <div class="form--enter__fieldset">
                      <p>We sent an email to {{ email }} to activate your account. Click the link to complete  registration.</p>
                      <p>If you don’t receive the  email, check your  spam folder.</p>
                        <div class="text-center">
                            <button class="button" type="button" @click="$router.push('/login')">Log In</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form form--enter" v-if="status === 'registered-confirmed'">
            <div class="form--enter__container mt-2">
                <fieldset class="form--enter__fieldset">
                    <div class="text-center">
                        <p>Account has been successfully confirmed.</p>
                        <p>Please login:</p>
                        <div class="text-center">
                            <button class="button" type="button" @click="$router.push('/login')">Log In</button>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </main>
</template>

<script>

import Signup from "@/components/common/Signup";

export default {
    components: {
        Signup,
    },

    data() {
        return {
            accountType: "",
            status: "new",
            email: "",
        }
    },

    mounted() {
        if (this.$route.query.account_type) {
            this.accountType = this.$route.query.account_type
        }
    },

    methods: {
        setAccountType(accountType) {
            this.accountType = accountType
        },

        registered({status, email}) {
            this.status = status
            this.email = email
        }
    }
}
</script>
