<template>
    <b-modal title="Manage Columns" id="manage-columns" modal-class="modal-w-lg">

        <div class="content-switcher" v-if="canEditDefaultColumns">
            <span>Personal settings</span>
            <div class="content-switcher__slider">
                <input type="checkbox" id="content-switcher__slider" v-model="defaultSettings">
                <label for="content-switcher__slider"></label>
            </div>
            <span>Default Settings</span>
        </div>
        
        <p>Select the columns you want to display on the table and rearrange their order.</p>

        <SelectColumns
            ref="selectColumns"
            :table-type="tableType"
            :use-type="useType"
            :tracker-type="trackerType"
            :tracker-id="trackerId"
            @changed="setColumns"
        />
        
        <template #modal-footer>
          <b-button variant="primary" size="lg" @click="saveColumns">Save</b-button>
        </template>
    </b-modal>
</template>

<script>

import user from '@/services/user.service'
import trackers from '@/services/trackers.service'
import SelectColumns from "@/components/manage/SelectColumns.vue";

export default {
    components: {
        SelectColumns,
    },

    props: {
        tableType: String,

        trackerId: {
            type: Number,
            default: 0,
        },

        trackerType: String,
    },

    data() {
        return {
            columns: {},
            profileFields: [],
            watchProfileFields: false,
            watchColumnsProfileFields: true,
            canEditDefaultColumns: false,
            defaultSettings: false,
        }
    },

    computed: {
        useType() {
            if (this.defaultSettings) {
                return 'default'
            } else {
                return 'personal'
            }
        },
    },

    methods: {
        open() {
            this.defaultSettings = false

            if (this.trackerId) {
                trackers.getUserPermissions(this.trackerId).then(response => {
                    let userPermissions = response.data

                    if (userPermissions.indexOf('tracker-manage-default-columns') >= 0) {
                        this.canEditDefaultColumns = true
                    }
                })
            }

            this.$bvModal.show('manage-columns')
        },

        setColumns(columns) {
            this.columns = {...columns};
        },

        saveColumns() {
            this.$bvModal.hide('manage-columns')

            user.updateColumns(this.tableType, this.useType, this.trackerId, this.columns).then(() => {
                if (this.useType !== "default") {
                    this.$emit('changeColumns')
                }
            })
        },
    }
}
</script>

<style scoped>

</style>
