<template>
    <b-modal title="Add To Folder" id="add-to-folder-popup" size="sm">
        <p>Trackers can only be added to one folder in order to maintain privacy settings</p>
        <multiselect
            v-model="folder"
            :options="foldersList"
            select-label=""
            deselect-label=""
            placeholder="Type to search your folders"
            label="name"
            track-by="name"
            class="multiselect-mid selectbox selectbox-mid"
        >
        </multiselect>

        <template #modal-footer>
            <b-button :disabled="!folder" variant="primary" size="lg" @click="add">Add</b-button>
        </template>
    </b-modal>
</template>

<script>

import multiselect from "vue-multiselect";
import trackersFolders from "@/services/trackers-folders.service";

export default {

    components: {
        multiselect,
    },

    data() {
        return {
            folder: null,
            foldersList: [],
        }
    },

    methods: {
        open(type = '', exceptFolder = null) {
            this.foldersList = [];

            trackersFolders.getList(0, type).then(response => {
                this.foldersList = response.data;

                if (exceptFolder) {
                    this.foldersList = this.foldersList.filter(item => item.id !== exceptFolder.id);
                }

                this.$bvModal.show('add-to-folder-popup')
            })
        },

        add() {
            this.$bvModal.hide('add-to-folder-popup')
            this.$emit('folderSelected', this.folder)
            this.folder = null
        },
    }
}
</script>
