<template>
    <b-modal id="trial-expired-modal" title="Trial Expired" modal-class="modal-w-md">

        <div>
            Your {{plan}} account trial has expired.
            Upgrade your account to continue using our full set of features
        </div>

        <template #modal-footer>
            <b-button size="lg" @click="close">Later</b-button>
            <b-button variant="primary" class="ml-2" size="lg" @click="upgrade">Upgrade Account</b-button>
        </template>
    </b-modal>
</template>

<script>

export default {

    data() {
        return {
            plan: "",
        }
    },

    methods: {
        open(plan) {
            this.plan = plan
            this.$bvModal.show('trial-expired-modal')
        },

        close() {
            this.$bvModal.hide('trial-expired-modal')
        },

        upgrade() {
            this.close()
            this.showUpgrade(this.plan)
        },
    }
}
</script>