import axios from '@/axios';

const RESOURCE_NAME = '/helper-items';

export default {
    getItems(page, filter = '') {
        return axios.get(`${RESOURCE_NAME}/get-items?page=${page}&filter=${filter}`)
    },

    getStructuredItems(page = '', filter = '') {
        return axios.get(`${RESOURCE_NAME}/get-structured-items?page=${page}&filter=${filter}`)
    },

    getKnowledgeBase() {
        return axios.get(`${RESOURCE_NAME}/get-knowledge-base`)
    },

    getText(id) {
        return axios.get(`${RESOURCE_NAME}/get-text?id=${id}`)
    },

    setUserWatched(id) {
        return axios.post(`${RESOURCE_NAME}/set-user-watched?id=${id}`)
    },

    getLastVersion() {
        return axios.get(`${RESOURCE_NAME}/get-last-version`)
    },

    getVersionsList() {
        return axios.get(`${RESOURCE_NAME}/get-versions-list`)
    },

    getVersionData(id) {
        return axios.get(`${RESOURCE_NAME}/get-version-data?id=${id}`)
    }
}
