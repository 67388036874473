<template>
    <b-modal id="modal-add-promo-code" :title="title" modal-class="modal-w-md">

        <div v-if="applied">
            {{codeInfo}}
        </div>

        <form class="form" v-else>
            <div class="form__item">
                <h6 class="heading6 mb-1">Promo Code</h6>
                <b-form-input class="form-control--mid mb-3" v-model="code" placeholder="Enter your code*"></b-form-input>

                <template v-if="typeof errors.code !== 'undefined'">
                    <div class="form__error" v-for="(error, index) in errors.code" :key="index">{{error}}</div>
                </template>
            </div>
        </form>

        <template #modal-footer>
            <b-button v-if="applied" variant="primary" size="lg" @click="close">Close</b-button>
            <b-button v-else variant="primary" size="lg" :disabled="!canSave" @click="save">Submit</b-button>
        </template>
    </b-modal>
</template>

<script>

import subscriptions from "@/services/subscriptions.service";
import user from "@/services/user.service";

export default {

    data() {
        return {
            code: "",
            errors: {},

            applied: false,
            codeInfo: "",
            needRedirect: false,
        }
    },

    computed: {
        title() {
            if (this.applied) {
                return "Promo Code Applied"
            }
            else {
                return "Add Promo Code"
            }
        },

        canSave() {
            return this.code.length
        },
    },

    methods: {

        init() {
            this.code = ""
            this.applied = false
        },

        open() {
            this.init()
            this.$bvModal.show('modal-add-promo-code')
        },

        save() {
            this.errors = {}
            subscriptions.enterPromoCode(this.code).then((response) => {
                if (Object.keys(response.data.errors).length) {
                    this.errors = response.data.errors
                }
                else {
                    this.applied = true
                    this.codeInfo = response.data.info

                    let prevApproveStatus = this.$store.state.approveStatus
                    user.getCurrentUserData().then(response => {
                        this.$store.commit('setUser', response.data)

                        if (prevApproveStatus != 'approved' && this.$store.state.approveStatus == 'approved') {
                            this.needRedirect = true
                        }
                    })
                }
            })
        },

        close() {
            this.$bvModal.hide('modal-add-promo-code')
            if (this.needRedirect) {
                this.$router.push('/')
            }
        },
    }
}
</script>
