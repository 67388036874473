<template>
    <b-modal :id="modalId" :title="title" modal-class="modal-w-md" v-on:shown="shown">
        <edit-interaction
            ref="editInteraction"
            :usersMentionList="usersMentionList"
            :company-type="companyType"
            v-on:saved="saved"
        >
        </edit-interaction>

        <template #modal-footer>
            <b-button variant="primary" size="lg" @click="save"><template v-if="id">Update</template><template v-else>Add</template></b-button>
        </template>
    </b-modal>
</template>

<script>
import EditInteraction from "@/components/interactions/EditInteraction";

export default {
    components: {
        EditInteraction,
    },

    props: {
        type: String,
        companyType: String,
        usersMentionList: Array,
        isPreviewList: Boolean,
    },

    data() {
        return {
            id: 0,
        }
    },

    computed: {
        title() {
            if (this.id) {
                return "Edit Interaction"
            } else {
                return "Add Interaction"
            }
        },
        modalId() {
            return this.isPreviewList ? "modal-edit-interaction-preview" : "modal-edit-interaction"
        }
    },

    methods: {
        open(id) {
            this.id = id
            this.$bvModal.show(this.modalId)
        },

        shown() {
            this.$refs.editInteraction.init(this.id)
        },

        save() {
            this.$refs.editInteraction.save()
        },

        saved() {
            this.$emit('saved')
            this.$bvModal.hide(this.modalId)
        },
    }
}
</script>

<style scoped>

</style>
