import axios from '@/axios';

const RESOURCE_NAME = '/registration';

export default {

    register(form, token) {
        return axios.post(`${RESOURCE_NAME}/register`, {'RegistrationForm': form, token: token})
    },

    confirm(activationCode) {
        return axios.post(`${RESOURCE_NAME}/confirm`, {activationCode})
    },

    checkInvite(hash) {
        return axios.get(`${RESOURCE_NAME}/check-invite?hash=${hash}`)
    },

    addPaymentMethod(data) {
        return axios.post(`${RESOURCE_NAME}/add-payment-method`, { data });
    },

    getCountriesList() {
        return axios.post(`${RESOURCE_NAME}/get-countries-list`);
    },

    getStatesList(data) {
        return axios.post(`${RESOURCE_NAME}/get-states-list`, { data });
    },

    createSubscription(data) {
        return axios.post(`${RESOURCE_NAME}/create-subscription`, data);
    },

    checkPromoCode(promo_code) {
        return axios.post(`${RESOURCE_NAME}/check-promo-code`, { promo_code });
    }
}
