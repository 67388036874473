<template>
    <b-modal id="editRole" :title="title" modal-class="modal-w-md">
        <b-row class="mb-4">
            <b-col cols="12">
                <h5 class="heading5 heading5--secondary">
                    Role Name*
                </h5>
                <b-form-input class="form-control--mid" v-model="role.name" placeholder="Role Name"></b-form-input>
            </b-col>
        </b-row>

        <b-row class="mb-4">
            <b-col cols="12">
                <div class="form__item">
                    <label><input type="checkbox" v-model="role.is_default"> Use as default role for new trackers</label>
                </div>
            </b-col>
        </b-row>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canSave" @click="save">Save</b-button>
        </template>
    </b-modal>
</template>

<script>
import permissions from "@/services/permissions.service";
import {mapActions} from "vuex";

export default {
    data() {
        return {
            id: 0,
            role: {
                name: "",
                is_default: 0,
            }
        }
    },

    computed: {
        title() {
            if (this.id) {
                return "Edit Role"
            } else {
                return "Create Role"
            }
        },

        canSave() {
            return this.role.name.length
        },
    },

    methods: {
        ...mapActions(['fetchRoles']),

        init() {
            this.role.name = ""
            this.role.is_default = 0

            if (this.id) {
                permissions.getRole(this.id).then(response => {
                    this.role = response.data
                    this.$bvModal.show("editRole")
                })
            } else {
                this.$bvModal.show("editRole")
            }
        },

        open(id) {
            this.id = id
            this.init()
        },

        save() {
            permissions.saveRole(this.id, this.role).then(() => {
                this.$emit('roleSaved')
                this.$bvModal.hide("editRole")
                this.fetchRoles(true)
            })
        },
    }
}
</script>

<style scoped>

</style>
