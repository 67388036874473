export default [
    {
        name: 'Category',
        value: 'category',
    },
    {
        name: 'Tracker',
        value: 'tracker',
    },
    {
        name: 'Funding Stage',
        value: 'stage',
    },
    {
        name: 'Funnel Stage',
        value: 'funnel',
    },
    {
        name: 'Tag',
        value: 'tag',
    },
    {
        name: 'Client',
        value: 'client',
    }
]
