<template>
    <b-modal id="modal-edit-group" :title="title" modal-class="modal-w-md">

        <div class="form form-modal mb-4">
            <b-form-input class="form-control--mid" v-model="group.name" placeholder="Enter Group Name*"></b-form-input>
        </div>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canSave" @click="save">Save</b-button>
        </template>
    </b-modal>
</template>

<script>

import team from "@/services/team.service";

export default {

    data() {
        return {
            group: {
                name: "",
            },

            id: 0,
        }
    },

    computed: {
        title() {
            if (this.id) {
                return "Edit Group"
            }
            else {
                return "Create New Group"
            }
        },

        canSave() {
            return this.group.name.length
        },
    },

    methods: {

        init() {
            this.group.name = ""

            if (this.id) {
                team.getGroupEdit(this.id).then(response => {
                    this.group.name = response.data.name
                })
            }
        },

        open(id) {
            this.id = id
            this.init()
            this.$bvModal.show('modal-edit-group')
        },

        save() {
            team.saveGroup(this.id, this.group).then(() => {
                this.$emit('groupSaved')
                this.$bvModal.hide('modal-edit-group')
            })
        }
    }
}
</script>
