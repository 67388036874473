import axios from '@/axios';

const RESOURCE_NAME = '/trackers-templates';

export default {
    getTemplates() {
        return axios.get(`${RESOURCE_NAME}/get-templates`)
    },

    getTemplatesIndex(data) {
        return axios.post(`${RESOURCE_NAME}/get-templates-index`, data)
    },

    getTemplateEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-template-edit?id=${id}`)
    },

    saveTemplate(id, data) {
        return axios.post(`${RESOURCE_NAME}/save-template?id=${id}`, data)
    },

    deleteTemplate(id) {
        return axios.post(`${RESOURCE_NAME}/delete-template?id=${id}`)
    },
}
