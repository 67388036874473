<template>
    <div>
        Interaction Tags
        <div class="text-right mb-4">
            <button @click="addTag" class="button">+ New Tag</button>
        </div>

        <Grid v-if="tags.length"
              :columns="columns"
              :data="tags"
              :page-length="pageLength"
              :total-rows-count="totalRowsCount"
              :currentPage="currentPage"
              v-on:changePage="changePage"
              v-on:action="action"
        >
        </Grid>

        <TagEdit
            ref="editTag"
            v-on:tagSaved="getList"
        >
        </TagEdit>
    </div>
</template>

<script>
import tags from "@/services/interaction-tags.service";
import TagEdit from "@/components/interactionTags/EditTag";
import grid from "@/mixins/grid";
import Grid from "@/components/Grid.vue";

export default {

    data() {
        return {
            tags: [],

            columns: [
                {
                    name: 'name',
                    label: 'Name',
                },
                {
                    name: 'types',
                    label: 'Interaction Types',
                },
                {
                    name: 'interactions_count',
                    label: 'Assigned Interactions',
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                }
            ]
        };
    },

    mixins: [grid],

    components: {
        Grid,
        TagEdit
    },

    mounted() {
        this.getList()
    },

    methods: {
        getList() {
            tags.getIndexList({currentPage: this.currentPage, pageLength: this.pageLength}).then(({data}) => {
                this.tags = data.data
                this.totalRowsCount = parseInt(data.total_count)
                this.currentPage = data.current_page
            })
        },

        addTag() {
            this.$refs.editTag.open(0)
        },

        action(params) {
            if (params.action === 'edit') {
                this.$refs.editTag.open(params.rowId)
            }

            if (params.action === 'delete') {
                if (confirm("Remove tag?")) {
                    tags.delete(params.rowId).then(() => {
                        this.getList()
                    })
                }
            }
        },
    }
}
</script>

<style scoped>

</style>
