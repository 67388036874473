import axios from '@/axios';

const RESOURCE_NAME = '/interactions';

export default {
    save(id, companyType, companyId, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}&company_type=${companyType}&company_id=${companyId}`, data)
    },

    getEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}`)
    },

    exportCSV(filters, fields) {
        return axios.post(`${RESOURCE_NAME}/export-csv`, {filters, fields})
    },
}
