<template>
    <div>
        <multiselect
            v-model="selectedOptions"
            :disabled="disabled"
            :placeholder="placeholder"
            :title="placeholder"
            :options="options"
            :multiple="true"
            select-label=""
            deselect-label=""
            :showLabels="false"
            label="name"
            track-by="name"
            :group-values="groupValues"
            group-label="group"
            class="multiselect-mid pl-2"
            @select="$emit('select', $event)"
            @search-change="$emit('search-change', $event)"
        >
            <template #selection="{ values, isOpen }">
                <span class="multiselect__single"
                      v-if="values.length"
                      v-show="!isOpen">
                    {{ values.length }} selected
                </span>
            </template>
        </multiselect>

        <ul class="multiselect-result">
            <li v-for="option in selectedOptions" :key="getSymbol(option.name)">
                {{option.name}} <a v-if="!disabled" class="multiselect-result__clear" @click="removeOption(option)"></a>
            </li>
        </ul>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    name: "SearchMultiselect",

    components: {
        Multiselect
    },

    props: {
        disabled: {
            type: Boolean,
            default: false
        },

        groupValues: {
            type: String,
            default: '',
        },

        customUpdate: {
            type: Boolean,
            default: false
        },

        placeholder: String,
        value: Array,
        options: Array,
    },

    data() {
        return {
            selectedOptions: [],
            notWatchChanges: false,
        }
    },

    watch: {
        value: {
            handler() {
                this.notWatchChanges = true;
                this.selectedOptions = this.value;

                this.$nextTick(() => {
                    this.notWatchChanges = false;
                })
            },

            immediate: true
        },

        selectedOptions(value) {
            if (!this.notWatchChanges) {
                this.$emit('input', value)
            }
        },
    },

    methods: {
        removeOption(option) {
            this.selectedOptions = this.selectedOptions.filter(item => item.value !== option.value)
        },

        getSymbol(name) {
            return Symbol(name)
        }
    }
}
</script>

<style scoped>

</style>
