import Vue from 'vue'
import App from './views/App.vue'
import store from './store';
import router from './router'
import ClickOutside from 'vue-click-outside'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueGtag from "vue-gtag";

import './assets/stylesheets/bootstrap.min.css'
import './assets/stylesheets/style.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faLinkedinIn, faTwitter, faFacebookSquare } from '@fortawesome/free-brands-svg-icons'

import InfiniteScroll from '@/directives/infiniteScroll';
import ClickOutsideWithExclusion from '@/directives/clickOutsideWithExclusion'

import {
  faUserSecret,
  faDollarSign,
  faShoppingCart,
  faGlobe,
  faUsers,
  faMapMarkerAlt,
  faCheckCircle,
  faTags,
  faPlus,
  faFileCsv,
  faFilePdf,
  faAddressBook,
  faUser,
  faCog,
  faQuestionCircle,
  faSignOutAlt,
  faTrash,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret, faDollarSign, faShoppingCart, faGlobe, faUsers, faMapMarkerAlt, faLinkedinIn, faTwitter, faFacebookSquare, faCheckCircle, faTags, faPlus, faFileCsv,faFilePdf, faAddressBook, faUser, faCog, faQuestionCircle, faSignOutAlt, faTrash, faInfoCircle)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import BootstrapVue from 'bootstrap-vue'
import Notifications from 'vue-notification'
import ArrayHelper from './helpers/array'
import StringHelper from './helpers/string'
import DateHelper from './helpers/date'
import FileHelper from './helpers/file'
import LogoHelper from './helpers/logo'
import Defaults from './constants/defaults'
import Constants from './constants/constants'

Vue.prototype.arrayHelper = ArrayHelper
Vue.prototype.stringHelper = StringHelper
Vue.prototype.dateHelper = DateHelper
Vue.prototype.fileHelper = FileHelper
Vue.prototype.LogoHelper = LogoHelper

Vue.prototype.defaults = Defaults
Vue.prototype.constants = Constants
Vue.prototype.apiKeys =
    {
        tinyMCE: process.env.VUE_APP_TINYMCE_API_KEY
    }

Vue.use(BootstrapVue)
Vue.use(Notifications)

Vue.directive('click-outside', ClickOutside)
Vue.directive('infinite-scroll', InfiniteScroll)
Vue.directive('click-outside-with-exclusion', ClickOutsideWithExclusion)

import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY})
Vue.use(VueGtag, {config: {id: process.env.VUE_APP_GOOGLE_TAG_ID}}, router)

Vue.directive('focus', {
    inserted: function (el) {
        el.focus()
    }
})

String.prototype.firstLetterUpperCase = function () {
    return this[0].toUpperCase() + this.substring(1).toLowerCase()
}

import commonMixin from '@/mixins/common'

Vue.mixin(commonMixin)

Vue.config.productionTip = false

import { StripePlugin } from '@vue-stripe/vue-stripe';

const options = {
    pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
};

Vue.use(StripePlugin, options);

window.app = new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
