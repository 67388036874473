import axios from '@/axios';

const RESOURCE_NAME = '/subscriptions';

export default {

    createSubscription(data) {
        return axios.post(`${RESOURCE_NAME}/create-subscription`, data)
    },

    getLastPaymentData() {
        return axios.get(`${RESOURCE_NAME}/get-last-payment-data`)
    },

    getPaymentMethods() {
        return axios.get(`${RESOURCE_NAME}/get-payment-methods`)
    },

    enterPromoCode(code) {
        return axios.post(`${RESOURCE_NAME}/enter-promo-code`, {code: code})
    },

    addPaymentMethod(data) {
        return axios.post(`${RESOURCE_NAME}/add-payment-method`, {data: data})
    },
}
