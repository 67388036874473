import axios from '@/axios';

const RESOURCE_NAME = '/company';

export default {
    getRoles() {
        return axios.get(`${RESOURCE_NAME}/get-roles`);
    },

    getEmailTemplates() {
        return axios.get(`${RESOURCE_NAME}/get-email-templates`)
    },

    getEmailTemplateEdit(type) {
        return axios.get(`${RESOURCE_NAME}/get-email-template-edit?type=${type}`)
    },

    getStageEmailTemplateEdit(trackerId, stageId) {
        return axios.get(`${RESOURCE_NAME}/get-email-template-edit?type=tracker-stage&tracker_id=${trackerId}&stage_id=${stageId}`)
    },

    saveEmailTemplate(type, data) {
        return axios.post(`${RESOURCE_NAME}/save-email-template?type=${type}`, data)
    },

    getConnectedEnterprises(data) {
        return axios.post(`${RESOURCE_NAME}/get-connected-enterprises`, data)
    },

    sendMessage(data){
        return axios.post(`${RESOURCE_NAME}/send-message`, data)
    },
}
