<template>
    <b-modal :title="title" :id="modalId" size="sm">
        <multiselect
            v-model="trackers"
            :multiple="true"
            :options="actualTrackersList"
            select-label=""
            deselect-label=""
            placeholder="Type to search your trackers"
            label="name"
            track-by="name"
            class="multiselect-mid selectbox selectbox-mid"
        >
            <template slot="option" slot-scope="props">
                <span v-b-popover.hover.top="props.option.created_by + ': ' + props.option.created_at">{{props.option.name}}</span>
            </template>
        </multiselect>

        <template #modal-footer>
            <b-button :disabled="!trackers.length" variant="primary" size="lg" @click="add">Add</b-button>
        </template>
    </b-modal>
</template>

<script>

import multiselect from "vue-multiselect";

export default {

    components: {
        multiselect,
    },

    props: {
        trackersList: Array,

        excludeTrackersList: {
            type: Array,
            default: () => []
        },

        toFolder: {
            type: Boolean,
            default: false,
        },

        modalId: {
            type: String,
            default: "add-to-trackers-popup",
        }
    },

    data() {
        return {
            trackers: [],
        }
    },

    computed: {
        title() {
            if (this.toFolder) {
                return "Add Trackers To Folder"
            }
            else {
                return "Add To Trackers"
            }
        },

        actualTrackersList() {
            return this.trackersList.filter(tracker => !this.excludeTrackersList.some(existedTracker => parseInt(existedTracker.id) === parseInt(tracker.id)))
        }
    },

    methods: {
        open() {
            this.$bvModal.show(this.modalId)
        },

        add() {
            this.$bvModal.hide(this.modalId)
            this.$emit('trackersSelected', this.trackers)
            this.trackers = []
        },
    }
}
</script>

<style scoped>
    .popover {
        padding: 10px;
        width: auto;
    }
</style>
