<template>
    <div>
        <div class="topbar">
            <h2 class="heading2 w-100 mb-3">
                Resources
            </h2>
            
            <div class="d-flex align-items-center flex-wrap">
                <input class="form-search-primary mb-2 mr-sm-4" v-model="search" v-debounce:500="getItems" placeholder="Search for resources" type="text"/>
                
                <div class="topbar__selectbox topbar__selectbox--blue mb-2">
                    <b-dropdown :text="pageFilterOptions[filterPage]" class="topbar__selectbox__item">
                        <b-dropdown-item @click="() => {filterPage = pageName}" v-for="(pageLabel, pageName) in pageFilterOptions" :key="pageName">
                            {{pageLabel}}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        </div>

        <div class="main main--dark main--center">
            <aside class="sidebar">
                <template v-if="lastVersion">
                    <h3 class="heading3 mb-4">About</h3>

                    <div class="card card--bordered-slim">
                        <h4 class="heading4 mb-3">SwitchPitch v{{lastVersion.version}}</h4>
                        
                        <p class="mb-0">Released {{lastVersion.release_date}}</p>

                        <footer class="card--bordered-slim__footer">
                            <a class="link link-open" @click="showReleaseNotes">View Release Notes</a>
                        </footer>
                        
                        <ReleaseNotes
                            ref="releaseNotes"
                        />
                    </div>
                </template>

                <template v-if="$store.state.user.company_id !== constants.companyPfizer">

                    <h3 class="heading3 mb-4">Need Help?</h3>

                    <div class="card card--bordered-slim">
                        <h4 class="heading4 mb-4">Your Point Person</h4>

                        <ManagerBlock/>
                    </div>
                </template>

                <template v-if="Object.keys(items.knowledge_base).length">
                    <h3 class="heading3 mb-4">Knowledgebase</h3>

                    <div class="card card--bordered-slim">
                        <div v-for="(items, section) in items.knowledge_base" :key="section">
                            <h6 class="heading7">{{ pages[section] }}</h6>

                            <ul class="list-basic">
                                <li v-for="item in items" :key="item.source_id">
                                    <a @click="() => {showArticle(item)}">{{item.name}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
            </aside>

            <div class="content">
                <template v-if="items.private.length">
                    <h3 class="heading3 mb-4">{{$store.state.user.company_name}} Resources</h3>

                    <div class="card card--bordered-slim card--full">
                        <ul class="list-grid list-resources">
                            <li v-for="(item, index) in privateItems" :key="index" :class="getPrivateItemClass(item.type)">
                                <a class="list-resources__video__image" @click="() => {showVideo(item)}">
                                    <img src="https://i.vimeocdn.com/video/1830872750-95ccb4b6bb85ec78bec5cf752c89cc9bb3f99e3def44bbea40edcdc07612c376-d_640" alt="">
                                </a>
                                <div>
                                    <h6 class="heading6">
                                        <a @click="showItem(item)">{{item.name}}</a>
                                    </h6>
                                    <p>{{getPrivateItemName(item.type)}}</p>
                                </div>
                            </li>
                        </ul>

                        <button v-if="items.private.length > privateItems.length" @click="showMorePrivateItems" class="button button--bordered--without--icon mx-auto">Show More</button>
                    </div>
                </template>

                <template v-if="videos.length">
                    <h3 class="heading3 mb-4">Guide Videos</h3>

                    <div class="card card--bordered-slim card--full">
                        <ul class="list-grid">
                            <li v-for="(video, index) in videos" :key="index">
                                <a @click="() => {showVideo(video)}">
                                    <figure>
                                        <img :src="video.thumbnail_url" :alt="video.name"/>
                                        <figcaption>{{video.name}}</figcaption>
                                    </figure>
                                </a>
                            </li>
                        </ul>

                        <button v-if="items.video.length > videos.length" @click="showMoreVideos" class="button button--bordered--without--icon mx-auto">Show More</button>
                    </div>
                </template>

                <template v-if="Object.keys(items.walkthrough).length">
                    <h3 class="heading3 mb-4">Walkthroughs</h3>

                    <div class="card card--bordered-slim card--full">
                        <p class="mb-0">Our walkthroughs are your guided tour through SwitchPitch’s features.</p>
                        <p>Click a walkthrough to be automatically taken through each step necessary to perform the action you’re looking for.</p>

                        <ul class="list-grid">
                            <li v-for="(items, section) in items.walkthrough" :key="section">
                                <div class="card-hints">
                                    <header class="card-hints__header" :class="pagesIconsClasses[section]">
                                        {{ pages[section] }}
                                    </header>

                                    <div class="card-hints__content">
                                        <ul class="list-basic list-basic--play">
                                            <li v-for="item in items" :key="item.source_id">
                                                <a @click="() => {startWalkthrough(item.source_id)}">{{item.name}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </template>

                <div v-if="!itemsCount && search.length">
                    We have no resources for “{{search}}”.
                    <p>
                        Use the Send Mesage button on the left panel to contact your account manager and let us know what content we can add that would be helpful.
                    </p>
                </div>
            </div>
        </div>

        <video-modal
            id="helper-video-modal"
            ref="videoModal"
        />

        <knowledge-base
            ref="knowledgeBase"
        />
    </div>
</template>

<script>
import helperItemsService from "@/services/helper-items.service";
import {pages, pagesIconsClasses} from "@/constants/helperItems";
import VideoModal from "@/components/widgets/VideoModal.vue";
import ManagerBlock from "@/components/ManagerBlock.vue";
import KnowledgeBase from "@/components/widgets/KnowledgeBase.vue";
import ReleaseNotes from "@/components/ReleaseNotes.vue";

export default {
    components: {ReleaseNotes, KnowledgeBase, ManagerBlock, VideoModal},
    data() {
        return {
            search: '',
            items: {
                walkthrough: {},
                knowledge_base: {},
                video: [],
                private: [],
            },

            itemsCount: 0,
            modalText: '',
            filterPage: '',
            videosCount: 6,
            privateItemsCount: 6,
            lastVersion: null,
        }
    },

    watch: {
        filterPage() {
            this.getItems();
        }
    },

    computed: {
        pages() {
            return pages
        },

        pagesIconsClasses() {
            return pagesIconsClasses
        },

        videos() {
            return this.items.video.slice(0, this.videosCount)
        },

        privateItems() {
            return this.items.private.slice(0, this.privateItemsCount)
        },

        pageFilterOptions() {
            return {'': 'All Sections', ...pages}
        },
    },

    mounted() {
        this.getItems();
        this.getLastVersion();
    },

    methods: {
        getItems() {
            helperItemsService.getStructuredItems(this.filterPage, this.search).then(response => {
                this.items = response.data.items;
                this.itemsCount = response.data.count;
            })
        },

        getLastVersion() {
            helperItemsService.getLastVersion().then(response => {
                this.lastVersion = response.data
            })
        },

        showMoreVideos() {
            this.videosCount += 6;
        },

        showMorePrivateItems() {
            this.privateItemsCount += 6;
        },

        showVideo(video) {
            this.$refs.videoModal.show(`https://player.vimeo.com/video/${video.source_id}`);
            helperItemsService.setUserWatched(video.id);
        },

        startWalkthrough(id) {
            window.pendo.showGuideById(id);
        },

        showArticle(item) {
            if (item.internal) {
                this.$refs.knowledgeBase.open(item);
            } else {
                window.open(item.source_id, '_blank').focus();
            }
        },

        getPrivateItemClass(type) {
            if (type === 'walkthrough') {
                return 'list-resources__walkthrough'
            } else if (type === 'video') {
                return 'list-resources__video';
            } else {
                return 'list-resources__search';
            }
        },

        getPrivateItemName(type) {
            if (type === 'walkthrough') {
                return 'Walkthrough'
            } else if (type === 'video') {
                return 'Guide Video';
            } else {
                return 'Knowledgebase Article';
            }
        },

        showItem(item) {
            if (item.type === 'walkthrough') {
                this.startWalkthrough(item.source_id)
            } else if (item.type === 'video') {
                this.showVideo(item)
            } else {
                this.showArticle(item)
            }
        },

        showReleaseNotes() {
            this.$refs.releaseNotes.show();
        }
    }
}
</script>

<style scoped>

</style>
