<template>
    <b-modal id="select-lessons-modal" modal-class="modal-w-456 select-lessons-modal" title="How Will You Use SwitchPitch?" @hide="onCloseModal">
        <p>Select all the activities that are relevant to you:</p>

        <div v-for="(lessonTitle, index) in lessonsTitles" :key="index">
            <div class="input-checkbox input-checkbox--xl">
                <input :id="'lesson-' + index" v-model="selectedLessons" :value="index" type="checkbox" class="">
                <label :for="'lesson-' + index">{{lessonTitle}}</label>
            </div>
        </div>

        <template #modal-footer>
            <div>
                <primary-button
                    caption="Continue"
                    @click="saveLessons"
                />

                <p v-if="showError" class="error">You must select at least one activity</p>
            </div>
        </template>
    </b-modal>
</template>

<script>

import {lessonsTitles} from "@/constants/lessons";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import userService from "@/services/user.service";

export default {
    components: {PrimaryButton},
    data() {
        return {
            selectedLessons: [],
            showError: false,
            lessonsSaved: false,
        }
    },

    computed: {
        lessonsTitles() {
            let lessons = {};

            for (let i in lessonsTitles) {
                if ((parseInt(i) !== 5 || this.$store.state.isAdmin) && (parseInt(i) !== 3 || this.$store.state.user.permissions.includes('tracker-create'))) {
                    lessons[i] = lessonsTitles[i]
                }
            }

            return lessons
        }
    },

    methods: {
        open() {
            this.$bvModal.show('select-lessons-modal');
        },

        saveLessons() {
            if (this.selectedLessons.length) {
                this.showError = false;

                userService.saveLessons(this.selectedLessons).then(() => {
                    this.lessonsSaved = true;
                    this.$bvModal.hide('select-lessons-modal');

                    this.$store.commit('setNeedUpdateUserData', true);

                    this.$nextTick(() => {
                        this.$store.commit('setNeedUpdateUserData', false);
                    })
                })
            } else {
                this.showError = true;
            }
        },

        onCloseModal() {
            if (!this.lessonsSaved) {
                this.selectedLessons = [];

                for (let i in this.lessonsTitles) {
                    this.selectedLessons.push(i);
                }

                this.saveLessons();
            }
        }
    }
}
</script>

<style scoped>

</style>
