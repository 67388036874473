import axios from '@/axios';

const RESOURCE_NAME = '/locations';

export default {

    getTopStartupsLocations() {
        return axios.get(`${RESOURCE_NAME}/get-top-startups-locations`)
    },

    getFilteredStartupsLocations(term) {
        return axios.get(`${RESOURCE_NAME}/get-filtered-startups-locations?query=${term}`)
    },

    getCountriesList() {
        return axios.get(`${RESOURCE_NAME}/get-countries-list`)
    },

    getStatesList() {
        return axios.get(`${RESOURCE_NAME}/get-states-list`)
    },

    getLocationsList(values) {
        return axios.post(`${RESOURCE_NAME}/get-locations-list`, {values: values})
    }
}
