<template>
    <div v-if="$store.state.user.is_admin">
        <Header></Header>
    </div>
</template>

<script>
import Header from "@/views/settings/admin/AdminHeader";

export default {
    components: {
        Header
    }
}
</script>

<style scoped>

</style>
