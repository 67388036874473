import Startup from '@/views/startups/Startup'
import Startups from "@/views/startups/Search";
import MyStartups from "@/views/startups/MyStartups";
import FindByDomain from "@/views/startups/FindByDomain";

export default [
    {
        path: '/startups',
        component: Startups,
        meta: {
            title: 'Startups',
        }
    },
    {
        path: '/startups/my-startups',
        component: MyStartups,
        meta: {
            title: 'My Startups',
        }
    },
    {
        path: '/startups/find-by-domain',
        component: FindByDomain,
        meta: {
            access: ['enterprise','ecosystem']
        }
    },
    {
        path: '/startups/:id',
        component: Startup,
        meta: {
            access: ['enterprise','ecosystem']
        }
    },
    {
        path: '/public-startup/:domain',
        component: Startup,
        meta: {
            guest: true,
            onlyGuest: true,
        }
    },
]
