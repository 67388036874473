<template>
    <div>
        <div v-for="error in errors" :key="error">
            {{error}}
        </div>
    </div>
</template>

<script>

  import auth from "@/services/auth.service";

  export default {
      data() {
          return {
              token: this.$route.params.token,
              adminToken: this.$route.params.adminToken,
              errors: [],
          }
      },

      mounted() {
          this.$store.commit('logout')

          auth.impersonate(this.adminToken, this.token)
              .then(response => {

                  this.errors = response.data.errors

                  if (this.errors.length) {
                      this.$store.commit('authError')
                  }
                  else if (response.data.user.token) {
                      this.$store.commit('authSuccess', response.data.user)

                      if (this.$route.query.tracker_id) {
                          window.location.href = "/trackers/" + this.$route.query.tracker_id
                      }
                      else if (response.data.account_type == 'startup') {
                          window.location.href = "/public-trackers"
                      }
                      else {
                          window.location.href = "/startups"
                      }
                  }
              })
              .catch(error => {
                  this.$store.commit('authError')
                  console.log(error)
              })
      }
  }
</script>

<style scoped>

</style>