import { render, staticRenderFns } from "./MyStartups.vue?vue&type=template&id=67e6b934&scoped=true&"
import script from "./MyStartups.vue?vue&type=script&setup=true&lang=js&"
export * from "./MyStartups.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67e6b934",
  null
  
)

export default component.exports