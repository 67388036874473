<template>
    <div>
        <Header></Header>

        <div class="main-inner">
            <div class="card card--large">
                <p>Enterprises are looking for your input for startup partnerships.</p>
                <p>
                    Set a message that will display beneath your company in search to encourage enterprises to reach out
                    and
                    work with you.
                    This will boost your visibility and increase the likelihood they will partner with your startup
                    portfolio.
                </p>

                <b-form-textarea class="form-control--mid" v-model="form.greeting" placeholder="Introduce yourself and your portfolio to enterprises looking for ecosystems"></b-form-textarea>

                <b-button v-if="hasGreeting !== null" variant="primary" size="lg" @click="save">
                    <template v-if="hasGreeting">
                        Update Greeting
                    </template>
                    <template v-else>
                        Add Greeting
                    </template>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/views/ecosystem/settings/Header.vue";
import settings from "@/services/ecosystem/settings.service";
import Vue from "vue";

export default {
    components: {
        Header,
    },

    data() {
        return {
            form: {
                greeting: "",
            },

            hasGreeting: null,
        }
    },

    mounted() {
        settings.getGreeting().then(response => {
            this.form.greeting = response.data
            this.hasGreeting = !!this.form.greeting
        })
    },

    methods: {
        save() {
            settings.saveGreeting(this.form).then(() => {
                this.hasGreeting = !!this.form.greeting.length
            })

            Vue.notify({
                group: 'app',
                type: 'success',
                duration: 2000,
                text: "Greeting saved"
            })
        },
    }
}
</script>
