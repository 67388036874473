<template>
    <div v-if="loaded">
        <div class="topbar">
            <div class="d-flex align-items-center">
                <div class="image-block image-block--lg mr-4">
                    <img :src="LogoHelper.getLogo(info.company_logo)" alt="img">
                </div>
                <div>
                    <h2 class="heading2">{{ info.company_name }}</h2>
                    <p class="text-big">Posted {{ dateHelper.decodeDate(info.posted, ['month-str']) }}</p>
                </div>
            </div>
        </div>
        <div class="main-inner min-vh-100" v-if="!showApplication">
            <div class="card card--info w-md-75 mx-auto" v-if="info.company_description.length">
                <div class="d-flex">
                    <font-awesome-icon class="fa-2x" :icon="['fa', 'info-circle']" />
                    <div class="pl-3">
                        <h4 class="heading4">About {{ info.company_name }}</h4>
                        <div v-html="info.company_description"></div>
                    </div>
                </div>
            </div>

            <div class="public-content w-md-75 mx-auto">
                <h3 class="heading3">{{ info.name }}</h3>

                <div v-html="info.description">

                </div>

                <p v-if="info.categories.length">
                    <b>Categories:</b>
                    {{ info.categories.join(', ') }}
                </p>
                <p v-if="info.start_date && info.end_date">
                    <b>Timeline:</b> {{ dateHelper.decodeDate(info.start_date, ['month-str']) }} - {{ dateHelper.decodeDate(info.end_date, ['month-str']) }}
                </p>

                <template v-if="info.submitter_fields.length">
                    <h5 class="heading5 heading5--secondary mr-4 mb-0">Tracker Details</h5>

                    <div v-for="(field, index) in info.submitter_fields" :key="index" class="mb-4">
                        <h5 class="heading5 heading5--secondary">{{field.field.name}}</h5>

                        <custom-field
                            :field="field.field"
                            :value="field.value"
                            :editable="false"
                            :disabled="true"
                            :from-tracker-application="true"
                        >
                        </custom-field>
                    </div>
                </template>

                <template v-if="!fromPreview && applicationStatus === 'applied'">
                    <div class="confirmation-send">Your application has been sent</div>
                </template>


                <div v-if="fromPreview || applicationStatus !== 'applied'" class="text-center pt-3">
                    <a @click="apply" class="btn btn-primary btn-lg">Apply</a>
                </div>
            </div>
        </div>

        <template v-else>
            <div class="main-inner min-vh-100">
                <div class="w-50 mx-auto">
                    <table class="details-table details-table--block form m-0 w-100">
                        <div v-if="loadingQuestions" class="loading__spinner mt-3">
                            <span><b-spinner class="mb-1 mr-2" small></b-spinner>Loading Questions...</span>
                        </div>

                        <tr v-for="(question, index) in questions" :key="index">
                            <td :class="[question.field.type == 'section_text' ? 'section-text' : '']">
                                {{question.field.name}} <span v-if="question.required">*</span>
                            </td>

                            <td v-if="['static_text','section_text'].indexOf(question.field.type) < 0">
                                <custom-field
                                    :field="question.field"
                                    :value="question.value"
                                    :index="index"
                                    :inline="false"
                                    :instantUpdate="false"
                                    v-on:customValueChanged="answerChanged"
                                >
                                </custom-field>
                                <span class="form__error" v-if="!fromPreview && question.error.length">{{question.error}}</span>
                            </td>
                        </tr>
                    </table>

                    <template v-if="info.has_terms && !loadingQuestions">
                        <div class="input-checkbox">
                            <input id="terms-agree" type="checkbox" v-model="agreed">
                            <label for="terms-agree">I have read and agree to the Challenge <a @click="showTerms" class="ml-1">Terms & Conditions</a></label>
                        </div>
                    </template>

                    <div v-if="!fromPreview && !loadingQuestions" class="text-center pt-3">
                        <button @click="sendApplication" class="btn btn-primary btn-lg" :disabled="(info.has_terms && !agreed) || sendingApplication">Submit Application</button>
                    </div>

                </div>

            </div>

        </template>

        <b-modal id="modal-terms" :hide-footer="true" modal-class="modal-w-xxl" title="">
            <template #modal-title>
                <div class="modal-top">
                    <h2 class="heading2 mb-0">Terms and Conditions</h2>
                </div>
            </template>
            <div v-html="info.terms">
            </div>
        </b-modal>
    </div>
</template>

<script>
import customField from "@/components/customFields/CustomField.vue";
import {validateNumericValues} from "@/helpers/functions";

export default {
    props: {
        fromPreview: {
            type: Boolean,
            default: false,
        },
        loaded: Boolean,
        applicationStatus: {
            type: String,
            default: "",
        },
        showApplication: Boolean,
        loadingQuestions: Boolean,
        info: Object,
    },

    components: {customField},

    data() {
        return {
            questions: [],
            agreed: 0,
            sendingApplication: false,
        }
    },

    methods: {

        apply() {
            this.$emit('apply')
        },

        sendApplication() {
            let hasErrors = false

            for (let i in this.questions) {
                this.questions[i].error = ''

                if (this.questions[i].required && !this.stringHelper.fieldFilled(this.questions[i].value)) {
                    this.questions[i].error = 'This field is mandatory'
                    hasErrors = true
                }
            }

            let [numberErrors, hasNumberErrors] = validateNumericValues(this.questions)

            for (let i in numberErrors) {
                this.questions[i].error = numberErrors[i]
            }

            if (!(hasErrors || hasNumberErrors)) {
                this.sendingApplication = true
                this.$emit('sendApplication', this.questions)
            }
        },

        answerChanged({index, value}) {
            this.questions[index].value = value

            if (this.questions[index].field.type === 'date') {
                this.questions[index].value.value = this.dateHelper.decodeDate(this.questions[index].value.value)
            }
        },

        showTerms() {
            this.$bvModal.show('modal-terms')
        },

        setErrors(errors) {
            errors.forEach(error => {
                this.questions.find(item => item.field.id === error.id).error = error.text;
            })
        }
    }
}
</script>

<style scoped>

</style>
