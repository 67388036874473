<template>
    <div>
        <ecosystem-enterprises
            v-if="$store.getters.isEcosystem"
        />
        <startup-enterprises
            v-if="$store.getters.isStartup"
        />
    </div>
</template>

<script>

import EcosystemEnterprises from "@/views/ecosystem/Enterprises.vue";
import StartupEnterprises from "@/views/startup/Enterprises.vue";

export default {
    components: {
        EcosystemEnterprises,
        StartupEnterprises,
    },
}
</script>
