import Settings from '@/views/Settings'
import CustomFields from '@/views/settings/CustomFields'
import TrackerTemplates from '@/views/settings/TrackerTemplates'
import Notifications from '@/views/Notifications'
import MyTags from '@/views/settings/Tags'
import EmailTemplates from '@/views/settings/EmailTemplates'

export default [
    {
        path: '/settings',
        component: Settings,
        meta: {
            access: ['enterprise','ecosystem'],
            title: 'Settings'
        }
    },
    {
        path: '/settings/custom-fields',
        component: CustomFields,
        meta: {
            access: ['enterprise','ecosystem'],
            title: 'Profile Fields'
        }
    },
    {
        path: '/settings/tracker-templates',
        component: TrackerTemplates,
        meta: {
            access: ['enterprise','premiumEcosystem'],
            title: 'Tracker Templates'
        }
    },
    {
        path: '/settings/notifications',
        component: Notifications,
        meta: {
            access: ['enterprise','ecosystem'],
            title: 'Notifications'
        }
    },
    {
        path: '/settings/my-tags',
        component: MyTags,
        meta: {
            access: ['enterprise'],
            title: 'My Tags'
        }
    },
    {
        path: '/settings/email-templates',
        component: EmailTemplates,
        meta: {
            access: ['enterprise'],
            title: 'Customize Emails'
        }
    },
]
