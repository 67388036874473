<template>
    <div>
        <Header></Header>
        <div class="main-inner">
            <div class="card card--bordered w-md-50 mx-auto pb-0">
                <header class="card__header align-items-center justify-content-between">
                    <h4 class="heading4">Opportunities</h4>
                </header>

                <template>
                    <div class="accordion accordion--in-card" role="tablist">
                        <div class="accordion__header mb-0">
                            <div v-b-toggle.accordion-tracker variant="info" class="accordion__title">Details</div>
                        </div>

                        <template v-if="settings['tracker-startups'].is_common === 1">
                            <b-collapse id="accordion-tracker" visible accordion="accordion-tracker" role="tabpanel" class="accordion__content">
                                <b-collapse id="accordion-tracker-0" visible accordion="my-accordion-tracker" role="tabpanel">
                                    <table class="table-simple w-md-75">
                                        <thead>
                                        <tr>
                                            <th>Event</th>
                                            <th>Real Time</th>
                                            <th>Daily</th>
                                            <th>Weekly</th>
                                            <th>Never</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        <tr>
                                            <td>Any new opportunity</td>
                                            <td></td>
                                            <td>
                                                <div class="input-radio input-radio--simple">
                                                    <input type="radio" v-model="settings['tracker-startups'].common[0].notifications['available']" value="day" id="tracker-available-day" name="tracker-available">
                                                    <label for="tracker-available-day"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-radio input-radio--simple">
                                                    <input type="radio" v-model="settings['tracker-startups'].common[0].notifications['available']" value="week" id="tracker-available-week" name="tracker-available">
                                                    <label for="tracker-available-week"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-radio input-radio--simple">
                                                    <input type="radio" v-model="settings['tracker-startups'].common[0].notifications['available']" value="never" id="tracker-available-never" name="tracker-available">
                                                    <label for="tracker-available-never"></label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Opportunities I am invited to</td>
                                            <td>
                                                <div class="input-radio input-radio--simple">
                                                    <input type="radio" v-model="settings['tracker-startups'].common[0].notifications['shared']" value="now" id="tracker-shared-now" name="tracker-shared">
                                                    <label for="tracker-shared-now"></label>
                                                </div>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <div class="input-radio input-radio--simple">
                                                    <input type="radio" v-model="settings['tracker-startups'].common[0].notifications['shared']" value="never" id="tracker-shared-never" name="tracker-shared">
                                                    <label for="tracker-shared-never"></label>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </b-collapse>
                            </b-collapse>
                        </template>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/views/ecosystem/settings/Header"
import user from "@/services/user.service";

export default {
    components: {
        Header
    },

    data() {
        return {
            settings: {
                'tracker-startups': {
                    'is_common': null,
                    'common': [],
                },
            },

            canWatch: false,
            updateSettings: false,
        }
    },

    watch: {
        settings: {
            deep: true,
            handler() {

                if (this.canWatch) {
                    let data = {
                        settings: this.settings
                    }

                    user.updateNotificationSettings(data)
                }
            }
        }
    },

    mounted() {
        user.getNotificationSettings().then(result => {
            this.settings = result.data

            this.$nextTick(() => {
                this.canWatch = true
            })
        })
    },
}
</script>

<style scoped>

</style>
