<template>
    <div>
        <div class="topbar topbar--search">

            <div class="tabs tabs-tertiary">
                <b-nav tabs>
                    <b-nav-item v-if="$store.state.accountType == 'premium'" to="/ecosystems" exact>
                        Find Ecosystems
                    </b-nav-item>

                    <li v-else class="nav-item">
                        <a @click="showNeedUpgrade('ecosystems-connections')">
                            Find Ecosystems
                        </a>
                    </li>

                    <b-nav-item to="/ecosystems/my-ecosystems" exact>
                        My Ecosystems
                    </b-nav-item>
                </b-nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        current: String
    }
}
</script>

<style scoped>

</style>
