import axios from '@/axios';

const RESOURCE_NAME = '/insights';

export default {

    getTopFundedCategories({period}) {
        return axios.get(`${RESOURCE_NAME}/get-top-funded-categories?period=${period}`)
    },

    getLastActionLogs() {
        return axios.get(`${RESOURCE_NAME}/get-action-logs`)
    },

    getStartupsActionLogs(maxDate, filter, limit) {
        return axios.post(`${RESOURCE_NAME}/get-startups-action-logs`, {
            maxDate,
            filter,
            limit
        })
    },

    getLastStartupsActionLogs(filter) {
        return axios.post(`${RESOURCE_NAME}/get-last-startups-action-logs`, {filter})
    },
}
