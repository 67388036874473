<template>
    <b-modal id="modal-add-investor" :title="title" modal-class="modal-w-md">

        <div class="form form-modal mb-4" v-if="showInvestors">
            Investor Name*

            <multiselect
                class="multiselect--complex multiselect-mid mb-3"
                v-model="investor"
                :options="investorsList"
                select-label=""
                deselect-label=""
                label="name"
                track-by="name"
                placeholder="Start typing..."
                @search-change="getInvestors"
            >
            </multiselect>
        </div>

        <div class="form form-modal mb-4">
            Rounds Founded*

            <multiselect
                class="multiselect--complex multiselect-mid mb-3"
                v-model="link.funding_series"
                :options="fundingSeries"
                :multiple="true"
                select-label=""
                deselect-label=""
                label="name"
                track-by="name"
                placeholder="Start typing..."
            >
            </multiselect>
        </div>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canSave" @click="save">Save</b-button>
        </template>
    </b-modal>
</template>

<script>

import investors from "@/services/ecosystem/investors.service";
import multiselect from "vue-multiselect";

export default {
    props: {
        startupId: Number,
    },

    components: {
        multiselect
    },

    data() {
        return {
            link: {
                funding_series: [],
            },

            investorId: 0,
            investorName: "",

            investor: null,

            investorsList: [],
            showInvestors: false,

            errors: {
                url: [],
            },
        }
    },

    watch: {
        investor() {
            if (this.investor) {
                this.investorId = this.investor.id
            }
            else {
                this.investorId = 0
            }
        }
    },

    computed: {
        canSave() {
            return this.investorId && this.link.funding_series.length
        },

        fundingSeries() {
            let array = []

            for (let i in this.constants.fundingTypes) {
                array.push({
                    value: i,
                    name: this.constants.fundingTypes[i]
                })
            }

            return array
        },

        title() {
            if (this.investorId) {
                return "Edit Investor " + this.investorName
            } else {
                return "Add Investor"
            }
        },
    },

    methods: {

        init() {
            this.investor = null
            this.link.funding_series = []

            if (this.investorId) {
                investors.getEdit(this.startupId, this.investorId).then(response => {
                    this.investorName = response.data.name

                    response.data.series.forEach(item => {
                        let index = this.arrayHelper.findIndexByColumnValue(this.fundingSeries, 'value', item)

                        if (index >= 0) {
                            this.link.funding_series.push(this.fundingSeries[index])
                        }
                    })
                })
            }
        },

        open(investorId = 0) {
            this.investorId = investorId

            if (this.investorId) {
                this.showInvestors = false
            }
            else {
                this.showInvestors = true
            }

            this.init()
            this.$bvModal.show('modal-add-investor')
        },

        save() {
            investors.saveRelation(this.startupId, this.investorId, this.link).then(() => {
                this.$emit('saved')
                this.$bvModal.hide('modal-add-investor')
            })
        },

        getInvestors(search) {
            if (search.length) {
                investors.getList({search: search, startup_id: this.startupId}).then(response => {
                    this.investorsList = response.data
                })
            }
            else {
                this.investorsList = []
            }
        },
    }
}
</script>
