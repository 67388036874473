<template>
    <b-modal id="modal-edit-portfolio" :title="title" modal-class="modal-w-md">
        
        <h6 class="heading6 mb-1">Portfolio Name*</h6>
        <span class="form__error" v-for="(error, index) in errors.name" :key="index">{{error}}</span>
        <b-form-input class="form-control--mid mb-3" v-model="portfolio.name"></b-form-input>
        
        <h6 class="heading6 mb-1">Description</h6>
        <b-form-textarea class="form-control--mid mb-3" v-model="portfolio.description"></b-form-textarea>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canSave" @click="save"><template v-if="id">Update</template><template v-else>Add</template></b-button>
        </template>
    </b-modal>
</template>

<script>
import portfolios from "@/services/ecosystem/portfolios.service"

export default {
    data() {
        return {
            id: 0,
            portfolio: {
                name: "",
                description: "",
            },

            errors: {
                name: []
            },
        }
    },

    computed: {
        title() {
            if (this.id) {
                return "Edit Portfolio"
            } else {
                return "New Portfolio"
            }
        },

        canSave() {
            return this.portfolio.name.length
        }
    },

    methods: {
        init() {
            this.portfolio.name = ""
            this.portfolio.description = ""
            this.errors.name = ""

            if (this.id) {
                portfolios.getEdit(this.id).then(response => {
                    this.portfolio.name = response.data.name
                    this.portfolio.description = response.data.description
                })
            }
        },

        open(id) {
            this.id = id
            this.init()
            this.$bvModal.show('modal-edit-portfolio')
        },

        save() {
            portfolios.save(this.id, this.portfolio).then((response) => {

                if (Object.keys(response.data.errors).length) {
                    this.errors = response.data.errors
                }
                else {
                    this.$emit('saved')
                    this.$bvModal.hide('modal-edit-portfolio')
                }
            })
        },
    }
}
</script>

<style scoped>

</style>
