<template>
    <div>
        <Activity></Activity>
    </div>
</template>

<script>
import Activity from "@/components/activity/ActivityWidget.vue";

export default {
    components: {
        Activity,
    },
}
</script>

<style scoped>

</style>
