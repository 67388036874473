import agencyClientsService from "@/services/agency-clients.service";

export default {
    state: {
        agencyClients: [],
        agencyClientsLoaded: false,
    },

    actions: {
        async fetchAgencyClients({state}) {
            if (!state.agencyClientsLoaded) {
                const response = await agencyClientsService.getClients();
                state.agencyClients = response.data
                state.agencyClientsLoaded = true;
            }
        }
    },

    getters: {
        agencyClients: state => state.agencyClients
    }
}
