import Team from "@/views/Team";
import Group from "@/views/team/Group";

export default [
    {
        path: '/team',
        component: Team,
        meta: {
            title: 'Team'
        }
    },
    {
        path: '/groups/:id',
        component: Group,
        meta: {
            access: ['enterprise'],
        }
    },
]