<template>
    <div>
        <div class="topbar pb-0">
            <div class="tabs tabs-tertiary mb-0">
                <b-nav tabs>
                    <b-nav-item to="/logs/fields-history" :active="$route.hash === '/logs/fields-history'">
                        Fields History
                    </b-nav-item>
    
                    <b-nav-item to="/logs/action-logs" :active="$route.hash === '/logs/action-logs'">
                        Action Logs
                    </b-nav-item>
                </b-nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        current: String
    }
}
</script>

<style scoped>

</style>