import { baseURL } from "@/axios";

export const copyProfileLink = type => function () {

    if (this.$store.state.user.company_id === this.constants.companyPfizer) {
        return navigator.clipboard.writeText(`${baseURL}/pfizer-saml/login?returnTo=${type}/${this.id}`)
    }

    if(this.$store.state.user.company_id === this.constants.companyColgate) {
        return navigator.clipboard.writeText(`${baseURL}/colgate-saml/login?returnTo=${type}/${this.id}`)
    }

    if(this.$store.state.user.company_id === this.constants.companyTravelers) {
        return navigator.clipboard.writeText(`${baseURL}/travelers-saml/login?returnTo=${type}/${this.id}`)
    }

    if(this.$store.state.user.company_id === this.constants.companyLloyds) {
        return navigator.clipboard.writeText(`${baseURL}/lloyds-saml/login?returnTo=${type}/${this.id}`)
    }

    return navigator.clipboard.writeText(`${document.location.origin}/${type}/${this.id}`)
}
