<template>
    <b-modal id="home-page-filters-modal" modal-class="modal-w-md" title="Filter Your Dashboard">
        <div class="form-filter">
            <div class="row">
                <div class="col">
                    <multiselect
                        v-model="filters.period"
                        :options="constants.commonFilterPeriods"
                        :allow-empty="false"
                        select-label=""
                        deselect-label=""
                        label="label"
                        class="form-filter__input form-filter__input--period"
                    />
                </div>

                <div class="col">
                    <multiselect
                        v-model="filters.userType"
                        :options="constants.usersTypes"
                        :allow-empty="false"
                        select-label=""
                        deselect-label=""
                        label="label"
                        class="form-filter__input form-filter__input--users"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <multiselect
                        v-model="filters.trackers"
                        :options="trackersOptions"
                        :multiple="true"
                        group-values="trackers"
                        group-label="folder"
                        :group-select="true"
                        select-label=""
                        deselect-label=""
                        select-group-label=""
                        deselect-group-label=""
                        placeholder="Type to search"
                        track-by="id"
                        label="name"
                        class="form-filter__input form-filter__input--tracker">

                        <span slot="noResult">No trackers found.</span>

                        <template slot="selection" slot-scope="{ values }">
                            <span class="multiselect__single" v-if="values.length ">
                                Selected {{ values.length }} {{ stringHelper.getNoun('Tracker', values.length) }}
                            </span>
                        </template>

                        <template slot="placeholder">
                            All Trackers
                        </template>

                    </multiselect>
                </div>

                <div class="col">
                    <multiselect
                        v-model="filters.tags"
                        :options="tagsOptions"
                        :multiple="true"
                        select-label=""
                        deselect-label=""
                        placeholder="Type to search"
                        track-by="id"
                        label="name"
                        class="form-filter__input form-filter__input--tag">

                        <span slot="noResult">No tags found.</span>

                        <template slot="selection" slot-scope="{ values }">
                            <span class="multiselect__single" v-if="values.length ">
                                Selected {{ values.length }} {{ stringHelper.getNoun('Tag', values.length) }}
                            </span>
                        </template>

                        <template slot="placeholder">
                            No Tags Selected
                        </template>
                    </multiselect>
                </div>
            </div>

            <label class="form-filter__label--text">Custom Fields</label>

            <custom-data-filter
                :custom-fields-list="customFieldsList"
                :init-filters="filters.customFields"
                :home-page="true"
                v-on:updateCustomDataFilter="updateCustomDataFilter"
                class="form-filter__custom"
            />
        </div>

        <template #modal-footer>
            <b-button variant="primary" size="lg" @click="apply">Save & Close</b-button>
        </template>
    </b-modal>
</template>

<script>
import multiselect from "vue-multiselect";
import {mapGetters} from "vuex";
import CustomDataFilter from "@/components/customFields/CustomDataFilter.vue";
import customFieldsService from "@/services/custom-fields.service";

export default {
    name: "HomePageFilters",
    components: {CustomDataFilter, multiselect},

    data() {
        return {
            filters: {
                period: null,
                userType: null,
                trackers: [],
                tags: [],
                customFields: [],
            },

            customFieldsList: {},
        }
    },

    computed: {
        ...mapGetters(['foldersTrackersList','tagsList']),

        trackersOptions() {
            return this.foldersTrackersList.map(item => ({
                folder: item[0],
                trackers: item[1],
            }));
        },

        tagsOptions() {
            return this.tagsList;
        }
    },

    mounted() {
        customFieldsService.getFields("company_diligence", 1).then(response => {
            if (Object.keys(response.data).length) {
                this.customFieldsList = response.data
            }
        });
    },

    methods: {
        updateCustomDataFilter(filter) {
            this.filters.customFields = filter;
        },

        setFilters(filters) {
            this.filters = filters;
        },

        open() {
            this.$bvModal.show('home-page-filters-modal');
        },

        apply() {
            this.$emit('filtersChanged', {...this.filters});
            this.$bvModal.hide('home-page-filters-modal');
        },
    }
}
</script>

<style scoped>

</style>
