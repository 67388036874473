<template>
    <div>
        <div v-if="startups.length" class="status-page__content">
            <aside class="status-page__aside">
                <header class="status-page__header">
                    <!--<aside class="status-page__aside"></aside>-->
                </header>
                <ul class="status-page__list">
                    <li v-for="(startup, index) in startups" :key="index">

                        <div class="status-page__list__content">
                            <div class="image-block image-block--22">
                                <img :src="LogoHelper.getLogo(startup.logoUrl)" alt="img"/>
                            </div>
                            <a :href="'/startups/' + startup.id" target="_blank">
                                <h5 class="heading5"><b-button v-b-modal.poc-modal><span class="line-clamp line-clamp1">{{startup.name}}</span></b-button></h5>
                            </a>
                        </div>
                        <footer v-if="startup.sourced_by" class="status-page__list__footer">
                            <p v-if="startup.department" class="status-page__list__department line-clamp line-clamp1">{{startup.department}}</p>
                            <p class="line-clamp line-clamp1">Sourced by {{startup.sourced_by}}</p>
                        </footer>
                    </li>
                </ul>
            </aside>

            <section class="status-page__data" id="board-wrapper" @scroll="checkTestPeriod">
                <div class="status-page__data__wrapper" :style="timelineWidth">
                    <header class="status-page__header">
                        <ul v-if="months.length" class="status-page__calendar" style="position: relative">
                            <li v-for="month in months" :key="month.year+'-'+month.month" :class="{'current-month': month.isCurrent}">
                                {{month.month}}
                                <span class="status-page__calendar__year" v-if="month.month === 'Jan'">
                                    <sup>
                                        {{month.year}}
                                    </sup>
                                </span>
                            </li>
                        </ul>
                    </header>

                    <!--<div v-if="startups.length" class="status-page__data__inner" :style="{backgroundSize: 'calc(100% / ' + this.months.length + ') calc(100% / ' + this.months.length + ')'}">-->
                    <div v-if="startups.length" class="status-page__data__inner">
                        <div v-for="(startup, index) in startups"
                             class="poc-block" :class="{'poc-block--add': !startup.end_date}"
                             :style="calculatePosition(startup)"
                             :key="index">

                            <div v-if="startup.test_start_date" class="status-page__testing" :class="{
                                'yellow-timeline': $store.state.user.company_id === 290485
                            }"
                                 :style="calculateTestPosition(startup)"></div>

                            <div class="poc-block__inner"
                                 :style="getBlockColor(startup)"
                                 :class="{'started-in-past': new Date(startup.start_date) < startDate}"
                                 @click="() => {openTimelineEditModal(startup.id)}">
                                <div class="poc-block__inner__inside">
                                    <template v-if="!startup.end_date">
                                        <h6 class="heading6">Fill in data for this startup</h6>
                                        <p>Click here to add status, timeline and notes</p>
                                    </template>
    
                                    <template v-else>
                                        <h6 class="heading6 line-clamp line-clamp1">{{startup.status}}</h6>
                                        <p class="line-clamp line-clamp1">{{startup.notes}}</p>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <timeline-edit-modal
            ref="timelineModal"
            :tracker-id="trackerId"
            :permissions="permissions"
            v-on="$listeners"
        />
    </div>
</template>

<script>

import {monthsList} from "@/constants/constants";
import TimelineEditModal from "@/components/trackers/TimelineEditModal.vue";

export default {
    props: {
        trackerId: Number,
        startups: Array,
        permissions: Array,
    },

    data() {
        return {
            startDate: null,
            endDate: null,
            timeline: null,
            months: [],
        }
    },

    components: {TimelineEditModal},

    watch: {
        startups() {
            this.calculateMonthsList();
        }
    },

    computed: {
        timelineWidth() {
            if (this.months.length < 12) {
                return {'width': '100%'}
            } else {
                return {'width': (90 * this.months.length) + 30 + 'px'}
            }
        },
    },

    updated() {
        let blocks = document.getElementsByClassName('poc-block');

        for (let block of blocks) {
            if (!block.classList.contains('poc-block--add')) {
                if (block.offsetWidth < 120) {
                    block.classList.add('poc-block--slim');
                } else {
                    block.classList.remove('poc-block--slim');
                }
            }
        }

        let wrapper = document.getElementById('board-wrapper');
        let currentMonth = document.getElementsByClassName('current-month');

        if (wrapper && currentMonth.length) {
            wrapper.scrollTo(currentMonth[0].offsetLeft, 0)
        }

        this.checkTestPeriod();
    },

    methods: {
        calculateMonthsList() {
            this.startDate = null;
            this.endDate = null;

            this.startups.forEach(startup => {
                if (startup.start_date) {
                    let firstMonthDate = this.dateHelper.firstDayOfMonth(new Date(startup.start_date));

                    if (!this.startDate || firstMonthDate < this.startDate) {
                        this.startDate = firstMonthDate
                    }
                }

                if (startup.end_date) {
                    let lastMonthDate = this.dateHelper.lastDayOfMonth(new Date(startup.end_date));

                    if (!this.endDate || lastMonthDate > this.endDate) {
                        this.endDate = lastMonthDate
                    }
                }
            })

            if (!this.startDate) {
                this.startDate = this.dateHelper.firstDayOfMonth(new Date());
            } else {
                let minDate = this.dateHelper.firstDayOfMonth(this.dateHelper.addMonth(new Date(), -4));

                if (this.startDate < minDate) {
                    this.startDate = minDate;
                }
            }

            if (!this.endDate) {
                let startDate = this.dateHelper.lastDayOfMonth(new Date());
                this.endDate = new Date(startDate.setMonth(startDate.getMonth()+11));
            }

            let currentMonth = this.startDate.getMonth();
            let currentYear = this.startDate.getFullYear();
            let months = [];
            let today = new Date();

            while (
                (currentYear <= this.endDate.getFullYear() && (currentYear < this.endDate.getFullYear() || currentMonth <= this.endDate.getMonth()))
                || months.length < 12
                ) {

                months.push({
                    month: monthsList[currentMonth],
                    year: currentYear,
                    isCurrent: today.getFullYear() === currentYear && today.getMonth() === currentMonth
                });

                ++currentMonth;

                if (currentMonth === 12) {
                    currentMonth = 0;
                    currentYear++;
                }
            }

            if (this.endDate < new Date(currentYear, currentMonth, 1)) {
                if (currentMonth === 0) {
                    currentMonth = 11;
                    currentYear--;
                } else {
                    currentMonth--;
                }
            }

            this.endDate = new Date(currentYear, currentMonth, 30);
            this.timeline = Math.ceil((this.endDate - this.startDate) / (1000 * 60 * 60 * 24)) + 1;
            this.months = months;
        },

        getBlockColor(startup) {
            if (startup.color) {
                return {
                    borderColor: startup.color,
                    backgroundColor: startup.color
                }
            } else {
                return {};
            }
        },

        openTimelineEditModal(startupId) {
            if (
                this.permissions.includes('tracker-diligenceFields-value-edit')
                || this.permissions.includes('tracker-change-status')
            ) {
                this.$refs.timelineModal.open(startupId);
            }
        },

        calculatePosition(startup) {
            let style = {
                width: 0
            };

            if (startup.start_date && startup.end_date) {
                let startDate = new Date(startup.start_date + 'T00:00:00');

                if (startDate < this.startDate) {
                    startDate = this.startDate;
                }

                const endDate = new Date(startup.end_date);

                const dateDiff1 = Math.floor((startDate - this.startDate) / (1000 * 60 * 60 * 24));
                const dateDiff2 = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));

                style.marginLeft = 100 * dateDiff1 / this.timeline + '%';
                style.width = 100 * dateDiff2 / this.timeline + '%';
                style.paddingRight = 0;
            }

            return style;
        },

        calculateTestPosition(startup) {
            let position = {
                width: 0
            };

            if (startup.start_date && startup.end_date && startup.test_start_date && startup.test_end_date) {
                let startDate = new Date(startup.start_date)

                if (startDate < this.startDate) {
                    startDate = this.startDate;
                }

                const endDate = new Date(startup.end_date)

                const period = (endDate - startDate) / (1000 * 60 * 60 * 24);

                const testStartDate = new Date(startup.test_start_date)
                const testEndDate = new Date(startup.test_end_date)

                const dateDiff1 = Math.ceil((testStartDate - startDate) / (1000 * 60 * 60 * 24));
                const dateDiff2 = Math.ceil((testEndDate - testStartDate) / (1000 * 60 * 60 * 24));

                position.marginLeft = 'calc(100% / ' + period + ' * ' + dateDiff1 + ')';
                position.width = 'calc(100% / ' + period + ' * ' + dateDiff2 + ')';
            }

            return position;
        },

        checkTestPeriod() {
            let leftSide = document.getElementsByClassName('status-page__aside');

            if (leftSide.length) {
                let leftSideOffsetBound = leftSide[0].getBoundingClientRect();
                let rightOffset = leftSideOffsetBound.x + leftSideOffsetBound.width;
                let blocks = document.getElementsByClassName('poc-block');

                for (let block of blocks) {
                    let testBlock = block.getElementsByClassName('status-page__testing');

                    if (testBlock.length) {
                        testBlock = testBlock[0];
                        let x =  testBlock.getBoundingClientRect().x;

                        if (x < rightOffset) {
                            testBlock.classList.add('status-page__testing--in-past')
                        } else {
                            testBlock.classList.remove('status-page__testing--in-past')
                        }
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
