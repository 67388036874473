import customFields from "@/services/custom-fields.service";

export default {
    state: {
        fieldTypes: {},
    },

    actions: {
        async fetchFieldTypes({commit, state}, fieldType){
            if (typeof state.fieldTypes[fieldType] === 'undefined') {
                const response = await customFields.getFieldTypes(fieldType)
                commit('setFieldTypes', {fieldType, data: response.data})
            }
        }
    },

    mutations: {
        setFieldTypes(state, {fieldType, data}) {state.fieldTypes[fieldType] = data}
    },

    getters: {
        allFieldTypes: (state) => state.fieldTypes
    }
}
