import Enterprises from "@/views/Enterprises";
import Enterprise from "@/views/enterprise/Profile";
import Connections from "@/views/Connections.vue";

export default [
    {
        path: '/enterprises',
        component: Enterprises,
        meta: {
            access: ['ecosystem','startup'],
        }
    },
    {
        path: '/enterprises/connected',
        component: Connections,
        meta: {
            access: ['ecosystem', 'startup']
        }
    },
    {
        path: '/enterprises/:id',
        component: Enterprise
    },
]
