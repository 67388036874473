<template>
    <div>
        <div class="topbar">
            <h2 class="heading2 w-100 mb-3">Settings</h2>
        </div>
            
        <div class="tabs tabs-primary" v-if="$store.getters.isApproved">
            <b-nav tabs>
                <b-nav-item to="/settings/account" :active="$route.hash === '/settings/account'">
                    Account
                </b-nav-item>
                <b-nav-item to="/settings/notifications" :active="$route.hash === '/settings/notifications'">
                    Notifications
                </b-nav-item>
                <b-nav-item to="/settings/greeting" :active="$route.hash === '/settings/greeting'">
                    Greeting
                </b-nav-item>
                <b-nav-item v-if="$store.getters.isPremiumEcosystem" to="/settings/custom-fields" :active="$route.hash === '/settings/custom-fields'">
                    Profile Fields
                </b-nav-item>
                <b-nav-item v-if="$store.getters.isPremiumEcosystem" to="/settings/tracker-templates" :active="$route.hash === '/settings/tracker-templates'">
                    Trackers
                </b-nav-item>
            </b-nav>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        current: String
    }
}
</script>

<style scoped>

</style>
