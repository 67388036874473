import axios from '@/axios';

const RESOURCE_NAME = '/enterprise';

export default {

    getProfileInfo() {
        return axios.get(`${RESOURCE_NAME}/get-profile-info`)
    },

    saveProfileInfo(data) {
        return axios.post(`${RESOURCE_NAME}/save-profile-info`, data)
    },

    saveDashboardSettings: settings => axios.post(`${RESOURCE_NAME}/save-dashboard-settings`, {settings: settings}),
    saveHomepageSettings: settings => axios.post(`${RESOURCE_NAME}/save-homepage-settings`, {settings: settings}),
    getFeatureSettings: () => axios.get(`${RESOURCE_NAME}/get-feature-settings`),
    setFeatureSettings: (name, value) => axios.post(`${RESOURCE_NAME}/set-feature-settings?name=${name}`, {value: value}),
}
