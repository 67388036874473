import axios from '@/axios'

const RESOURCE_NAME = '/auth'
import store from '@/store'

export default {

    login(loginData) {
        return axios.post(`${RESOURCE_NAME}/login`, {LoginForm: loginData})
    },

    checkToken() {
        let token = store.state.token
        return axios.post(`${RESOURCE_NAME}/check-token`, {token: token})
    },

    impersonate(adminToken, token) {
        return axios.post(`${RESOURCE_NAME}/impersonate`, {admin_token: adminToken, token: token})
    },

    resetPassword(email){
        return axios.post(`${RESOURCE_NAME}/reset-password`, {email: email})
    },

    checkResetPasswordCode(code) {
        return axios.post(`${RESOURCE_NAME}/check-reset-password-code`, {code: code})
    },

    setPassword(data) {
        return axios.post(`${RESOURCE_NAME}/set-password`, data)
    },
}
