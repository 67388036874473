import axios from '@/axios';

const RESOURCE_NAME = '/ecosystem/startups';

export default {

    getStartups(data) {
        return axios.post(`${RESOURCE_NAME}/get-startups`, data)
    },

    getDetails(startupId) {
        return axios.get(`${RESOURCE_NAME}/` + startupId + `/get-details`)
    },

    deleteStartup(startupId) {
        return axios.post(`${RESOURCE_NAME}/delete-startup`, {startupId})
    },

    getCommonData(startupId) {
        return axios.get(`${RESOURCE_NAME}/` + startupId + `/get-common-data`)
    },

    getCustomData(startupId, type) {
        return axios.get(`${RESOURCE_NAME}/` + startupId + `/get-custom-data?type=${type}`)
    },

    getCustomDescription(startupId) {
        return axios.get(`${RESOURCE_NAME}/` + startupId + `/get-custom-description`)
    },

    saveCustomDescription(startupId, text) {
        return axios.post(`${RESOURCE_NAME}/` + startupId + `/save-custom-description`, {text: text})
    },

    getContacts(startupId, search) {
        return axios.get(`${RESOURCE_NAME}/` + startupId + `/get-contacts?search=${search}`)
    },

    getInvestors(startupId) {
        return axios.get(`${RESOURCE_NAME}/` + startupId + `/get-investors`)
    },

    getCounts() {
        return axios.get(`${RESOURCE_NAME}/get-counts`)
    },
}
