import Insights from "@/views/Insights.vue";

export default [
    {
        path: '/insights',
        component: Insights,
        meta: {
            access: ['enterprise'],
            title: 'Insights',
        }
    }
]
