import axios from '@/axios';

const RESOURCE_NAME = '/ecosystem/profile';

export default {

    getInfo() {
        return axios.get(`${RESOURCE_NAME}/get-info`)
    },

    saveInfo(data) {
        return axios.post(`${RESOURCE_NAME}/save-info`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
}
