import AgencyClients from "@/views/AgencyClients.vue";

export default [
    {
        path: '/clients',
        component: AgencyClients,
        meta: {
            access: ['enterprise']
        }
    },
]
