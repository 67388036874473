<template>
    <div class="table-cover">
        <header class="table-cover__header">
            <p>Edit users' access to different features on SwitchPitch</p>
            <button @click="addTemplate" class="button">+ Add Template</button>
        </header>
        
        <Grid v-if="templates.length"
            :columns="columns"
            :data="templates"
            :page-length="pageLength"
            :total-rows-count="totalRowsCount"
            :sort="sort"
            :currentPage="currentPage"
            v-on:changePage="changePage"
            v-on:changeSort="changeSort"
            v-on:action="action"
        >
        </Grid>

        <EditTemplate
            ref="editTemplate"
            v-on:templateSaved="getList"
        >
        </EditTemplate>
    </div>
</template>

<script>
import grid from "@/mixins/grid";
import Grid from "@/components/Grid";
import EditTemplate from "@/components/trackers/EditTemplate";
import trackersTemplates from "@/services/trackers-templates.service";

export default {
    mixins: [grid],

    components: {
        Grid,
        EditTemplate,
    },

    data() {
        return {
            templates: [],

            columns: [
                {
                    name: 'name',
                    label: 'Name',
                },
                {
                    name: 'description',
                    label: 'Description',
                },
                {
                    name: 'permissions',
                    label: 'Permissions',
                    component: 'Permissions',
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                }
            ]
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        getList() {
            let currentPage = this.currentPage
            let pageLength = this.pageLength

            trackersTemplates.getTemplatesIndex({currentPage, pageLength}).then(response => {
                this.templates = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            })
        },

        addTemplate() {
            this.$refs.editTemplate.open(0)
        },

        action(params) {
            if (params.action == 'edit') {
                this.$refs.editTemplate.open(params.rowId)
            }

            if (params.action == 'delete') {
                let string = "Delete Template?";

                if (confirm(string)) {
                    trackersTemplates.deleteTemplate(params.rowId).then(() => {
                        this.getList()
                    })
                }
            }
        },
    }
}
</script>

<style scoped>

</style>
