<template>
    <div>
        <Header></Header>

        <div class="account-page">
            <div>
                <header class="account-page__header">
                    <h3 class="heading3">Account</h3>

                    <a class="link" v-if="$store.state.accountType == 'free'" @click="addPromoCode">Add promo code</a>
                </header>

                <div class="account-plan">
                    <div class="account-plan__item account-plan__item--active">
                        <h5 class="heading5 heading5--secondary">Account Type</h5>

                        <input class="account-plan__input" disabled :value="constants.ecosystemAccountTypes[$store.state.accountType]">

                        <template v-if="paymentData.last4 && paymentData.last4.length">
                            <h5 class="heading5 heading5--secondary">Payment Method</h5>

                            <div class="account-plan__item__payment">
                                <h6 class="heading6">{{ paymentData.account_holder_name }}</h6>
                                <p>xxxx - xxxx - xxxx - {{ paymentData.last4 }}</p>
                            </div>

                        </template>

                        <h5 class="heading5 heading5--secondary">Account Features</h5>
                        <ul class="account-plan__list">
                            <li v-for="(item, index) in constants.ecosystemAccountTypesFeatures[$store.state.accountType]" :key="index">{{item}}</li>
                        </ul>
                    </div>

                    <div class="account-plan__item" v-if="['free','invited'].includes($store.state.accountType)">
                        <h3 class="heading3">Standard Access</h3>

                        <ul class="account-plan__list-check">
                            <li v-for="(item, index) in constants.ecosystemAccountTypesFeatures['standard']" :key="index">{{item}}</li>
                        </ul>

                        <footer class="account-plan__item__footer">
                            <p>{{constants.ecosystemAccountTypesPrices['basic']}}</p>

                            <a  href="mailto:info@switchpitch.com"><button class="button">Contact Sales</button></a>
                        </footer>
                    </div>

                    <div class="account-plan__item account-plan__item--premium" v-if="$store.state.accountType != 'premium'">
                        <h3 class="heading3">Premium Access</h3>

                        <ul class="account-plan__list-check">
                            <li v-for="(item, index) in constants.ecosystemAccountTypesFeatures['premium']" :key="index">{{item}}</li>
                        </ul>

                        <footer class="account-plan__item__footer">
                            <p v-if="false">{{constants.ecosystemAccountTypesPrices['premium']}}</p>

                            <a  href="mailto:info@switchpitch.com"><button class="button">Contact Sales</button></a>
                        </footer>
                    </div>
                </div>
            </div>

            <add-promo-code ref="addPromoCode"></add-promo-code>

        </div>
    </div>
</template>

<script>

import AddPromoCode from "@/components/subscriptions/AddPromoCode";
import subscriptions from "@/services/subscriptions.service";
import Header from "@/views/ecosystem/settings/Header";

export default {
    components: {
        AddPromoCode,
        Header
    },

    data() {
        return {
            paymentData: {
                account_holder_name: '',
                last4: '',
            },
        }
    },

    mounted() {
        this.getPaymentData()

        this.$store.watch(state => {
            if (state.needUpdatePaymentData) {
                this.getPaymentData()
            }
        })
    },

    methods: {
        upgrade(type) {
            this.showUpgrade(type)
        },

        addPromoCode() {
            this.$refs.addPromoCode.open()
        },

        getPaymentData() {
            subscriptions.getLastPaymentData().then(response => {
                this.paymentData = response.data
            })
        }
    }
}
</script>

<style scoped>

</style>
