<template>
    <b-modal
        modal-class="modal-w-md"
        hide-footer
        :id="startup.id.toString()"
    >
        <template #modal-header>
            <h5 class="modal-title">{{ startup.note ? 'View note' : 'Add note' }}</h5>
            <button v-if="startup.note" @click="deleteNote" class="link-trash-can">Delete</button>
            <button class="close" @click="$bvModal.hide(startup.id.toString())"></button>
        </template>
        <b-form @submit="handleSubmit" class="form">
            <div class="form__item">
                <b-form-textarea
                    class="form-control--mid form-control form-control--xl"
                    v-model="note"
                    no-resize="no-resize"
                    placeholder="Enter text"
                ></b-form-textarea>
            </div>
            <div class="modal-footer">
                <b-button v-if="startup.note" type="submit" class="button" :disabled="processing">Save changes</b-button>
                <b-button v-if="!startup.note" type="submit" class="button" :disabled="processing">Save note</b-button>
            </div>
        </b-form>
    </b-modal>
</template>

<script>
import trackersCompanies from "@/services/trackers-companies.service"

export default {
    name: 'NoteModal',
    data() {
        return {
            note: this.startup.note ?? '',
            processing: false
        }
    },
    props: {
        trackerId: String,
        startup: Object,
        onSuccess: Function
    },
    watch: {
        startup() {
            if (this.note !== this.startup.note) {
                this.note = this.startup.note
            }
        }
    },
    methods: {
        deleteNote: async function () {
            await this.processRequest(null)
        },
        handleSubmit: async function (e) {
            e.preventDefault()
            await this.processRequest(this.note)
        },
        processRequest: async function (note) {
            this.processing = true

            try {
                await trackersCompanies.saveNote(this.trackerId, this.startup.id, {note})
                this.$bvModal.hide(this.startup.id.toString())
                this.onSuccess(note)
                this.note = ''
                this.processing = false
            } catch (e) {
                console.error(e)
                this.processing = false
            }
        },
    }
}
</script>
