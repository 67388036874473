<template>
    <div>
        <div class="topbar">
            <div class="topbar__flex-box">
                <router-link to="/my-startups/portfolios" class="link-back">All Portfolios</router-link>
                
                <div class="info-plate info-plate--updated" :class="showInfoPanel ? 'open' : 'not-open'">
                    <div class="info-plate__link" v-b-toggle.collapse-side variant="primary">
                        Info & Manage
                    </div>

                    <b-collapse id="collapse-side" class="info-plate__content" v-model="showInfoPanel">

                        <b-card no-body>
                            <b-tabs justified>
                                <b-tab title="Access" active>
                                    <ul class="rows-list mb-3">
                                        <li v-for="(enterprise, index) in info.enterprises" :key="index">
                                            <div class="image-block">
                                                <img :src="LogoHelper.getLogo(enterprise.shortLogoURL)" alt=""/>
                                            </div>
                                            <h5 class="heading5 heading5--secondary">{{ enterprise.name }}</h5>
                                        </li>
                                    </ul>

                                    <div class="text-center">
                                        <button class="button" @click="manageAccess">Manage Access</button>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </b-card>

                    </b-collapse>
                </div>
            </div>
            
            <div class="topbar__inner">
                <div class="topbar__key">
                    <div class="d-flex justify-content-between">
                        <div>
                            <header class="topbar__key__header">
        
                                {{info.name}}
                            </header>
        
                            Show
                            <select v-if="totalRowsCount >= 25" class="topbar__key__select" v-model="pageLength">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </select>
                            <template v-else>{{totalRowsCount}}</template>
                            of {{totalRowsCount}}
                            startups
                        </div>
                        <footer class="topbar__key__footer" style="margin-top: 60px">
                            <ul class="topbar__side actions-list">
                                <li class="actions-list__add"><a class="" @click="$refs.addStartups.open()">Add Startups</a></li>
                            </ul>
                        </footer>
                    </div>
                </div>
            </div>
        </div>

        <div class="main-table-wrapper overflow-auto">
            <div class="main-table">
                <Grid
                    :columns="columns"
                    :data="startups"
                    :addCellData="addCellData"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    v-on:changePage="changePage"
                    v-on:changeSort="changeSort"
                >
                </Grid>
            </div>
        </div>

        <AddStartups
            ref="addStartups"
            :portfolios="[id]"
            v-on:saved="getList"
        >
        </AddStartups>

        <share-portfolio
            ref="sharePortfolio"
            v-on:saved="getInfo"
        >
        </share-portfolio>
    </div>
</template>

<script>
import grid from "@/mixins/grid";
import portfolios from "@/services/ecosystem/portfolios.service";
import AddStartups from "@/components/portfolios/AddStartups";
import Grid from "@/components/Grid";
import SharePortfolio from "@/components/portfolios/SharePortfolio";

export default {
    mixins: [grid],

    components: {
        AddStartups,
        Grid,
        SharePortfolio,
    },

    data() {
        return {
            id: this.$route.params.id,

            info: {
                name: '',
                enterprises: [],
            },

            startups: [],

            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    component: 'SearchCompanyNameColumn',
                },
                {
                    label: 'Stage',
                    name: 'stage',
                },
                {
                    label: 'Location',
                    name: 'location',
                    component: 'SearchCompanyLocationColumn',
                },
                {
                    label: 'Total Raised',
                    name: 'total_raised',
                },
            ],

            addCellData: {
                companyType: "startups",
            },

            selectedStartups: [],
            showInfoPanel: false,
        }
    },

    mounted() {
        this.getInfo()
        this.getList()

        this.$store.watch(state => {
            if (state.needUpdateStartups) {
                this.getList()
            }
        })
    },

    methods: {
        getInfo() {
            portfolios.getInfo(this.id).then(response => {
                this.info = response.data
            })
        },

        getList() {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let sort = this.sort

            portfolios.getStartups(this.id, {columns, sort, currentPage, pageLength}).then(response => {
                this.startups = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            })
        },

        manageAccess() {
            this.$refs.sharePortfolio.open(this.id)
        },
    }
}
</script>

<style scoped>

</style>
