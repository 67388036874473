<template>
    <b-modal id="timeline-edit-modal" modal-class="modal-w-lg modal-funnel-card-popup">
        <div class="modal__top mb-3">
            <div class="image-block">
                <img :src="LogoHelper.getLogo(details.startup_logo)" alt="">
            </div>
            <h3 class="heading3">
                <router-link :to="'/startups/' + this.startupId">{{details.startup_name}}</router-link>
            </h3>

            <a @click="removeStartup" class="btn btn-red ml-auto text-nowrap">Remove From Tracker</a>
        </div>

        <p>{{details.startup_description}}</p>

        <form class="form">
            <b-row>
                <b-col>
                    <h5 class="heading5 heading5--secondary">Status</h5>

                    <span v-if="'status' in errors" class="form__error" >{{errors.status}}</span>

                    <b-form-select
                        v-model="form.status"
                        :disabled="!permissions.includes('tracker-change-status')" class="mb-3"
                    >
                        <option v-for="status in details.statuses" :key="status.id" :value="status.id">
                            {{status.name}}
                        </option>
                    </b-form-select>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <h5 class="heading5 heading5--secondary">POC Dates</h5>

                    <span v-if="'test_start_date' in errors" class="form__error" >{{errors.test_start_date}}</span>

                    <date-picker
                        :disabled="!permissions.includes('tracker-diligenceFields-value-edit')"
                        v-model="pov_timeline"
                        format = "MM/DD/YYYY"
                        placeholder="Select Start / End Date"
                        range class="mb-3">
                    </date-picker>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <h5 class="heading5 heading5--secondary">Testing Dates</h5>

                    <date-picker
                        :disabled="!permissions.includes('tracker-diligenceFields-value-edit')"
                        v-model="test_timeline"
                        format = "MM/DD/YYYY"
                        placeholder="Select Start / End Date"
                        range class="mb-3">
                    </date-picker>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <h5 class="heading5 heading5--secondary">Department</h5>
                    <b-form-input
                        :disabled="!permissions.includes('tracker-diligenceFields-value-edit')"
                        class="form-control--mid mb-3"
                        v-model="form.department">
                    </b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <h5 class="heading5 heading5--secondary">Sourced By</h5>
                    <b-form-input
                        :disabled="!permissions.includes('tracker-diligenceFields-value-edit')"
                        class="form-control--mid mb-3"
                        v-model="form.sourced_by">
                    </b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <h5 class="heading5 heading5--secondary">Notes</h5>
                    <b-form-textarea
                        :disabled="!permissions.includes('tracker-diligenceFields-value-edit')"
                        class="form-control--mid mb-3"
                        v-model="form.note">
                    </b-form-textarea>
                </b-col>
            </b-row>
        </form>

        <template #modal-footer>
            <primary-button
                :loading="loading"
                caption="Save"
                loading-caption="Saving..."
                @click="save"
            />
        </template>

    </b-modal>
</template>

<script>
import trackersCompanies from "@/services/trackers-companies.service";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import DatePicker from "vue2-datepicker";

export default {
    components: {
        PrimaryButton,
        DatePicker,
    },

    props: {
        trackerId: Number,
        permissions: Array,
    },

    data() {
        return {
            startupId: 0,
            details: {},

            form: {
                status: null,
                start_date: null,
                end_date: null,
                test_start_date: null,
                test_end_date: null,
                department: null,
                sourced_by: null,
                note: null,
            },

            errors: {},
            loading: false,

            pov_timeline: [null, null],
            test_timeline: [null, null],
        }
    },

    watch: {
        pov_timeline() {
            if (this.pov_timeline[0]) {
                let interval = this.dateHelper.encodeIntervalForServer(this.pov_timeline)
                this.form.start_date = interval[0]
                this.form.end_date = interval[1]
            } else {
                this.form.start_date = null
                this.form.end_date = null
            }
        },

        test_timeline() {
            if (this.test_timeline[0]) {
                let interval = this.dateHelper.encodeIntervalForServer(this.test_timeline)
                this.form.test_start_date = interval[0]
                this.form.test_end_date = interval[1]
            } else {
                this.form.test_start_date = null
                this.form.test_end_date = null
            }
        },
    },

    methods: {
        init() {
            this.details = {}
            this.errors = {}
            this.pov_timeline = [null, null]
            this.test_timeline = [null, null]

            this.form.note = null;
            this.form.status = null;
            this.form.department = null;
            this.form.sourced_by = null;

            this.getData()
        },

        getData() {
            if (this.trackerId && this.startupId) {
                trackersCompanies.getTimelineDetails(this.trackerId, this.startupId).then(result => {
                    this.details = result.data;

                    this.form.note = this.details.note;
                    this.form.status = this.details.status;
                    this.form.department = this.details.department;
                    this.form.sourced_by = this.details.sourced_by;

                    if (this.details.start_date && this.details.end_date) {
                        this.pov_timeline = this.dateHelper.decodeIntervalFromServer([this.details.start_date, this.details.end_date])
                    }

                    if (this.details.test_start_date && this.details.test_end_date) {
                        this.test_timeline = this.dateHelper.decodeIntervalFromServer([this.details.test_start_date, this.details.test_end_date])
                    }
                })
            }
        },

        open(startupId) {
            this.startupId = startupId

            this.init()
            this.$bvModal.show('timeline-edit-modal')
        },

        save() {
            this.loading = true;

            trackersCompanies.saveTimelineDetails(this.trackerId, this.startupId, this.form).then(response => {
                if (Object.keys(response.data.errors).length) {
                    this.errors = response.data.errors;
                } else {
                    this.$bvModal.hide('timeline-edit-modal')
                    this.$emit('timeline-changed');
                }
            }).finally(() => {
                this.loading = false;
            })
        },

        removeStartup() {
            if (confirm('Remove Startup?')) {
                this.$emit('removeStartup', this.startupId)
                this.$bvModal.hide('timeline-edit-modal')
            }
        },
    }
}
</script>

<style scoped>

</style>
