<template>
    <div>
        <header class="section-header">
            <h3 class="heading3">{{section.name}}
                <a @click="editSection(section.id)" class="heading-edit"></a>
                <a v-if="!fields.length && !section.is_default" @click="deleteSection(section.id)" class="link-delete link-delete--icon ml-3">
                    <font-awesome-icon style="width:16px;height: 17px;" :icon="['fa', 'trash']" />
                </a>
            </h3>

            <div class="section-users ml-auto mr-3">
                <b-dropdown class="dropdown--secondary dropdown-menu--right" variant="link" toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                        <div class="section-users mr-2">
                            <div class="section-users__images">
                                <div v-for="(avatar, index) in stringHelper.getAvatarsForDisplay(section.permissions)" :key="index" class="section-users__images__item">
                                    <img :src="avatar" alt=""/>
                                </div>
                            </div>
                            <p>
                                <a v-for="(item, index) in stringHelper.getPermissionNamesForDisplay(section.permissions)" :key="index">
                                    {{item}}
                                </a>
                            </p>
                        </div>
                    </template>

                    <b-dropdown-header>
                        <header class="dropdown__header-inner">
                            <h4 class="heading4">Users in {{ section.name }}</h4>

                            <a v-if="false" href="#" class="link link--lg">Edit</a>
                        </header>

                        <ul class="rows-list pb-0">
                            <li  v-for="(permission, index) in section.permissions" :key="index">
                                <div class="rows-list__avatar">
                                    <Avatar
                                        :username="permission.name"
                                        :src="permission.avatar"
                                        :size="30">
                                    </Avatar>
                                </div>

                                <h5 class="heading5 heading5--secondary">{{ permission.name }}</h5>
                                <span class="ml-auto">{{ constants.customFieldAccessTypes[permission.accessType] }}</span>
                            </li>
                        </ul>
                    </b-dropdown-header>
                </b-dropdown>
            </div>

            <a @click="addField" class="link">+ Add Field</a>
        </header>

        <Grid v-if="fields.length"
              :columns="columns"
              :data="fields"
              :page-length="pageLength"
              :total-rows-count="totalRowsCount"
              :sort="sort"
              :draggable="true"
              :currentPage="currentPage"
              v-on:changePage="changePage"
              v-on:changeSort="changeSort"
              v-on:action="action"
              v-on:dragged="changeSorting"
        >
        </Grid>

        <EditField
            ref="editField"
            :sectionId="section.id"
            type="company_diligence"
            v-on:fieldSaved="getList"
            :index="'section' + section.id"
        >
        </EditField>

        <EditSection
            :id="section.id"
            ref="editSection"
            v-on:sectionSaved="$emit('sectionSaved'); getList()"
        >
        </EditSection>
    </div>
</template>

<script>
import grid from "@/mixins/grid";
import Grid from "@/components/Grid";
import customFields from "@/services/custom-fields.service";
import EditField from "@/components/customFields/EditField"
import EditSection from "@/components/customFields/EditSection";
import Avatar from "vue-avatar";

export default {
    props: {
        section: Object,
        type: String,
    },

    data() {
        return {
            fields: [],

            columns: [
                {
                    name: 'name',
                    label: 'Name',
                },
                {
                    name: 'type',
                    label: 'Type',
                },
                {
                    name: 'permissions',
                    label: 'Permissions',
                    component: 'Permissions',
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                }
            ]
        }
    },

    mixins: [grid],

    components: {
        Grid,
        EditField,
        EditSection,
        Avatar,
    },

    mounted() {
        this.getList()
    },

    methods: {
        getList() {
            let currentPage = this.currentPage
            let pageLength = this.pageLength

            customFields.getIndexSectionFields(this.type, this.section.id, {currentPage, pageLength}).then(response => {
                this.fields = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            })
        },

        action(params) {
            if (params.action == 'edit') {
                this.$refs.editField.open(params.rowId)
            }

            if (params.action == 'delete') {
                if (confirm("Remove Field?")) {
                    customFields.delete(params.rowId).then(() => {
                        this.getList()
                    })
                }
            }
        },

        addField() {
            this.$refs.editField.open(0)
        },

        editSection(id) {
            this.$refs.editSection.open(id)
        },

        deleteSection(id) {
            if (confirm("Delete section?")) {
                customFields.deleteSection(id).then(() => {
                    this.$emit('sectionSaved')
                })
            }
        },

        changeSorting(data) {
            customFields.changeSorting({data: data})
        },
    }
}
</script>

<style scoped>

</style>
