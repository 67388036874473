<template>
    <b-tab>
        <template #title>
            Opportunities
            <small v-if="loading">Loading...</small>
            <small v-if="!loading">{{ total }} active</small>
        </template>
        <div class="tabs-primary__inner">
            <span v-if="loading">Loading...</span>
            <!-- NO DATA STATE -->
            <span v-if="!loading && !briefs.length">
                <h2 class="heading2 heading2--no-briefs">This enterprise hasn't posted any opportunities.</h2>
            </span>
            <!-- NO DATA STATE END -->
            <ul class="list-cards list-cards--bordered" v-if="!loading && briefs.length">
                <li v-for="brief in briefs" :key="brief.id">
                    <div class="image-block image-block--md">
                        <img :src="LogoHelper.getLogo(brief.enterprise.logoUrl)" v-bind:alt="brief.name"/>
                    </div>

                    <div class="list-cards__content">
                        <router-link class="heading4 heading4--secondary" :to="`/opportunities/${brief.id}`">{{ brief.name }}</router-link>
                        <h5 class="heading5 heading5--tertiary">{{ brief.enterprise.name }}</h5>

                        <p>
                            <v-clamp autoresize :max-lines="3"> {{ brief.description }}
                                <template #after="{ toggle, expanded, clamped }">
                                    <a v-if="expanded" class="link-normal" @click="toggle"> less</a>
                                    <a v-if="clamped" class="link-normal" @click="toggle"> more</a>
                                </template>
                            </v-clamp>
                        </p>

                        <div class="btn-wrapper">
                            <a class="btn btn-transparent" v-for="category in brief.categories" :key="category.id" @click="goToBriefsWithCategory(category.id)">
                                {{ category.name }}
                            </a>
                        </div>
                    </div>

                    <div class="list-cards__side">
                        <p><router-link class="button button--link" :to="`/opportunities/${brief.id}`">View Details</router-link></p>

                        <p v-if="brief.startups_count.length > 0" class="list-cards__side__startups">Submitted {{brief.startups_count}} startups</p>
                    </div>
                </li>
            </ul>
        </div>
    </b-tab>
</template>

<script>
import qs from "qs";
import VClamp from "vue-clamp";

export default {
    name: 'BriefsTab',
    props: {
        total: Number,
        briefs: Array,
        loading: Boolean
    },
    methods: {
        goToBriefsWithCategory: (categoryId) => {
            window.open(`/?${qs.stringify({ categories: [categoryId] }, { arrayFormat: 'brackets' })}`)
        }
    },
    components: {
        VClamp
    }
}
</script>
