import axios from '@/axios';

const RESOURCE_NAME = '/public-collections';

export default {
    getCollectionInfo(hash) {
        return axios.get(`${RESOURCE_NAME}/get-collection-info?hash=${hash}`)
    },

    getTrackersFromCollection(hash, filters) {
        return axios.post(`${RESOURCE_NAME}/get-trackers-from-collection?hash=${hash}`, filters)
    },
}
