import axios from '@/axios';

const RESOURCE_NAME = '/categories';

export default {

    getList() {
        return axios.get(`${RESOURCE_NAME}/get-list`)
    },

    getFilteredList(term) {
        return axios.get(`${RESOURCE_NAME}/get-filtered-list?term=${term}`)
    },
}
