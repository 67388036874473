<template>
    <div>
        <div v-if="filters.length" class="form-filter__custom__inner">
            <div class="form-filter__custom__item" v-for="(filter, index) in filters" :key="filter.id">

                <div v-if="homePage">
                    <b-dropdown :text="filters[index].id ? customFieldsList[filters[index].id].name : 'Select field'" class="form-filter__custom__select">
                        <b-dropdown-item v-for="(filterItem, id) in actualCustomFieldsList(filters[index].id)"
                            :key="id"
                            class="form-filter__custom__select"
                            @click="filters[index].id = id"
                        >
                            {{filterItem.name}}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>

                <select v-else v-model="filters[index].id" @change="resetFilter(index)" class="multiselect__input selectbox selectbox-mid form-filter__custom__select">
                    <option value="0">
                        Select field
                    </option>

                    <option v-for="(filterItem, id) in actualCustomFieldsList(filters[index].id)" :key="id" :value="id">
                        {{filterItem.name}}
                    </option>
                </select>

                <div class="d-flex align-items-center">
                    <template v-if="filter.id > 0">
                        <input v-if="customFieldsList[filter.id].type === 'text' || customFieldsList[filter.id].type === 'textarea'"
                               v-model="filters[index].valueText"
                               class="form-control form-control--mid mb-2"
                               placeholder="Type value..."/>

                        <div v-else-if="['radio', 'checkbox', 'select', 'multiselect', 'users_list'].includes(customFieldsList[filter.id].type)" class="form-control-wrapper w-100 mb-2">
                            <multiselect
                                v-model="filters[index].valueArray"
                                :multiple="true"
                                :options="customFieldsList[filter.id].options"
                                select-label=""
                                deselect-label=""
                                label="value"
                                track-by="value"
                                class="multiselect-mid"
                            >
                            </multiselect>
                        </div>

                        <div v-else-if="customFieldsList[filter.id].type === 'date'" class="mb-2 w-100">
                            <date-picker v-model="filters[index].valueArray" range></date-picker>
                        </div>

                        <div v-else-if="customFieldsList[filter.id].type === 'numeric'" class="d-flex align-items-center mb-2">
                            <span class="mr-2">From</span>
                            <input class="form-control--mid form-control" type="number" v-model="filters[index].valueNumbers[0]">
                            <span class="mx-2">to</span>
                            <input class="form-control--mid form-control" type="number" v-model="filters[index].valueNumbers[1]">
                        </div>

                        <a @click="removeFilter(index)" class="form-filter__custom__item__remove"></a>
                    </template>
                </div>
            </div>

        </div>

        <div v-else>
            <a @click="addFilter" class="form-filter__custom__new">+Add A Field & Value</a>
        </div>

        <div class="link-wrapper">
            <div v-if="filters.length && filters[filters.length - 1].id > 0" @click="addFilter" class="form-custom__action link ml-auto">+Add Another Field & Value</div>
        </div>
    </div>
</template>

<script>

import multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker';

export default {
    props: {
        customFieldsList: Object,
        initFilters: Array,
        homePage: {
            type: Boolean,
            default: false,
        },

        forSearch: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        multiselect,
        DatePicker
    },

    data() {
        return {
            filters: [],
        }
    },

    watch: {
        filters: {
            deep: true,
            handler() {
                this.applyFilter()
            }
        }
    },

    mounted() {
        this.setFilter(this.initFilters);
    },

    methods: {
        applyFilter() {
            let filters = [];

            for (let i in this.filters) {
                let currentFilter = this.filters[i];

                if (currentFilter.id > 0) {
                    let type = this.customFieldsList[currentFilter.id].type

                    if (type === 'text' || type === 'textarea') {
                        if (currentFilter.valueText.length) {
                            if (this.forSearch) {
                                filters.push(
                                    {
                                        id: currentFilter.id,
                                        name: 'custom_' + currentFilter.id,
                                        label: this.customFieldsList[currentFilter.id].name,
                                        value: currentFilter.valueText,
                                        valueAlias: currentFilter.valueText,
                                    }
                                )
                            } else {
                                filters.push(
                                    {
                                        id: currentFilter.id,
                                        value: currentFilter.valueText,
                                        valueAlias: currentFilter.valueText,
                                    }
                                )
                            }
                        }
                    } else if (['radio', 'checkbox', 'select', 'multiselect', 'tracker', 'users_list'].indexOf(type) >= 0) {
                        if (currentFilter.valueArray.length) {
                            if (this.forSearch) {
                                let values = this.arrayHelper.arrayColumn(currentFilter.valueArray, 'value')
                                filters.push(
                                    {
                                        id: currentFilter.id,
                                        name: 'custom_' + currentFilter.id,
                                        label: this.customFieldsList[currentFilter.id].name,
                                        value: values,
                                        valueAlias: values.join(', '),
                                    }
                                )
                            } else {
                                let values = this.arrayHelper.arrayColumn(currentFilter.valueArray, 'id')
                                let names = this.arrayHelper.arrayColumn(currentFilter.valueArray, 'value')
                                filters.push(
                                    {
                                        id: currentFilter.id,
                                        value: values,
                                        valueAlias: names.join(', '),
                                    }
                                )
                            }
                        }
                    } else if (type === 'date') {
                        if (currentFilter.valueArray.length === 2 && currentFilter.valueArray[0] instanceof Date && currentFilter.valueArray[1] instanceof Date) {
                            let interval = this.dateHelper.decodeInterval(currentFilter.valueArray);

                            if (this.forSearch) {
                                filters.push(
                                    {
                                        id: currentFilter.id,
                                        name: 'custom_' + currentFilter.id,
                                        label: this.customFieldsList[currentFilter.id].name,
                                        value: interval,
                                        valueAlias: interval.join('-'),
                                    }
                                )
                            } else {
                                filters.push(
                                    {
                                        id: currentFilter.id,
                                        value: interval,
                                        valueAlias: interval.join('-'),
                                    }
                                )
                            }

                        }
                    } else if (type === 'numeric') {
                        if (currentFilter.valueNumbers[0] || currentFilter.valueNumbers[1]) {
                            if (this.forSearch) {
                                filters.push(
                                    {
                                        id: currentFilter.id,
                                        name: 'custom_' + currentFilter.id,
                                        label: this.customFieldsList[currentFilter.id].name,
                                        value: this.arrayHelper.cloneArray(currentFilter.valueNumbers),
                                        valueAlias: currentFilter.valueNumbers.join('-'),
                                    }
                                )
                            } else {
                                filters.push(
                                    {
                                        id: currentFilter.id,
                                        value: this.arrayHelper.cloneArray(currentFilter.valueNumbers),
                                        valueAlias: currentFilter.valueNumbers.join('-'),
                                    }
                                )
                            }
                        }
                    }
                }
            }

            this.$emit('updateCustomDataFilter', filters)
        },

        setFilter(filter) {
            this.filters = [];

            filter.forEach(item => {
                let filterItem = {
                    id: item.id,
                    valueText: '',
                    valueArray: [],
                    valueNumbers: [null, null],
                }

                if (Array.isArray(item.value)) {
                    if (this.customFieldsList[item.id].type === 'numeric') {
                        filterItem.valueNumbers = item.value;
                    } else if (this.customFieldsList[item.id].type === 'date') {
                        filterItem.valueArray = this.dateHelper.encodeInterval(item.value)
                    } else {
                        item.value.forEach(optionId => {
                            if (this.forSearch) {
                                filterItem.valueArray.push(this.customFieldsList[item.id].options.find(option => option.value === optionId));
                            } else {
                                filterItem.valueArray.push(this.customFieldsList[item.id].options.find(option => option.id === optionId));
                            }
                        })
                    }
                } else {
                    filterItem.valueText = item.value;
                }

                this.filters.push(filterItem);
            })
        },

        addFilter() {
            this.filters.push(
                {
                    id: 0,
                    valueText: '',
                    valueArray: [],
                    valueNumbers: [null, null],
                }
            )
        },

        removeFilter(index) {
            this.filters.splice(index, 1)
        },

        actualCustomFieldsList(selectedId) {
            let existed = this.arrayHelper.arrayColumn(this.filters, 'id')
            let array = {}

            for (let id in this.customFieldsList) {
                if (['text','textarea','date','numeric','radio','checkbox', 'select', 'multiselect', 'users_list'].includes(this.customFieldsList[id].type)
                    && (existed.indexOf(id) < 0 || id === selectedId)) {
                    array[id] = this.customFieldsList[id]
                }
            }

            return array
        },

        resetFilter(index) {
            this.filters[index].valueText = ''
            this.filters[index].valueArray = []
            this.filters[index].valueNumbers = [null, null]
        }
    }
}
</script>

<style scoped>

</style>
