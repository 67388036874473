<template>
    <b-modal id="modal-shared-ecosystems" title="Invite Ecosystems To Tracker" modal-class="modal-w-md">

        Sharing ecosystems on your tracker allows them to see your tracker description and add startups from
        their portfolio. They can't see startups you've added or edit your tracker

        <h4 class="heading4 mt-3">Select an Ecosystem to share this tracker with*</h4>

        <div class="form">
            <multiselect class="multiselect multiselect-mid form-control mb-3"
                 v-model="newEcosystem"
                 :options="actualEcosystems"
                 select-label=""
                 deselect-label=""
                 label="name"
                 track-by="name"
            >
                <template slot="option" slot-scope="props">
                    <div class="d-flex align-items-center">
                        <div class="image-block mr-2">
                            <img :src="LogoHelper.getLogo(props.option.logo)" alt="">
                        </div>
                        {{ props.option.name }}
                    </div>
                </template>
            </multiselect>
        </div>

        <ul class="rows-list mb-3">

            <li v-for="(ecosystem, index) in ecosystems" :key="index" class="">
                <div class="image-block">
                    <img :src="LogoHelper.getLogo(ecosystem.logo)" alt="">
                </div>

                <h5 class="heading5 heading5--secondary">{{ ecosystem.name }}</h5>

                <a @click="ecosystems.splice(index, 1)" class="remove-action" href="#">Remove</a>
            </li>
        </ul>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canSave" @click="save">Share Tracker</b-button>
        </template>
    </b-modal>

</template>

<script>

import trackers from "@/services/trackers.service";
import multiselect from "vue-multiselect";
import ecosystems from "@/services/ecosystems.service";

export default {
    props: {
        trackerId: Number,
    },

    components: {
        multiselect,
    },

    data() {
        return {
            newEcosystem: null,
            ecosystems: [],
            ecosystemsList: [],
        }
    },

    watch: {
        newEcosystem() {
            if (this.newEcosystem) {
                this.addEcosystem()
            }
        }
    },

    computed: {
        canSave() {
            return this.ecosystems.length
        },

        actualEcosystems() {
            return this.ecosystemsList.filter(ecosystem => !this.ecosystems.some(existed => existed.id === ecosystem.id))
        }
    },

    mounted() {
        ecosystems.getConnectedEcosystemsList().then(response => {
            this.ecosystemsList = response.data
        })
    },

    methods: {

        init() {
            this.newEcosystem = null
            this.ecosystems = []

            trackers.getSharedEcosystems(this.trackerId).then(response => {
                this.ecosystems = response.data
            })
        },

        open(id) {
            this.id = id
            this.init()
            this.$bvModal.show('modal-shared-ecosystems')
        },

        addEcosystem() {
            this.ecosystems.push(this.newEcosystem)
            this.newEcosystem = null
        },

        save() {
            let ecosystemIds = this.arrayHelper.arrayColumn(this.ecosystems, 'id')

            trackers.shareWithEcosystems(this.trackerId, ecosystemIds).then(() => {
                this.$emit('trackerShared', ecosystemIds.length)
                this.$bvModal.hide('modal-shared-ecosystems')
            })
        },
    }
}
</script>
