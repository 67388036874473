import axios from '@/axios';

const RESOURCE_NAME = '/articles';

export default {
    getArticles(startupId) {
        return axios.get(`${RESOURCE_NAME}/get-articles?startup_id=${startupId}`)
    },

    getEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}`)
    },

    save(id, startupId, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}&startup_id=${startupId}`, data)
    },

    delete(id) {
        return axios.post(`${RESOURCE_NAME}/delete-article?id=${id}`)
    },
}
