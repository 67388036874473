import date from "@/helpers/date";

function syncRulesWithFilters(query, filterName, filterValue) {
    return query.rules.some(function (rule, i, object) {
        if (typeof rule.id !== 'undefined' && rule.name === filterName) {
            let exists = filterValue.some(function (filter) {
                return filter.value === rule.value[0]
            });

            if (!exists) {
                object.splice(i, 1)
                return true
            }
        } else {
            if (typeof rule.condition !== 'undefined') {
                return rule.rules.some(function (elem, j, innerObject) {
                    if (typeof elem.id !== 'undefined' && elem.name === filterName) {
                        let exists = filterValue.some(function (filter) {
                            return filter.value === elem.value[0]
                        });

                        if (!exists) {
                            innerObject.splice(j, 1);
                            return true
                        }
                    }
                })
            }
        }
    });
}

function addFilterRule(query, ruleToAdd) {
    let result = query.rules.some((rule, i, object) => {
        if (typeof rule.id !== 'undefined' && rule.name === ruleToAdd.name) {
            let updatedRule = {
                'condition': 'OR',
                'rules': [rule, ruleToAdd],
            }

            object.splice(i, 1, updatedRule);
            return true;
        } else {
            if (typeof rule.condition !== 'undefined') {
                return rule.rules.some(function (elem, j, innerObject) {
                    if (typeof elem.id !== 'undefined' && elem.name === ruleToAdd.name) {
                        innerObject.push(ruleToAdd);
                        return true;
                    }
                })
            }
        }
    });

    if (!result) {
        query.rules.push(ruleToAdd)
    }
}

function updateRule(query, rule, level) {
    if (level['count'] === 1) {
        query.rules[level['level_1']].value = rule.value;
        query.rules[level['level_1']].valueAlias = rule.valueAlias;

        // exceptional case for that condition
        if (rule.name === 'trackers' && rule.value[0] === 'all') {
            query.rules[level['level_1']].negative = false;
        }
    }

    if (level['count'] === 2) {
        query.rules[level['level_1']].rules[level['level_2']].value = rule.value;
        query.rules[level['level_1']].rules[level['level_2']].valueAlias = rule.valueAlias;

        // exceptional case for that condition
        if (rule.name === 'trackers' && rule.value[0] === 'all') {
            query.rules[level['level_1']].rules[level['level_2']].negative = false;
        }
    }
}

function getNextRuleLevel(query, rule, level) {
    let rules = query.rules;
    let currentIndex = level['level_1'];
    let nextIndex = currentIndex + 1;
    let nextLevel = {'level_1': nextIndex, 'count': 1};

    // Check if current is inside group
    if (level['count'] === 2) {
        let secondLevelNextIndex = level['level_2'] + 1;
        // First search inside current group
        if (typeof rules[currentIndex].rules[secondLevelNextIndex] != 'undefined') {
            Object.assign(nextLevel, {
                'level_1' : currentIndex,
                'level_2' : secondLevelNextIndex,
                'count': 2
            });

            return nextLevel;
        }
    }

    if (typeof rules[nextIndex] != 'undefined') {
        if (typeof rules[nextIndex].condition != 'undefined') {
            Object.assign(nextLevel, {'level_2' : 0, 'count': 2});
        }
    } else {
        if (typeof rules[currentIndex].condition != 'undefined') {
            let nextSecondLevel = level['level_2'] + 1;
            Object.assign(nextLevel, {
                'level_1' : currentIndex,
                'level_2' : nextSecondLevel,
                'count'   : 2
            });
        }
    }

    return nextLevel;
}

function determineCase(currentLevel, nextLevel) {
    // Case 1: current and next in level 1
    if (currentLevel.count === 1 && nextLevel.count === 1) {
        return 1;
    }

    // Case 2: current in level 1 and next in group
    if (currentLevel.count === 1 && nextLevel.count === 2) {
        return 2;
    }

    // Case 3: current and next in group
    if (currentLevel.count === 2 && nextLevel.count === 2) {
        return 3;
    }
    // Case 3: current and next in group
    if (currentLevel.count === -1 && nextLevel.count === 2) {
        return 3;
    }

    // Case 4: current in group and next in level 1
    if (currentLevel.count === 2 && nextLevel.count === 1) {
        return 4;
    }
    // Case 4: current in group and next in level 1
    if (currentLevel.count === -1 && nextLevel.count === 1) {
        return 4;
    }

    return 0;
}


export function getCurrentRuleLevel(query, rule, inGroup = false, field = 'id') {
    var index = {}

    for (let i in query.rules) {
        i = parseInt(i);
        let item = query.rules[i];

        if (inGroup) {
            if (item === rule) {
                index = {'level_1': i, count: -1};
                break;
            }
        } else {
            if (typeof item.id !== 'undefined') {
                if (item[field] === rule[field]) {
                    index = {'level_1': i, 'count': 1};
                    break;
                }
            } else {
                if (typeof item.condition !== 'undefined') {
                    for (let j in item.rules) {
                        j = parseInt(j);
                        let elem = item.rules[j];

                        if (typeof elem[field] !== 'undefined') {
                            if (elem[field] === rule[field]) {
                                index = {'level_1': i, 'level_2': j, 'count': 2};
                                break;
                            }
                        }
                    }
                }
            }
        }
    }

    return index;
}

export function getCurrentRuleLevelByField(query, rule, inGroup = false) {
    return getCurrentRuleLevel(query, rule, inGroup, 'name');
}

export function getLastId(query) {
    return query.rules.reduce((lastId, rule) => {
        if ('condition' in rule) {
            lastId = rule.rules.reduce((subLastId, subRule) => {
                if (subRule.id > subLastId) {
                    subLastId = subRule.id;
                }

                return subLastId;
            }, lastId);
        } else if (rule.id > lastId) {
            lastId = rule.id;
        }

        return lastId;
    }, 0);
}

export function deleteRule(query, rule, field = 'id') {
    let level = getCurrentRuleLevel(query, rule, false, field);

    // Level 1
    if (level['count'] === 1) {
        query.rules.splice(level['level_1'], 1);
    }

    // Level 2
    if (level['count'] === 2) {
        const subRulesCount = query.rules[level['level_1']].rules.length;

        if (subRulesCount > 2) {
            query.rules[level['level_1']].rules.splice(level['level_2'], 1);
        } else {
            query.rules[level['level_1']].rules.splice(level['level_2'], 1);
            const remainingRule = query.rules[level['level_1']].rules[0];
            query.rules.splice(level['level_1'], 1, remainingRule);
        }
    }
}

export function invertRule(query, rule) {
    if (!rule.negative) {
        rule.broadMode = false;
    }

    let level = getCurrentRuleLevel(query, rule);

    // Level 1
    if (level['count'] === 1) {
        query.rules[level['level_1']].negative = !query.rules[level['level_1']].negative;
    }

    // Level 2
    if (level['count'] === 2) {
        query.rules[level['level_1']].rules[level['level_2']].negative = !query.rules[level['level_1']].rules[level['level_2']].negative;
    }
}

export function toggleCondition(query, rule, inGroup, oldCond) {
    inGroup = inGroup || false;
    let currentLevel = getCurrentRuleLevel(query, rule, inGroup);
    let nextLevel  = getNextRuleLevel(query, rule, currentLevel);

    let usecase = determineCase(currentLevel, nextLevel);

    if (usecase === 1) {
        if (oldCond === 'AND') {
            let spliced = query.rules.splice(currentLevel['level_1'], 2);
            let newRule = {
                'condition' : 'OR',
                'rules'     : spliced,
            }

            query.rules.splice(currentLevel['level_1'], 0, newRule);
        }
    }

    if (usecase === 2) {
        if (oldCond === 'AND') {
            let spliced = query.rules.splice(currentLevel['level_1'], 1);
            let newIndex = nextLevel['level_1'] - 1;
            query.rules[newIndex].rules.unshift(spliced[0]);
        }
    }

    if (usecase === 3) {
        if (oldCond === 'AND') {
            if (currentLevel.count === -1) {
                let extractedRules = query.rules[nextLevel['level_1']].rules;
                query.rules.splice(nextLevel['level_1'], 1);
                for (let i = 0; i < extractedRules.length; i++) {
                    query.rules[currentLevel['level_1']].rules.push(extractedRules[i]);
                }
            }
        } else {
            let groupLength = query.rules[nextLevel['level_1']].rules.length;

            // Just two items in the group
            if (currentLevel['level_2'] === 0 && nextLevel['level_2'] === (groupLength-1)) {
                let extractedRues = query.rules[currentLevel['level_1']].rules;
                query.rules.splice(currentLevel['level_1'], 1);

                for (let i = 0; i < extractedRues.length; i++) {
                    let position = currentLevel['level_1'] + i;
                    query.rules.splice(position, 0, extractedRues[i]);
                }
            }

            // First item of the group
            else if (currentLevel['level_2'] === 0) {
                let extracted = query.rules[currentLevel['level_1']].rules.splice(currentLevel['level_2'], 1);
                query.rules.splice(currentLevel['level_1'], 0, extracted[0]);
            }

            // Next is last of group
            else if (nextLevel['level_2'] === (groupLength-1)) {
                let extracted = query.rules[nextLevel['level_1']].rules.splice(nextLevel['level_2'], 1);
                query.rules.splice(currentLevel['level_1']+1, 0, extracted[0]);
            }

            else {
                // Next and current in the middle
                let itemsToExtract = groupLength - nextLevel['level_2'];
                let extracted = query.rules[nextLevel['level_1']].rules.splice(nextLevel['level_2'], itemsToExtract);
                let newRule = {
                    'condition': 'OR',
                    'rules': extracted,
                }
                query.rules.splice(currentLevel['level_1'] + 1, 0, newRule);
            }
        }
    }

    if (usecase === 4) {
        if (oldCond === 'AND') {
            // When current is group
            if (currentLevel.count === -1) {
                // Add next rule to current group
                let spliced = query.rules.splice(nextLevel['level_1'], 1);
                query.rules[currentLevel['level_1']].rules.push(spliced[0]);
            }
        }
    }
}

export function updateFilterRule(query, name, filterValue) {
    let nextId = getLastId(query) + 1;

    let rule = {
        "id": nextId,
        "name": name,
        "label": name,
        "negative": false,
    }

    let needDelete = false

    if (['lastFundingDate','foundedYear','lastInteraction'].includes(name)) {

        if (!filterValue || filterValue[0] === null || filterValue[1] === null) {
            needDelete = true
        } else {
            rule.value = date.decodeInterval(filterValue)

            if (name === 'lastInteraction') {
                rule.valueAlias = date.decodeDate(filterValue[0]) + '-' + date.decodeDate(filterValue[1]);
            } else {
                rule.valueAlias = date.decodeYear(filterValue[0]) + '-' + date.decodeYear(filterValue[1])
            }
        }
    } else if (name === 'similarTracker') {
        if (!filterValue) {
            needDelete = true
        } else {
            rule.label = "Similar to tracker"
            rule.value = filterValue.id
            rule.valueAlias = filterValue.name
        }
    } else if (name === 'categories') {
        if (!filterValue.length) {
            needDelete = true
        } else {
            rule.label = "Category"
            rule.value = [filterValue.slice(-1)[0].value]
            rule.valueAlias = filterValue.slice(-1)[0].name
        }
    } else if (name === 'tags') {
        if (!filterValue.length) {
            needDelete = true
        } else {
            rule.label = "Tag"
            rule.value = [filterValue.slice(-1)[0].value]
            rule.valueAlias = filterValue.slice(-1)[0].name
        }
    } else if (name === 'trackers') {
        if (!filterValue.length) {
            needDelete = true
        } else {
            let values = filterValue.map(item => item.id);
            let labels = filterValue.map(item => item.name);
            rule.value = values;
            rule.valueAlias = labels.join(', ');
        }
    } else if (name === 'irlScore') {
        if (filterValue && filterValue.length === 2 && !(filterValue[0] === 1 && filterValue[1] === 10)) {
            rule.label = "IRL Score";
            rule.value = filterValue;
            rule.valueAlias = filterValue[0].toString() + '-' + filterValue[1].toString();
        } else {
            needDelete = true;
        }
    } else {
        if (name === 'clientsCategories') {
            rule.label = "Client's Industry";
        }

        if (!filterValue.length) {
            needDelete = true;
        } else {
            let values = filterValue.map(item => item.value);
            let labels = filterValue.map(item => item.name);
            rule.value = values;
            rule.valueAlias = labels.join(', ');
        }
    }

    let level = getCurrentRuleLevelByField(query, rule)

    if (!needDelete) {
        if (['categories','tags'].includes(name)) {
            let isDeleting = syncRulesWithFilters(query, name, filterValue);

            if (!isDeleting) {
                addFilterRule(query, rule);
            }
        } else {
            if (typeof level.level_1 === "undefined") {
                query.rules.push(rule);
            } else {
                updateRule(query, rule, level)
            }
        }
    } else {
        deleteRule(query, rule, 'name');
    }
}

export function normalizeQuery(query) {
    let rules = []

    query.rules.forEach(rule => {
        if (rule.condition !== undefined) {
            if (rule.rules.length > 1) {
                rules.push(rule)
            } else if (rule.rules.length === 1) {
                rules.push(rule.rules[0])
            }
        } else {
            rules.push(rule)
        }
    })

    query.rules = rules
}
