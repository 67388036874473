<template>

    <b-collapse visible id="collapse-activities-list">
        <ul class="activity-list">
            <li v-for="(activity, index) in activities" :key="index">
                <div class="activity-list__author">
                    <div class="image-block image-block--rounded image-block--rounded--md">
                        <Avatar
                            :username="activity.user_name"
                            :src="activity.user_avatar"
                        >
                        </Avatar>
                    </div>
                    <div>
                        <h5 class="heading5 heading5--secondary">{{ activity.user_name }}</h5>
                        <time class="activity-list__time">
                            {{ dateHelper.decodeDate(activity.date) }}
                        </time>
                    </div>
                </div>
                <div class="activity-list__content">
                    <h5 class="activity-list__title" :class="getActivityIconClass(activity)">
                        {{ activity.title }}
                        <template v-if="activity.interaction_date">
                            ({{ dateHelper.decodeDate(activity.interaction_date) }})
                        </template>
                    </h5>
                    <div v-html="activity.text" class="text-wrap">

                    </div>
                </div>

                <b-dropdown v-if="activity.can_edit || activity.can_delete" right
                            class="dropdown-simple dropdown-single dropdown-single--big icons-list ml-auto pl-5">
                    <b-dropdown-item v-if="activity.can_edit" class="icons-list__edit" @click="editActivity(activity)">
                        Edit
                    </b-dropdown-item>
                    <b-dropdown-item v-if="activity.can_delete" class="icons-list__delete2"
                                     @click="removeActivity(activity)">
                        Remove
                    </b-dropdown-item>
                </b-dropdown>
            </li>
        </ul>
    </b-collapse>
</template>

<script>

import Avatar from "vue-avatar";

export default {
    props: {
        activities: Array,
    },

    components: {
        Avatar,
    },

    methods: {

        removeActivity(activity) {
            this.$emit('remove', activity)
        },

        editActivity(activity) {
            this.$emit('edit', activity)
        },

        getActivityIconClass(activity) {
            if (activity.type === 'note') {
                return 'activity-list__title--note'
            } else if (activity.type === 'phone_call') {
                return 'activity-list__title--call'
            } else if (activity.type === 'email') {
                return 'activity-list__title--email'
            } else if (activity.type === 'meeting') {
                return 'activity-list__title--meeting'
            }

            return '';
        },
    }
}
</script>

<style scoped>

</style>
