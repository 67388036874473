<template>
    <div>
        <enterprise-startup v-if="$store.getters.isEnterprise"></enterprise-startup>
        <ecosystem-startup v-if="$store.getters.isEcosystem"></ecosystem-startup>
        <public-startup v-if="!$store.getters.isLoggedIn"></public-startup>
    </div>
</template>

<script>
import EnterpriseStartup from "@/views/startups/Profile.vue";
import EcosystemStartup from "@/views/ecosystem/startups/Profile";
import PublicStartup from "@/views/startups/PublicStartup";

export default {
    components: {
        EnterpriseStartup,
        EcosystemStartup,
        PublicStartup,
    }
}
</script>
