<template>
    <div>
        <enterprise-portfolio v-if="$store.getters.isEnterprise"></enterprise-portfolio>
        <ecosystem-portfolio v-if="$store.getters.isEcosystem"></ecosystem-portfolio>
    </div>
</template>
<script>
import EnterprisePortfolio from "@/views/ecosystems/Portfolio";
import EcosystemPortfolio from "@/views/ecosystem/Portfolio";

export default {
    components: {
        EnterprisePortfolio,
        EcosystemPortfolio,
    }
}
</script>