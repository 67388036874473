<template>
    <b-modal id="add-startups" title="Add Startups">

        <multiselect
            class="multiselect--complex multiselect-mid"
            v-model="startups"
            :options="startupsList"
            select-label=""
            deselect-label=""
            label="name"
            track-by="name"
            :multiple="true"
            :loading="startupsLoading"
            :showNoOptions="!startupsLoading"
            placeholder="Start typing..."
            @search-change="updateStartupsList"
        >
            <template slot="option" slot-scope="props"><img :src="LogoHelper.getLogo(props.option.logo)" style="width:40px" alt=""> {{ props.option.name }}</template>
        </multiselect>

        <template #modal-footer>
            <b-button variant="primary" @click="addStartups" size="lg" :disabled="!startups.length || loading">Add</b-button>
        </template>
    </b-modal>
</template>

<script>
import multiselect from "vue-multiselect"
import trackersCompanies from "@/services/trackers-companies.service"
import startups from "@/services/startups.service";
import debounce from "@/mixins/debounce";

export default {
    props: {
        trackerId: Number
    },

    components: {
        multiselect,
    },

    mixins: [debounce],

    data() {
        return {
            startupsList: [],
            startups: [],
            loading: false,
            startupsLoading: false,
            // lastAddedStartups: [],
        }
    },

    mounted() {
        this.getLastAddedStartups()
    },

    methods: {
        getLastAddedStartups() {
            startups.getLastAddedStartups(this.trackerId).then(response => {
                if (response.data.length) {
                    this.startupsList = response.data
                } else {
                    this.updateStartupsList('');
                }
            })
        },

        updateStartupsList(search) {
            this.startupsLoading = true
            this.debounce(() => {
                trackersCompanies.getStartupsListToAdd(this.trackerId, search).then(response => {
                    this.startupsList = response.data
                    this.startupsLoading = false
                })
            }, 500)
        },

        open() {
            this.startups = []
            // this.startupsList = this.lastAddedStartups.length ? this.lastAddedStartups : this.startupsList
            this.$bvModal.show('add-startups')
        },

        addStartups() {
            this.loading = true
            let items = this.startups.map(item => ({id: item.id, type: item.type ?? 'startup' }))

            trackersCompanies.addCompanies([this.trackerId], items).then(() => {
                this.$emit('startupsAdded')
                this.$bvModal.hide('add-startups')
                this.getLastAddedStartups()
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>

</style>
