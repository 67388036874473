import axios from '@/axios';

const RESOURCE_NAME = '/radar';

export default {
    getList(data) {
        return axios.post(`${RESOURCE_NAME}/get-list`, data)
    },

    getDefaultFunnel() {
        return axios.get(`${RESOURCE_NAME}/get-default-funnel`)
    },
}
