<template>
    <b-modal id="modal-edit-tag" :title="title" modal-class="modal-w-md">
        <p class="modal-text">Tags are only visible to users in your company.</p>
        
        <h6 class="heading6 mb-1">Tag Name*</h6>
        <span class="form__error" v-for="(error, index) in errors.name" :key="index">{{error}}</span>
        <b-form-input class="form-control--mid mb-3" v-model="tag.name"></b-form-input>
        
        <h6 class="heading6 mb-1">Description (Optional)</h6>
        <b-form-input class="form-control--mid mb-3" v-model="tag.description"></b-form-input>

        <template v-if="parentsList.length">
            <h6 class="heading6 mb-1">Root Tag (optional)</h6>

            <select v-model="tag.parentId" class="multiselect__input selectbox selectbox-mid w-100">
                <option value="0"></option>
                <option v-for="parent in parentsList" :key="parent.id" :value="parent.id">
                    {{parent.name}}
                </option>
            </select>
        </template>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canSave" @click="save"><template v-if="id">Update</template><template v-else>Add</template></b-button>
        </template>
    </b-modal>
</template>

<script>
import tags from "@/services/tags.service"

export default {
    data() {
        return {
            id: 0,
            tag: {
                name: "",
                description: "",
                parentId: 0,
            },

            parentsList: [],
            errors: {
                name: []
            },
        }
    },

    computed: {
        title() {
            if (this.id) {
                return "Edit Tag"
            } else {
                return "Add New Tag"
            }
        },

        canSave() {
            return this.tag.name.length
        }
    },

    methods: {
        init() {
            this.tag.name = ""
            this.tag.description = ""
            this.tag.parentId = 0
            this.errors.name = ""

            this.getParentsList()

            if (this.id) {
                tags.getEdit(this.id).then(response => {
                    this.tag.name = response.data.name
                    this.tag.description = response.data.description
                    this.tag.parentId = response.data.parent_id
                })
            }
        },

        open(id) {
            this.id = id
            this.init()
            this.$bvModal.show('modal-edit-tag')
        },

        save() {
            tags.save(this.id, this.tag).then((response) => {

                if (Object.keys(response.data.errors).length) {
                    this.errors = response.data.errors
                }
                else {
                    this.$emit('tagSaved')
                    this.$bvModal.hide('modal-edit-tag')
                }
            })
        },

        getParentsList() {
            tags.getParentsList(this.id).then(response => {
                this.parentsList = response.data
            })
        }
    }
}
</script>

<style scoped>

</style>
