<template>
    <b-modal :id="modalId" :title="title" modal-class="modal-w-md" v-on:shown="shown">

        <edit-article
            ref="editArticle"
            :startup-id="startupId"
            v-on:saved="saved"
            v-on:canSave="setCanSave"
        >
        </edit-article>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canSave" @click="save">Save</b-button>
        </template>
    </b-modal>
</template>

<script>

import EditArticle from "@/components/articles/EditArticle";

export default {
    props: {
        startupId: Number,
        isPreviewList: Boolean,
    },

    components: {
        EditArticle
    },

    data() {
        return {
            article: {
                title: "",
                url: "",
                posted_on: "",
            },

            date: '',
            id: 0,
            errors: {
                url: [],
            },

            canSave: false,
        }
    },

    computed: {
        title() {
            if (this.id) {
                return "Edit Article"
            }
            else {
                return "Add An Article"
            }
        },
        modalId() {
            return this.isPreviewList ? "modal-edit-article-preview" : "modal-edit-article"
        }
    },

    methods: {
        open(id) {
            this.id = id
            this.$bvModal.show(this.modalId)
        },

        shown() {
            this.$refs.editArticle.init(this.id)
        },

        save() {
            this.$refs.editArticle.save()
        },

        saved() {
            this.$emit('saved')
            this.$bvModal.hide(this.modalId)
        },

        setCanSave(canSave) {
            this.canSave = canSave
        }
    }
}
</script>
