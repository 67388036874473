import tags from "@/services/tags.service"

export default {
    state: {
        tags: [],
        tagsLoaded: false,
    },

    actions: {
        async fetchTags({state}, reload = false){

            if (state.tagsLoaded && !reload) {
                return state.tags
            }

            const response = await tags.getList()
            state.tags = response.data
            state.tagsLoaded = true
        }
    },

    mutations: {
        clearTags: (state) => {state.tagsLoaded = false; state.tags = []}
    },

    getters: {
        tagsList: state => state.tags,
        tagsLoaded: state => state.tagsLoaded
    }
}
