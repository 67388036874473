<template>
    <div class="main-table__data" style="display: contents">
        <template>
            <a :id="'trackers-link-'+startupId" class="counter-wrapper">
                <span v-on="selectedTrackerId ? { click: setAddedTracker } : { click: getCompanyDetails }"
                      class="counter">
                    <template v-if="trackersCountValue > 0">{{trackersCountValue}}</template>
                    <template v-else>+</template>
                </span>
            </a>
        </template>

        <b-popover v-if="$store.state.user.company_type === 'enterprise'" ref="trackersPopover"
                   :target="'trackers-link-'+startupId" placement="bottomright">
            <div class="popover__content popover__content--secondary" v-click-outside="closeTrackersPopover">
                <header class="popover__content__header">
                    <h4 class="heading4">Add to tracker</h4>
                </header>

                <div class="popover__content__body flex-column">
                    <multiselect
                        class="multiselect multiselect-mid"
                        v-model="addedTracker"
                        :options="actualTrackersList"
                        select-label=""
                        deselect-label=""
                        label="name"
                        track-by="name"
                    >
                    </multiselect>

                    <h4 class="heading4 mt-4" v-if="startupDetails.trackers.length">
                        On {{startupDetails.trackers.length}} {{stringHelper.getNoun('tracker', startupDetails.trackers.length)}}
                    </h4>

                    <ul class="rows-list">
                        <li v-for="tracker in startupDetails.trackers" :key="tracker.id">
                            <h5 class="heading5 heading5--secondary"><a :href="'/trackers/' + tracker.id"
                                                                        target="_blank">{{ tracker.name }}</a></h5>
                            <a v-if="tracker.type !== 'public'" @click="removeFromTracker(tracker.id)"
                               class="remove-action">Remove</a>
                        </li>
                    </ul>

                </div>
            </div>
        </b-popover>
    </div>
</template>

<script>
    import multiselect from "vue-multiselect";
    import ecosystemStartups from "@/services/ecosystem/startups.service";
    import enterpriseStartups from "@/services/startups.service";
    import trackersCompanies from "@/services/trackers-companies.service";

    export default {
        components: {
            multiselect
        },
        props: {
            startupId: {
                type: Number,
                required: true,
            },
            trackersList: {
                type: Array,
                required: true,
            },
            trackersCount: {
                type: [String, Number],
                required: true,
            },
            selectedTrackerId: {
                type: Number,
                default: null,
            }
        },
        data() {
            return {
                addedTracker: null,
                startupDetails: {
                    trackers: [],
                },
                trackersCountValue: this.trackersCount,
                actualTrackersList: this.trackersList,
                updating: false,
            }
        },
        watch: {
            addedTracker() {
                if (this.addedTracker != null) {
                    this.addToTracker()
                }
            },
            trackersCount(propValue) {
                this.trackersCountValue = propValue
            },
            'startupDetails.trackers': function (startupTrackers) {
                this.actualTrackersList = this.trackersList.filter(tracker => !startupTrackers.some(startupTracker => parseInt(startupTracker.id) === parseInt(tracker.id)))
            },
        },
        methods: {
            getCompanyDetails() {
                if (this.$store.state.user.company_type === 'ecosystem') {
                    ecosystemStartups.getDetails(this.startupId).then(response => {
                        this.startupDetails = response.data
                    })
                } else {
                    enterpriseStartups.getDetails(this.startupId).then(response => {
                        this.startupDetails = response.data
                        this.trackersCountValue = this.startupDetails.trackers.length
                    })
                }
            },
            closeTrackersPopover() {
                this.$refs.trackersPopover.$emit('close')
            },
            addToTracker() {
                let addedTrackers = [this.addedTracker.id]
                let startups = [this.startupId]
                trackersCompanies.addCompanies(addedTrackers, startups).then(() => {
                    this.getCompanyDetails()
                    // applies just for current tracker
                    if (this.selectedTrackerId && (this.selectedTrackerId === this.addedTracker.id)) {
                        //TODO change name emitted event
                        this.$emit('startupsAdded')
                    }
                    this.addedTracker = null

                    this.$emit('startupAddedToTracker')
                })
            },
            setAddedTracker() {
                if (this.selectedTrackerId) {
                    this.addedTracker = {'id': this.selectedTrackerId}
                }
            },
            removeFromTracker(trackerId) {
                if (!this.updating) {
                    this.updating = true

                    trackersCompanies.removeCompanies(trackerId, [this.startupId]).then(() => {
                        this.getCompanyDetails()
                        // applies just for current tracker
                        if (this.selectedTrackerId && (this.selectedTrackerId === trackerId)) {
                            this.$emit('startupRemoved', this.startupId)
                        }

                        this.$emit('startupRemovedFromTracker')
                    }).finally(() => {
                        this.updating = false
                    })
                }
            },
        }
    }
</script>

<style scoped>

</style>
