<template>
    <div>
        <enterprise-home v-if="$store.getters.isEnterprise"></enterprise-home>
        <ecosystem-home v-if="$store.getters.isEcosystem"></ecosystem-home>
        <startup-home v-if="$store.getters.isStartup"></startup-home>
    </div>
</template>

<script>
import EnterpriseHome from "@/views/enterprise/home/Home";
import EcosystemHome from "@/views/ecosystem/Home";
import StartupHome from "@/views/startup/Profile.vue";

export default {
    components: {
        EnterpriseHome,
        EcosystemHome,
        StartupHome,
    }
}
</script>
