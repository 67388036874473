<template>
    <b-modal id="modal-add-startups-to-portfolio" title="Add startups" modal-class="modal-w-md">

        <multiselect
            v-model="selectedStartups"
            :multiple="true"
            placeholder="Select startups"
            :options="startupsList"
            select-label=""
            deselect-label=""
            label="name"
            track-by="name"
            class="multiselect-mid pl-2"
        >
        </multiselect>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canAdd" @click="add">Add</b-button>
        </template>
    </b-modal>
</template>

<script>
import portfolios from "@/services/ecosystem/portfolios.service"
import Vue from "vue";
import multiselect from "vue-multiselect";

export default {
    components: {
        multiselect
    },

    props: {
        portfolios: Array
    },

    data() {
        return {
            startupsList: [],
            selectedStartups: [],
        }
    },

    watch: {
        portfoliosList: {
            deep: true,
            handler() {
                this.selectedPortfolios = []

                this.portfoliosList.forEach(item => {
                    if (item.checked) {
                        this.selectedPortfolios.push(item.id)
                    }
                })
            }
        }
    },

    computed: {
        canAdd() {
            return this.selectedStartups.length
        }
    },

    methods: {
        open() {
            this.selectedStartups = []
            this.getStartupsList()
            this.$bvModal.show('modal-add-startups-to-portfolio')
        },

        add() {
            let selectedStartups = this.arrayHelper.arrayColumn(this.selectedStartups, 'id')

            portfolios.addStartups(this.portfolios, selectedStartups).then((response) => {
                this.$emit('saved')
                this.$bvModal.hide('modal-add-startups-to-portfolio')

                let added = response.data.added
                let existed = response.data.existed

                let message = []

                if (added) {
                    message.push("Added " + added + " " + this.stringHelper.getNoun('startup', added))
                }

                if (existed) {
                    message.push("Already existed " + existed + " " + this.stringHelper.getNoun('startup', existed))
                }

                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 5000,
                    text: message.join('; ')
                })

            })
        },

        getStartupsList() {
            portfolios.getStartupsListToAdd(this.portfolios[0]).then(response => {
                this.startupsList = response.data
            })
        },
    }
}
</script>

<style scoped>

</style>
