<template>
    <b-modal v-if="settings.trackers" title="Tracker Notifications" id="tracker-settings-modal" size="md" modal-class="tracker-settings-modal">

        <div v-if="global">
            Your notification settings are set to global
            <a href="/settings/notifications?tab=startup-trackers" target="_blank">Edit notification settings</a>
        </div>

        <div v-else>
            <div class="card card--bordered-slim card--notification">
                <div class="card--notification__inner">
                    <h6 class="heading6">Tracker Updates</h6>

                    <div v-if="settings.trackers.value === 'mixed'">
                        Mixed
                    </div>

                    <multiselect
                        v-else
                        class="multiselect--blue"
                        v-model="settings.trackers"
                        :options="periods"
                        :hideSelected="true"
                        select-label=""
                        label="name"
                        track-by="name"
                    >
                    </multiselect>
                </div>

                <i class="card--notification__hint">New {{objectTypeName}}s, team or {{objectTypeName}} reviews</i>

                <div class="card--notification__inner">
                    <h6 class="heading6">{{stringHelper.ucFirst(objectTypeName)}} News</h6>

                    <div v-if="settings.startups.value === 'mixed'">
                        Mixed
                    </div>

                    <multiselect
                        v-else
                        class="multiselect--blue"
                        v-model="settings.startups"
                        :options="periodsForStartups"
                        :hideSelected="true"
                        select-label=""
                        label="name"
                        track-by="name"
                    >
                    </multiselect>
                </div>

                <i class="card--notification__hint">
                    Articles, <template v-if="trackerType !== trackerTypes.ECOSYSTEMS">new funding, </template>interactions and custom field updates
                </i>

                
            </div>

            <p class="modal-text">
                To manage different notification types more granularly, edit your preferences in settings:
            </p>

            <p class="modal-text">
                <a class="heading-edit-link heading-edit-link--round" :href="customizeLink" target="_blank">
                    Customize Notifications
                </a>
            </p>
        </div>

        <template #modal-footer>
            <b-button variant="primary" size="lg" @click="save">
                <template v-if="canSave">Save & </template>Close
            </b-button>
        </template>

    </b-modal>
</template>

<script>
import multiselect from "vue-multiselect";
import {notificationPeriods} from "@/constants/motifications";
import {trackerTypes} from "@/constants/trackers";

export default {
    name: "TrackerNotificationSettings",

    props: {
        trackerType: String,
        trackerId: Number,
        initSettings: Object,
    },

    components: {multiselect},

    data() {
        return {
            global: false,

            settings: {
                trackers: {value: null},
                startups: {value: null},
            },
        }
    },

    watch: {
        initSettings() {
            this.setSettings();
        }
    },

    computed: {
        objectTypeName() {
            if (this.trackerType === trackerTypes.ECOSYSTEMS) {
                return 'ecosystem';
            } else {
                return 'startup';
            }
        },

        periods() {
            let list = [];

            for (let i in notificationPeriods) {
                list.push({
                    value: i,
                    name: notificationPeriods[i],
                })
            }

            return list;
        },

        periodsForStartups() {
            return this.periods.filter(item => item.value !== 'now');
        },

        canSave() {
            return !this.global && !(this.settings.trackers.value === 'mixed' && this.settings.startups.value === 'mixed')
        },

        trackerTypes() {
            return trackerTypes;
        },

        customizeLink() {
            return '/settings/notifications?tab=' + (this.trackerType === 'ecosystems' ? 'ecosystem-trackers' : 'startup-trackers') + '&trackerId=' + this.trackerId
        }
    },

    mounted() {
        this.setSettings();
    },

    methods: {
        open() {
            this.$bvModal.show('tracker-settings-modal');
        },

        setSettings() {
            this.global = this.initSettings.global;

            if (!this.global) {
                let settings = this.initSettings.settings;

                for (let type in settings) {
                    if (settings[type] === 'mixed') {
                        this.settings[type] = {value: 'mixed'};
                    } else {
                        this.settings[type] = this.periods.find(item => item.value === settings[type]);
                    }
                }
            }
        },

        save() {
            if (this.canSave) {
                let settings = {};

                if (this.settings.trackers.value !== 'mixed') {
                    settings.trackers = this.settings.trackers.value;
                }

                if (this.settings.startups.value !== 'mixed') {
                    settings.startups = this.settings.startups.value;
                }

                this.$emit('saved', settings);
            }

            this.$bvModal.hide('tracker-settings-modal');
        },
    }
}
</script>

<style scoped>

</style>
