<template>
    <b-modal id="startupsActionLogsModal" modal-class="modal--dark modal-w-833 startups-action-logs-modal"
             title="Tracked startups news" hide-footer>

        <template #modal-title>
            Tracked startups news
            <FilterByTrackers
                @changed="trackersFilterChanged"
            />
        </template>

        <ul v-if="hasLogs" class="activity-list action-logs-list">
            <li v-for="(log, index) in logs" :key="index">
                <div class="activity-list__author">
                    <div class="image-block">
                        <img :src="LogoHelper.getLogo(log.startup_logo)" alt="">
                    </div>
                    <div>
                        <a :href="'/startups/' + log.startup_id" target="_blank">
                            <h5 class="heading5 heading5--secondary">
                                <template>
                                    {{ log.startup_name }}
                                </template>
                            </h5>
                        </a>
                        <time class="activity-list__time">{{dateHelper.decodeDate(log.date)}}</time>
                    </div>
                </div>

                <div class="activity-list__content">
                    <h5 class="activity-list__title" :class="getActivityIconClass(log)">{{ log.title }}</h5>
                    <div class="activity-list__text" v-html="log.text"></div>
                </div>
            </li>
        </ul>

        <div v-if="!hasLogs && loaded" class="card card--empty card--transparent">
            <header class="card__header">
                <h4 class="heading4">No Startup Updates Yet</h4>
            </header>
        </div>

        <div class="loading__spinner">
            <span v-if="loading"><b-spinner class="mr-1" small></b-spinner>Loading...</span>
            <a v-if="hasMore && !loading" @click="getList">Load More</a>
        </div>

        <ScrolledBottom @scrolledBottom = scrolledBottom />
    </b-modal>

</template>

<script>
    import insightsService from "@/services/insights.service";
    import ScrolledBottom from "@/components/ScrolledBottom.vue";
    import FilterByTrackers from "@/components/widgets/FilterByTrackers.vue";

    export default {
        components: {FilterByTrackers, ScrolledBottom},
        data() {
            return {
                logs: [],
                maxDate: null,
                loading: false,
                hasMore: false,
                hasLogs: true,
                loaded: false,
                filter: {
                    trackers: [],
                },
            }
        },

        watch: {
            filter: {
                deep: true,
                handler() {
                    this.logs = []
                    this.getList()
                }
            }
        },

        methods: {
            open() {
                this.logs = []
                this.maxDate = null
                this.getList()
                this.$bvModal.show('startupsActionLogsModal')
            },

            getList() {
                this.loading = true
                let limit = 20

                insightsService.getStartupsActionLogs(this.maxDate, this.filter, limit).then(({data}) => {
                    this.loading = false
                    this.logs.push(...data.logs)
                    this.maxDate = data.max_date
                    this.hasMore = data.logs.length >= limit
                    this.loaded = true
                    this.hasLogs = !!this.logs.length
                })
            },

            getActivityIconClass(log) {
                return 'activity-list__title--' + log.type
            },

            scrolledBottom() {
                if (this.hasMore && this.logs.length) {
                    this.getList()
                }
            },

            trackersFilterChanged(trackersIds) {
                this.filter.trackers = trackersIds
            }
        }
    }
</script>

<style scoped>

</style>
