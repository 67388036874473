<template>
    <b-modal id="modal-edit-tracker-description" title="Add A Description To Your Tracker*" modal-class="modal-w-md">

        <p>Before you can share your tracker with an ecosystem you need to add a description that gives them context for
        your needs and the types of startups you're looking for</p>

        <b-form-textarea class="form-control--mid" v-model="description">

        </b-form-textarea>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canSave" @click="save">Save And Continue</b-button>
        </template>
    </b-modal>
</template>

<script>

export default {
    props: {
        id: Number,
    },

    data() {
        return {
            description: "",
        }
    },

    computed: {
        canSave() {
            return this.description.length
        },
    },

    methods: {

        open() {
            this.$bvModal.show('modal-edit-tracker-description')
        },

        save() {
            this.$emit('descriptionChanged', this.description)
            this.$bvModal.hide('modal-edit-tracker-description')
        }
    }
}
</script>
