<template>
    <b-modal title="Filter By Your Custom Fields" :id="'custom-data-filter-' + prefix" size="sm">
        <div>
            <p>Select the field you want to use, then the values you want to filter by.</p>
        </div>

        <custom-data-filter
            ref="customDataFilter"
            :init-filters="this.filters"
            :custom-fields-list="customFieldsList"
            :for-search="forSearch"
            v-on:updateCustomDataFilter="updateFilter"
        />

        <template #modal-footer>
            <b-button variant="primary" size="lg" @click="apply">Apply</b-button>
        </template>
    </b-modal>
</template>

<script>

import CustomDataFilter from "@/components/customFields/CustomDataFilter.vue";
import customFieldsService from "@/services/custom-fields.service";

export default {
    props: {
        prefix: {
            type: String,
            default: '',
        },

        forSearch: {
            type: Boolean,
            default: false,
        },

        customOptions: {
            type: Object,
            default() {
                return null
            } ,
        },
    },

    components: {
        CustomDataFilter,
    },

    data() {
        return {
            filters: [],
            customFieldsList: {},
        };
    },

    mounted() {
        if (this.customOptions) {
            this.customFieldsList = this.customOptions;
        } else {
            customFieldsService.getFields("company_diligence", 1).then(response => {
                this.customFieldsList = response.data
            });
        }
    },

    methods: {
        open() {
            this.$bvModal.show('custom-data-filter-' + this.prefix);
        },

        updateFilter(filters) {
            this.filters = filters;
        },

        apply() {
            this.$emit('updateCustomDataFilter', this.arrayHelper.cloneArray(this.filters));
            this.$bvModal.hide('custom-data-filter-' + this.prefix);
        },

        removeFilter(index) {
            this.filters.splice(index, 1);
        },

        removeFilterById(id) {
            let index = this.filters.findIndex(item => parseInt(item.id) === parseInt(id));

            if (index >= 0) {
                this.filters.splice(index, 1);
            }
        },

        clearFilters() {
            this.filters = [];
        }
    }
}
</script>

<style scoped>

</style>
