<template>
    <b-modal id="greeting-popup" modal-class="greeting-popup modal-w-967" title="Welcome to SwitchPitch!">
        <figure>
            <figcaption>
                <p>Enterprises are looking for your input for startup partnerships. Adding a greeting will make you stand out and explain why they should work with you.
                Set your greeting below.</p>
            </figcaption>
            <img :src="require('@/images/greeting.png')" alt="Enterprises are looking for your input for startup partnerships.">
        </figure>
        
        <p class="mb-3">Your message:</p>
        <b-form-textarea class="form-control--mid" v-model="form.greeting" placeholder="Introduce yourself and your portfolio to enterprises looking for ecosystems"></b-form-textarea>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!form.greeting.length" @click="save">Add Greeting</b-button>
        </template>
    </b-modal>

</template>

<script>
    import settings from "@/services/ecosystem/settings.service"

    export default {
        data() {
            return {
                form: {
                    greeting: "",
                }
            }
        },

        methods: {
            open() {
                this.$bvModal.show('greeting-popup')

                settings.setGreetingShown().then(() => {
                    this.$store.commit('setUserSettingsItem', {name: "greeting_shown", value: 1})
                })
            },

            save() {
                settings.saveGreeting(this.form)
                this.$bvModal.hide('greeting-popup')
            },
        }
    }
</script>

<style scoped>

</style>
