import Activity from '../views/activity/Activity'
import FieldsHistory from '../views/logs/FieldsHistory'
import ActionLogs from '../views/logs/ActionLogs'
import Logs from "@/views/logs/Logs";

export default [
    {
        path: '/activity',
        component: Activity,
        meta: {
            title: 'Activity'
        }
    },
    {
        path: '/logs/fields-history',
        component: FieldsHistory,
        meta: {
            onlyAdmin: true,
            title: 'Fields History'
        }
    },
    {
        path: '/logs/action-logs',
        component: ActionLogs,
        meta: {
            onlyAdmin: true,
            title: 'Action Logs'
        }
    },
    {
        path: '/logs',
        component: Logs,
        meta: {
            onlyAdmin: true,
            title: 'Activity'
        }
    },
]