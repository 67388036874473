<template>
    <b-modal id="modal-add-startups-to-portfolio" title="Add to portfolios" modal-class="modal-add-startups-to-portfolio modal-w-lg">
        <template v-if="noPortfolios">
            No portfolios to add
        </template>
        <template v-else>
            <ul class="add-to-portfolio-list">
                <li v-for="(portfolio, index) in portfoliosList" :key="index">
                    <b-form-checkbox class="input-checkbox" v-model="portfoliosList[index].checked">{{portfolio.name}}</b-form-checkbox>
                    <p>{{portfolio.description}}</p>
                </li>
            </ul>
        </template>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canAdd" @click="add">Add</b-button>
        </template>
    </b-modal>
</template>

<script>
import portfolios from "@/services/ecosystem/portfolios.service"
import Vue from "vue";

export default {
    props: {
        startups: Array
    },

    data() {
        return {
            portfoliosList: [],
            selectedPortfolios: [],
            noPortfolios: false,
        }
    },

    watch: {
        portfoliosList: {
            deep: true,
            handler() {
                this.selectedPortfolios = []

                this.portfoliosList.forEach(item => {
                    if (item.checked) {
                        this.selectedPortfolios.push(item.id)
                    }
                })
            }
        }
    },

    computed: {
        canAdd() {
            return this.selectedPortfolios.length
        }
    },

    methods: {
        getPortfoliosList(startupId) {
            this.portfoliosList = []

            portfolios.getListToAddStartup(startupId).then(response => {
                if (response.data.length) {
                    response.data.forEach(item => {
                        this.portfoliosList.push({
                            id: item.id,
                            name: item.name,
                            description: item.description,
                            checked: false,
                        })
                    })
                }
                else {
                    this.noPortfolios = true
                }
            })
        },

        open(startupId) {
            this.noPortfolios = false
            this.getPortfoliosList(startupId)
            this.$bvModal.show('modal-add-startups-to-portfolio')
        },

        add() {
            portfolios.addStartups(this.selectedPortfolios, this.startups).then((response) => {
                this.$emit('saved')
                this.$bvModal.hide('modal-add-startups-to-portfolio')

                let added = response.data.added
                let existed = response.data.existed

                let message = []
                if (added) {
                    message.push("Added to " + added + " " + this.stringHelper.getNoun('portfolio', added))
                }
                if (existed) {
                    message.push("Already existed in " + existed + " " + this.stringHelper.getNoun('portfolio', existed))
                }

                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 5000,
                    text: message.join('; ')
                })

            })
        },
    }
}
</script>

<style scoped>

</style>
