<template>
    <b-modal id="modal-add-analyst-hours" title="Purchase Analyst Hours" modal-class="modal-w-md">
        <div v-if="$store.state.user.is_admin" class="modal-sub">
            <div class="modal-sub__inner">
                <div v-show="!showPaymentForm" class="form__item mb-3">

                    <div class="input-radio input-radio--hours" v-for="(plan, index) in plans" :key="index">
                        <input type="radio" :value="plan.name" v-model="selectedPlan" :id="'plan-'+plan.name">
                        <label :for="'plan-'+plan.name">
                            <span class="input-radio--hours__input"></span>
                            <span class="time-badge"></span>
                            <p>
                                <b>{{ plan.title }}: {{plan.hours}} - ${{plan.price.toLocaleString()}}</b> (${{(plan.price / plan.hours)}}/hour)
                                <br/>
                                {{plan.description}}
                            </p>
                        </label>
                    </div>
                    
                    <div>

                    <h5 v-if="paymentMethods.length > 0" class="heading5 heading5--secondary">Payment</h5>
                    
                    <div v-if="loadingPaymentMethods">
                        <span><b-spinner class="mr-1" small></b-spinner>Loading...</span>
                    </div>

                    <template v-else>
                        <template v-if="paymentMethods.length">

                            <select v-model="paymentMethodId" class="selectbox selectbox-mid w-100">
                                <option v-for="method in this.paymentMethods" :key="method.id" :value="method.id">
                                    Card ending in {{method.last4}}
                                </option>
                            </select>
                        </template>
                    </template>

                    <p class="pt-3 text-right"><a @click="showPaymentForm = true" class="link">+ Add Payment Method</a></p>
                    </div>
                </div>

                <div v-show="showPaymentForm">
                    <a @click="showPaymentForm = false" class="link-back-black">Back</a>

                    <Payment
                        ref="payment"
                        v-on:paymentMethodCreated="paymentMethodAdded"
                        v-on:start-loading="loading = true"
                        v-on:stop-loading="loading = false"
                    >
                    </Payment>

                </div>
            </div>

            <div v-if="selectedPlan && !showPaymentForm" class="text-center">
                <b>Total ${{purchaseSum}}</b>
            </div>
        </div>
        <div v-else>
            <p> You are not authorized to purchase analyst hours.</p>
            <b-form-group label="Message your admin to let them know you need additional analyst support" :label-for="`request-input-message`">
                <b-form-textarea class="form-control--mid mb-3" v-model="message"></b-form-textarea>
            </b-form-group>


        </div>

        <template #modal-footer>
            <template v-if="$store.state.user.is_admin">
                <div v-if="!showPaymentForm">
                    <button v-if="!loading" class="button btn" :disabled="!selectedPlan || !paymentMethodId" @click="purchase">Purchase</button>
                    <span v-else><b-spinner class="mr-1" small></b-spinner>Purchasing in progress...</span>
                </div>

                <div v-else>
                    <button v-if="!loading" class="button" @click="addPaymentMethod">Add Payment Method</button>
                    <span v-else><b-spinner class="mr-1" small></b-spinner>Loading...</span>
                </div>
            </template>
            <template v-else>
                <b-button :disabled="!message" size="lg" variant="primary" @click="sendRequest">
                    Send Message
                </b-button>
            </template>
        </template>

    </b-modal>
</template>

<script>

import Payment from "@/components/Payment";
import analyst from "@/services/analyst.service";
import Vue from "vue";
import subscriptions from "@/services/subscriptions.service";

export default {
    components: {
        Payment,
    },

    data() {
        return {
            showPaymentForm: false,
            paymentMethods: [],
            selectedPlan: '',
            selectedPlanAmount: 0,
            paymentMethodId: 0,
            plans: [],
            loading: false,
            loadingPaymentMethods: false,
            message: '',
        }
    },
    mounted() {
        if ( this.$route.query['open_analyst_hours']) {
            this.open();
        }
    },

    watch: {
        showPaymentForm() {
            if (this.showPaymentForm) {
                this.$refs.payment.createForm()
            }
        }
    },

    computed: {
        purchaseSum() {
            if (this.selectedPlan) {
                let index = this.arrayHelper.findIndexByColumnValue(this.plans, 'name', this.selectedPlan)
                return this.plans[index].price.toLocaleString()
            }
            else {
                return 0
            }
        }
    },

    methods: {
        open() {
            this.getAnalystPlans()
            this.getPaymentMethods()
            this.$bvModal.show('modal-add-analyst-hours')
        },

        getAnalystPlans() {
            analyst.getPlans().then(response => {
                this.plans = response.data
            })
        },

        addPaymentMethod() {
            this.$refs.payment.apply()
        },

        getPaymentMethods() {
            this.loadingPaymentMethods = true

            subscriptions.getPaymentMethods().then(response => {
                this.loadingPaymentMethods = false
                this.paymentMethods = response.data

                if (this.paymentMethods.length && !this.paymentMethodId) {
                    this.paymentMethodId = this.paymentMethods[0].id
                }
            })
        },

        paymentMethodAdded(methodId) {
            this.loading = false
            this.showPaymentForm = false
            this.getPaymentMethods()
            this.paymentMethodId = methodId
        },

        purchase() {
            this.loading = true

            analyst.purchasePlan({plan: this.selectedPlan, payment_method_id: this.paymentMethodId}).then(response => {

                if (response.data.result === 'paid') {
                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 10000,
                        text: response.data.hours + " analyst hours successfully purchased"
                    })

                    this.$emit('analyst-hours-added')

                    this.$bvModal.hide('modal-add-analyst-hours')
                } else {
                    Vue.notify({
                        group: 'app',
                        type: 'error',
                        duration: 10000,
                        text: `Error occurred: ${response.data.message}`
                    })
                }
            }).finally(() => {
                this.loading = false
            })
        },
        async sendRequest() {
            let data = false
            let successMessage = ''


            const response = await analyst.requestAnalystHours({ message: this.message })
            data = response.data
            successMessage = 'Request Sent'


            if (data === true) {
                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 5000,
                    text: successMessage
                });
                this.message = ''
                this.$bvModal.hide('modal-add-analyst-hours');
            } else {
                Vue.notify({
                    group: 'app',
                    type: 'error',
                    duration: 5000,
                    text: 'Error. Message has not been sent.'
                });
            }
        }
    }
}
</script>
