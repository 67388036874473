import CompanyInformation from "@/views/settings/admin/CompanyInformation";
import Permissions from "@/views/settings/admin/Permissions";
import TrackerRoles from "@/views/settings/admin/TrackerRoles";
import FeatureSettings from "@/views/settings/admin/FeatureSettings";
import Account from "@/views/settings/admin/Account";
import DownloadCenter from "@/views/settings/admin/DownloadCenter.vue";

export default [
    {
        path: '/admin',
        component: Permissions,
        meta: {
            access: ['enterprise'],
            onlyAdmin: true,
            title: 'Admin'
        }
    },
    {
        path: '/admin/company-info',
        component: CompanyInformation,
        meta: {
            access: ['enterprise'],
            onlyAdmin: true,
            title: 'Company Information'
        }

    },
    {
        path: '/admin/permissions',
        component: Permissions,
        meta: {
            access: ['enterprise'],
            onlyAdmin: true,
            title: 'Permissions'
        }
    },
    {
        path: '/admin/tracker-roles',
        component: TrackerRoles,
        meta: {
            access: ['enterprise'],
            onlyAdmin: true,
            title: 'Tracker Roles'
        }
    },
    {
        path: '/admin/account',
        component: Account,
        meta: {
            access: ['enterprise'],
            onlyAdmin: true,
            title: 'Account'
        }
    },
    {
        path: '/admin/download-center',
        component: DownloadCenter,
        meta: {
            access: ['enterprise'],
            onlyAdmin: true,
            title: 'Download Center',
            accountAccess: ['premium'],
        }
    },
    {
        path: '/admin/feature-settings',
        component: FeatureSettings,
        meta: {
            access: ['enterprise'],
            onlyAdmin: true,
            title: 'Feature Settings',
            accountAccess: ['premium'],
        }
    },
]
