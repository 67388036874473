<template>
    <main class="enter-page">
        <header class="header">
            <span class="logo"></span>
        </header>

        <template v-if="sent">
            <div class="form form--enter">
                <header class="form--enter__header">
                    <h1 class="heading1">Success</h1>
                </header>
                <div class="form--enter__container mt-2">
                    <div class="form--enter__fieldset">
                        <p>Password has been successfully set.</p>
                        <div class="text-center">
                            <router-link tag="button" class="button mt-2" to="/">Log In</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
            <template v-if="validCode == false">
                <div class="form form--enter">
                    <header class="form--enter__header">
                        <h1 class="heading1">Set New Password</h1>
                    </header>
                    <div class="form--enter__container mt-2">
                        <div class="form--enter__fieldset">
                            <div class="form--enter__error text-center">
                                <span class="form__error" >Wrong set password url</span>
                            </div>
                            <div class="text-center mt-4">
                                <router-link tag="button" class="button" to="/">Log In</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="validCode == true">
                <form class="form form--enter" @submit.prevent="send">

                    <header class="form--enter__header justify-content-start">
                        <router-link class="link-back" to="/"></router-link>
                        <h1 class="heading1">Set New Password</h1>
                    </header>

                    <div class="form--enter__container">
                        <fieldset class="form--enter__fieldset">
                            <div v-for="error in errors" :key="error">
                                <span class="form__error">{{error}}</span>
                            </div>

                            <div class="form__item">
                                <label class="form__label">Password</label>
                                <input class="form__input" required v-model="password" type="password"/>
                            </div>

                            <div class="form__item">
                                <label class="form__label">Confirm Password</label>
                                <input class="form__input" required v-model="confirmPassword" type="password"/>
                            </div>

                            <div class="text-center">
                                <button class="button" type="submit" :disabled="!canSend">Reset</button>
                            </div>
                        </fieldset>
                    </div>
                </form>
            </template>
        </template>
    </main>
</template>

<script>

import auth from '../../services/auth.service';

export default {

    data () {
        return {
            validCode: null,
            code: this.$route.params.resetPasswordCode,
            password: "",
            confirmPassword: "",
            errors: [],

            sent: false,
        }
    },

    computed: {
        canSend() {
            return this.password.length && this.password == this.confirmPassword
        }
    },

    mounted() {
        auth.checkResetPasswordCode(this.code).then(response => {
            this.validCode = response.data
        })
    },

    methods: {
        send() {
            let data = {
                code: this.code,
                password: this.password,
                confirmPassword: this.confirmPassword,
            }

            auth.setPassword(data)
                .then(response => {
                    this.errors = response.data.errors
                    if (!this.errors.length) {
                        this.sent = true
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>