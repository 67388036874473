<template>
    <div>
        <div class="topbar">
            <div v-if="false" class="topbar__inner">
                <ul class="topbar__side">
                    <li>
                        <div class="mt-4">
                            <b-dropdown id="dropdown-1" text="Actions" class="topbar__side__actions">
                                <b-dropdown-item class="action">
                                    <font-awesome-icon :icon="['fa', 'file-pdf']" />
                                    <a @click="generateReport">Export PDF</a>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <main class="main main--details">
            <aside class="aside aside--details">
                <div class="aside__inner" v-if="loading.common">Loading...</div>
                <div class="aside__inner" v-if="!loading.common">
                    <div class="image-block image-block--lg">
                        <img :src="LogoHelper.getLogo(profile.logo_url)" alt="img"/>
                    </div>

                    <h2 class="heading2">{{ profile.name }}</h2>
                    <span class="enterprise-label mt-4">Enterprise</span>

                    <div class="aside--details__button">
                        <a v-if="profile.connect_status === 'none'" class="button button--link button--green" @click="handleConnect">
                            <template v-if="$store.getters.isEcosystem">
                                + Connect
                            </template>
                            <template v-else>
                                Request Connection
                            </template>
                        </a>

                        <template v-if="profile.connect_status === 'ecosystem-need-accept'">
                            <a class="button button--bordered--with--icon button--connect mb-3" @click="resolveConnectionRequest('accepted')">Accept Invite</a>
                            <a class="button button--full button--red mb-3" @click="resolveConnectionRequest('rejected')">Reject</a>
                        </template>
                    </div>

                    <div v-if="profile.short_description" class="aside--details__description mt-3">
                        <h4 class="heading4 heading4--tertiary">Summary</h4>
                        <p>{{ profile.short_description }} <a v-if="profile.description && (profile.short_description !== profile.description)" id="show-description" class="link">(more)</a></p>
                    </div>
                    <b-popover v-if="profile.short_description !== profile.description" ref="descriptionPopover" target="show-description">
                        <div class="popover__content" v-click-outside="closeDescriptionPopover">
                            {{ profile.description }}
                        </div>
                    </b-popover>

                    <h4 class="heading4 heading4--tertiary">Overview</h4>
                    <ul class="overview-list">
                        <li v-if="profile.employees">
                            <span class="overview-list__icon icon-employees--light"></span>
                            {{ profile.employees }} 
                            Employees
                        </li>

                        <li v-if="profile.website">
                            <a :href="profile.website" class="d-flex align-items-center" target="_blank">
                                <span class="overview-list__icon icon-globe"></span>
                                {{ profile.url_domain }}
                            </a>
                        </li>
                        <li v-if="location">
                            <span class="overview-list__icon icon-location--light"></span>
                            {{ location }}
                        </li>
                    </ul>

                    <template v-if="profile.categories?.length">
                        <h4 class="heading4 heading4--tertiary">
                            Categories
                            <a v-if="false" class="heading-edit"></a>
                        </h4>

                        <div class="tags-list tags-list--light">
                            <span v-for="category in profile.categories" :key="category.id" class="tags-list__tag" :title="category.name">{{ category.name }}</span>
                        </div>
                    </template>
                </div>
            </aside>
            <div class="content">
                <b-tabs class="tabs-primary" content-class="" v-model="currentTab">
                    <activities-tab v-if="$store.getters.isStartup" :company-id="id"></activities-tab>
                    <briefs-tab v-if="$store.getters.isEcosystem" :briefs="briefs" :loading="loading.briefs" :total="briefs.length"></briefs-tab>
                    <portfolios-tab v-if="$store.getters.isEcosystem" :portfolios="portfolios" :loading="loading.portfolios" :total="portfolios.length"></portfolios-tab>
                    <challenges-tab v-if="$store.getters.isStartup" :challenges="challenges" :loading="loading.challenges"></challenges-tab>
                </b-tabs>
            </div>
        </main>

        <add-connection
            ref="addEnterprise"
            v-on:connection-request-sent="getCommonData"
        />
    </div>
</template>

<script>
import BriefsTab from "@/views/enterprise/Tabs/Briefs";
import PortfoliosTab from "@/views/enterprise/Tabs/Portfolios";
import ChallengesTab from "@/views/enterprise/Tabs/Challenges";
import ActivitiesTab from "@/views/enterprise/Tabs/Activities";
import AddConnection from "@/components/connections/AddEnterpriseConnection";
import trackers from "@/services/trackers.service";
import portfoliosService from "@/services/ecosystem/portfolios.service";
import ecosystemEnterprisesService from "@/services/ecosystem/enterprises.service";
import startupEnterprisesService from "@/services/startup/enterprises.service";
import Vue from "vue";
import {mapMutations} from "vuex";

export default {
    components: {
        BriefsTab,
        AddConnection,
        PortfoliosTab,
        ChallengesTab,
        ActivitiesTab,
    },

    data() {
        return {
            id: +this.$route.params.id,
            briefs: [],
            profile: {},
            loading: {
                briefs: false,
                common: false,
                portfolios: false,
                challenges: false,
            },
            currentTab: 0,
            portfolios: [],
            challenges: [],
            fundingRounds: [],
            startChatWith: this.$route.query.start_chat_with,
        };
    },

    watch: {
        briefs() {
            this.currentTab = this.briefs.length === 0 ? 1 : 0;
        }
    },

    computed: {
        enterprisesService() {
            if (this.$store.getters.isStartup) {
                return startupEnterprisesService
            } else {
                return ecosystemEnterprisesService
            }
        },

        location() {
            const str = []

            if (this.profile.city) {
                str.push(this.profile.city)
            }

            if (this.profile.state) {
                str.push(this.profile.state)
            }

            if (this.profile.country) {
                str.push(this.profile.country)
            }

            if (this.profile.continent) {
                str.push(this.profile.continent)
            }

            return str.join(', ')
        }
    },

    mounted() {
        this.getCommonData();

        if (this.$store.getters.isEcosystem) {
            this.getBriefs();
            this.getPortfolios();
        }

        if (this.$store.getters.isStartup) {
            this.getChallenges();
        }

        if (this.startChatWith) {
            this.$store.watch(state => {
                if (state.messageCenter.roomsSynced) {
                    this.enterprisesService.getAvailableRecipients(this.id).then(response => {
                        let recipients = response.data.filter(item => parseInt(item.id) === parseInt(this.startChatWith));

                        if (recipients.length) {
                            this.addNewRoom(recipients)
                        }
                    })
                }
            })
        }
    },

    methods: {
        ...mapMutations(['addNewRoom']),

        getBriefs() {
            this.loading.briefs = true;

            trackers.getOpportunitiesIndex({
                submitted: true,
                notSubmitted: true,
                enterprise_id: this.id
            }).then(response => {
                this.briefs = response.data;
            }).finally(() => {
                this.loading.briefs = false;
            })
        },

        getCommonData() {
            this.loading.common = true;

            this.enterprisesService.getCommonData(this.id).then(response => {
                this.profile = response.data;
            }).finally(() => {
                this.loading.common = false;
            })
        },

        getPortfolios() {
            this.loading.portfolios = true;

            portfoliosService.getPortfoliosForEnterprise(this.id).then(response => {
                this.portfolios = response.data;
            }).finally(() => {
                this.loading.portfolios = false;
            })
        },

        getChallenges() {
            this.loading.challenges = true;

            trackers.getTrackersForApply({
                filter: {
                    enterprise_id: this.id
                }
            }).then((response) => {
                this.challenges = response.data;
            }).finally(() => {
                this.loading.challenges = false;
            })
        },

        handleConnect() {
            this.$refs.addEnterprise.open(this.id);
        },

        resolveConnectionRequest(status) {
            this.enterprisesService.setConnectionRequestStatus(this.id, status).then(response => {

                if (response.data) {
                    let [status, hasAccess] = response.data
                    this.profile.connect_status = status

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 3000,
                        text: "Invitation " + status
                    })

                    if (!hasAccess) {
                        this.$router.push('/')
                    }
                } else {
                    Vue.notify({
                        group: 'app',
                        type: 'error',
                        duration: 3000,
                        text: "Error occurred"
                    })
                }
            })
        },

        generateReport() {},

        closeDescriptionPopover() {
            this.$refs.descriptionPopover.hide();
        },
    },
}
</script>
