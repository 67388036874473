<template>
    <b-modal id="modal-invite-user" title="Invite User" modal-class="modal-w-md">
        <div class="form form-modal mb-4">
            <h5 class="heading5 heading5--secondary">
                Email
            </h5>
            <b-form-input class="form-control--mid" v-model="email"></b-form-input>
        </div>

        <template #modal-footer>
            <primary-button
                caption="Invite"
                :disabled="!canSave"
                :loading="loading"
                @click="invite"
            />
        </template>
    </b-modal>
</template>

<script>

import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import contactsService from "@/services/contacts.service";
import Vue from "vue";

export default {
    components: {
        PrimaryButton
    },

    data() {
        return {
            contactId: 0,
            email: '',
            loading: false,
        }
    },

    computed: {
        canSave() {
            return this.email.length && this.stringHelper.validEmail(this.email);
        }
    },

    methods: {
        open(contactId, email) {
            this.contactId = contactId;

            if (email) {
                this.email = email;
            } else {
                this.email = "";
            }

            this.$bvModal.show('modal-invite-user');
        },

        invite() {
            this.loading = true;

            contactsService.invite(this.contactId, this.email).then(() => {
                this.$bvModal.hide('modal-invite-user');
                this.$emit('invite-sent');

                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 5000,
                    text: "Invite Sent"
                })
            }).finally(() => {
                this.loading = false;
            })
        }
    }
}
</script>

<style scoped>

</style>
