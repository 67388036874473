<template>
    <div v-if="loaded">
        <div class="topbar">
            <div class="topbar__inner">
                <ul class="topbar__side">

                </ul>
            </div>
        </div>

        <main class="main main--details">
            <aside class="aside aside--details">
                <div class="aside__inner">
                    <div class="image-block image-block--lg">
                        <img :src="logoUrl" alt="img"/>
                    </div>
                    <h2 class="heading2">{{commonData.name}}</h2>

                    <div v-if="false" class="type-label">Buzzing</div>
                    
                    <div class="aside--details__description mt-3">
                        <span class="ecosystem-label">Ecosystem</span>
                        <p>{{ commonData.short_description }} <a
                            v-if="commonData.short_description != commonData.description" id="show-description"
                            class="link">(more)</a></p>
                    </div>

                    <b-popover v-if="commonData.short_description != commonData.description" ref="descriptionPopover"
                               target="show-description">
                        <div class="popover__content" v-click-outside="closeDescriptionPopover">
                            {{ commonData.description }}
                        </div>
                    </b-popover>
                    <template v-if="commonData.connect_status === 'none'">
                        <button v-if="$store.state.user.settings.enable_connections" class="button button--bordered--with--icon button--connect mb-3"
                                @click="$refs.inviteOrConnectEcosystem.process(id)">Connect
                        </button>
                    </template>
                    <div v-else-if="commonData.connect_status === 'enterprise-need-accept'" class="aside--details__button">
                        <a class="button button--bordered--with--icon button--connect mb-3" @click="resolveConnectionRequest('accepted')">Accept Invite</a>
                        <a class="button button--full button--red mb-3" @click="resolveConnectionRequest('rejected')">Reject</a>
                    </div>
                    <template v-else-if="commonData.has_account && commonData.connect_status === 'new'">
                        <button class="button button--bordered--with--icon button--connect--pending mb-3 w-100">
                            Connection Pending
                        </button>
                    </template>
                    <template v-else-if="commonData.has_account && commonData.connect_status === 'accepted'">
                        <button class="button button--bordered--with--icon button--connect--accepted mb-3">Connected
                        </button>
                    </template>

                    <template v-if="commonData.has_account && recipients.length && !$store.state.user.settings.disable_external_messaging">
                        <button class="button button--bordered--with--icon button--send_a_message mb-3" @click="$refs.startChat.open()">
                            Send A Message
                        </button>

                        <StartChatPopup
                            ref="startChat"
                            :recipients="recipients"
                        />
                    </template>

                    <h4 class="heading4 heading4--tertiary">
                        Overview
                        <a v-if="false" class="heading-edit"></a>
                    </h4>

                    <ul class="overview-list">
                        <li v-if="commonData.url_domain.length">
                            <a :href="'http://' + commonData.url_domain" class="d-flex align-items-center" target="_blank">
                                <span class="overview-list__icon icon-globe"></span>
                                {{ commonData.url_domain }}
                            </a>
                        </li>
                        <li v-if="commonData.total_raised > 0">
                            <span class="d-flex align-items-center">
                                <span class="overview-list__icon icon-funding icon-funding--light"></span>
                                $ {{ stringHelper.formatNumber(commonData.total_raised) }}
                            </span>
                        </li>
                        <li v-if="commonData.revenue_range">
                            <span class="overview-list__icon icon-income icon-income--light"></span>
                            {{ commonData.revenue_range }} Annual Income
                        </li>
                        <li v-if="false && commonData.employees">
                            <span class="overview-list__icon icon-employees--light"></span>
                            {{ commonData.employees }} Employees
                        </li>
                        <li>
                            <span class="overview-list__icon icon-location--light"></span>
                            {{ location }}
                        </li>
                        <li v-if="commonData.founded">
                            <span class="overview-list__icon icon-founded icon-founded--light"></span> 
                            Founded
                            {{ commonData.founded }}
                        </li>
                        <li v-if="commonData.start_date || commonData.end_date">
                            <span class="overview-list__icon icon-schedule icon-schedule--light"></span>
                            <span v-if="commonData.start_date">{{ dateHelper.decodeDate(commonData.start_date) }}</span>
                            - <span v-if="commonData.end_date">{{ dateHelper.decodeDate(commonData.end_date) }}</span>
                        </li>
                    </ul>

                    <ul class="social-list">
                        <li v-if="commonData.linkedin_url">
                            <a :href="commonData.linkedin_url">
                                <font-awesome-icon :icon="['fab', 'linkedin-in']"/>
                            </a>
                        </li>
                        <li v-if="commonData.twitter_url">
                            <a :href="commonData.twitter_url">
                                <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 448 512">
                                    <path fill="#353535" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z"/>
                                </svg>
                            </a>
                        </li>
                        <li v-if="commonData.facebook_url">
                            <a :href="commonData.facebook_url">
                                <font-awesome-icon :icon="['fab', 'facebook-square']"/>
                            </a>
                        </li>
                    </ul>

                    <a class="copy-link" @click="copyProfileLink">Copy profile link</a>

                    <template v-if="commonData.categories.length">
                        <h4 class="heading4 heading4--tertiary">
                            Categories
                            <a v-if="false" class="heading-edit"></a>
                        </h4>

                        <div class="tags-list tags-list--light">
                            <span v-for="category in commonData.categories" :key="category.id" class="tags-list__tag"
                                  :title="category.name">{{ category.name }}</span>
                        </div>
                    </template>

                    <p v-if="commonData.crunch_status">
                        Data provided by Crunchbase <br>
                        Updated {{ dateHelper.decodeDate(commonData.last_crunchbase_sync_date) }}
                    </p>
                    <p v-else>
                        Data provided by SwitchPitch
                    </p>

                    Data incorrect? <a class="link" @click="$refs.errorReport.open()">Contact us</a>

                    <!--                    <a class="link" href="#">More</a>-->
                    <template v-if="commonData.website_keywords.length">
                        <div class="accordion mt-4">
                            <header class="accordion__header" v-b-toggle.accordion-1>
                                <h4 class="accordion__title heading4 heading4--tertiary mb-0">Website Keywords</h4>
                            </header>
                            <b-collapse id="accordion-1" accordion="my-accordion">
                                <div class="tags-list tags-list--light">
                                    <div v-for="keyword in commonData.website_keywords" :key="keyword"
                                         class="tags-list__tag" :title="keyword">
                                        <v-clamp autoresize :max-lines="1">{{ keyword }}</v-clamp>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                    </template>
                </div>
            </aside>

            <div class="content">
                <b-tabs class="tabs-primary" content-class="">
                    <b-tab active>
                        <template #title>
                            Startups
                            <small v-if="areThereStartups !== null">{{ totalRowsCount }} total</small>
                        </template>

                        <div class="tabs-primary__inner">

                            <div v-if="areThereStartups" class="topbar--ecosystem">
                                <div class="topbar--ecosystem__inner">
                                    <input v-model="startupsQuery" placeholder="Search by Name and Description"
                                           type="text" class="form-search-primary">

                                    <div class="topbar__multiselect">
                                        <multiselect
                                            v-model="selectedStartupsCategories"
                                            :multiple="true"
                                            placeholder="Select categories"
                                            :options="startupsCategories"
                                            label="name"
                                            track-by="value"
                                            class="multiselect-mid pl-2"
                                        />
                                    </div>

                                    <button class="button button--bordered--with--icon button--open-in"
                                            @click="openInSearch">Open in Search
                                    </button>
                                </div>
                            </div>
                            <div v-if="areThereStartups === false" class="no-data-message">
                                <h4 class="heading4">No Startups</h4>
                                <p>We don’t currently have any startup<br/> information for {{ this.commonData.name }}.
                                </p>
                            </div>
                        <!--</div>-->
<!--<div class="tabs-primary__inner">-->
<div class="main-table-scroll">
                            <template v-if="areThereStartups">
                                <Grid
                                    v-if="startups.length"
                                    :columns="startupColumns"
                                    :data="startups"
                                    :page-length="parseInt(pageLength)"
                                    :total-rows-count="totalRowsCount"
                                    :sort="sort"
                                    :currentPage="currentPage"
                                    :addCellData="addCellData"
                                    v-on:changePage="changePage"
                                />
                                <div v-else class="no-data-message">
                                    <h4 class="heading4">No Startups</h4>
                                    <p>Try changing your search criteria</p>
                                </div>
                            </template>
</div>
                        </div>
                    </b-tab>
                    <b-tab :active="showActivityTab">
                        <template #title>
                            Activity
                            <small>{{ activities.length }} updates</small>
                        </template>

                        <div class="form-add activity-notes-section">
                            <div class="activity-notes-list">
                                <a @click="interactionType = 'meeting'" class="activity-notes-list__meeting"
                                   :class="{active: interactionType == 'meeting'}">Meeting</a>
                                <a @click="interactionType = 'email'" class="activity-notes-list__email"
                                   :class="{active: interactionType == 'email'}">Email</a>
                                <a @click="interactionType = 'phone_call'" class="activity-notes-list__call"
                                   :class="{active: interactionType == 'phone_call'}">Call</a>
                                <a @click="interactionType = 'note'" class="activity-notes-list__note"
                                   :class="{active: interactionType == 'note'}">Note</a>
                            </div>

                            <div class="activity-notes-section__form">
                                <AddNote
                                    v-if="interactionType == 'note'"
                                    companyType="ecosystem"
                                    :companyId="id"
                                    :usersMentionList="usersMentionList"
                                    v-on:saved="activitySaved"
                                >
                                </AddNote>

                                <AddInteraction
                                    v-if="['phone_call','email','meeting'].includes(interactionType)"
                                    :type="interactionType"
                                    companyType="ecosystem"
                                    :companyId="id"
                                    :usersMentionList="usersMentionList"
                                    v-on:saved="activitySaved"
                                >
                                </AddInteraction>
                            </div>
                        </div>

                        <div class="accordion accordion--secondary accordion--tabs">
                            <div class="accordion__header-wrapper">
                                <div class="accordion__header" v-b-toggle.collapse-activities-list>
                                    <h4 class="heading4 accordion__title">All Activity</h4>
                                </div>

                                <div class="tasks__select"
                                     v-click-outside="function() {openInteractionOrderSelect = false}">
                                    <div @click="openInteractionOrderSelect = true" class="tasks__select__chosen">
                                        <span
                                            :title="interactionOrderTypes[interactionOrder]">{{ interactionOrderTypes[interactionOrder] }}</span>
                                    </div>
                                    <ul v-show="openInteractionOrderSelect" class="multiselect__content">
                                        <li class="multiselect__element" v-for="(name, value) in interactionOrderTypes"
                                            :key="value"
                                            @click="interactionOrder = value; openInteractionOrderSelect = false; getActivities()">
                                            {{ name }}
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <activities-list
                                ref="activitiesList"
                                :activities="activities"
                                v-on:edit="editActivity"
                                v-on:remove="removeActivity"
                            />
                        </div>
                    </b-tab>

                    <b-tab v-if="$store.state.accountType !== 'free'">
                        <template #title>
                            Trackers
                            <small>{{ trackers.length }} {{ stringHelper.getNoun('tracker', trackers.length) }}</small>
                        </template>

                        <div class="tabs-primary__inner">

                            <header class="main-inner__header text-right mb-2">
                                <a class="link link--xl" @click="openAddToTrackers">+ Add To A Tracker</a>
                            </header>
<!--<div class="main-table-scroll">-->
                            <table class="main-table" v-if="trackers.length">
                                <thead>
                                    <tr>
                                        <th>Tracker Name</th>
                                        <th class="main-table__auto">Description</th>
                                        <th class="main-table__auto">Ecosystems</th>
                                        <th>Permissions</th>
                                        <th class="main-table__auto">Last Modified</th>
                                        <th class="main-table__auto">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="tracker in trackers" :key="tracker.id">
                                        <td><a :href="'/trackers/' + tracker.id">{{ tracker.name }}</a></td>
                                        <td>
                                            <div class="line-clamp line-clamp4" v-html="tracker.description"></div>
                                        </td>
                                        <td>{{ tracker.ecosystems_count }}</td>
                                        <td>
                                            <b-dropdown class="dropdown--table" variant="link"
                                                        toggle-class="text-decoration-none" no-caret>
                                                <template #button-content>
                                                    <div class="section-users flex-nowrap mr-2">
                                                        <div class="section-users__images">
                                                            <div v-for="(permission, index) in tracker.permissions"
                                                                 :key="index" class="section-users__images__item">
                                                                <img
                                                                    v-if="permission.type == 'user' && permission.avatar.length"
                                                                    :src="permission.avatar" alt=""/>
                                                            </div>
                                                        </div>
    
                                                        <p>
                                                            <a v-for="(item, index) in stringHelper.getPermissionNamesForDisplay(tracker.permissions)"
                                                               :key="index">
                                                                {{ item }}
                                                            </a>
                                                        </p>
                                                    </div>
                                                </template>
    
                                                <b-dropdown-header>
                                                    <header class="dropdown__header-inner">
                                                        <h4 class="heading4">Users in {{ tracker.name }}</h4>
    
                                                        <a v-if="false" href="#" class="link link--lg">Edit</a>
                                                    </header>
    
                                                    <ul class="rows-list">
                                                        <li v-for="(permission, index) in tracker.permissions" :key="index">
                                                            <div class="rows-list__avatar">
                                                                <Avatar
                                                                    :username="permission.name"
                                                                    :src="permission.avatar"
                                                                    :size="30">
                                                                </Avatar>
                                                            </div>
    
                                                            <h5 class="heading5 heading5--secondary">{{
                                                                    permission.name
                                                                }}</h5>
    
                                                            <p class="ml-auto">{{ permission.role.name }}</p>
                                                            <!--<a class="link-info" href="#">
                                                                <span class="link-info__content">Can view and edit all information, and can delete and archive items</span>
                                                            </a>-->
                                                            <a class="link-info" v-b-popover.hover.top="permission.role.description" title="" hideArrow={true}></a>
                                                        </li>
                                                    </ul>
                                                </b-dropdown-header>
                                            </b-dropdown>
                                        </td>
                                        <td>
                                            {{ tracker.last_modified }}
                                        </td>
                                        <td>
                                            <div class="card flex-row" v-if="tracker.user_can_manage_ecosystems">
                                                <b-dropdown
                                                    class="ml-5 drag-n-drop icons-list dropdown-simple dropdown-small"
                                                    boundary="viewport">
                                                    <b-dropdown-item class="icons-list__delete2"
                                                                     @click="removeFromTracker(tracker.id)">
                                                        Remove From Tracker
                                                    </b-dropdown-item>
                                                </b-dropdown>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
<!--</div>-->
                            <AddToTrackers
                                ref="addToTrackers"
                                :trackers-list="otherTrackersList"
                                :exclude-trackers-list="trackers"
                                v-on:trackersSelected="addToTrackers"
                            >
                            </AddToTrackers>
                        </div>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            Contacts
                            <small>{{ contactsTotalCount }} total</small>
                        </template>
                        <div class="tabs-primary__inner tabs-primary__inner--nooverflow">
                            <div class="d-flex align-items-center justify-content-between flex-wrap mb-4">
                                <div class="form-search form-search--mid mb-3 mb-sm-0">
                                    <input v-if="contactsTotalCount > 0" type="text" v-model="contactsSearch" v-debounce:500="updateContactsSearch"
                                           placeholder="Search by name or title"/>
                                </div>
                                <a @click="addContact" class="link link--xl">+ Add A Contact</a>
                            </div>

                            <ul v-if="contactsTotalCount > 0" class="list-contacts">
                                <li v-for="contact in contacts" :key="contact.id" @click="showContactInfo(contact.id)">
                                    <div class="card card--bordered card--centered"
                                         :class="{'card--rated': contact.is_primary > 0}">

                                        <b-dropdown v-if="contact.can_edit || !contact.custom"
                                            class="drag-n-drop icons-list dropdown-simple dropdown-small position-absolute"
                                            boundary="viewport">
                                            <b-dropdown-item v-if="contact.custom && contact.can_edit"
                                                             class="icons-list__delete2"
                                                             @click.stop="deleteContact(contact.id)">
                                                Delete Contact
                                            </b-dropdown-item>

                                            <b-dropdown-item v-if="!contact.custom" class="icons-list__edit"
                                                             @click.stop="editContact(contact.id)">
                                                Edit Contact
                                            </b-dropdown-item>
                                        </b-dropdown>

                                        <div class="image-block image-block--rounded--lg">
                                            <Avatar
                                                :username="contact.name"
                                                :src="contact.avatar"
                                                :size="75"
                                            >
                                            </Avatar>
                                        </div>
                                        <h5 class="heading5">{{ contact.name }}</h5>
                                        <p>{{ contact.job_title }}</p>
                                    </div>
                                </li>
                                <div v-if="contacts.length == 0" class="no-data-message no-data-message--contact">
                                    <h4 class="heading4">No Contacts</h4>
                                    <p>Try changing your search criteria.</p>
                                </div>
                            </ul>

                            <div v-else class="no-data-message no-data-message--contact">
                                <h4 class="heading4">No Contacts</h4>
                                <p>We don’t currently have any contact information for {{ this.commonData.name }}.</p>
                            </div>

                            <edit-contact
                                ref="editContact"
                                v-on:contactSaved="getContacts"
                            >
                            </edit-contact>
                        </div>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            News
                            <small>{{ news.length }} total</small>
                        </template>

                        <div class="tabs-primary__inner">
                            <ul v-if="news.length > 0" class="list-news">
                                <li v-for="newsItem in news" :key="newsItem.id">
                                    <div class="list-news__date">
                                        {{ dateHelper.decodeDate(newsItem.posted_on, ['month-str']) }}
                                    </div>
                                    <div>
                                        <h5 class="heading5"><a :href="newsItem.url" target="_blank">{{
                                                newsItem.title
                                            }}</a></h5>
                                        {{ newsItem.domain }}
                                        <!--<p><time></time></p>-->
                                    </div>
                                </li>
                            </ul>

                            <div v-else class="no-data-message no-data-message--news">
                                <h4 class="heading4">No News</h4>
                                <p>We don't currently have any news<br/> articles for {{ this.commonData.name }}.</p>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            Tasks
                            <small>{{ tasksCount.active }} active, {{ tasksCount.done }} done</small>
                        </template>

                        <div class="tabs-primary__inner">
                            <TasksList
                                ref="tasksList"
                                :search="taskSearch"
                                :show-completed="tasksShowCompleted"
                                :ecosystem-id="id"
                                v-on:listUpdated="tasksListUpdated"
                            >
                            </TasksList>
                        </div>
                    </b-tab>

                    <b-tab v-if="portfolios.length > 0">
                        <template #title>
                            Related
                            <small>{{ portfolios.length }} portfolios</small>
                        </template>

                        <div class="tabs-primary__inner">
                            <template v-if="portfolios.length">
                                <h2 class="heading2 mb-4">Shared Portfolios</h2>

                                <div class="main-table-scroll">
                                    <table class="main-table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Shared on</th>
                                                <th>Startups</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(portfolio, index) in portfolios" :key="index">
                                                <td>
                                                    <router-link :to="'/portfolios/' + portfolio.id">{{ portfolio.name }}
                                                    </router-link>
                                                </td>
                                                <td>
                                                    {{ dateHelper.decodeDate(portfolio.shared_on) }}
                                                </td>
                                                <td>
                                                    {{ portfolio.startups_count }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </template>
                        </div>
                    </b-tab>

                </b-tabs>
            </div>
        </main>

        <EditNote
            ref="editNote"
            company-type="ecosystem"
            :company-id="id"
            v-on:saved="activitySaved"
        >
        </EditNote>

        <EditInteraction
            ref="editInteraction"
            company-type="ecosystem"
            v-on:saved="activitySaved"
        >
        </EditInteraction>

        <ErrorReport
            ref="errorReport"
            :ecosystem-id="id"
        >
        </ErrorReport>

        <invite-or-connect-ecosystem
            ref="inviteOrConnectEcosystem"
            @inviteSent="getData()"
            @connectionEstablished="getData()"
        ></invite-or-connect-ecosystem>
    </div>
</template>

<script>

import ecosystems from "@/services/ecosystems.service"
import VClamp from "vue-clamp"
import Avatar from "vue-avatar"
import editContact from "@/components/contacts/EditContact"
import actionLogs from "@/services/action-logs.service"
import Grid from "@/components/Grid";
import grid from "@/mixins/grid";
import tags from "@/services/tags.service";
import EditNote from "@/components/notes/EditNoteModal";
import EditInteraction from "@/components/interactions/EditInteractionModal";
import contacts from "@/services/contacts.service";
import TasksList from "@/components/tasks/List";
import ErrorReport from "@/components/AddErrorReport";
import team from "@/services/team.service";
import AddNote from "@/components/notes/EditNoteInline";
import AddInteraction from "@/components/interactions/EditInteractionInline";
import {copyProfileLink} from "@/helpers";
import AddToTrackers from "@/components/manage/SelectTrackers.vue";
import {mapGetters} from "vuex";
import trackersCompanies from "@/services/trackers-companies.service";
import Vue from "vue";
import ActivitiesList from "@/components/activity/ActivitiesList.vue";

export default {
    components: {
        ActivitiesList,
        AddToTrackers,
        VClamp,
        Avatar,
        editContact,
        Grid,
        EditNote,
        EditInteraction,
        ErrorReport,
        TasksList,
        AddNote,
        AddInteraction,
        StartChatPopup: () => import("@/views/messages/StartChatPopup"),
        InviteOrConnectEcosystem: () => import("@/components/ecosystem/InviteOrConnectEcosystem"),
        multiselect: () => import("vue-multiselect"),
    },

    mixins: [grid],

    data() {
        return {
            id: parseInt(this.$route.params.id),
            loaded: false,
            news: [],
            commonData: {},
            contacts: [],
            contactsTotalCount: 0,
            contactsSearch: "",
            activities: [],
            trackers: [],

            startups: [],
            startupsCategories: [],
            selectedStartupsCategories: [],
            startupsQuery: "",

            areThereStartups: null,
            portfolios: [],
            newNote: "",
            tasksCount: {
                active: 0,
                done: 0,
            },
            taskSearch: "",
            tasksShowCompleted: false,
            startupColumns: [
                {
                    label: 'Company',
                    name: 'name',
                    component: 'SearchCompanyNameColumn',
                },
                {
                    label: 'Location',
                    name: 'location',
                    component: 'SearchCompanyLocationColumn',
                },
                {
                    label: 'Category',
                    name: 'category',
                }
            ],

            getGridDataMethodName: 'getStartupsList',

            addCellData: {
                companyType: "startups",
                tagsList: [],
                trackersList: [],
            },

            interactionOrder: 'created_at',
            openInteractionOrderSelect: false,
            interactionOrderTypes: {
                'created_at': 'Date added',
                'interaction_date': 'Interaction date',
            },

            showActivityTab: false,
            usersMentionList: [],
            mentionApplied: false,

            interactionType: 'meeting',

            recipients: [],
        }
    },

    mounted() {
        if (this.$route.query.activity) {
            this.showActivityTab = true
        }

        this.getData()
        this.updateList()
        this.getTagsList()

        this.getUsersMentionList()

        this.getStartupsCategoriesList()
    },

    watch: {
        startupsQuery() {
            this.getStartupsList()
        },

        selectedStartupsCategories() {
            this.getStartupsList()
        },
    },

    computed: {
        ...mapGetters({trackersList: 'ecosystemTrackersList'}),

        logoUrl() {
            if (this.commonData.logo_url) {
                return this.commonData.logo_url
            } else {
                return require('@/images/' + this.defaults.startupLogo)
            }
        },

        location() {
            let str = []

            if (this.commonData.city) {
                str.push(this.commonData.city)
            }

            if (this.commonData.state) {
                str.push(this.commonData.state)
            }

            if (this.commonData.country) {
                str.push(this.commonData.country)
            }

            if (this.commonData.continent) {
                str.push(this.commonData.continent)
            }

            return str.join(', ')
        },

        otherTrackersList() {
            let array = []
            let existed = this.arrayHelper.arrayColumn(this.trackers, 'id')
            this.trackersList.forEach(item => {
                if (existed.indexOf(item.value) < 0) {
                    array.push(item)
                }
            })

            return array
        },
    },

    methods: {
        getData() {
            ecosystems.getCommonData(this.id).then(response => {
                this.commonData = response.data
                document.title = this.commonData.name + ' | SwitchPitch'
                this.fundingRounds = this.commonData.funding_rounds
                this.loaded = true
            })

            ecosystems.getNews(this.id).then(response => {
                this.news = response.data
            })

            ecosystems.getSharedPortfolios(this.id).then(response => {
                this.portfolios = response.data
            })

            this.getContacts()
            this.getActivities()
            this.getTrackers()
            this.getAvailableRecipients()
        },

        getStartupsList() {
            let columns = this.arrayHelper.arrayColumn(this.startupColumns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let query = this.startupsQuery
            let categories = this.selectedStartupsCategories.map(category => category.id)

            ecosystems.getStartups(this.id, {columns, currentPage, pageLength, query, categories}).then(response => {
                this.startups = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page

                if (!query) {
                    this.areThereStartups = !!this.totalRowsCount
                }
            })
        },

        getStartupsCategoriesList() {
            ecosystems.getStartupsCategories(this.id).then(response => {
                this.startupsCategories = response.data
            })
        },

        getTagsList() {
            tags.getList().then(response => {
                this.addCellData.tagsList = response.data
            })
        },

        updateContactsSearch() {
            ecosystems.getContacts(this.id, this.contactsSearch).then(response => {
                this.contacts = response.data
            })
        },

        getContacts() {
            ecosystems.getContacts(this.id).then(response => {
                this.contacts = response.data
                this.contactsTotalCount = this.contacts.length
            })
        },

        getAvailableRecipients() {
            ecosystems.getAvailableRecipients(this.id).then(response => {
                this.recipients = response.data
            })
        },

        getActivities() {
            ecosystems.getActivities(this.id, this.interactionOrder).then(response => {
                this.activities = response.data
            })
        },

        getTrackers() {
            ecosystems.getTrackers(this.id).then(response => {
                this.trackers = response.data
            })
        },

        openAddToTrackers() {
            this.$refs.addToTrackers.open()
        },

        addToTrackers(addedTrackers) {
            let trackersIds = this.arrayHelper.arrayColumn(addedTrackers, 'id')
            trackersCompanies.addCompanies(trackersIds, [this.id]).then(() => {
                this.getTrackers()
            })
        },

        removeFromTracker(trackerId) {
            trackersCompanies.removeCompanies(trackerId, [this.id]).then(() => {
                this.getTrackers()
            })
        },

        closeDescriptionPopover() {
            this.$refs.descriptionPopover.$emit('close')
        },

        showContactInfo(id) {
            this.$refs.editContact.open(id)
        },

        addContact() {
            this.$refs.editContact.open(0, 0, this.id)
        },

        tasksListUpdated(counts) {
            this.tasksCount = counts
        },

        addNote() {
            if (this.mentionApplied) {
                this.$nextTick(() => {
                    this.mentionApplied = false
                })
            } else if (this.newNote.length) {
                ecosystems.saveNote(this.id, 0, {text: this.newNote}).then(() => {
                    this.activitySaved()
                })
            }
        },

        addInteraction() {
            this.$refs.editInteraction.open(0, 'ecosystem', this.id, this.newNote)
        },

        removeActivity(activity) {
            if (confirm("Remove activity?")) {
                actionLogs.removeActivity(activity.log_id).then(() => {
                    this.getActivities()
                })
            }
        },

        editActivity(activity) {
            if (activity.object_type === 'ecosystem-note') {
                this.$refs.editNote.open(activity.object_id)
            } else if (activity.object_type === 'interaction') {
                this.$refs.editInteraction.open(activity.object_id)
            }
        },

        activitySaved() {
            this.getActivities()
        },

        deleteContact(id) {
            if (confirm("Delete contact?")) {
                contacts.delete(id).then(() => {
                    this.getContacts()
                })
            }
        },

        editContact(id) {
            this.$refs.editContact.open(id, 0, 0, true)
        },

        getUsersMentionList() {
            team.getUsersMentionList().then(response => {
                this.usersMentionList = response.data
            })
        },

        copyProfileLink: copyProfileLink('ecosystems'),

        openInSearch() {
            let url = "/startups?ecosystem=" + encodeURIComponent(this.commonData.name)
            window.open(url, '_blank')
        },

        resolveConnectionRequest(status) {
            ecosystems.setConnectionRequestStatus(this.id, status).then(response => {

                if (response.data) {
                    let [status, hasAccess] = response.data
                    this.commonData.connect_status = status

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 3000,
                        text: "Invitation " + status
                    })

                    if (!hasAccess) {
                        this.$router.push('/')
                    }
                } else {
                    Vue.notify({
                        group: 'app',
                        type: 'error',
                        duration: 3000,
                        text: "Error occurred"
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
    .link-info {
        padding: 0;
        background-color: transparent !important;
        border: none;
    }

    .popover {
        top: 50px !important;
        left: auto !important;
        margin: auto !important;
        max-width: 160px !important;
        padding: 5px 10px;
        background: #fff;
        border-radius: 4px;
        color: #7b7b7b !important;
        filter: drop-shadow(1px 3px 4px rgba(#000, 0.3));
        box-shadow: none !important;
        font-size: 10px !important;
        font-weight: 400;
        line-height: 1.5;
    }
    .popover.bs-popover-bottom,
	.popover.bs-popover-top {
		margin-left: 0 !important;
        left: 0 !important;
	}
    .popover.bs-popover-bottom .arrow {
        display: none !important;
        opacity: 0;
        visibility: hidden !important;
    }
    div.popover.b-popover .arrow {display: none !important; visibility:hidden !important;}
</style>
