import Impersonate from '../views/users/Impersonate'

export default [
    {
        path: '/impersonate/:adminToken/:token',
        component: Impersonate,
        meta: {
            guest: true
        }
    },
]