const scroll = {
    data() {
        return {
            scrolled: false,
        }
    },

    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },

    watch: {
        pageLength() {
            this.getList()
        },
    },

    methods: {
        handleScroll() {
            let header = document.getElementsByClassName('scroll-anchor')

            if (header.length) {
                let offset = header[0].getBoundingClientRect().top

                if (offset < 0) {
                    this.scrolled = true;
                }
                else {
                    this.scrolled = false;
                }
            }
            else {
                this.scrolled = false
            }
        }
    }
}

export default scroll
