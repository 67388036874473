
const clickOutsideWithExclusion = {
    bind (el, binding, vnode) {
        el.clickOutsideWithExclusionEvent = (e) => {
            e.stopPropagation()
            const { handler, exclude } = binding.value
            let clickedOnExcludedEl = false
            exclude.forEach(refName => {
                if (!clickedOnExcludedEl) {
                    const excludedEl = vnode.context.$refs[refName] ? (vnode.context.$refs[refName].$el || vnode.context.$refs[refName]) : false

                    if (excludedEl) {
                        if (Array.isArray(excludedEl)) {
                            if (excludedEl.length) {
                                // remove style(display: none) for correct comparison
                                const style = excludedEl[0].getAttributeNode("style")
                                if (style) {
                                    excludedEl[0].removeAttributeNode(style)
                                }

                                clickedOnExcludedEl = excludedEl[0].toString() === e.target.toString()
                            }
                        } else {
                            clickedOnExcludedEl = excludedEl.contains(e.target)
                        }
                    }
                }
            })

            if (!el.contains(e.target) && !clickedOnExcludedEl) {
                vnode.context[handler]()
            }
        }

        document.addEventListener('click', el.clickOutsideWithExclusionEvent)
        document.addEventListener('touchstart', el.clickOutsideWithExclusionEvent)
    },
    unbind (el) {
        document.removeEventListener('click', el.clickOutsideWithExclusionEvent)
        document.removeEventListener('touchstart', el.clickOutsideWithExclusionEvent)
    }
};

export default clickOutsideWithExclusion
