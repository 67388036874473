import axios from '@/axios';

const RESOURCE_NAME = '/tasks';

export default {

    getEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}`)
    },

    save(id, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}`, data)
    },

    getIndex(type, startupId, trackerId, ecosystemId, data) {
        return axios.post(`${RESOURCE_NAME}/get-index?type=${type}&startup_id=${startupId}&tracker_id=${trackerId}&ecosystem_id=${ecosystemId}`, data)
    },

    delete(id) {
        return axios.post(`${RESOURCE_NAME}/delete-task?id=${id}`)
    },

    update(id, data) {
        return axios.post(`${RESOURCE_NAME}/update-task?id=${id}`, data)
    },

    getUsersList(trackerId = 0) {
        return axios.get(`${RESOURCE_NAME}/get-users-list?tracker_id=${trackerId}`)
    },
}
