export default {
    namespaced: true,
    state: {
        defaultLayout: [
            'dashboard',
            'tasks',
            'startups',
            'searches',
            'trackers',
            'users',
        ],
    },
    mutations: {
        changeLayout(state, layout) {
            this.state.user.settings.homepage = layout
        },
    },
    getters: {
        layout: (state, getters, rootState) => {
            if (rootState.user.id && !Array.isArray(rootState.user.settings.homepage)) {
                if (rootState.accountType === 'free') {
                    rootState.user.settings.homepage = state.defaultLayout.filter(item => !['searches','tasks'].includes(item))
                }
                else {
                    rootState.user.settings.homepage = [...state.defaultLayout]
                }
            }

            return rootState.user.settings.homepage
        },
    },
}
