<template>
    <div>
        <div v-show="form.payment_method == 'card'">
            <div class="mb-3">
                <div class="form__item mb-2">
                    <h5 class="heading5 heading5--secondary">Card information</h5>

                    <input class="form-control--mid form-control" type="text" v-model="form.name_on_card" placeholder="Full name on card">
                    <span class="modal-upgrade__error" v-if="typeof errors.name_on_card !== 'undefined'">{{errors.name_on_card}}</span>
                </div>

                <div class="form__item mb-2">
                    <div id="card-number"></div>
                </div>

                <div class="mb-2">
                    <b-row>
                        <b-col cols="6">
                            <div id="card-cvc"></div>
                        </b-col>
                        <b-col cols="6">
                            <div id="card-expiry"></div>
                        </b-col>
                    </b-row>
                </div>

                <span class="modal-upgrade__error" v-if="cardError != ''">{{cardError}}</span>
            </div>
        </div>

        <div v-if="typeof errors.stripe != 'undefined'">{{errors.stripe}}</div>

        <template v-if="form.payment_method == 'bank'">
            <div class="mb-3">
                <div class="form__item mb-2">
                    <h5 class="heading5 heading5--secondary">Bank Information</h5>

                    <input class="form-control--mid form-control" type="text" v-model="form.account_number" placeholder="Account number">
                    <input class="form-control--mid form-control" type="text" v-model="form.routing_number" placeholder="Routing number">
                    <input class="form-control--mid form-control" type="text" v-model="form.account_holder_name" placeholder="Account holder name">

                    <select v-model="form.account_type" class="selectbox selectbox-mid w-100">
                        <option value="" disabled>Account type</option>
                        <option value="personal">Individual</option>
                        <option value="business">Company</option>
                    </select>
                </div>

                <div class="mb-2">
                    <b-row>
                        <b-col cols="6">
                            <multiselect
                                v-model="form.account_country"
                                placeholder="Country"
                                :options="countriesList"
                                select-label=""
                                deselect-label=""
                                label="name"
                                track-by="name"
                                class="multiselect-mid pl-2"
                            >
                            </multiselect>
                        </b-col>
                        <b-col cols="6">
                            <select v-model="form.currency" class="selectbox selectbox-mid w-100">
                                <option value="" disabled>Currency</option>
                            </select>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </template>

        <div class="form__item mb-3">
            <h5 class="heading5 heading5--secondary">Billing  Address</h5>

            <div class="mb-2">
                <input class="form-control--mid form-control" type="text" v-model="form.address_street" placeholder="Street address">
                <span class="modal-upgrade__error" v-if="typeof errors.address_street !== 'undefined'">{{errors.address_street}}</span>
            </div>

            <input class="form-control--mid form-control mb-2" type="text" v-model="form.address_line2" placeholder="Address line 2 (Optional)">

            <div class="form__item mb-2">
                <multiselect
                    v-model="addressCountry"
                    placeholder="Country"
                    :options="countriesList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                >
                </multiselect>

                <span class="modal-upgrade__error" v-if="typeof errors.addressCountry !== 'undefined'">{{errors.addressCountry}}</span>
            </div>

            <div class="form__item mb-2">
                <input class="form-control--mid form-control" type="text" v-model="form.address_city" placeholder="City">
                <span class="modal-upgrade__error" v-if="typeof errors.address_city !== 'undefined'">{{errors.address_city}}</span>
            </div>

            <b-row>
                <b-col cols="6" v-if="form.address_country && form.address_country == 1">
                    <div class="form__item">
                        <multiselect
                            v-model="addressState"
                            placeholder="State"
                            :options="statesList"
                            select-label=""
                            deselect-label=""
                            label="name"
                            track-by="name"
                            class="multiselect-mid pl-2"
                        >
                        </multiselect>

                        <span class="modal-upgrade__error" v-if="typeof errors.addressState !== 'undefined'">{{errors.addressState}}</span>
                    </div>
                </b-col>

                <b-col cols="6">
                    <input class="form-control--mid form-control" type="number" v-model="form.address_zip" placeholder="Zip">
                    <span class="modal-upgrade__error" v-if="typeof errors.address_zip !== 'undefined'">{{errors.address_zip}}</span>
                </b-col>
            </b-row>
        </div>

    </div>
</template>

<script>

import multiselect from "vue-multiselect";
import locations from "@/services/locations.service";
import subscriptions from "@/services/subscriptions.service";

const cardInputStyle = {
    base: {
        lineHeight: '36px',
    },
};

export default {
    components: {
        multiselect
    },

    data() {
        return {
            form: {
                payment_method: 'card',
                name_on_card: '',

                address_street: '',
                address_line2: '',
                address_country: 0,
                address_city: '',
                address_state: 0,
                address_zip: '',

                account_number: '',
                routing_number: '',
                account_holder_name: '',
                account_type: 'personal',
                account_country: '',
                currency: '',
                token: '',
            },

            addressCountry: null,
            addressState: null,

            cardNumber: '',
            cardCvc : '',
            cardExpiry: '',

            cardError: '',
            needPaymentData: true,
            cardInfo: '',

            countriesList: [],
            statesList: [],
            errors: {},
        }
    },

    watch: {
        addressCountry() {
            if (this.addressCountry) {
                this.form.address_country = this.addressCountry.id
            }
            else {
                this.form.address_country = 0
            }
        },

        addressState() {
            if (this.addressState) {
                this.form.address_state = this.addressState.id
            }
            else {
                this.form.address_state = 0
            }
        }
    },

    computed: {
        stripeElements () {
            return this.$stripe.elements();
        },
    },

    methods: {
        createForm() {

            if (typeof this.cardNumber == 'object') {
                this.cardNumber.destroy();
            }
            if (typeof this.cardExpiry == 'object') {
                this.cardExpiry.destroy();
            }
            if (typeof this.cardCvc == 'object') {
                this.cardCvc.destroy();
            }

            this.cardNumber = this.stripeElements.create('cardNumber', {classes: {base: 'form-control--mid form-control'}, placeholder: 'Card Number', style: cardInputStyle});
            this.cardNumber.mount('#card-number');

            this.cardExpiry = this.stripeElements.create('cardExpiry', {classes: {base: 'form-control--mid form-control'}, placeholder: 'Exp. MM/YY', style: cardInputStyle});
            this.cardExpiry.mount('#card-expiry');

            this.cardCvc = this.stripeElements.create('cardCvc', {classes: {base: 'form-control--mid form-control'}, placeholder: 'CVV', style: cardInputStyle});
            this.cardCvc.mount('#card-cvc');

            locations.getCountriesList().then(response => {
                this.countriesList = response.data
            })

            locations.getStatesList().then(response => {
                this.statesList = response.data
            })
        },

        apply() {

            this.$emit('start-loading')

            if (this.form.payment_method == 'card') {

                let cardData = {}
                this.errors = {}

                if (this.form.name_on_card.length) {
                    cardData.name = this.form.name_on_card
                }
                else {
                    this.errors.name_on_card = "Full name on card cannot be empty";
                }

                if (this.form.address_street.length) {
                    cardData.address_line1 = this.form.address_street
                }
                else {
                    this.errors.address_street = "Street address cannot be empty";
                }

                if (this.form.address_line2.length) {
                    cardData.address_line2 = this.form.address_line2
                }

                if (this.addressCountry) {
                    cardData.address_country = this.addressCountry.code

                    if (this.addressCountry.id == 1) {

                        if (this.addressState) {
                            cardData.address_state = this.addressState.name
                        }
                        else {
                            this.errors.addressState = "State cannot be empty";
                        }
                    }
                }
                else {
                    this.errors.addressCountry = "Country cannot be empty";
                }

                if (this.form.address_city.length) {
                    cardData.address_city = this.form.address_city
                }
                else {
                    this.errors.address_city = "City cannot be empty";
                }

                if (this.form.address_zip > 0) {
                    cardData.address_zip = this.form.address_zip.toString()
                }
                else {
                    this.errors.address_zip = "Zip cannot be empty";
                }

                if (! Object.keys(this.errors).length) {
                    this.createToken(cardData)
                }
                else {
                    this.$emit('stop-loading')
                }
            }
        },

        createToken (cardData) {
            this.cardError = ''

            this.$stripe.createToken(this.cardNumber, cardData).then(response => {
                this.$emit('stop-loading')

                if (response.error) {
                    this.cardError = response.error.message
                }
                else {
                    if (response.token.id) {
                        this.form.token = response.token.id
                        this.addPaymentMethod()
                    }
                    else {
                        this.cardError = "Error while generating token"
                    }
                }
            });
        },

        addPaymentMethod() {
            this.$emit('start-loading')

            subscriptions.addPaymentMethod(this.form).then(response => {
                if (response.data.errors.length) {
                    this.cardError = response.data.errors[0]
                    this.$emit('stop-loading')
                }
                else {
                    this.$emit('paymentMethodCreated', response.data.method_id)
                }
            })
        }
    }
}
</script>